import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL,{ Post, Delete, Get, Nama_DESA, JENIS_DESA } from '../../../function/Koneksi';
import Select from 'react-select';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmData = {
	nama : "",
	umur : "",
	jenis_kelamin : "",
	pendidikan : "",
	bidang : "",
	alamat : "",
	keterangan : "",
	uuid: '',
}

class MenuD4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dt: frmData,
			judul: 'Tambah Kader Pemberdayaan Masyarakat '+JENIS_DESA,
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				select: false
			},
			loading: false,
			loadingPreview: false,
			detailPreview: false,
			detailData: null,
			frmJenisKelamin: [],
			jenisKelaminStat: null,
			// Pagination
			pagination: [],
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			// Alert
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// Cari Data
			textCari: '',
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}
		Post("kader-pemberdayaan-masyarakat" + link, null, {
			q: this.state.textCari
		}, dtkat => {

			if (dtkat.data.results.data) {
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
	};

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();
		
		this.setState({ loadingPreview: true })

		if(this.state.jenisKelaminStat){
			let bodyData = {
				nama: document.getElementById("nama").value,
				umur: document.getElementById("umur").value,
				jenis_kelamin: this.state.jenisKelaminStat.value,
				pendidikan: document.getElementById("pendidikan").value,
				bidang: document.getElementById("bidang").value,
				alamat: document.getElementById("alamat").value,
				keterangan: document.getElementById("keterangan").value,
				uuid: document.getElementById("uuid").value,
			}
	
			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyData.uuid === "") {
				psn = "tambah";
				resstat = 201;
				bodyData.uuid = null;
			} else {
				psn = "ubah";
				resstat = 200;
				metode = "update";
			}
	
			Post('kader-pemberdayaan-masyarakat/' + metode, bodyData.uuid, bodyData, res => {
				if (res.status === resstat) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "success",
						basicTitle: "Data Kader Pemberdayaan Masyarakat",
						pesanAlert: "Berhasil " + psn + " Data",
						dt: frmData,
						status: {
							...this.state.status,
							form: false
						}
					});
				} else {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Kader Pemberdayaan Masyarakat",
						pesanAlert: "Gagal " + psn + " Data"
					});
				}
	
				this.componentDidMount();
			})
		}
		else{
			this.setState({
				loadingPreview: false,
				show: true,
				basicType: "danger",
				basicTitle: "Data Kader Pemberdayaan Masyarakat",
				pesanAlert: "Jenis Kelamin Wajib Diisi!"
			});
		}
	});
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		
		Delete('kader-pemberdayaan-masyarakat/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Kader Pemberdayaan Masyarakat',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Kader Pemberdayaan Masyarakat',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Kader Pemberdayaan Masyarakat",
			editMode: true
		})

		if (data) {
			this.setState({
				dt: {
					nama: data.nama,
					umur: data.umur,
					jenis_kelamin: data.jenis_kelamin,
					pendidikan: data.pendidikan,
					bidang: data.bidang,
					alamat: data.alamat,
					keterangan: data.keterangan,
					uuid: data.uuid,
				},	
				jenisKelaminStat: { value: data.jenis_kelamin, label: data.jenis_kelamin },
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	componentDidMount() {
		this.fetch()

		Get('select-enum/penduduk/jk', null, (data) => {
			let frmJenisKelamin = [];
			data.forEach((dt) => {
				frmJenisKelamin.push({ value: dt, label: dt });
			});
			this.setState({ frmJenisKelamin });
		});
	}

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jk':
					this.state.dt.jenis_kelamin = e.value;
					this.setState({ jenisKelaminStat: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'jk':
					this.state.dt.jenis_kelamin = 0;
					this.setState({ jenisKelaminStat: e });
					break;
				default:
					// no default
					break;
			}
		}

		this.state.status.select = false;
		this.forceUpdate();
	};

	
	// DATA DOWNLOAD LAPORAN
		downloadLaporan = () => {
			let link = API_URL + 'kader-pemberdayaan-masyarakat/cetak';
			let title = 'Buku Kader Pemberdayaan Masyarakat '+ JENIS_DESA +' '+ Nama_DESA
			fetch(link, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
				'Content-Type': 'application/pdf',
			},
			})
			.then((response) => response.blob())
			.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${title}.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
			this.setState({
				loading: false,
			})
			});
			// ==============

			this.forceUpdate();
		};

	// END DATA DOWNLOAD LAPORAN

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'nama',
				headerAlign: 'center',
				align: 'center',
				text: 'Nama',
				sort: false
			},
			{
				dataField: 'umur',
				headerAlign: 'center',
				align: 'center',
				text: 'Umur',
				sort: false
			},
			{
				dataField: 'jenis_kelamin',
				headerAlign: 'center',
				align: 'center',
				text: 'Jenis Kelamin',
				sort: false
			},
			{
				dataField: 'pendidikan',
				headerAlign: 'center',
				align: 'center',
				text: 'Pendidikan',
				sort: false
			},
			{
				dataField: 'bidang',
				headerAlign: 'center',
				align: 'center',
				text: 'Bidang',
				sort: false
			},
			{
				dataField: 'alamat',
				headerAlign: 'center',
				align: 'center',
				text: 'Alamat',
				sort: false
			},
			{
				dataField: 'keterangan',
				headerAlign: 'center',
				align: 'center',
				text: 'Keterangan',
				sort: false
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
							
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Peraturan Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													this.setState({
														loading: true,
													});
													this.downloadLaporan();
												}}
												>
													Cetak
											</Button>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Kader Pemberdayaan Masyarakat',
														dt: frmData,
														jenisKelaminStat: null,
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-md-12">
											{this.state.loading ? (
												<div className="row">
													<div className="col-sm-12" align="center">
														<img
															alt="loading"
															src={require("../../../assets/images/tes-loading.gif")}
															style={{
																borderRadius: "10px",
																width: '100px'
															}} />
													</div>
												</div>
											) : (
												<>
													<div className='row'>
														<div className="col-md-5 text-right">
															<input
																type="text"
																className="form-control"
																style={{ marginBottom: '15px' }}
																id="cariTable"
																placeholder="Cari berdasarkan nama kader"
																defaultValue={this.state.textCari}
																onChange={(e) => {
																	this.setState({ textCari: e.target.value })
																}}
																onKeyPress={this.cariData}
															/>
															<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
																<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
																	this.setState({
																		textCari: '',
																		loading: true
																	});
																	setTimeout(
																		function () {
																			this.fetch();
																		}.bind(this),
																		500
																	);
																	document.getElementById('cariTable').value = ''
																}} ></span>
															</div>
														</div>
														<div className="col-md-7" style={{ marginBottom: '15px' }}>
															<Button
																size="sm"
																color="info"
																className="btn-square"
																onClick={() => {
																	this.setState({
																		textCari: document.getElementById("cariTable").value,
																		loading: true
																	});
																	setTimeout(
																		function () {
																			this.fetch();
																		}.bind(this),
																		500
																	);
																}}
															>
																<i className="fa fa-search"></i>
															</Button>
														</div>
														{data.length > 0 ? (
															<>
																<div className="col-md-12">
																	<BootstrapTable
																		keyField="id"
																		data={data}
																		columns={columns}
																	/>
																	<div className="pull-right text-white">
																		{this.state.awal}
																		{this.state.sebelum}
																		{this.state.hal.map(dt => {
																			return dt;
																		})}
																		{this.state.setelah}
																		{this.state.akhir}
																	</div>
																</div>
															</>
														) : (
															<div className="col-md-12">
																<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
															</div>
														)}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Nama
									</Label>
									<input
										className="form-control"
										id="nama"
										type="text"
										defaultValue={this.state.dt.nama}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Umur
									</Label>
									<input
										className="form-control"
										id="umur"
										type="text"
										pattern="\d*"
										maxlength="3"
										defaultValue={this.state.dt.umur}
										required
									/>
								</Col>
							</FormGroup>	
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Jenis Kelamin
									</Label>
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'jk');
										}}
										defaultValue={this.state.jenisKelaminStat}
										value={this.state.jenisKelaminStat}
										name="Jenis Kelamin"
										options={this.state.frmJenisKelamin}
										placeholder="Pilih Jenis Kelamin"
										isClearable
									/>
								</Col>
							</FormGroup>	
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Pendidikan
									</Label>
									<input
										className="form-control"
										id="pendidikan"
										type="text"
										defaultValue={this.state.dt.pendidikan}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Bidang
									</Label>
									<input
										className="form-control"
										id="bidang"
										type="text"
										defaultValue={this.state.dt.bidang}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Alamat
									</Label>
									<input
										className="form-control"
										id="alamat"
										type="text"
										defaultValue={this.state.dt.alamat}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Keterangan
									</Label>
									<textarea
										className="form-control"
										id="keterangan"
										type="text"
										cols="30"
										defaultValue={this.state.dt.keterangan}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuD4;
