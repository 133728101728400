import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

// ** Import custom components **
import store from './store';
import App from './components/app';
// import ChartWidget from './components/widget/chart-widget';

// Custom
import Operator from './feature/operator';
import Role from './feature/role';
import Provinsi from './feature/provinsi';
import KabKota from './feature/kabkota';
import Kecamatan from './feature/kecamatan';
import Keldes from './feature/keldes';
import Dusun from './feature/dusun';
import RW from './feature/rw';
import RT from './feature/rt';
import Pekerjaan from './feature/pekerjaan';

import Agama from './feature/agama';

import JenjangPendidikan from './feature/jenjangpendidikan';
import Shdk from './feature/shdk';
import UkuranKertas from './feature/ukurankertas';
import JabatanPerangkat from './feature/jabatanperangkat';
import Penduduk from './feature/penduduk';
import PersyaratanSurat from './feature/persyaratansurat';
import PejabatPenandatangan from './feature/pejabatpenandatangan';
import DaftarAplikasi from './feature/daftaraplikasi';
import JenisSurat from './feature/jenis-surat';
import KartuKeluarga from './feature/kartu-keluarga';
import DataPotensi from './feature/data-potensi';
import Perangkat from './feature/perangkat';
import KonfigurasiKiosk from './feature/konfigurasi-kiosk';
import Pengguna from './feature/pengguna';
import Login from './feature/login';
// import Register from './feature/register';
// import ForgetPassword from './feature/forget-password';
// import ResetPassword from './feature/reset-password';
import Wilayah from './feature/wilayah/index';

// PENGATURAN
import LembagaMasyarakat from './feature/pengaturan/lembaga-masyarakat/index';
import Profile from './feature/pengaturan/profile-desa/index';
import Aplikasi from './feature/pengaturan/aplikasi/index';
import Absensi from './feature/pengaturan/absensi/index';
import PengaturanAplikasi from './feature/pengaturan/pengaturan-aplikasi';
// import PengaturanDesa from './feature/pengaturan/pengaturan-desa';
import StrukturOrganisasi from './feature/struktur-organisasi';
// import PengaturanAplikasi from './feature/pengaturan-aplikasi';
import TandaTangan from './feature/tandatangan';
import HariLibur from './feature/hari-libur';
import BannerDashboard from './feature/banner-dashboard';
import AlurDisposisi from './feature/alur-disposisi';
// Surat
import SuratUndangan from './feature/suratundangan';
import Suratskck from './feature/suratskck';
import Suratkehilangan from './feature/suratkehilangan';
import Suratbelummemilikirumah from './feature/suratbelummemilikirumah';
import SuratKeteranganPenghasilan from './feature/suratketeranganpenghasilan';
import Suratjalan from './feature/suratjalan';
import SuratKeteranganKematian from './feature/suratketerangankematian';
import SuratKeteranganPernahMenikah from './feature/suratketeranganpernahmenikah';
import SuratKeteranganKelahiran from './feature/suratketerangankelahiran';
import SuratKeteranganAhliWaris from './feature/suratketeranganahliwaris';
import SuratKeteranganDomisili from './feature/suratketerangandomisili';
import SuratKeteranganDomisiliUsaha from './feature/suratketerangandomisiliusaha';
import SuratKeteranganTidakMampu from './feature/suratketerangantidakmampu';
import SuratKeteranganKepemilikanRumah from './feature/suratketerangankepemilikanrumah';
import SuratKeterangan from './feature/suratketerangan';
import Suratbelumnikah from './feature/suratbelumnikah';
import Surattidaknikah from './feature/surattidaknikah';
import Berita from './feature/berita';
import SuratKeteranganPenyaksianTanah from './feature/suratketeranganpenyaksiantanah';

// Dashboards
import Dashboard from './feature/dashboard';
import Default from './feature/dashboard';
import Laporan from './feature/laporan';
import Catatan from './feature/catatan';
import NotifDesa from './feature/notif-desa';
import Banner from './feature/banner';
import Suku from './feature/suku';
import Datuak from './feature/datuak';
import PendudukSuku from './feature/penduduksuku';

// Layanan KUA
import DafarPelayananKUA from './feature/layanan/kua/kua';
import KUAPernikahan from './feature/layanan/kua/pernikahan';

// Layanan Disdukcapil
import DafarPelayananDisdukcapil from './feature/layanan/capil/disdukcapil';
import DisdukcapilKelahiran from './feature/layanan/capil/kelahiran';
import DisdukcapilKematian from './feature/layanan/capil/kematian';
import DisdukcapilPindahDatang from './feature/layanan/capil/pindah-datang';
import DisdukcapilKartuKeluarga from './feature/layanan/capil/kartu-keluarga';

// Coming Soon
import ComingSoonLayanan from './feature/layanan/coming_soon';

// Surat
import Suratpindahnikah from './feature/suratpindahnikah';
import Suratpindahdatangwni from './feature/suratketeranganpindahdatangwni';
import Suratizinkeramaian from './feature/suratizinkeramaian';
import Suratketeranganusaha from './feature/suratketeranganusaha';
import Suratkuasaskgr from './feature/suratkuasaskgr';
import Suratkuasa from './feature/suratkuasa';
// import Comingsoon from './components/pages/comingsoon';
// import Maintenance from './components/pages/maintenance';
// import Landing from './components/pages/landing';

import { DataToken } from './function/Format';
import Disposisi from './feature/disposisi';
import Verifikasi from './feature/verifikasi';
// perubahan data
import PerubahanNik from './feature/perubahan-data/nik-kk';
import PindahKK from './feature/perubahan-data/pindah-kk';
import Pengajuan from './feature/perubahan-data/pengajuan';
import Notifikasi from './feature/notifikasi';

// BANSOS
import MasterBansos from './feature/bansos/master-bansos';
import MasterKategoriBansos from './feature/bansos/master-kategori-bansos';
import DetailBansos from './feature/bansos/detail-bansos';
import DetailBansosPenduduk from './feature/bansos/detail-bansos-penduduk';

// KiosK
import Beranda from './kios/Beranda';
import LoginMasyarakat from './kios/LoginMasyarakat';
import Home from './kios/Home';
import Layanan from './kios/Layanan';
import Wisata from './feature/wisata';
import VerifikasiCode from './feature/verifikasi-code';
import DataMasterPotensi from './feature/data-master-potensi';
import LaporanPotensi from './feature/laporan-potensi';
import PenomoranSurat from './feature/nomor-surat';
import EditProfil from './feature/edit-profil';
import HomeKecamatan from './feature/layanan/kecamatan/home';
import KecamatanPindahDatang from './feature/layanan/kecamatan/pindah-datang';
import KecamatanRekomendasiUsaha from './feature/layanan/kecamatan/rekomendasi-usaha';
import KecamatanIUMK from './feature/layanan/kecamatan/iumk';
import JoinUs from './feature/layanan/join-us';
import Expired from './feature/layanan/expired';
import BelumSetting from './feature/layanan/belum-setting';
import StatistikBansos from './feature/bansos/statistik-bansos';
import JamKerja from './feature/jam-kerja';
import PenghasilanOrtu from './feature/suratketeranganpenghasilanortu';
import SuratKeteranganPenguburan from './feature/suratketeranganpenguburan';
import RekapAbsensi from './feature/rekap-absensi';
import RekapAbsensiDetail from './feature/rekap-absensi-detail';
import MenuSPPD from './feature/suratperintahperjalanandinas';
import MenuSPT from './feature/suratperintahtugas';
import IzinMendirikanBangunan from './feature/suratizinmendirikanbangunan';
import KeteranganBedaNama from './feature/suratketeranganbedanama';
import KeteranganTidakSengketa from './feature/suratketerangantidaksengketa';
import Buku from './feature/buku';
import BukuAdministrasiUmum from './feature/buku/administrasi-umum';
import MenuA1 from './feature/buku/administrasi-umum/A1';
import MenuA2 from './feature/buku/administrasi-umum/A2';
import MenuA3 from './feature/buku/administrasi-umum/A3';
import MenuA4 from './feature/buku/administrasi-umum/A4';
import MenuA5 from './feature/buku/administrasi-umum/A5';
import MenuA6 from './feature/buku/administrasi-umum/A6';
import MenuA7 from './feature/buku/administrasi-umum/A7';
import MenuA8 from './feature/buku/administrasi-umum/A8';
import MenuA9 from './feature/buku/administrasi-umum/A9';
import BukuAdministrasiPenduduk from './feature/buku/administrasi-penduduk';
import MenuB1 from './feature/buku/administrasi-penduduk/B1';
import BukuAdministrasiKeuangan from './feature/buku/administrasi-keuangan';
import MenuC4 from './feature/buku/administrasi-keuangan/C4';
import MenuC5 from './feature/buku/administrasi-keuangan/C5';
import MenuC6 from './feature/buku/administrasi-keuangan/C6';
import MenuC3 from './feature/buku/administrasi-keuangan/C3';
import MenuC3Detail from './feature/buku/administrasi-keuangan/C3-detail';
import MenuC2 from './feature/buku/administrasi-keuangan/C2';
import MenuC2Detail from './feature/buku/administrasi-keuangan/C2-detail';
import MenuC1 from './feature/buku/administrasi-keuangan/C1';
import MenuC1Detail from './feature/buku/administrasi-keuangan/C1-detail';
import BukuAdministrasiPembangunan from './feature/buku/administrasi-pembangunan';
import MenuD1 from './feature/buku/administrasi-pembangunan/D1';
import MenuD2 from './feature/buku/administrasi-pembangunan/D2';
import MenuD3 from './feature/buku/administrasi-pembangunan/D3';
import MenuD4 from './feature/buku/administrasi-pembangunan/D4';
import MenuB2 from './feature/buku/administrasi-penduduk/B2';
import MenuB3 from './feature/buku/administrasi-penduduk/B3';
import MenuB4 from './feature/buku/administrasi-penduduk/B4';
import MenuB5 from './feature/buku/administrasi-penduduk/B5';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

// private route n public route
// Semua role kecuali kiosk
const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				let token = DataToken(sessionStorage.getItem('access_token'));
				if (token.sub.data_role.nama_role === 'Admin KiosK') {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location }
							}}
						/>
						// <Redirect
						// 	to={{
						// 		pathname: '/beranda',
						// 		state: { from: props.location }
						// 	}}
						// />
					);
				} else {
					return <Component {...props} />;
				}
			} else {
				// localStorage.clear();
				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				);
			}
		}}
	/>
);

// Khusus Admin
const AdminRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				let token = DataToken(sessionStorage.getItem('access_token'));
				if (token.sub.data_role.nama_role.toLowerCase() !== 'admin') {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location }
							}}
						/>
					);
				} else {
					return <Component {...props} />;
				}
			} else {
				// localStorage.clear();
				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				);
			}
		}}
	/>
);

// Superoperator & Superadmin
const OperatorRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				let token = DataToken(sessionStorage.getItem('access_token'));
				let role = token.sub.data_role.nama_role.toLowerCase()
				console.log(role);
				if (role === 'admin' || role === 'admin kiosk') {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location }
							}}
						/>
					);
				} else {
					return <Component {...props} />;
				}
			} else {
				// localStorage.clear();
				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				);
			}
		}}
	/>
);

const KioskRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			sessionStorage.getItem('access_token') ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>
			)}
	/>
);

// Route untuk public, tidak perlu login bisa akses route ini
const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				let token = DataToken(sessionStorage.getItem('access_token'));
				if (token.sub.data_role.nama_role === 'Admin KiosK') {
					return (
						<Redirect
							to={{
								pathname: '/beranda',
								state: { from: props.location }
							}}
						/>
					);
				} else {
					return (
						<Redirect
							to={{
								pathname: '/admin/dashboard',
								state: { from: props.location }
							}}
						/>
					);
				}
			} else {
				return <Component {...props} />;

				// return (
				// 	<Redirect
				// 		to={{
				// 			pathname: '/admin/dashboard',
				// 			state: { from: props.location }
				// 		}}
				// 	/>
				// );
				// sessionStorage.getItem('access_token') ? (
				// 	<Redirect
				// 		to={{
				// 			pathname: '/admin/dashboard',
				// 			state: { from: props.location }
				// 		}}
				// 	/>
				// ) : (
				// 	<Component {...props} /> // apa maksudnya ... props ?
				// );
			}
		}}
	/>
);

// Khusus Superadmin
const SuperRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				let token = DataToken(sessionStorage.getItem('access_token'));
				let role = token.sub.data_role.nama_role.toLowerCase()
				console.log(role);
				if (role !== 'super admin') {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location }
							}}
						/>
					);
				} else {
					return <Component {...props} />;
				}
			} else {
				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				);
			}
		}}
	/>
);

class Root extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter basename={'/'}>
					<ScrollContext>
						<Switch>
							<PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />

							<PublicRoute path={`${process.env.PUBLIC_URL}/login`} component={Login} />

							<PublicRoute
								path={`${process.env.PUBLIC_URL}/verifikasi/:jenis/:id`}
								component={VerifikasiCode}
							/>
							{/* 
							<PublicRoute path={`${process.env.PUBLIC_URL}/register`} component={Register} />
							<PublicRoute
								path={`${process.env.PUBLIC_URL}/forget-password`}
								component={ForgetPassword}
							/>
							<PublicRoute
								path={`${process.env.PUBLIC_URL}/reset-password/:token`}
								component={ResetPassword}
							/>
							<Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
							<Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
							<Route path={`${process.env.PUBLIC_URL}/pages/Landing`} component={Landing} /> */}

							{/* kiosk */}
							<KioskRoute path={`${process.env.PUBLIC_URL}/beranda`} component={Beranda} />
							<KioskRoute
								path={`${process.env.PUBLIC_URL}/login-masyarakat`}
								component={LoginMasyarakat}
							/>
							<KioskRoute path={`${process.env.PUBLIC_URL}/home-masyarakat`} component={Home} />
							{/* <KioskRoute path={`${process.env.PUBLIC_URL}/home-masyarakat`} render={ props => <Home {...props} />}  /> */}

							<KioskRoute path={`${process.env.PUBLIC_URL}/layanan-masyarakat`} component={Layanan} />

							{/* end kios k */}

							<App>
								{
									<PrivateRoute
										path={`${process.env.PUBLIC_URL}/dashboard/default`}
										component={Default}
									/>
								}

								{/* ALL perangkat */}
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/dashboard`}
									component={Dashboard}
								/>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/edit-profil`} component={EditProfil} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/penduduk`} component={Penduduk} />
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/kartu-keluarga`}
									component={KartuKeluarga}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/coming_soon`}
									component={ComingSoonLayanan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/laporan-potensi`}
									component={LaporanPotensi}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/join-us`}
									component={JoinUs}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/expired`}
									component={Expired}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/belum-setting`}
									component={BelumSetting}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/data-potensi`}
									component={DataPotensi}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku`}
									component={Buku}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-umum`}
									component={BukuAdministrasiUmum}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-keuangan`}
									component={BukuAdministrasiKeuangan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a1`}
									component={MenuA1}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a2`}
									component={MenuA2}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a3`}
									component={MenuA3}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a4`}
									component={MenuA4}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a5`}
									component={MenuA5}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a6`}
									component={MenuA6}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a7`}
									component={MenuA7}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a8`}
									component={MenuA8}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-a9`}
									component={MenuA9}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-penduduk`}
									component={BukuAdministrasiPenduduk}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-b1`}
									component={MenuB1}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-b2`}
									component={MenuB2}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-b3`}
									component={MenuB3}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-b4`}
									component={MenuB4}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-b5`}
									component={MenuB5}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-c1`}
									component={MenuC1}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/c1/detail`}
									component={MenuC1Detail}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-c2`}
									component={MenuC2}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/c2/detail`}
									component={MenuC2Detail}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-c3`}
									component={MenuC3}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/c3/detail`}
									component={MenuC3Detail}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-c4`}
									component={MenuC4}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-c5`}
									component={MenuC5}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-c6`}
									component={MenuC6}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-pembangunan`}
									component={BukuAdministrasiPembangunan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-d1`}
									component={MenuD1}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-d2`}
									component={MenuD2}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-d3`}
									component={MenuD3}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/buku-d4`}
									component={MenuD4}
								/>
								{/* ALL perangkat */}

								{/* ADMIN ONLY */}
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/verifikasi`}
									component={Verifikasi}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/disposisi`}
									component={Disposisi}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-belum-menikah`}
									component={Suratbelumnikah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-tidak-menikah`}
									component={Surattidaknikah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-penyaksian-tanah`}
									component={SuratKeteranganPenyaksianTanah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-undangan`}
									component={SuratUndangan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pengantar-skck`}
									component={Suratskck}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-kehilangan`}
									component={Suratkehilangan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-belum-memiliki-rumah`}
									component={Suratbelummemilikirumah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-jalan`}
									component={Suratjalan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pengantar-pindah-nikah`}
									component={Suratpindahnikah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-pindah-datang-wni`}
									component={Suratpindahdatangwni}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-izin-keramaian`}
									component={Suratizinkeramaian}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-izin-mendirikan-bangunan`}
									component={IzinMendirikanBangunan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-beda-nama`}
									component={KeteranganBedaNama}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-tidak-sengketa`}
									component={KeteranganTidakSengketa}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-usaha`}
									component={Suratketeranganusaha}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-kuasa-skgr`}
									component={Suratkuasaskgr}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-kuasa`}
									component={Suratkuasa}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-penghasilan`}
									component={SuratKeteranganPenghasilan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-kematian`}
									component={SuratKeteranganKematian}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-pernah-menikah`}
									component={SuratKeteranganPernahMenikah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-kelahiran`}
									component={SuratKeteranganKelahiran}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-penghasilan-orang-tua`}
									component={PenghasilanOrtu}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-penguburan`}
									component={SuratKeteranganPenguburan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-ahli-waris`}
									component={SuratKeteranganAhliWaris}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-domisili`}
									component={SuratKeteranganDomisili}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-domisili-usaha`}
									component={SuratKeteranganDomisiliUsaha}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-tidak-mampu`}
									component={SuratKeteranganTidakMampu}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-kepemilikan-rumah`}
									component={SuratKeteranganKepemilikanRumah}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan`}
									component={SuratKeterangan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-perintah-perjalanan-dinas`}
									component={MenuSPPD}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-perintah-tugas`}
									component={MenuSPT}
								/>
								<AdminRoute path={`${process.env.PUBLIC_URL}/admin/laporan`} component={Laporan} />
								<AdminRoute path={`${process.env.PUBLIC_URL}/admin/catatan`} component={Catatan} />
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/kua/kua`}
									component={DafarPelayananKUA}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/kua/pernikahan`}
									component={KUAPernikahan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/capil/disdukcapil`}
									component={DafarPelayananDisdukcapil}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/capil/kelahiran`}
									component={DisdukcapilKelahiran}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/capil/kematian`}
									component={DisdukcapilKematian}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/capil/pindah-datang`}
									component={DisdukcapilPindahDatang}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/capil/kartu-keluarga`}
									component={DisdukcapilKartuKeluarga}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/kecamatan/beranda`}
									component={HomeKecamatan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/kecamatan/pindah-datang`}
									component={KecamatanPindahDatang}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/kecamatan/rekomendasi-usaha`}
									component={KecamatanRekomendasiUsaha}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/layanan/kecamatan/iumk`}
									component={KecamatanIUMK}
								/>
								<AdminRoute path={`${process.env.PUBLIC_URL}/admin/berita`} component={Berita} />
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/perubahan-data/nik-kk`}
									component={PerubahanNik}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/perubahan-data/pindah-kk`}
									component={PindahKK}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/perubahan-data/pengajuan`}
									component={Pengajuan}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/bansos/master-kategori-bansos`}
									component={MasterKategoriBansos}
								/>
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/bansos/master-bansos`}
									component={MasterBansos}
								/>
								<AdminRoute 
									path={`${process.env.PUBLIC_URL}/admin/bansos/detail-bansos`} 
									component={DetailBansos} />
								<AdminRoute 
									path={`${process.env.PUBLIC_URL}/admin/bansos/statistik`} 
									component={StatistikBansos} />
								<AdminRoute 
									path={`${process.env.PUBLIC_URL}/admin/bansos/detail-bansos-penduduk`} 
									component={DetailBansosPenduduk} />
								<AdminRoute
									path={`${process.env.PUBLIC_URL}/admin/notifikasi`}
									component={Notifikasi}/>
								<AdminRoute path={`${process.env.PUBLIC_URL}/admin/wisata`} component={Wisata} />
								{/* ADMIN ONLY */}

								{/* Superoperator & superadmin */}
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/perangkat`}
									component={Perangkat}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/konfigurasi-kiosk`}
									component={KonfigurasiKiosk}
								/>
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/pengguna`} component={Pengguna} />
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/lembaga-masyarakat`} component={LembagaMasyarakat} />
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/nomor-surat`}
									component={PenomoranSurat}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/tanda-tangan`}
									component={TandaTangan}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/hari-libur`}
									component={HariLibur}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/jam-kerja`}
									component={JamKerja}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/rekap-absensi`}
									component={RekapAbsensi}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/rekap-absensi-detail`}
									component={RekapAbsensiDetail}
								/>
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/dusun`} component={Dusun} />
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/jabatan-perangkat`}
									component={JabatanPerangkat}
								/>
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/rw`} component={RW} />
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/rt`} component={RT} />
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/wilayah`}
									component={Wilayah}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/profile-desa`}
									component={Profile}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/profile-kelurahan`}
									component={Profile}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/aplikasi`}
									component={Aplikasi}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/absensi`}
									component={Absensi}
								/>
								{/* <OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/pengaturan-desa`}
									component={PengaturanDesa}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/pengaturan-kelurahan`}
									component={PengaturanDesa}
								/> */}
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/struktur-organisasi-desa`}
									component={StrukturOrganisasi}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/struktur-organisasi-kelurahan`}
									component={StrukturOrganisasi}
								/>
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/banner-dashboard`}
									component={BannerDashboard}
								/>
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/banner`} component={Banner} />
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/suku`} component={Suku} />
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/datuak`} component={Datuak} />
								<OperatorRoute
									path={`${process.env.PUBLIC_URL}/admin/penduduksuku`}
									component={PendudukSuku}
								/>
								<OperatorRoute path={`${process.env.PUBLIC_URL}/admin/operator`} component={Operator} />
								{/* Superoperator & superadmin */}

								{/* SUPERADMIN ONLY */}
								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/jenis-surat`}
									component={JenisSurat}
								/>
								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/alur-disposisi/:idalur`}
									component={AlurDisposisi}
								/>
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/operator`} component={Operator} /> */}
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/notif-desa`} component={NotifDesa} />
								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/pengaturan-aplikasi`}
									component={PengaturanAplikasi}
								/>
								{/* SUPERADMIN ONLY */}

								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/role`} component={Role} /> */}
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/provinsi`} component={Provinsi} /> */}
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/kabkota`} component={KabKota} /> */}
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/kecamatan`} component={Kecamatan} /> */}
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/keldes`} component={Keldes} /> */}

								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/pekerjaan`} component={Pekerjaan} /> */}
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/agama`} component={Agama} /> */}
								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/shdk`} component={Shdk} /> */}
								{/* <SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/jenjang-pendidikan`}
									component={JenjangPendidikan}
								/> */}
								{/* <SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/ukuran-kertas`}
									component={UkuranKertas}
								/> */}

								{/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/persyaratan-surat`}
									component={PersyaratanSurat}
								/> */}
								{/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/pejabat-penandatangan`}
									component={PejabatPenandatangan}
								/> */}
								{/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/daftar-aplikasi`}
									component={DaftarAplikasi}
								/> */}

								{/* <SuperRoute path={`${process.env.PUBLIC_URL}/admin/perangkat`} component={Perangkat} /> */}

								{/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/data-master-potensi`}
									component={DataMasterPotensi}
								/> */}
								
								{/* Tidak ada beda dengan profile-desa */}
								{/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/profile-kelurahan`}
									component={Profile}
								/> */}
							</App>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
