import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL, { Post, Delete, Get, JENIS_DESA, } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Datetime from 'react-datetime';

import ReactSelect from 'react-select';

const frmDex = {
	uuid: '',
	nama_pemilik: "",
	luas: "",
	hak_milik: "0",
	hak_guna_bangunan: "0",
	hak_pakai: "0",
	hak_guna_usaha: "0",
	hak_pengelolaan: "0",
	hak_milik_adat: "0",
	hak_verponding_indonesia: "0",
	tanah_negara: "0",
	perumahan: "0",
	perdagangan_dan_jasa: "0",
	perkantoran: "0",
	industri: "0",
	fasilitas_umum: "0",
	sawah: "0",
	tegal: "0",
	perkebunan: "0",
	peternakan_perikanan: "0",
	hutan_belukar: "0",
	hutan_lebat: "0",
	mutasi_tanah: "",
	tanah_kosong: "0",
	lain_lain: "0",
	keterangan: '',
}

const dateFormat = require('dateformat');

class MenuA6 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			loading: false,
			loadingPreview: false,
			imagePreview: null,
			detailPreview: false,
			detailData: null,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDex,
			judul: 'Tambah Tanah di ' + JENIS_DESA,
			textCari: '',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			editMode: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}

		// if (this.state.textCari === '') {
		// 	Get("tanah-desa" + link, null, dtkat => {
		// 		// console.log(dtkat);

		// 		if (dtkat.results) {
		// 			this.setState({
		// 				data: dtkat.results.data,
		// 				pagination: dtkat.results,
		// 				loading: false
		// 			})
		// 			this.pagination(dtkat.results);
		// 		}

		// 	});
		// } else { // Jika pencarian
		Post("tanah-desa" + link, null, {
			q: this.state.textCari
		}, dtkat => {
			if (dtkat.data.results) {
				console.log('masuk');
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
		// }

	};

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();
		this.setState({ loadingPreview: true })

		let bodyData = this.state.dt

		let psn = "";
		let resstat = 204;
		let metode = "create";
		if (bodyData.uuid === "") {
			psn = "tambah";
			resstat = 201;
			bodyData.uuid = null;
		} else {
			psn = "ubah";
			resstat = 200;
			metode = "update";
		}

		console.log(bodyData);

		Post('tanah-desa/' + metode, bodyData.uuid, bodyData, res => {
			// console.log(res);
			if (res.status === resstat) {
				this.setState({
					loadingPreview: false,
					show: true,
					basicType: "success",
					basicTitle: "Data Tanah di " + JENIS_DESA,
					pesanAlert: "Berhasil " + psn + " Data"
				});

				document.getElementById('form-data').reset()
				this.fetch();
				this.setState({
					dt: frmDex,
					status: {
						...this.state.status,
						form: false
					}
				})
			} else {
				this.setState({
					loadingPreview: false,
					show: true,
					basicType: "danger",
					basicTitle: "Data Tanah di " + JENIS_DESA,
					pesanAlert: "Gagal " + psn + " Data"
				});
			}
		})

	});
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Tanah di " + JENIS_DESA,
			editMode: true
		})

		if (data) {
			this.setState({
				dt: data,
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	cariData = (e) => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	};

	downloadSurat = () => {
		let token = sessionStorage.getItem("access_token")
			? sessionStorage.getItem("access_token")
			: "public";

		fetch(API_URL + 'tanah-desa/cetak', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer ' + token,
			},
		})
		// .then((response) => response.blob())
		.then((response) => {
			if (response.status == 200) {
				return response.blob()
			}
			this.setState({
				loading: false,
				show: true,
				basicType: 'danger',
				basicTitle: 'Data Tanah di ' + JENIS_DESA,
				pesanAlert: 'Gagal cetak surat'
			});
			throw new Error('Something went wrong');
		})
		.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Tanah di ${JENIS_DESA}.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
			this.setState({
				loading: false,
			})
		})
		.catch((e) => {
			// console.log(e);
		})
		;
	}

	componentDidMount() {
		this.fetch()
	}

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'nama_pemilik',
				text: 'NAMA PERORANGAN/ BADAN HUKUM',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'luas',
				text: 'JML (m)',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			// {
			// 	dataField: 'hak_milik',
			// 	text: 'HM (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hak_guna_bangunan',
			// 	text: 'HGB (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hak_pakai',
			// 	text: 'HP (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hak_guna_usaha',
			// 	text: 'HGU (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hak_pengelolaan',
			// 	text: 'HPL (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hak_milik_adat',
			// 	text: 'MA (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hak_verponding_indonesia',
			// 	text: 'VI (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'tanah_negara',
			// 	text: 'TN (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'perumahan',
				text: 'SUDAH BERSERTIFIKAT (m)',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: function (cell, row) {
					let sudah_bersertifikat = row.hak_milik + row.hak_guna_bangunan + row.hak_pakai + row.hak_guna_usaha + row.hak_pengelolaan
					return sudah_bersertifikat
				}
			},
			{
				dataField: 'perumahan',
				text: 'BELUM BERSERTIFIKAT (m)',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: function (cell, row) {
					let belum_bersertifikat = row.hak_milik_adat + row.hak_verponding_indonesia + row.tanah_negara;
					return belum_bersertifikat
				}
			},
			{
				dataField: 'perumahan',
				text: 'NON-PERTANIAN (m)',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: function (cell, row) {
					let non_pertanian = row.perumahan + row.perdagangan_dan_jasa + row.perkantoran + row.industri + row.fasilitas_umum
					return non_pertanian
				}
			},
			{
				dataField: 'perumahan',
				text: 'PERTANIAN (m)',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: function (cell, row) {
					let pertanian = row.sawah + row.tegal + row.perkebunan + row.peternakan_perikanan + row.hutan_belukar + row.hutan_lebat + row.tanah_kosong + row.lain_lain + row.mutasi_tanah
					return pertanian
				}
			},
			// {
			// 	dataField: 'perdagangan_dan_jasa',
			// 	text: 'PERDAGANGAN/ JASA (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'perkantoran',
			// 	text: 'PERKANTORAN (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'industri',
			// 	text: 'INDUSTRI (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'fasilitas_umum',
			// 	text: 'FASILITAS UMUM (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'sawah',
			// 	text: 'SAWAH (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'tegal',
			// 	text: 'TEGALAN (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'perkebunan',
			// 	text: 'PERKEBUNAN (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'peternakan_perikanan',
			// 	text: 'PETERNAKAN/ PERIKANAN (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hutan_belukar',
			// 	text: 'HUTAN BELUKAR (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'hutan_lebat',
			// 	text: 'HUTAN LEBAT/ LINDUNG (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'mutasi_tanah',
			// 	text: 'MUTASI TANAH DI ' + JENIS_DESA,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'tanah_kosong',
			// 	text: 'TANAH KOSONG (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'lain_lain',
			// 	text: 'LAIN-LAIN (m)',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			// {
			// 	dataField: 'keterangan',
			// 	text: 'KET.',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div style={{ minWidth: "61px" }}>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="info"
								className="btn-icon mr-2"
								onClick={() => {
									this.setState({
										detailPreview: true,
										detailData: row
									})
								}}
							>
								<i className="fa fa-eye"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="danger"
								className="btn-icon"
								onClick={() => {
									this.setState({
										alert: (
											<SweetAlert
												showCancel
												confirmBtnText="Hapus"
												confirmBtnBsStyle="danger"
												cancelBtnBsStyle="success"
												type="danger"
												title="Yakin ingin hapus data ?"
												onCancel={this.hideAlert}
												onConfirm={() => {
													Delete('tanah-desa/delete', row.uuid, (res) => {
														if (res === 200) {
															this.setState({
																show: true,
																basicType: 'success',
																basicTitle: 'Data Tanah di ' + JENIS_DESA,
																pesanAlert: 'Berhasil hapus data'
															});
															this.fetch()
														} else {
															this.setState({
																show: true,
																basicType: 'danger',
																basicTitle: 'Data Tanah di ' + JENIS_DESA,
																pesanAlert: 'Gagal hapus data'
															});
														}
														this.setState({ alert: null })
													})
												}}
											>
												Proses ini tidak dapat dibatalkan!
											</SweetAlert>
										)
									});
								}}
								disabled={this.state.status.btnAksi}
							>
								<i className="fa fa-trash"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Peraturan Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													this.setState({ loading: true })
													this.downloadSurat()
												}}
											>
												Cetak
											</Button>{" "}
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Tanah di ' + JENIS_DESA,
														dt: frmDex,
														tmpImages: ['kosong'],
														editMode: false,
														currentLocation: this.state.curStaticLocation
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react custom-font-sm">
									{/* <div className="row">
										<div className="col-md-12"> */}
									{this.state.loading ? (
										<div className="row">
											<div className="col-sm-12" align="center">
												<img
													alt="loading"
													src={require("../../../assets/images/tes-loading.gif")}
													style={{
														borderRadius: "10px",
														width: '100px'
													}} />
											</div>
										</div>
									) : (
										<div className='row'>
											<div className="col-md-5 text-right">
												<input
													type="text"
													className="form-control"
													style={{ marginBottom: '15px' }}
													id="cariTable"
													placeholder="Cari berdasarkan nama pemilik"
													defaultValue={this.state.textCari}
													onChange={(e) => {
														this.setState({ textCari: e.target.value })
													}}
													onKeyPress={this.cariData}
												/>
												<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
													<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
														this.setState({
															textCari: '',
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
														document.getElementById('cariTable').value = ''
													}} ></span>
												</div>
											</div>
											<div className="col-md-7" style={{ marginBottom: '15px' }}>
												<Button
													size="sm"
													color="info"
													className="btn-square"
													onClick={() => {
														this.setState({
															textCari: document.getElementById("cariTable").value,
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
													}}
												>
													<i className="fa fa-search"></i>
												</Button>
											</div>
											{data.length > 0 ? (
												<>
													<div className="col-md-12">
														<BootstrapTable
															keyField="id"
															data={data}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal}
															{this.state.sebelum}
															{this.state.hal.map(dt => {
																return dt;
															})}
															{this.state.setelah}
															{this.state.akhir}
														</div>
													</div>
												</>
											) : (
												<div className="col-md-12">
													<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
												</div>
											)}
										</div>
									)}
									{/* </div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Detail Data Tanah di {JENIS_DESA}</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Nama Perorangan / Badan Hukum</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nama_pemilik}</span></td>
													</tr>
													<tr>
														<th width="280">Jumlah Luas Tanah (M<sup>2</sup>)</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.luas}</span></td>
													</tr>

													<tr style={{ borderTop: "1px solid black" }}>
														<th><b>Status Hak Tanah (M<sup>2</sup>)</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th><b>a. Sudah Bersertifikat</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th className='pl-4'>Hak Milik</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hak_milik}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hak Guna Bangunan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hgb}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hak Pakai</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hak_pakai}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hak Guna Usaha</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hak_guna_usaha}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hak Pengelolaan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hak_pengelolaan}</span></td>
													</tr>
													<tr>
														<th><b>b. Belum Bersertifikat</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th className='pl-4'>Milik Adat</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hak_milik_adat}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hak Verponding Indonesia (milik pribumi)</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hak_verponding_indonesia}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Tanah Negara</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tanah_negara}</span></td>
													</tr>

													<tr style={{ borderTop: "1px solid black" }}>
														<th><b>Penggunaan Tanah (M<sup>2</sup>)</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th><b>a. Non Pertanian</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th className='pl-4'>Perumahan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.perumahan}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Perdaganan dan Jasa</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.perdagangan_dan_jasa}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Perkantoran</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.perkantoran}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Industri</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.industri}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Fasilitas Umum</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.fasilitas_umum}</span></td>
													</tr>
													<tr>
														<th><b>b. Pertanian</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th className='pl-4'>Sawah</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.sawah}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Tegalan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tegal}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Perkebunan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.perkebunan}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Peternakan / Perikanan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.peternakan_perikanan}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hutan Belukar</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hutan_belukar}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Hutan Lebat / Lindung</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.hutan_lebat}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Tanah Kosong</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tanah_kosong}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Mutasi Tanah di {JENIS_DESA}</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.mutasi_tanah}</span></td>
													</tr>
													<tr>
														<th className='pl-4'>Lain-lain</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.lain_lain}</span></td>
													</tr>

													<tr style={{ borderTop: "1px solid black" }}>
														<th>Keterangan Tambahan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.keterangan}</span></td>
													</tr>
												</tbody>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}

							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Nama Pemilik</Label>
									<input
										className="form-control"
										id="nama_pemilik"
										type="text"
										placeholder={"Masukkan nama pemilik tanah"}
										defaultValue={this.state.dt.nama_pemilik}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													nama_pemilik: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Total Luas Tanah (m<sup>2</sup>)</Label>
									<input
										className="form-control"
										id="luas"
										type="number"
										min={0}
										placeholder={"Masukkan jumlah atau total luas tanah. Misal : 7000"}
										defaultValue={this.state.dt.luas}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													luas: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>STATUS HAK TANAH (m<sup>2</sup>)</b>
								</Label>
							</FormGroup>
							<FormGroup className="row mb-1">
								<Label
									className="col-sm-12 col-form-label"
								>
									<b>a. Sudah bersertifikat</b>
								</Label>
							</FormGroup>
							<div className="form-row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak Milik</Label>
									<input
										className="form-control"
										id="hak_milik"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_milik}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_milik: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak Guna Bangunan</Label>
									<input
										className="form-control"
										id="hak_guna_bangunan"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_guna_bangunan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_guna_bangunan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak Pakai</Label>
									<input
										className="form-control"
										id="hak_pakai"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_pakai}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_pakai: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak Guna Usaha</Label>
									<input
										className="form-control"
										id="hak_guna_usaha"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_guna_usaha}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_guna_usaha: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak Pengelolaan</Label>
									<input
										className="form-control"
										id="hak_pengelolaan"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_pengelolaan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_pengelolaan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
							</div>
							<FormGroup className="row mb-1">
								<Label
									className="col-sm-6 col-form-label"
								>
									<b>b. Belum bersertifikat</b>
								</Label>
							</FormGroup>
							<div className="form-row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak Milik Adat</Label>
									<input
										className="form-control"
										id="hak_milik_adat"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_milik_adat}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_milik_adat: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hak VI / Milik Pribumi</Label>
									<input
										className="form-control"
										id="hak_verponding_indonesia"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hak_verponding_indonesia}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hak_verponding_indonesia: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tanah Negara</Label>
									<input
										className="form-control"
										id="tanah_negara"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.tanah_negara}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													tanah_negara: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>PENGGUNAAN TANAH (m<sup>2</sup>)</b>
								</Label>
							</FormGroup>
							<FormGroup className="row mb-1">
								<Label
									className="col-sm-6 col-form-label"
								>
									<b>a. Non Pertanian</b>
								</Label>
							</FormGroup>
							<div className="form-row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Perumahan</Label>
									<input
										className="form-control"
										id="perumahan"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.perumahan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													perumahan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Perdaganan dan Jasa</Label>
									<input
										className="form-control"
										id="perdagangan_dan_jasa"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.perdagangan_dan_jasa}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													perdagangan_dan_jasa: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Perkantoran</Label>
									<input
										className="form-control"
										id="perkantoran"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.perkantoran}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													perkantoran: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Industri</Label>
									<input
										className="form-control"
										id="industri"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.industri}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													industri: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Fasilitas Umum</Label>
									<input
										className="form-control"
										id="fasilitas_umum"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.fasilitas_umum}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													fasilitas_umum: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
							</div>
							<FormGroup className="row mb-1">
								<Label
									className="col-sm-6 col-form-label"
								>
									<b>b. Pertanian</b>
								</Label>
							</FormGroup>
							<div className="form-row mb-2">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Sawah</Label>
									<input
										className="form-control"
										id="sawah"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.sawah}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													sawah: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tegalan</Label>
									<input
										className="form-control"
										id="tegal"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.tegal}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													tegal: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Perkebunan</Label>
									<input
										className="form-control"
										id="perkebunan"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.perkebunan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													perkebunan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Peternakan/ Perikanan</Label>
									<input
										className="form-control"
										id="peternakan_perikanan"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.peternakan_perikanan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													peternakan_perikanan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hutan Belukar</Label>
									<input
										className="form-control"
										id="hutan_belukar"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hutan_belukar}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hutan_belukar: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Hutan Lebat/ Lindung</Label>
									<input
										className="form-control"
										id="hutan_lebat"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.hutan_lebat}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													hutan_lebat: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tanah Kosong</Label>
									<input
										className="form-control"
										id="tanah_kosong"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.tanah_kosong}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													tanah_kosong: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Lain-lain</Label>
									<input
										className="form-control"
										id="lain_lain"
										type="number"
										min={0}
										placeholder={"Masukkan luas tanah"}
										defaultValue={this.state.dt.lain_lain}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													lain_lain: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Mutasi Tanah di {JENIS_DESA}</Label>
									<input
										className="form-control"
										id="mutasi_tanah"
										type="number"
										min={0}
										placeholder={"Diisi setiap terjadi mutasi tanah di " + JENIS_DESA}
										defaultValue={this.state.dt.mutasi_tanah}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													mutasi_tanah: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
							</div>
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
								>
									Mutasi Tanah
								</Label>
								<Col sm="9">
									<textarea className='form-control' name="mutasi_tanah" id="mutasi_tanah" cols="30" placeholder={"Diisi setiap terjadi mutasi tanah di " + JENIS_DESA} defaultValue={this.state.dt.mutasi_tanah} value={this.state.dt.mutasi_tanah} onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													mutasi_tanah: e.target.value
												}
											})
										}} rows="3" required />
								</Col>
							</FormGroup> */}

							<hr />

							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
								>
									Keterangan
								</Label>
								<Col sm="9">
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder='Diisi dengan catatan-catatan lain yang dianggap perlu.' defaultValue={this.state.dt.keterangan} value={this.state.dt.keterangan} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												keterangan: e.target.value
											}
										})
									}} rows="3" required />
								</Col>
							</FormGroup> */}

							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Keterangan tambahan</Label>
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder='Diisi dengan catatan-catatan lain yang dianggap perlu.' defaultValue={this.state.dt.keterangan} rows="3" />
								</Col>
							</div>

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuA6;
