import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DESA, JENIS_DUSUN, Post } from '../../function/Koneksi';

const ModalSuratIzinMendirikanBangunan = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData, kiosk }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: '',
		id_jenis: "",
		nik: "",
		kk: "",
		nama_pemohon: "",
		tempat_lahir: "",
		tanggal_lahir: "",
		pekerjaan: "",
		pendidikan_terakhir: "",
		agama: "",
		status_perkawinan: "",
		jenis_kelamin: "",
		alamat: "",
		kewarganegaraan: "Indonesia",
		tujuan_pembuatan: "",
		nik_pelapor: "",
		detail_surat: {
			luas_tanah: '',
			batas_utara: '',
			batas_timur: '',
			batas_selatan: '',
			batas_barat: '',
			
		}
	}

	const initFormData = {
		frmNik: optionData.frmNik,
		tmplistPenduduk: optionData.tmplistPenduduk,
		nikStat: null,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [kioskMode, setKioskMode] = useState(kiosk)
	const [dt, setDt] = useState(data ? data : frmDex)
	const [draggable, setDraggable] = useState({
		isDown: false,
		startY: null,
		scrollUp: null,
	})

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}
		console.log(data);

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e && kioskMode == false) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik = [];
				let tmplistPenduduk = {}
				datanik.data.results.data.forEach(dt => {
					frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
					tmplistPenduduk = {
						...tmplistPenduduk,
						[dt.id]: dt
					}
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							frmNik,
							tmplistPenduduk,
							nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "tujuan_pembuatan":
				setDt({
					...dt,
					tujuan_pembuatan: e.target.value,
				})
				break;
			case "luas_tanah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						luas_tanah: e.target.value
					},
				})
				break;
			case "batas_utara":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						batas_utara: e.target.value
					},
				})
				break;
			case "batas_timur":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						batas_timur: e.target.value
					},
				})
				break;
			case "batas_selatan":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						batas_selatan: e.target.value
					},
				})
				break;
			case "batas_barat":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						batas_barat: e.target.value
					},
				})
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			let getNik = e.value

			const tmpDetailPenduduk = formData.tmplistPenduduk[getNik]
			console.log(formData.tmplistPenduduk);
			console.log(tmpDetailPenduduk);

			// New set alamat
			let alamat = tmpDetailPenduduk.alamat;
			const dataKK = tmpDetailPenduduk.data_kk
			const dataDesa = tmpDetailPenduduk.data_keldes
			const dataKecamatan = dataDesa.data_kecamatan
			const dataKabkota = dataKecamatan.data_kabkota
			const dataProvinsi = dataKabkota.data_provinsi

			if (JENIS_DESA.toLowerCase() !== 'kelurahan') {
				if (dataKK.data_rt) {
					const dataRT = dataKK.data_rt
					const dataRW = dataRT.data_rw
					const dataDusun = dataRW.data_dusun ? dataRW.data_dusun : {nama: '-'}

					// Jika tidak ada RT
					if (dataRT.id === '0') {
						alamat = `${JENIS_DUSUN} ${dataDusun.nama}, ${JENIS_DESA} ${dataDesa.nama}, Kecamatan ${dataKecamatan.nama}, ${dataKabkota.nama}, ${dataProvinsi.nama}`
					} else {
						alamat = `RT/RW : ${dataRT.nama}/${dataRW.nama}, ${JENIS_DUSUN} ${dataDusun.nama}, ${JENIS_DESA} ${dataDesa.nama}, Kecamatan ${dataKecamatan.nama}, ${dataKabkota.nama}, ${dataProvinsi.nama}`
					}
				}
			} else { // Jika kelurahan maka tanpa dusun
				if (dataKK.data_rt) {
					const dataRT = dataKK.data_rt
					const dataRW = dataRT.data_rw

					alamat = `RT/RW : ${dataRT.nama}/${dataRW.nama}, ${JENIS_DESA} ${dataDesa.nama}, Kecamatan ${dataKecamatan.nama}, ${dataKabkota.nama}, ${dataProvinsi.nama}`
				}
			}

			// pelapor
			setFormData({
				...formData,
				nikStat: e,
				status: {
					...formData.status,
					dataPenduduk: "block",
				},
			})

			setDt({
				...dt,
				nik: tmpDetailPenduduk.id,
				nik_pelapor: tmpDetailPenduduk.id,
				kk: tmpDetailPenduduk.id_kk,
				nama_pemohon: tmpDetailPenduduk.nama,
				tempat_lahir: tmpDetailPenduduk.tempat_lahir,
				tanggal_lahir: new Date(
					tmpDetailPenduduk.tanggal_lahir
				),
				pekerjaan: tmpDetailPenduduk.data_pekerjaan.nama,
				pendidikan_terakhir: tmpDetailPenduduk.data_pendidikan_terakhir.nama,
				agama: tmpDetailPenduduk.data_agama.nama,
				status_perkawinan: tmpDetailPenduduk.status_perkawinan,
				jenis_kelamin: tmpDetailPenduduk.jk,
				alamat,
				kewarganegaraan: 'Indonesia',
			})

		} else {
			switch (jns) {
				case "pelapor":
					setDt({
						...dt,
						nik: '',
						nik_pelapor: '',
						kk: '',
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: null,
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						jenis_kelamin: '',
						alamat: '',
						kewarganegaraan: ''
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null
					})
					break;
				default:
					break;
			}
		}
	};

	const gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_mulai":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					setDt({
						...dt,
						tgl_mulai: new Date(tgl)
					})
        }
        break;
      case "tanggal_selesai":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					setDt({
						...dt,
						tgl_selesai: new Date(tgl)
					})
        }
        break;
      default:
        // no default
        break;
    }
  };

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);

		if (kioskMode) {
			props.setLoadingPreview(true)
		}

		// let formDetail = [
    //   {
    //     nama_item: "tgl_mulai",
    //     isi_item: dateFormat(dt.tgl_mulai, 'yyyy-mm-dd HH:MM:ss')
    //   },
    //   {
    //     nama_item: "tgl_selesai",
    //     isi_item: dateFormat(dt.tgl_selesai, 'yyyy-mm-dd HH:MM:ss')
    //   },
    //   {
    //     nama_item: "tempat",
    //     isi_item: document.getElementById("tempat").value
    //   },
    //   {
    //     nama_item: "hiburan",
    //     isi_item: document.getElementById("hiburan").value
    //   }
    // ];

		let bodyRaw = {
			...dt,
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			detail_surat: {
				luas_tanah: document.getElementById("luas_tanah").value,
				batas_utara: document.getElementById("batas_utara").value,
				batas_timur: document.getElementById("batas_timur").value,
				batas_selatan: document.getElementById("batas_selatan").value,
				batas_barat: document.getElementById("batas_barat").value,
			}
		}
		if (dt.uuid === '') {
			if (kioskMode) {
				bodyRaw.id_jenis = props.id_jenis
			} else {
				bodyRaw.id_jenis = props.location.state.uuid
			}
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nikStat;

		if (validasiPelapor) {
			//console.log("Data Simpan Pindah Nikah")
			//console.log(bodyRaw)
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "store";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-keterangan/new-format/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)

					// Kalau mode kiosk
					if (kioskMode) {
						let dataAntrian = {
							nama: props.data.results.nama,
							nik: props.data.results.id,
							keterangan: '',
							uuid_surat: res.data.results,
						};

						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + props.id_jenis, null, (res2) => {
							console.log(res2)
							if (res2.code === 201) {
								props.getAntrian('Surat Izin Mendirikan Bangunan', 'berhasil', false, dataAntrian);
							}
							else {
								props.getAntrian('Surat Izin Mendirikan Bangunan', 'gagal');
							}
						});

					} else { // Kalau bukan kiosk
						if (res.status === resstat) {
							const alertData = {
								show: true,
								basicType: "success",
								basicTitle: "Data Surat Izin Mendirikan Bangunan",
								pesanAlert: "Berhasil " + psn + " Data",
								successAlert: true,
							}
							setAlert(alertData)
						} else {
							const alertData = {
								show: true,
								basicType: "danger",
								basicTitle: "Data Surat Izin Mendirikan Bangunan",
								pesanAlert: "Gagal " + psn + " Data"
							}
							setAlert(alertData)
						}

						// Refresh list
						setStatusRefresh(true)
					}
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} >
					<div
						onMouseDown={(e) => {
							let slider = document.querySelector('.card-scrollable');
							setDraggable({
								isDown: true,
								startY: e.pageY - slider.offsetTop,
								scrollUp: slider.scrollTop,
							})
						}}
						onMouseLeave={() => {
							setDraggable({
								...draggable,
								isDown: false
							})
						}}
						onMouseUp={() => {
							setDraggable({
								...draggable,
								isDown: false
							})
						}}
						onMouseMove={(e) => {
							e.preventDefault();

							let slider = document.querySelector('.card-scrollable');

							if (!draggable.isDown) {
								return;
							}

							const y = e.pageY - slider.offsetTop;
							const startY = draggable.startY;
							const walk = y - startY;
							slider.scrollTop = draggable.scrollUp - walk;
						}}
					>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Izin Mendirikan Bangunan</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							NIK
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK">
								<Select
									inputId="nik"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik", "pelapor");
									}}
									defaultValue={formData.nikStat}
									value={formData.nikStat}
									name="nik"
									options={formData.frmNik}
									placeholder="Pilih NIK"
									onInputChange={e => handleInputChangeNIK(e, 'nik')}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pemohon
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_pemohon"
									type="text"
									placeholder="Nama Pemohon"
									value={dt.nama_pemohon}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Tujuan Pembuatan
						</Label>
						<Col sm="9">
							<input
								data-testid="tujuan pembuatan"
								name="tujuan_pembuatan"
								className="form-control"
								id="tujuan_pembuatan"
								type="text"
								required
								placeholder="Contoh : Mendirikan Rumah"
								defaultValue={dt.tujuan_pembuatan}
								value={dt.tujuan_pembuatan}
								onChange={e => changeInputText(e, "tujuan_pembuatan")}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Luas Tanah (m<sup>2</sup>)
						</Label>
						<Col sm="9">
							<input
								data-testid="luas_tanah"
								name="luas_tanah"
								className="form-control"
								id="luas_tanah"
								type="text"
								required
								placeholder="Contoh: 200 x 300"
								defaultValue={dt.detail_surat.luas_tanah}
								value={dt.detail_surat.luas_tanah}
								onChange={e => changeInputText(e, "luas_tanah")}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Batas Utara
						</Label>
						<Col sm="9">
							<input
								data-testid="batas_utara"
								name="batas_utara"
								className="form-control"
								id="batas_utara"
								type="text"
								required
								placeholder="Masukkan nama pemilik. Contoh: Nurdi"
								defaultValue={dt.detail_surat.batas_utara}
								value={dt.detail_surat.batas_utara}
								onChange={e => changeInputText(e, "batas_utara")}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Batas Timur
						</Label>
						<Col sm="9">
							<input
								data-testid="batas_timur"
								name="batas_timur"
								className="form-control"
								id="batas_timur"
								type="text"
								required
								placeholder="Masukkan nama pemilik. Contoh: Nurdi"
								defaultValue={dt.detail_surat.batas_timur}
								value={dt.detail_surat.batas_timur}
								onChange={e => changeInputText(e, "batas_timur")}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Batas Selatan
						</Label>
						<Col sm="9">
							<input
								data-testid="batas_selatan"
								name="batas_selatan"
								className="form-control"
								id="batas_selatan"
								type="text"
								required
								placeholder="Masukkan nama pemilik. Contoh: Nurdi"
								defaultValue={dt.detail_surat.batas_selatan}
								value={dt.detail_surat.batas_selatan}
								onChange={e => changeInputText(e, "batas_selatan")}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Batas Barat
						</Label>
						<Col sm="9">
							<input
								data-testid="batas_barat"
								name="batas_barat"
								className="form-control"
								id="batas_barat"
								type="text"
								required
								placeholder="Masukkan nama pemilik. Contoh: Nurdi"
								defaultValue={dt.detail_surat.batas_barat}
								value={dt.detail_surat.batas_barat}
								onChange={e => changeInputText(e, "batas_barat")}
							/>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratIzinMendirikanBangunan;
