import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";

// koneksi
import { Tanggal, HariTanggal } from "../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { JENIS_DESA, Post } from "../../function/Koneksi";

const dateFormat = require('dateformat');

class BukuAdministrasiKeuangan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: [],
      },
      loading: false,

      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ===================================

      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: true,
      successAlert: false
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/grup/" + this.props.location.state.uuid,
        null,
        dataSuket,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  bukaForm = () => {
    this.state.status.form = true;
    this.state.tanggal_mulai = new Date();
    this.state.tanggal_selesai = null;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.showBio = false;
    this.state.pendStat = [];
    this.state.pendStat2 = [];
    this.state.status.btnForm = true;
    this.state.formAcc = false;
    this.state.tableAcc = "none";
    this.state.accStat = [];
    this.state.formDisposisi = false;
    this.state.status.form = false;
    this.forceUpdate();
  };

  componentDidMount() {

  }

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    var data = this.state.data;

    return (
      <div data-testid="page-surat Keterangan Beda Nama 1">

        {/* <Breadcrumb title="Data Surat Keramaian" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="row d-flex"
                    >
                      <div className="col-sm-12 mb-4">
                        <Button
                          size="sm"
                          color="default"
                          // className="btn-secondary"
                          style={{ backgroundColor: '#FFC312' }}
                          onClick={this.props.history.goBack}
                        >
                          <i className="fa fa-arrow-left"></i> Kembali
                        </Button>
                      </div>
                      <div className="col-sm-3 mb-4">
                        <Link to={{ pathname: "/admin/buku-c1" }}>
                          <div className="card o-hidden mb-0">
                            <div className="card-body p-3 background-info text-white"
                            >
                              <div className="media static-top-widget">
                                <div
                                  className="media-body p-0"
                                  style={{
                                    minHeight: '6.5em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className="m-0 p-0"
                                    style={{ fontSize: '18px' }}
                                  >
                                    C1. Anggaran Pendapatan dan Belanja {JENIS_DESA}
                                  </span>
                                  <i className="icofont icofont-law-book icon-bg text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-3 mb-4">
                        <Link to={{ pathname: "/admin/buku-c2" }}>
                          <div className="card o-hidden mb-0">
                            <div className="card-body p-3 background-info text-white"
                            >
                              <div className="media static-top-widget">
                                <div
                                  className="media-body p-0"
                                  style={{
                                    minHeight: '6.5em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className="m-0 p-0"
                                    style={{ fontSize: '18px' }}
                                  >
                                    C2. Buku Rencana Anggaran Biaya (RAB)
                                  </span>
                                  <i className="icofont icofont-address-book icon-bg text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-3 mb-4">
                        <Link to={{ pathname: "/admin/buku-c3" }}>
                          <div className="card o-hidden mb-0">
                            <div className="card-body p-3 background-info text-white"
                            >
                              <div className="media static-top-widget">
                                <div
                                  className="media-body p-0"
                                  style={{
                                    minHeight: '6.5em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className="m-0 p-0"
                                    style={{ fontSize: '18px' }}
                                  >
                                    C3. Buku Kas Pembantu Kegiatan
                                  </span>
                                  <i className="icofont icofont-money icon-bg text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-3 mb-4">
                        <Link to={{ pathname: "/admin/buku-c4" }}>
                          <div className="card o-hidden mb-0">
                            <div className="card-body p-3 background-info text-white"
                            >
                              <div className="media static-top-widget">
                                <div
                                  className="media-body p-0"
                                  style={{
                                    minHeight: '6.5em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className="m-0 p-0"
                                    style={{ fontSize: '18px' }}
                                  >
                                    C4. Buku Kas Umum
                                  </span>
                                  <i className="icofont icofont-ebook icon-bg text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-3 mb-4">
                        <Link to={{ pathname: "/admin/buku-c5" }}>
                          <div className="card o-hidden mb-0">
                            <div className="card-body p-3 background-info text-white"
                            >
                              <div className="media static-top-widget">
                                <div
                                  className="media-body p-0"
                                  style={{
                                    minHeight: '6.5em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className="m-0 p-0"
                                    style={{ fontSize: '18px' }}
                                  >
                                    C5. Buku Kas Pembantu
                                  </span>
                                  <i className="icofont icofont-papers icon-bg text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-3 mb-4">
                        <Link to={{ pathname: "/admin/buku-c6" }}>
                          <div className="card o-hidden mb-0">
                            <div className="card-body p-3 background-info text-white"
                            >
                              <div className="media static-top-widget">
                                <div
                                  className="media-body p-0"
                                  style={{
                                    minHeight: '6.5em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className="m-0 p-0"
                                    style={{ fontSize: '18px' }}
                                  >
                                    C6. Buku Bank Desa
                                  </span>
                                  <i className="icofont icofont-bank icon-bg text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BukuAdministrasiKeuangan;
