import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DUSUN, ID_DESA, Post } from '../../../function/Koneksi';

const ModalDusun = ({ data, optionData, additionalData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	
	
	const frmDusun = {
		id: 0,
		idbaru: '',
		id_keldes: '',
		nama: '',
	}

	const initFormData = {
		dataWilayahDusun: additionalData.dataWilayahDusun,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDusun)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmDusun)

		Get('keldes/find/'+ID_DESA, null, (data) => {
			setFormData({
				...formData,
				dataWilayahDusun: {
					...formData.dataWilayahDusun,
					id_provinsi: data.results.data_kecamatan.data_kabkota.data_provinsi.id,
					id_kabkota: data.results.data_kecamatan.data_kabkota.id,
					id_kecamatan: data.results.data_kecamatan.id,
					id_keldes: data.results.id,
				}
			})
		})

		Get('dusun/kel/'+ID_DESA+'/list', null, (data) => {
			if(data.results.length!==0){
				let akhir = data.results.length-1;
				let getDataNext = data.results[akhir];
				let nextID = getDataNext.id+1;
				
				setFormData({
					...formData,
					dataWilayahDusun: {
						...formData.dataWilayahDusun,
						next_id: nextID,
					}
				})
			}
			else{
				let nextID = ID_DESA+'001';
				setFormData({
					...formData,
					dataWilayahDusun: {
						...formData.dataWilayahDusun,
						next_id: nextID,
					}
				})
			}
		});

		setFormData(data ? {
			...initFormData,
		} : initFormData)
	}, [isOpen])

	
	const tutupForm = () => {
		setDt(frmDusun)
		setIsOpen(false)
	};

	const simpan = (e) => {
		e.preventDefault();
		
		if (document.getElementById("nama").value !== '') {
            let id = parseInt(document.getElementById("id").value);
			let bodyRaw = {
				...dt,
				id: formData.dataWilayahDusun.next_id,
                id_keldes: ID_DESA,
                nama: document.getElementById("nama").value,
			}

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (id === 0) {
				psn = "Tambah";
				resstat = 201;
				id = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			
			Post("dusun/" + metode, id, bodyRaw,res => {
				setFormData({
					...formData,
					status: {
						...formData.status,
						btnForm: false,
						form: false,
					}
				})
				setIsOpen(false)
				if (res.status === resstat) {
					const alertData = {
						show: true,
						basicType: "success",
						basicTitle: "Data Dusun",
						pesanAlert: "Berhasil " + psn + " Data",
						successAlert: true,
					}
					setAlert(alertData)
				} else {
					const alertData = {
						show: true,
						basicType: "danger",
						basicTitle: "Data Dusun",
						pesanAlert: "Gagal " + psn + " Data"
					}
					setAlert(alertData)
				}

				// Refresh list
				setStatusRefresh(true)
			});
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} 
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.id == '' ? 'Tambah' : 'Edit'} Dusun</h5>
				</div>
				<div className="modal-body">
					<input
						className="form-control"
						id="id"
						type="hidden"
						placeholder=""
						defaultValue={dt.id}
					/>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama {JENIS_DUSUN}</Label>
						<Col sm='9'>
							<input className="form-control" id="nama" type="text" placeholder="Nama Dusun" defaultValue={dt.nama} />
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setDt(frmDusun)
							tutupForm()
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalDusun;
