import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL, { Post, Get, Delete, ID_DESA, JENIS_DESA, Nama_DESA } from '../../../function/Koneksi';
import { DataToken } from '../../../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';

import Datetime from "react-datetime";
const dateFormat = require('dateformat');

const frmJamKerja = {
	uuid: '',
	jam_absen: null,
	keterlambatan: null,
	tipe: '',
	urutan: null,
};

const frmHariLibur = {
	uuid: '',
	tanggal: new Date(),
	keterangan: '',
};

const frmRekapAbsensi= {
	tgl_awal: null,
	tgl_akhir: null,
};

class BasicTable extends Component {
  _isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
      // DATA JAM KERJA
        dataJamKerja: [],
        paginationJamKerja: [],
        statusJamKerja: {
          form: false,
          btnForm: false,
          btnAksi: false
        },
        awalJamKerja: '',
        sebelumJamKerja: '',
        halJamKerja: [],
        setelahJamKerja: '',
        akhirJamKerja: '',
        dtJamKerja: frmJamKerja,
        judulJamKerja: 'Jam Kerja',
        loadingJamKerja: false,
      // END DATA JAM KERJA

      // DATA HARI LIBUR
        dataHariLibur: [],
        paginationHariLibur: [],
        statusHariLibur: {
          form: false,
          btnForm: false,
          btnAksi: false
        },
        awalHariLibur: '',
        sebelumHariLibur: '',
        halHariLibur: [],
        setelahHariLibur: '',
        akhirHariLibur: '',
        dtHariLibur: frmHariLibur,
        judulHariLibur: 'Hari Libur',
        loadingHariLibur: false,
      // END DATA HARI LIBUR

      // DATA REKAP ABSENSI
        dataRekapAbsensi: [],
        paginationRekapAbsensi: [],
        statusRekapAbsensi: {
          form: false,
          btnForm: false,
          btnAksi: false
        },
        awalRekapAbsensi: '',
        sebelumRekapAbsensi: '',
        halRekapAbsensi: [],
        setelahRekapAbsensi: '',
        akhirRekapAbsensi: '',
        dtRekapAbsensi: frmRekapAbsensi,
        tgl_awal: null,
			  tgl_akhir: null,
        loadingRekapAbsensi: false,
        detailDataStat: false,
        detailRekapAbsensi:[],
        detailRekapAbsensiTemporary:[],
        judulDetailRekapAbsensi:'',
        jamStat: null,
        frmJam: [],
      // END DATA REKAP ABSENSI


        alert: null,
        show: false,
        basicTitle: '',
        basicType: 'default',
        pesanAlert: '',
        akun: DataToken(sessionStorage.getItem('access_token')),
			  dataQRCode: null,
        loadingQrCode: false,

			
			// Tab Vertical
        active_tab_vertical: '1',
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// Toggle Vertical Tab
	toggle_vertical(tab) {
        if (this.state.active_tab_vertical !== tab) {
            this.setState({
                active_tab_vertical: tab,
                detailDataStat: false,
                detailLembaga: [],
            });
        }
    }

  // DATA JAM KERJA
  // ================== Ambil dataJamKerja dari db untuk table ==================
    fetchJamKerja = (params = {}) => {
      this.setState({ loadingJamKerja: true })
      let link = '';
      if (params.page) {
        switch (params.page) {
          case '>>':
            link = '?page=' + Math.ceil(this.state.paginationJamKerja.total / this.state.paginationJamKerja.per_page);
            break;
          case '>':
            link = '?page=' + (parseInt(this.state.paginationJamKerja.current_page) + 1);
            break;
          case '<':
            link = '?page=' + (parseInt(this.state.paginationJamKerja.current_page) - 1);
            break;
          case '<<':
            link = '?page=1';
            break;
          default:
            link = '?page=' + params.page;
            break;
        }
      }
      Get('absensi/master-jam' + link, null, (dtkat) => {
        if (dtkat.results) {
          this.setState({ dataJamKerja: dtkat.results, loadingJamKerja: false });
        }
      });
    };

    // paginationJamKerja
    paginationJamKerja = (data) => {
      // const data = this.state.paginationJamKerja;
      //console.log(data);
      let awalJamKerja = '';
      let halJamKerja = [];
      let sebelumJamKerja = '';
      let setelahJamKerja = '';
      let akhirJamKerja = '';
      if (data.total > 0) {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3) {
          start = 1;
          end = 5;
          if (data.last_page > data.current_page) {
            n = data.current_page + 1;
          }
        } else {
          p = data.current_page - 1;
          n = data.current_page + 1;
          start = data.current_page - 2;
          end = data.current_page + 2;
        }
        if (end >= data.last_page - 2) {
          p = data.current_page - 1;
          if (start >= 5) {
            start = data.last_page - 4;
          }
          end = data.last_page;
        }

        for (let i = start; i <= end; i++) {
          let warna = 'primary-custom';
          if (i === data.current_page) {
            warna = 'danger';
          }
          halJamKerja.push(
            <a
              href={() => false}
              onClick={() => this.fetchJamKerja({ page: i })}
              className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
            >
              {i}
            </a>
          );
        }
        if (p > 0) {
          sebelumJamKerja = (
            <a
              href={() => false}
              onClick={() => this.fetchJamKerja({ page: p })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {'<'}
            </a>
          );
        }
        if (n > 0) {
          setelahJamKerja = (
            <a
              href={() => false}
              onClick={() => this.fetchJamKerja({ page: n })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {'>'}
            </a>
          );
        }
        awalJamKerja = (
          <a
            href={() => false}
            onClick={() => this.fetchJamKerja({ page: 1 })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {'<<'}
          </a>
        );
        akhirJamKerja = (
          <a
            href={() => false}
            onClick={() => this.fetchJamKerja({ page: data.last_page })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {'>>'}
          </a>
        );
      }
      this.setState({ awalJamKerja, sebelumJamKerja, halJamKerja, setelahJamKerja, akhirJamKerja });
    };
    
    // ================== simpan data ==================
    simpanJamKerja = (e) => {
      e.preventDefault();
      // console.log(this.state.dtJamKerja);
      // console.log(document.getElementById('tanggal'));
      // console.log(document.getElementById('keterangan').value);

      let bodyForm = {
        uuid: document.getElementById('uuid').value,
        keterlambatan: document.getElementById('keterlambatan').value,
        jam_absen: this.state.dtJamKerja.jam_absen + ':00',
        tipe: this.state.dtJamKerja.tipe.value,
      };

      let psn = '';
      let resstat = 204;
      let metode = 'create';
      if (bodyForm.uuid === '') {
        psn = 'Tambah';
        resstat = 201;
        bodyForm.uuid = null
        // delete bodyForm.uuid
      } else {
        psn = 'Ubah';
        resstat = 200;
        metode = 'update';
      }

      Post('absensi/master-jam/' + metode, bodyForm.uuid, bodyForm, (res) => {
        this.setState({
          statusJamKerja: {
            ...this.state.statusJamKerja,
            btnForm: false,
            form: false
          }
        });
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Jam Kerja',
            pesanAlert: 'Berhasil ' + psn + ' Data'
          });
          this.componentDidMount();
          document.getElementById('form-data').reset()
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Jam Kerja',
            pesanAlert: 'Gagal ' + psn + ' Data'
          });
        }
      });
      
    };

    // ============================== Ubah Data ===============================
    ubahDataJamKerja = (id) => {
      const splitJamAbsen = id.jam_absen.split(':')
      this.setState({
        statusJamKerja: {
          ...this.state.statusJamKerja,
          btnAksi: true
        },
        dtJamKerja: {
          uuid: id.uuid,
          jam_absen: splitJamAbsen[0] + ':' + splitJamAbsen[1],
          keterlambatan: id.keterlambatan,
          tipe: {
            label: id.tipe,
            value: id.tipe,
          },
        },
        judulJamKerja: 'Ubah Jam Kerja'
      });
      this.bukaFormJamKerja();
    };
    
    bukaFormJamKerja = () => {
      this.setState({
        statusJamKerja: {
          ...this.state.statusJamKerja,
          form: true
        }
      });
      this.forceUpdate();
    };

    tutupFormJamKerja = () => {
      this.setState({
        statusJamKerja: {
          ...this.state.statusJamKerja,
          form: false
        }
      });
      this.forceUpdate();
    };

    tombolAksiJamKerja = (cell, row) => {
      return (
        <div>
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => this.ubahDataJamKerja(row)}
            disabled={this.state.statusJamKerja.btnAksi}
          >
            <i className="fa fa-pencil" />
          </Button>
          {/* &nbsp;&nbsp;
          <Button
            size="xs"
            color="danger"
            className="btn-icon"
            onClick={() => this.konfirmHapusJamKerja(row.uuid)}
            disabled={this.state.statusJamKerja.btnAksi}
          >
            <i className="fa fa-trash" />
          </Button> */}
        </div>
      );
    };


  // END DATA JAM KERJA




  // DATA HARI LIBUR
    // ================== Ambil data dari db untuk table ==================
    fetchHariLibur = (params = {}) => {
      this.setState({ loadingHariLibur: true })
      let link = '';
      if (params.page) {
        switch (params.page) {
          case '>>':
            link = '?page=' + Math.ceil(this.state.paginationHariLibur.total / this.state.paginationHariLibur.per_page);
            break;
          case '>':
            link = '?page=' + (parseInt(this.state.paginationHariLibur.current_page) + 1);
            break;
          case '<':
            link = '?page=' + (parseInt(this.state.paginationHariLibur.current_page) - 1);
            break;
          case '<<':
            link = '?page=1';
            break;
          default:
            link = '?page=' + params.page;
            break;
        }
      }
      Get('absensi/master-hari-libur' + link, null, (dtkat) => {
        if (dtkat.results) {
          this.setState({ dataHariLibur: dtkat.results.data, paginationHariLibur: dtkat.results, loadingHariLibur: false });
          this.paginationHariLibur(dtkat.results);
        }
      });
    };

    // paginationHariLibur
    paginationHariLibur = (data) => {
      // const data = this.state.paginationHariLibur;
      //console.log(data);
      let awalHariLibur = '';
      let halHariLibur = [];
      let sebelumHariLibur = '';
      let setelahHariLibur = '';
      let akhirHariLibur = '';
      if (data.total > 0) {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3) {
          start = 1;
          end = 5;
          if (data.last_page > data.current_page) {
            n = data.current_page + 1;
          }
        } else {
          p = data.current_page - 1;
          n = data.current_page + 1;
          start = data.current_page - 2;
          end = data.current_page + 2;
        }
        if (end >= data.last_page - 2) {
          p = data.current_page - 1;
          if (start >= 5) {
            start = data.last_page - 4;
          }
          end = data.last_page;
        }

        for (let i = start; i <= end; i++) {
          let warna = 'primary-custom';
          if (i === data.current_page) {
            warna = 'danger';
          }
          halHariLibur.push(
            <a
              href={() => false}
              onClick={() => this.fetchHariLibur({ page: i })}
              className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
            >
              {i}
            </a>
          );
        }
        if (p > 0) {
          sebelumHariLibur = (
            <a
              href={() => false}
              onClick={() => this.fetchHariLibur({ page: p })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {'<'}
            </a>
          );
        }
        if (n > 0) {
          setelahHariLibur = (
            <a
              href={() => false}
              onClick={() => this.fetchHariLibur({ page: n })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {'>'}
            </a>
          );
        }
        awalHariLibur = (
          <a
            href={() => false}
            onClick={() => this.fetchHariLibur({ page: 1 })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {'<<'}
          </a>
        );
        akhirHariLibur = (
          <a
            href={() => false}
            onClick={() => this.fetchHariLibur({ page: data.last_page })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {'>>'}
          </a>
        );
      }
      this.setState({ awalHariLibur, sebelumHariLibur, halHariLibur, setelahHariLibur, akhirHariLibur });
    };

    // ================== simpan data ==================
    simpanHariLibur = (e) => {
      e.preventDefault();

      const formData = new FormData(document.getElementById('form-data'))

      let bodyForm = {
        uuid: document.getElementById('uuid').value,
        keterangan: document.getElementById('keterangan').value,
        tanggal: this.state.dtHariLibur.tanggal,
      };

      let psn = '';
      let resstat = 204;
      let metode = 'create';
      if (bodyForm.uuid === '') {
        psn = 'Tambah';
        resstat = 201;
        bodyForm.uuid = null
        // delete bodyForm.uuid
      } else {
        psn = 'Ubah';
        resstat = 200;
        metode = 'update';
      }

      Post('absensi/master-hari-libur/' + metode, bodyForm.uuid, bodyForm, (res) => {
        this.setState({
          statusHariLibur: {
            ...this.state.statusHariLibur,
            btnForm: false,
            form: false
          }
        });
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Hari Libur',
            pesanAlert: 'Berhasil ' + psn + ' Data'
          });
          this.componentDidMount();
          document.getElementById('form-data').reset()
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Hari Libur',
            pesanAlert: 'Gagal ' + psn + ' Data'
          });
        }
      });
      
    };
  
    // ============================== Ubah Data ===============================
    ubahDataHariLibur = (id) => {
      this.setState({
        statusHariLibur: {
          ...this.state.statusHariLibur,
          btnAksi: true
        },
        dtHariLibur: {
          uuid: id.uuid,
          tanggal: new Date(id.tanggal),
          keterangan: id.keterangan,
        },
        judulHariLibur: 'Ubah Hari Libur'
      });
      this.bukaFormHariLibur();
    };

    // ============================= Hapus Data ===============================
    konfirmHapusHariLibur = (id) => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusDataHariLibur(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusDataHariLibur = (id) => {
      this.hideAlert();
      let psn = 'Hapus';
      Delete('absensi/master-hari-libur/delete', id, (res) => {
        if (res === 200) {
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Hari Libur',
            pesanAlert: 'Berhasil ' + psn + ' Data'
          });
          this.componentDidMount();
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Hari Libur',
            pesanAlert: 'Gagal ' + psn + ' Data'
          });
        }
        // this.componentDidMount();
      });
    };

    bukaFormHariLibur = () => {
      this.setState({
        statusHariLibur: {
          ...this.state.statusHariLibur,
          form: true
        }
      });
      this.forceUpdate();
    };
  
    tutupFormHariLibur = () => {
      this.setState({
        statusHariLibur: {
          ...this.state.statusHariLibur,
          form: false
        }
      });
      this.forceUpdate();
    };
    
    tombolAksiHariLibur = (cell, row) => {
      return (
        <div>
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => this.ubahDataHariLibur(row)}
            disabled={this.state.statusHariLibur.btnAksi}
          >
            <i className="fa fa-pencil" />
          </Button>{' '}
          &nbsp;&nbsp;
          {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
          <Button
            size="xs"
            color="danger"
            className="btn-icon"
            onClick={() => this.konfirmHapusHariLibur(row.uuid)}
            disabled={this.state.statusHariLibur.btnAksi}
          >
            <i className="fa fa-trash" />
          </Button>
          ) : ''}
        </div>
      );
    };

  // END DATA HARI LIBUR



  // DATA REKAP ABSENSI

    fetchRekapAbsensi = (params = {}) => {
      this.setState({ loadingRekapAbsensi: true })
      let link = '';
      
      if (params.page) {
          switch (params.page) {
              case '>>':
                  link = '?page=' + Math.ceil(this.state.paginationRekapAbsensi.total / this.state.paginationRekapAbsensi.per_page);
                  break;
              case '>':
                  link = '?page=' + (parseInt(this.state.paginationRekapAbsensi.current_page) + 1);
                  break;
              case '<':
                  link = '?page=' + (parseInt(this.state.paginationRekapAbsensi.current_page) - 1);
                  break;
              case '<<':
                  link = '?page=1';
                  break;
              default:
                  link = '?page=' + params.page;
                  break;
          }
      }
      let awal = ''
      let akhir =''
      if(this.state.dtRekapAbsensi.tgl_awal){
          if(link){
              awal = `&start=${this.state.dtRekapAbsensi.tgl_awal}`
          }
          else{
              awal = `?start=${this.state.dtRekapAbsensi.tgl_awal}`
          }
      }
      if(this.state.dtRekapAbsensi.tgl_akhir){
          akhir = `&end=${this.state.dtRekapAbsensi.tgl_akhir}`
      }

      Get('absensi/absen' + link + awal + akhir, null, (dtkat) => {
          if (dtkat.results) {
              this.setState({ dataRekapAbsensi: dtkat.results.data, paginationRekapAbsensi: dtkat.results, loadingRekapAbsensi: false });
              this.paginationRekapAbsensi(dtkat.results);
          }
      });
    };

    // paginationRekapAbsensi
    paginationRekapAbsensi = (data) => {
        // const data = this.state.paginationRekapAbsensi;
        //console.log(data);
        let awalRekapAbsensi = '';
        let halRekapAbsensi = [];
        let sebelumRekapAbsensi = '';
        let setelahRekapAbsensi = '';
        let akhirRekapAbsensi = '';
        if (data.total > 0) {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3) {
                start = 1;
                end = 5;
                if (data.last_page > data.current_page) {
                    n = data.current_page + 1;
                }
            } else {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2) {
                p = data.current_page - 1;
                if (start >= 5) {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i = start; i <= end; i++) {
                let warna = 'primary-custom';
                if (i === data.current_page) {
                    warna = 'danger';
                }
                halRekapAbsensi.push(
                    <a
                        href={() => false}
                        onClick={() => this.fetchRekapAbsensi({ page: i })}
                        className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
                    >
                        {i}
                    </a>
                );
            }
            if (p > 0) {
                sebelumRekapAbsensi = (
                    <a
                        href={() => false}
                        onClick={() => this.fetchRekapAbsensi({ page: p })}
                        className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
                    >
                        {'<'}
                    </a>
                );
            }
            if (n > 0) {
                setelahRekapAbsensi = (
                    <a
                        href={() => false}
                        onClick={() => this.fetchRekapAbsensi({ page: n })}
                        className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
                    >
                        {'>'}
                    </a>
                );
            }
            awalRekapAbsensi = (
                <a
                    href={() => false}
                    onClick={() => this.fetchRekapAbsensi({ page: 1 })}
                    className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
                >
                    {'<<'}
                </a>
            );
            akhirRekapAbsensi = (
                <a
                    href={() => false}
                    onClick={() => this.fetchRekapAbsensi({ page: data.last_page })}
                    className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
                >
                    {'>>'}
                </a>
            );
        }
        this.setState({ awalRekapAbsensi, sebelumRekapAbsensi, halRekapAbsensi, setelahRekapAbsensi, akhirRekapAbsensi });
    };

    lihatRiwayat() {
      if(this.state.dtRekapAbsensi.tgl_awal && this.state.dtRekapAbsensi.tgl_akhir){
        this.fetchRekapAbsensi({ page: null, where: [] });
      }
      else{
        this.setState({ show: true, basicType:'danger', basicTitle:'Riwayat Absensi', pesanAlert:"Tanggal Awal dan Akhir Tidak Boleh Kosong" });
      }
    }

    gantiTgl = (date, fld) => {
      switch (fld) {
        case 'tanggal_awal':
          if (typeof date._isValid !== 'undefined') {
            let tgl  = dateFormat(date, 'yyyy-mm-dd')
            this.setState({ 
              dtRekapAbsensi:{
                ...this.state.dtRekapAbsensi,
                tgl_awal: tgl,
              },
              tgl_awal: new Date(date.format('YYYY-MM-DD')) 
            });
          }
          break;
        case 'tanggal_akhir':
          if (typeof date._isValid !== 'undefined') {
            let tgl  = dateFormat(date, 'yyyy-mm-dd')
            this.setState({ 
              dtRekapAbsensi:{
                ...this.state.dtRekapAbsensi,
                tgl_akhir: tgl,
              },
              tgl_akhir: new Date(date.format('YYYY-MM-DD')) 
            });
            // this.setState({ tgl_akhir: new Date(date.format('YYYY-MM-DD')) });
          }

          break;
        default:
          // no default
          break;
      }
    };

    
    tombolAksiRekapAbsensi = (cell, row) => {
      return (
        <div>
          {/* <Link to={{ pathname: "/admin/rekap-absensi-detail", state: {dataRekapAbsensi:row, tgl_awal: this.state.dtRekapAbsensi.tgl_awal, tgl_akhir: this.state.dtRekapAbsensi.tgl_akhir} }}> */}
            <Button
              size="md"
              color="info"
              className="btn-icon"
              onClick={() => this.detailDataRekapAbsensi(row)}
              // disabled={this.state.status.btnAksi}
            >
              <i className="fa fa-eye" /> Detail Absensi
            </Button>
          {/* </Link> */}
        </div>
      );
    };
  
    detailDataRekapAbsensi = (data) => {
      this.setState({
          detailDataStat: true,
          detailRekapAbsensi:data,
          detailRekapAbsensiTemporary:data,
          judulDetailRekapAbsensi: "Detail Rekap Absensi",
      })
      this.forceUpdate();
    }

    changeSelectValue = (e, sel) => {
      if (e) {
        switch (sel) {
          case 'jenis_jam':
            this.setState({ 
              jamStat: e, 
            });
            break;
          default:
            // no default
            break;
        }
      } else {
        switch (sel) {
          case 'jenis_jam':
            this.setState({ 
              jamStat: null, 
              detailRekapAbsensi:this.state.detailRekapAbsensiTemporary
            });
            this.state.jamStat = null
            this.forceUpdate()
            break;
          
          default:
            // no default
            break;
        }
      }

      this.forceUpdate();
    };
    
    lihatRiwayatDetail() {
      if(this.state.jamStat){
        
        let dataFilter = this.state.detailRekapAbsensiTemporary.absensi
        // let dataFilter = this.state.detailRekapAbsensi.absensi

        dataFilter = dataFilter.filter((day) => day.data_jam.uuid == this.state.jamStat.value);
        
        this.setState({
          detailRekapAbsensi:{
            ...this.state.detailRekapAbsensi,
            absensi:dataFilter,
          }
        })
      }
      else{
        this.setState({
          jamStat:null,
        })
        // this.fetch({ page: null, where: [] });
      }
    }
  // END DATA REKAP ABSENSI



  // DATA DOWNLOAD QRCODE
    downloadQrCodeToken = () => {
      let link = API_URL + 'absensi/qrcode-token';
      let title = 'QRCode Absensi '+ JENIS_DESA +' '+ Nama_DESA
      fetch(link, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
          'Content-Type': 'application/png',
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${title}.png`,
        );
  
        // Append to html link element page
        document.body.appendChild(link);
  
        // Start download
        link.click();
  
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({
          loadingQrCode: false,
        })
      });
      // ==============

      this.forceUpdate();
    };

  // END DATA DOWNLOAD QRCODE


	// ========================================================================

	componentDidMount() {
    // HARI LIBUR
		this.fetchHariLibur({ page: null, where: [] });
    // END HARI LIBUR
    
    // JAM KERJA
    this.fetchJamKerja({ page: null, where: [] });
    // END JAM KERJA
    
    // REKAP ABSENSI
    this.fetchRekapAbsensi({ page: null, where: [] });
    Get('absensi/master-jam', null, (dtkat) => {
			let frmJam = [];
			dtkat.results.forEach(dt => {
				frmJam.push({ value: dt.uuid, label: dt.tipe+' - '+dt.jam_absen });
			});
			this.setState({ frmJam });
		});

    // END REKAP ABSENSI


    this.setState({
      token: DataToken(sessionStorage.getItem('access_token')),
    })
    // DATA BANNER MOBILE

    // END DATA BANNER MOBILE
	}

  
  componentWillUnmount() {
    this._isMounted = false;
  }


	render() {
    // DATA JAM KERJA
      var dataJamKerja = this.state.dataJamKerja;
      
      const columnsJamKerja = [
        {
          dataField: 'urutan',
          text: 'Urutan',
          sort: true,
          headerAlign: 'center',
          align: 'center',
        },
        {
          dataField: 'jam_absen',
          text: 'Jam',
          sort: true,
          headerAlign: 'center',
          align: 'center',
        },
        {
          dataField: 'keterlambatan',
          text: 'Batas Keterlambatan',
          sort: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (obj) => {
            return obj + ' menit'
          }
        },
        {
          dataField: 'tipe',
          text: 'Tipe',
          sort: true,
          headerAlign: 'center',
          align: 'center',
        },
        {
          dataField: 'aksi',
          text: 'Aksi',
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiJamKerja
        }
      ];
    // END DATA JAM KERJA

    // DATA HARI LIBUR
		  var dataHariLibur = this.state.dataHariLibur;
      
      const columnsHariLibur = [
        {
          dataField: 'no',
          text: 'No',
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) => {
            let current_pagenum = this.state.paginationHariLibur.current_page;
            let total_records_per_page = this.state.paginationHariLibur.per_page;
            let row_index = index + 1;
            let serial_num = total_records_per_page * (current_pagenum - 1) + row_index;
            return serial_num;
          }
        },
        {
          dataField: 'tanggal',
          text: 'Tanggal',
          sort: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
            let plainTime = '-'
            if (obj.tanggal) {
              let date = new Date(obj.tanggal)
              date = date.toLocaleString('id-ID')
              
              const datetime = date.split(' ')
    
              date = datetime[0]
              // let time = datetime[1]
    
              const splitDate = date.split('/')
              // time = time.replaceAll('.', ':')
    
              const list_bulan = [
                'Januari',
                'Februari',
                'Maret',
                'April',
                'Mei',
                'Juni',
                'Juli',
                'Agustus',
                'September',
                'Oktober',
                'November',
                'Desember'
              ];

              plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}`;
            }

            return plainTime
          },
        },
        {
          dataField: 'keterangan',
          text: 'Nama',
          sort: true,
          headerAlign: 'center',
        },
        {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          style: {
            width: '130px'
          },
          formatter: this.tombolAksiHariLibur
        }
      ];
    // END DATA HARI LIBUR

    // DATA REKAP ABSENSI
      var dataRekapAbsensi = this.state.dataRekapAbsensi;

      const columnsRekapAbsensi = [
        {
          dataField: 'no',
          text: 'No',
          style: {
            width: '80px'
          },
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) => {
            return index + 1;
  
            let current_pagenum = this.state.paginationRekapAbsensi.current_page;
            let total_records_per_page = this.state.paginationRekapAbsensi.per_page;
            let row_index = index + 1;
            let serial_num = total_records_per_page * (current_pagenum - 1) + row_index;
            return serial_num;
          }
        },
        {
          dataField: 'tanggal',
          text: 'Tanggal',
          sort: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
                  let plainTime = '-'
            if (obj.tanggal) {
              let date = new Date(obj.tanggal)
              date = date.toLocaleString('id-ID')
              
              const datetime = date.split(' ')
    
              date = datetime[0]
              // let time = datetime[1]
    
              const splitDate = date.split('/')
              // time = time.replaceAll('.', ':')
    
              const list_bulan = [
                'Januari',
                'Februari',
                'Maret',
                'April',
                'Mei',
                'Juni',
                'Juli',
                'Agustus',
                'September',
                'Oktober',
                'November',
                'Desember'
              ];
  
              plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}`;
            }
  
            return plainTime
              },
        },
        {
          dataField: 'aksi',
          text: 'Aksi',
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiRekapAbsensi,
          headerStyle: (colum, colIndex) => {
                      return { width: '80px', };
                  }
        }
      ];

      var dataDetailRekapAbsensi = this.state.detailRekapAbsensi.absensi;
      
      const columnsDetailRekapAbsensi = [
        {
          dataField: 'no',
          text: 'No',
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) => {
            return index + 1;
          },
          headerStyle: (colum, colIndex) => {
              return { width: '30px', };
          }
        },
        {
          dataField: "data_perangkat.data_penduduk.nama",
          text: "Nama Karyawan",
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
              return obj && obj.data_perangkat ? obj.data_perangkat.data_penduduk.nama : '';
          },
          sort: true
        },
        {
          dataField: "data_jam.tipe",
          text: "Jenis Absen",
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
              return obj.data_jam.tipe;
          },
          sort: true
        },
        {
          dataField: "onsite",
          text: "Lokasi Absen",
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
            let tampil = '';
            if(obj.on_site === 0){
              tampil = 'Absen Di luar'
            }
            else if(obj.on_site === 1){
              tampil = 'Absen Di dalam'
            }
              return tampil;
          },
          sort: true
        },
        {
          dataField: "updated_at",
          text: "Jam Absen",
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
            // console.log('Jam Absen')
            // console.log(obj.updated_at)
            // console.log(dateFormat(obj.updated_at, 'HH:mm:ss'))
              return dateFormat(obj.updated_at, 'HH:mm:ss');
          },
          sort: true
        },
        {
          dataField: "keterlambatan",
          text: "Keterlambatan",
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => {
            const hours = Math.floor(obj.keterlambatan / 60);  
            const minutes = obj.keterlambatan % 60;
            let hasil = '';
            if(hours === 0){
              // hasil = `${minutes} Menit`;
              hasil = `${hours} Jam ${minutes} Menit`;
            }
            else{
              hasil = <b><font color='red'>{hours} Jam {minutes} Menit</font></b>;
            }
            return hasil;

              // return obj.keterlambatan;
          },
          sort: true
        },
        // {
        // 	dataField: 'aksi',
        // 	text: 'Aksi',
        // 	isDummyField: true,
        // 	csvExport: false,
        // 	headerAlign: 'center',
        // 	align: 'center',
        // 	formatter: this.tombolAksi,
        // 	headerStyle: (colum, colIndex) => {
              //         return { width: '80px', };
              //     }
        // }
      ];
    // END DATA REKAP ABSENSI
 
		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}
				
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <Nav  className={'nav flex-column nav-pills'}>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
                                              onClick={() => { this.toggle_vertical('1'); }}>
                                            Jam Kerja
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '2' })}
                                              onClick={() => { this.toggle_vertical('2'); }}>
                                            Hari Libur
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '3' })}
                                              onClick={() => { this.toggle_vertical('3'); }}>
                                            Rekap Absensi
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '4' })}
                                              onClick={() => { this.toggle_vertical('4'); }}>
                                            Download QRCode Absen
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                    <div className="col-lg-9">
                                      <TabContent activeTab={this.state.active_tab_vertical}>
                                        <TabPane tabId="1">
                                          <Row>
                                            <Col sm="12">
                                              <div className="col-sm-6">
                                                <h5>DATA JAM KERJA</h5>
                                              </div>
                                              <div className="card-body datatable-react">
                                                {this.state.loadingJamKerja ? (
                                                  <div className="row">
                                                    <div className="col-sm-12" align="center">
                                                      <img
                                                        alt="loading"
                                                        src={require("../../../assets/images/tes-loading.gif")}
                                                        style={{
                                                          borderRadius: "10px",
                                                          width: '100px'
                                                        }} />
                                                    </div>
                                                  </div>
                                                ) : dataJamKerja.length > 0 ? (
                                                  <>
                                                  <BootstrapTable
                                                    keyField="id"
                                                    data={ dataJamKerja }
                                                    columns={ columnsJamKerja }
                                                  />
                                                <div className="pull-right text-white">
                                                  {this.state.awalJamKerja}
                                                  {this.state.sebelumJamKerja}
                                                  {this.state.halJamKerja.map((dt) => {
                                                    return dt;
                                                  })}
                                                  {this.state.setelahJamKerja}
                                                  {this.state.akhirJamKerja}
                                                </div>
                                                  </>
                                                ) : (
                                                  <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                )}
                                              </div>
                                              
                                              <Modal
                                                open={this.state.statusJamKerja.form}
                                                onClose={this.tutupFormJamKerja}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                                styles={{ modal: { width: '50%' } }}
                                              >
                                                <Form id="form-data" className="theme-form" onSubmit={this.simpanJamKerja}>
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.judulJamKerja}</h5>
                                                  </div>
                                                  <div className="modal-body">
                                                    <input
                                                      className="form-control"
                                                      id="uuid"
                                                      type="hidden"
                                                      defaultValue={this.state.dtJamKerja.uuid}
                                                    />
                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                        Jam
                                                      </Label>
                                                      <Col sm="9">
                                                        <Input
                                                          placeholder='Jam Absen'
                                                          defaultValue={this.state.dtJamKerja.jam_absen}
                                                          type='time'
                                                          onChange={(e => {
                                                            this.setState({ dtJamKerja : {
                                                              ...this.state.dtJamKerja,
                                                              jam_absen : e.target.value
                                                            } })
                                                          })}
                                                          required
                                                        />
                                                      </Col>
                                                    </FormGroup>
                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                        Keterlambatan
                                                      </Label>
                                                      <Col sm="9">
                                                        <input
                                                          className="form-control"
                                                          id="keterlambatan"
                                                          type="number"
                                                          placeholder="Waktu keterlambatan (menit)"
                                                          defaultValue={this.state.dtJamKerja.keterlambatan}
                                                          required
                                                        />
                                                      </Col>
                                                    </FormGroup>
                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="tipe">
                                                        Tipe
                                                      </Label>
                                                      <Col sm="9">
                                                        <Select
                                                          classNamePrefix="select"
                                                          onChange={e => {
                                                            // console.log(e);
                                                            this.setState({ dtJamKerja : {
                                                              ...this.state.dtJamKerja,
                                                              tipe : e
                                                            } })
                                                          }}
                                                          defaultValue={this.state.dtJamKerja.tipe}
                                                          value={this.state.dtJamKerja.tipe}
                                                          name="tipe"
                                                          options={[
                                                            {
                                                              label: 'Masuk',
                                                              value: 'Masuk',
                                                            },
                                                            {
                                                              label: 'Keluar',
                                                              value: 'Keluar',
                                                            },
                                                          ]}
                                                          placeholder="Pilih Tipe"
                                                          // isClearable
                                                        />
                                                        {/* <input
                                                          className="form-control"
                                                          id="tipe"
                                                          type="number"
                                                          placeholder="Waktu keterlambatan (menit)"
                                                          defaultValue={this.state.dtJamKerja.keterlambatan}
                                                          required
                                                        /> */}
                                                      </Col>
                                                    </FormGroup>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      disabled={this.state.statusJamKerja.btnForm}
                                                      onClick={this.tutupFormJamKerja}
                                                    >
                                                      Tutup
                                                    </button>
                                                    <button type="submit" className="btn btn-success" disabled={this.state.statusJamKerja.btnForm}>
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                          <Row>
                                              <Col sm="12">
                                                <div className="col-sm-12">
                                                  <div className="card mb-0">
                                                    <div className="card-header">
                                                      <div className="row">
                                                        <div className="col-sm-6">
                                                          <h5>Data Hari Libur</h5>
                                                        </div>
                                                        <div className="col-sm-6 text-right">
                                                          <Button
                                                            size="sm"
                                                            color="success"
                                                            className="btn-square"
                                                            onClick={() => {
                                                              this.setState({
                                                                judulHariLibur: 'Tambah Hari Libur',
                                                                dtHariLibur: frmHariLibur,
                                                              });
                                                              this.bukaFormHariLibur();
                                                            }}
                                                          >
                                                            Tambah Data
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="card-body datatable-react">
                                                      {this.state.loadingHariLibur ? (
                                                        <div className="row">
                                                          <div className="col-sm-12" align="center">
                                                            <img
                                                              alt="loadingHariLibur"
                                                              src={require("../../../assets/images/tes-loading.gif")}
                                                              style={{
                                                                borderRadius: "10px",
                                                                width: '100px'
                                                              }} />
                                                          </div>
                                                        </div>
                                                      ) : dataHariLibur.length > 0 ? (
                                                        <>
                                                      <BootstrapTable keyField="id" data={dataHariLibur} columns={columnsHariLibur} />
                                                      <div className="pull-right text-white">
                                                        {this.state.awalHariLibur}
                                                        {this.state.sebelumHariLibur}
                                                        {this.state.halHariLibur.map((dt) => {
                                                          return dt;
                                                        })}
                                                        {this.state.setelahHariLibur}
                                                        {this.state.akhirHariLibur}
                                                      </div>
                                                        </>
                                                      ) : (
                                                        <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <br />
                                                <Modal
                                                  open={this.state.statusHariLibur.form}
                                                  onClose={this.tutupFormHariLibur}
                                                  closeOnEsc={false}
                                                  closeOnOverlayClick={false}
                                                  styles={{ modal: { width: '50%' } }}
                                                >
                                                  <Form id="form-data" className="theme-form" onSubmit={this.simpanHariLibur}>
                                                    <div className="modal-header">
                                                      <h5 className="modal-title">{this.state.judulHariLibur}</h5>
                                                    </div>
                                                    <div className="modal-body">
                                                      <input
                                                        className="form-control"
                                                        id="uuid"
                                                        type="hidden"
                                                        defaultValue={this.state.dtHariLibur.uuid}
                                                      />
                                                      <FormGroup className="row">
                                                        <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                          Tanggal
                                                        </Label>
                                                        <Col sm="9">
                                                          <Datetime
                                                            inputProps={{ placeholder: 'Pilih Tanggal', required: true }}
                                                            inputId="tanggal"
                                                            name="tanggal"
                                                            locale="id-ID"
                                                            timeFormat={false}
                                                            dateFormat="YYYY/MM/DD"
                                                            // input={true}
                                                            closeOnTab={true}
                                                            closeOnSelect={true}
                                                            // initialValue={new Date()}
                                                            initialValue={new Date()}
                                                            value={this.state.dtHariLibur.tanggal}
                                                            onChange={e => {
                                                              if (typeof e.isValid !== 'undefined' && e._isValid) {
                                                                this.setState({ dtHariLibur : {
                                                                  ...this.state.dtHariLibur,
                                                                  tanggal : e.format('YYYY/MM/DD')
                                                                } })
                                                              } else {
                                                                this.setState({
                                                                  show: true,
                                                                  basicType: 'warning',
                                                                  basicTitle: 'Perhatian',
                                                                  pesanAlert: 'Isi data tanggal dengan benar',
                                                                  dtHariLibur: {
                                                                    ...this.state.dtHariLibur, 
                                                                    tanggal: new Date()
                                                                  }
                                                                });
                                                              }
                                                              // console.log(e);
                                                              // console.log(e._isValid);
                                                              // console.log(e.format('YYYY/MM/DD'));
                                                            }}
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                      <FormGroup className="row">
                                                        <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                          Keterangan
                                                        </Label>
                                                        <Col sm="9">
                                                          <input
                                                            className="form-control"
                                                            id="keterangan"
                                                            type="text"
                                                            placeholder="Contoh : Hari Raya Idul Fitri"
                                                            defaultValue={this.state.dtHariLibur.keterangan}
                                                            required
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                    <div className="modal-footer">
                                                      <button
                                                        type="button"
                                                        className="btn btn-warning"
                                                        disabled={this.state.statusHariLibur.btnForm}
                                                        onClick={this.tutupFormHariLibur}
                                                      >
                                                        Tutup
                                                      </button>
                                                      <button type="submit" className="btn btn-success" disabled={this.state.statusHariLibur.btnForm}>
                                                        Simpan
                                                      </button>
                                                    </div>
                                                  </Form>
                                                </Modal>
                                              </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                          <Row>
                                            <Col sm="12">

                                            {
                                              this.state.detailDataStat?
                                                <div className='row'>
                                                  <div className="col-sm-9">
                                                      <h5>Detail Rekap Absensi</h5>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                      <button onClick={() => { 
                                                        this.setState({ 
                                                          detailDataStat: false,
                                                          detailRekapAbsensi:[],
                                                          detailRekapAbsensiTemporary:[],
                                                        });
                                                      }}
                                                      color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
                                                    </div>
                                                    <div className="col-sm-12 text-left">
                                                      <div className="row">
                                                        <div className="col-sm-6">
                                                            <Select
                                                            classNamePrefix="select"
                                                            onChange={(e) => {
                                                              this.changeSelectValue(e, 'jenis_jam');
                                                            }}
                                                            defaultValue={this.state.jamStat}
                                                            value={this.state.jamStat}
                                                            name="Jam Absen"
                                                            options={this.state.frmJam}
                                                            placeholder="Pilih Jenis Jam Absen"
                                                            isClearable
                                                          />
                                                        </div>
                                                        <div className="col-sm-4 text-left">
                                                          <Button
                                                            color="success"
                                                            onClick={() => {
                                                              this.lihatRiwayatDetail();
                                                            }}
                                                          >
                                                            Lihat Riwayat
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  <div className="col-sm-12">
                                                    <div className="card-body datatable-react">
                                                      {dataDetailRekapAbsensi.length > 0 ? (
                                                      <>
                                                        <BootstrapTable keyField="id" data={dataDetailRekapAbsensi} columns={columnsDetailRekapAbsensi} />
                                                      </>
                                                      ) : (
                                                        <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              :
                                                <div className="row">
                                                  <div className="col-sm-12">
                                                    <Form className="theme-form" onSubmit={(e) => this.simpan(e, 0)}>
                                                      <div className="row">
                                                        <div className="col-sm-5">
                                                          <Datetime
                                                            locale="id-ID"
                                                            input={true} timeFormat={false}
                                                            closeOnTab={true}
                                                            initialValue={this.state.tgl_awal}
                                                            value={this.state.tgl_awal}
                                                            onChange={(e) => {
                                                              this.gantiTgl(e, 'tanggal_awal');
                                                            }}
                                                            inputProps={{
                                                              required: true,
                                                              placeholder: 'Pilih Tanggal Awal'
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="col-sm-5">
                                                          <Datetime
                                                            locale="id-ID"
                                                            input={true}
                                                            timeFormat={false}
                                                            closeOnTab={true}
                                                            initialValue={this.state.tgl_akhir}
                                                            value={this.state.tgl_akhir}
                                                            onChange={(e) => {
                                                              this.gantiTgl(e, 'tanggal_akhir');
                                                            }}
                                                            inputProps={{
                                                              placeholder: 'Pilih Tanggal Akhir'
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="col-sm-2">
                                                          <Button
                                                            color="success"
                                                            onClick={() => {
                                                              this.lihatRiwayat();
                                                            }}
                                                            style={{ width: '100%' }}
                                                          >
                                                            Cari
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </Form>
                                                  </div>
                                                  <div className="col-sm-12">
                                                    <div className="card-body datatable-react">
                                                      {this.state.loadingRekapAbsensi ? (
                                                        <div className="row">
                                                          <div className="col-sm-12" align="center">
                                                            <img
                                                              alt="loading"
                                                              src={require("../../../assets/images/tes-loading.gif")}
                                                              style={{
                                                                borderRadius: "10px",
                                                                width: '100px'
                                                              }} />
                                                          </div>
                                                        </div>
                                                      ) : dataRekapAbsensi.length > 0 ? (
                                                        <>
                                                      <BootstrapTable keyField="id" data={dataRekapAbsensi} columns={columnsRekapAbsensi} />
                                                      <div className="pull-right text-white">
                                                        {this.state.awalRekapAbsensi}
                                                        {this.state.sebelumRekapAbsensi}
                                                        {this.state.halRekapAbsensi.map((dt) => {
                                                          return dt;
                                                        })}
                                                        {this.state.setelahRekapAbsensi}
                                                        {this.state.akhirRekapAbsensi}
                                                      </div>
                                                        </>
                                                      ) : (
                                                        <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                              
                                            
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                          <Row>
                                            <Col sm="12">
                                              <div className="row">
                                                {this.state.loadingQrCode ? (
                                                  <div className="col-sm-12">
                                                    <div align="center">
                                                      <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang Proses Download...</h5>
                                                      <br />
                                                      <img
                                                        alt="loading"
                                                        src={require("../../../assets/images/tes-loading.gif")}
                                                        style={{
                                                          // width: "100%",
                                                          borderRadius: "10px",
                                                          width: '150px'
                                                        }}
                                                      />
                                                      <br /><br />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="container-fluid">
                                                    <div className="row">
                                                      <div className="col-sm-12">
                                                        <div className="col-sm-12">
                                                          <b><font color='red'>CATATAN:</font></b> <br/>
                                                          QRCode yang terdownload/terunduh merupakan QRCode yang digunakan oleh perangkat desa untuk melakukan <b>Absensi Secara Digital</b> di Aplikasi <b>AMANAT</b>
                                                        </div>
                                                        <div className="col-sm-12" align='center'>
                                                          &nbsp;
                                                        </div>
                                                        <div className="col-sm-12" align='center'>
                                                          <Button
                                                            size="sm"
                                                            color="secondary"
                                                            className="btn-square"
                                                            onClick={() => {
                                                                this.setState({
                                                                    loadingQrCode: true,
                                                                });
                                                                this.downloadQrCodeToken();
                                                            }}
                                                          >
                                                              Download QRCode Absen
                                                          </Button>
                                                        </div>

                                                        {/* <div className='col-12'>
                                                          <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                                                            <QRCode value='https://ws-dgd-spt0761.digidesa.com/api/v1/web/absensi/qrcode' renderAs="canvas" />
                                                          </div>
                                                        </div> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

export default BasicTable;