import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { Post, Get, Delete, ID_DESA, JENIS_DESA } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';

import ModalTandaTangan from "./modal/tanda-tangan"
import ModalStrukturOrganisasiDesa from "./modal/struktur-organisasi-desa";
import { DataToken } from '../../../function/Format';
var HtmlToReactParser = require("html-to-react").Parser;
// import ModalLembaga from "./modal/lembaga";
// import ModalDetailLembaga from "./modal/lembaga-detail";


// const frmDef = {
// 	uuid: '',
// 	nama: ''
// };

const frmTTD = {
  uuid: '',
  jabatan  : '',
  jabatan_pengganti  : '',
  nama  : '',
  nip  : '',
  status  : '',
}

const frmLembaga = {
  uuid: '',
  nama_lembaga: '',
  tahun_berdiri: '',
  status: 1,
}

const frmDetailLembaga = {
  uuid: '',
  uuid_lembaga: '',
  nama: '',
  jabatan: '',
  tanggal_mulai: '',
  tanggal_selesai: '',
  status: 1,
  foto: ""
}

class BasicTable extends Component {
  _isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_detail: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			foto_desa: '',
			ket: null,
			foto_info: '',
			input: 'none',
			view: 'inherit',
			judul: 'Tambah Profile',
			alert: null,
			show: false,
			loading: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			akun: DataToken(sessionStorage.getItem('access_token')),

      // DATA STRUKTUR ORGANISASI DESA
      statusStrukturDesa: {
        form:false,
        btnForm:false,
        btnAksi:false
      },

      // DATA LEMBAGA
        dataLembaga: [],
        paginationLembaga: [],
        statusLembaga: {
            form:false,
            btnForm:false,
            btnAksi:false
        },
        awalLembaga: "",
        sebelumLembaga: "",
        halLembaga: [],
        setelahLembaga: "",
        akhirLembaga: "",
        dtLembaga:frmLembaga,
        loadingLembaga: false,
        judulLembaga: "Tambah Lembaga",
        // =================================
        alertLembaga:null,
        showLembaga: false,
        basicTitleLembaga:'',
        basicTypeLembaga:"default",
        pesanAlertLembaga:'',
        // =================================
      // END DATA STRUKTUR LEMBAGA

      // DATA TANDA TANGAN
        dataTTD: [],
        paginationTTD: [],
        statusTTD: {
            form:false,
            btnForm:false,
            btnAksi:false
        },
        awalTTD: "",
        sebelumTTD: "",
        halTTD: [],
        setelahTTD: "",
        akhirTTD: "",
        dtTTD:frmTTD,
        loadingTTD: false,
        judulTTD: "Tambah Tanda Tangan",
        // =========== Select 2 ============
        frmPrgTTD: [],
        statusStatTTD: [],
        prgStatTTD: [],
        prgStatTTD2: [],
        // =================================
        alertTTD:null,
        showTTD: false,
        basicTitleTTD:'',
        basicTypeTTD:"default",
        pesanAlertTTD:'',
        // =================================
        frmPenggantiTTD: 'none',    
			  isKelurahanTTD: false,
      // END DATA TANDA TANGAN

			
			// Tab Vertical
        active_tab_vertical: '1',

      // Detail Data Lembaga
        detailDataStat: false,
        detailLembaga:[],
        dataDetailLembaga:[],
        paginationDetailLembaga: [],
        statusDetailLembaga: {
            form:false,
            btnForm:false,
            btnAksi:false
        },
        awalDetailLembaga: "",
        sebelumDetailLembaga: "",
        halDetailLembaga: [],
        setelahDetailLembaga: "",
        akhirDetailLembaga: "",
        dtDetailLembaga:frmDetailLembaga,
        loadingDetailLembaga: false,
        judulDetailLembaga: "Tambah Detail Lembaga",
        // =================================
        alertDetailLembaga:null,
        showDetailLembaga: false,
        basicTitleDetailLembaga:'',
        basicTypeDetailLembaga:"default",
        pesanAlertDetailLembaga:'',
      //  End Detail Data Lembaga
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// Toggle Vertical Tab
	toggle_vertical(tab) {
        if (this.state.active_tab_vertical !== tab) {
            this.setState({
                active_tab_vertical: tab,
                detailDataStat: false,
                detailLembaga: [],
            });
        }
    }

	// ================== Ambil data dari db untuk table ==================
	fetch = () => {
		this.setState({ loading: true })
		Get('profile-desa', null, (dtkat) => {
			if (dtkat.results) {
				let defaultKet = {
					no_wa: '',
					testimoni: '',
					narasi: '',
				}

				let getKet = dtkat.results.ket ? dtkat.results.ket : JSON.stringify(defaultKet)
				this.setState({ 
					data: dtkat.results,
					foto_desa: dtkat.results.foto_desa,
					ket: JSON.parse(getKet)
				});
			} else {
				this.setState({
					input: 'none',
				})
				this.forceUpdate();
			}
			this.setState({ loading: false })
		});
	};


	// ========================================================================

	tutupForm = (e) => {
		this.setState({
			input: 'none',
			view: 'inherit',
			status: {
				...this.state.status,
				form: false,
			},
		});
		this.forceUpdate()
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.setState({
			status: {
				...this.state.status,
				btnForm: true,
			},
		})
		this.forceUpdate();
		let uuid = this.state.data.uuid;
		let addProfile = {
			id_keldes: ID_DESA,
			nama_desa: document.getElementById('nama_desa').value,
			// kode_desa: document.getElementById('kode_desa').value,
			kode_desa: ID_DESA + document.getElementById('nama_desa').value,
			alamat_desa: document.getElementById('alamat_desa').value,
			kode_pos: document.getElementById('kode_pos').value,
			notelp: document.getElementById('notelp').value,
			email: document.getElementById('email').value,
			fax: this.state.data.fax,
			website: document.getElementById('website').value,
			batas_utara: document.getElementById('batas_utara').value,
			batas_timur: document.getElementById('batas_timur').value,
			batas_selatan: document.getElementById('batas_selatan').value,
			batas_barat: document.getElementById('batas_barat').value,
      link_fb: document.getElementById('link_fb').value,
			link_ig: document.getElementById('link_ig').value,
			link_youtube: document.getElementById('link_youtube').value,
			ket: JSON.stringify({
				no_wa: document.getElementById('whatsapp').value,
				testimoni: document.getElementById('testimoni').value,
				narasi: document.getElementById('narasi').value,
				// link_fb: document.getElementById('link_fb').value,
				// link_ig: document.getElementById('link_ig').value,
				// link_yt: document.getElementById('link_yt').value,
			})
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (uuid === '' || uuid === 'undefined' || uuid === null) {
			psn = "Tambah";
			resstat = 201;
			addProfile.uuid = null;
			addProfile.foto_desa = this.state.foto_desa;

		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
			addProfile.uuid = uuid

			if (this.state.foto_desa) {
				addProfile.foto_desa = this.state.foto_desa;
			}
			else {
				addProfile.foto_desa = this.state.data.foto_desa;
			}
		}

		//console.log("Data Simpan")
		//console.log(addProfile)
		//console.log(uuid)
		//console.log(psn+ '/'+resstat+'/'+metode)

		Post('profile-desa/' + metode, addProfile.uuid, addProfile, (res) => {
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Profile',
					pesanAlert: 'Berhasil ' + psn + ' Data',
					status: {
						...this.state.status,
						form: false,
					},
				});
				this.tutupForm()
				this.forceUpdate()
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Profile',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			// this.setState({ input: 'none', view: 'inherit' });
			this.fetch();
		});
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (status, uuid) => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
			judul: 'Ubah Profil ' + JENIS_DESA,
		})
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};
	// ========================================================================

	componentDidMount() {
		this.fetch({ page: null, where: [] });
		// this.fetchLembaga({ page: null, where: [] });
		// this.fetchDetailLembaga({ page: null, where: [] });

    // DATA TANDA TANGAN
    this._isMounted = true;
    this.fetchTTD({page:null,where:[]});
    Get('jabatan-perangkat/list', null, (data) => {
      let frmPrgTTD = [];
      data.results.forEach((dt) => {
          frmPrgTTD.push({value:dt.nama, label:dt.nama});
      });
      this.setState({frmPrgTTD});
    })
    Get('select-enum/tanda_tangan/status', null, (data) => {
      let frmStatusTTD = [];
      data.forEach((dt) => {
          frmStatusTTD.push({value:dt, label:dt});
      });
      this.setState({frmStatusTTD});
    })

    if (JENIS_DESA.toLowerCase() === 'kelurahan') {
      this.setState({ isKelurahanTTD: true });
    }
    // END DATA TANDA TANGAN
	}

  
  componentWillUnmount() {
    this._isMounted = false;
  }

	processGbr = (d) => {
		// //console.log(d[0].type);
		let type = d[0].type.split('/');
		if (
			type[1] === 'jpg' ||
			type[1] === 'jpeg' ||
			type[1] === 'png' ||
			type[1] === 'bmp' ||
			type[1] === 'tiff' ||
			type[1] === 'webp'
		) {
			Resizer.imageFileResizer(
				d[0],
				300,
				300,
				type[1],
				100,
				0,
				(uri) => {
					this.setState({ foto_desa: uri, foto_info: '' });
				},
				'base64'
			);
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
			});
		}
	};

  // DATA STRUKTUR ORGANISASI DESA
  
  tambahDataStrukturDesa = e => {
    this.setState({
      statusStrukturDesa: {
        ...this.state.statusStrukturDesa,
        form: true
      },
      judul: "Struktur Organisasi Pemerintah "+{JENIS_DESA},
    });
  };

  // DATA LEMBAGA
    // ================== Sweet Alert ==================
    closeAlertLembaga = () => {
      this.setState({
        showLembaga: false
      });
    }

    onRecieveInputLembaga = (value) => {
      this.setState({
        alertLembaga: (
            <SweetAlert success title="Nice!" onConfirm={this.hideAlertLembaga}>
                You wrote: {value}
            </SweetAlert>
        )
      });
    }

    hideAlertLembaga = () => {
      this.setState({
          alertLembaga: null
      });
    }

  // ================== Ambil data dari db untuk table Lembaga ==================
  fetchLembaga = (params = {}) => 
  {    
      this.setState({ loadingLembaga: true })
      let linkLembaga = "";
      if (params.page)
      {
          switch(params.page)
          {
              case '>>': linkLembaga = "?page="+(Math.ceil(this.state.paginationLembaga.total / this.state.paginationLembaga.per_page));break;
              case '>': linkLembaga = "?page="+(parseInt(this.state.paginationLembaga.current_page) + 1);break;
              case '<': linkLembaga = "?page="+(parseInt(this.state.paginationLembaga.current_page) - 1);break;
              case '<<': linkLembaga = "?page=1";break;
              default: linkLembaga = "?page="+params.page;break;
          }
      }
      Get('lembaga-kemasyarakatan'+linkLembaga, null, (dtkat) => {
        console.log('data lembaga')
        console.log(dtkat)
        if (this._isMounted) {
          if (dtkat.results) {
            this.setState({dataLembaga:dtkat.results.data, paginationLembaga:dtkat.results, loadingLembaga: false});
            this.paginationLembaga(dtkat.results);
          }
        }
      });
  }

  // paginationLembaga
  paginationLembaga = (data) => {
    // const data = this.state.paginationLembaga;
    //console.log(data);
    let awalLembaga = "";
    let halLembaga = [];
    let sebelumLembaga = "";
    let setelahLembaga = "";
    let akhirLembaga = "";
    if (data.total > 0)
    {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3)
        {   
            start = 1;
            end = 5;
            if (data.last_page > data.current_page)
            {
                n = data.current_page + 1;
            }
        }
        else 
        {
            p = data.current_page - 1;
            n = data.current_page + 1;
            start = data.current_page - 2;
            end = data.current_page + 2;
        }
        if (end >= data.last_page - 2)
        {
            p = data.current_page - 1;
            if (start >= 5)
            {
                start = data.last_page - 4;
            }
            end = data.last_page;
        }

        for (let i=start;i<=end;i++) 
        {
            let warna = "primary-custom";
            if (i === data.current_page)
            {
                warna = "danger"
            }
            halLembaga.push(<a href={() => false} onClick={()=>this.fetchLembaga({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        }
        if (p > 0)
        {
            sebelumLembaga = (<a href={() => false} onClick={()=>this.fetchLembaga({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        }
        if (n > 0)
        {
            setelahLembaga = (<a href={() => false} onClick={()=>this.fetchLembaga({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        }
        awalLembaga = (<a href={() => false} onClick={()=>this.fetchLembaga({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhirLembaga = (<a href={() => false} onClick={()=>this.fetchLembaga({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
    }
    this.setState({awalLembaga,sebelumLembaga,halLembaga,setelahLembaga,akhirLembaga});
  }

   // Cari Data Table
  cariDataLembaga = e => {
    if (e.key === "Enter")
    {
        let addTandaTangan = {
            nama : e.target.value,
        };
        Post('tanda-tangan',null, addTandaTangan, (data) => {
            this.setState({dataLembaga:data.data.results.data, paginationLembaga:data.data.results});
        });
    }
  }

  // ============================== Tambah Data ===============================
  
  tambahDataLembaga = e => {
    this.setState({
      statusLembaga: {
        ...this.state.statusLembaga,
        form: true
      },
      judul: "Tambah Lembaga Kemasyarakatan",
      dtLembaga: frmLembaga,
    });
  };

  // ============================== Ubah Data ===============================
    
  ubahDataLembaga = (data) => {
    this.setState({
        statusLembaga:{
            ...this.state.statusLembaga,
            btnAksi: true,
        },
        judulLembaga: "Ubah Lembaga",
    })
    this.forceUpdate();
      this.setState({
          dtLembaga: data,
          statusLembaga:{
              ...this.state.statusLembaga,
              btnAksi: false,
          }
      })
      this.bukaFormLembaga();
  }

  // ============================== Ubah Data ===============================
    
  detailDataLembaga = (data) => {
    console.log('data detail')
    console.log(data)
    this.setState({
        detailDataStat: true,
        detailLembaga:data,
        statusDetailLembaga:{
            ...this.state.statusDetailLembaga,
            btnAksi: true,
        },
        judulDetailLembaga: "Detail Lembaga",
    })
    this.forceUpdate();
    
    this.componentDidMount();
  }

  
  // ============================== Hapus Data ===============================

  konfirmHapusLembaga = (id) => {
    this.setState({
        alertLembaga: (
            <SweetAlert
                showCancel
                confirmBtnText="Hapus"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                type="danger"
                title="Yakin ingin hapus data ?"
                onCancel={this.hideAlertLembaga}
                onConfirm={()=> this.hapusDataLembaga(id)}
            >
                Proses ini tidak dapat dibatalkan!
            </SweetAlert>
        )
    });
  }

  hapusDataLembaga = (id) => {
      this.hideAlertLembaga();
      let psn = "Hapus";
      console.log('delete')
      console.log(id)
      Delete('lembaga-kemasyarakatan/delete', id, (res) => {
          if (res === 200)
          {
              this.setState({ showLembaga: true, basicTypeLembaga:'success', basicTitleLembaga:'Data Lembaga', pesanAlertLembaga:'Berhasil '+psn+' Data' });
          }
          else
          {
              this.setState({ showLembaga: true, basicTypeLembaga:'danger', basicTitleLembaga:'Data Lembaga', pesanAlertLembaga:'Gagal '+psn+' Data' });
          }
          this.componentDidMount();
      })
  }

  tombolAksiLembaga = (cell, row) => {
    return (
      <>
          <Button  size="xs" color="btn btn-secondary" className="btn-icon" onClick={() => this.detailDataLembaga(row)} disabled={this.state.statusLembaga.btnAksi}><i className="fa fa-eye"></i></Button> <br/>
          <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataLembaga(row)} disabled={this.state.statusLembaga.btnAksi}><i className="fa fa-pencil"></i></Button> <br/>
          <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapusLembaga(row.uuid)} disabled={this.state.statusLembaga.btnAksi}><i className="fa fa-trash"></i></Button>  
      </>
    )
  }

  // ================== simpan data ==================
   
  bukaFormLembaga = () => {
      this.setState({
          statusLembaga:{
              ...this.state.statusLembaga,
              form: true,
          }
      })
      this.forceUpdate();
  }

  // END DATA LEMBAGA

  // DATA DETAIL LEMBAGA
  
  // ================== Ambil data dari db untuk table Lembaga ==================
  fetchDetailLembaga = (params = {}) => 
  {    
      this.setState({ loadingDetailLembaga: true })
      let linkDetailLembaga = "";
      if (params.page)
      {
          switch(params.page)
          {
              case '>>': linkDetailLembaga = "?page="+(Math.ceil(this.state.paginationDetailLembaga.total / this.state.paginationDetailLembaga.per_page));break;
              case '>': linkDetailLembaga = "?page="+(parseInt(this.state.paginationDetailLembaga.current_page) + 1);break;
              case '<': linkDetailLembaga = "?page="+(parseInt(this.state.paginationDetailLembaga.current_page) - 1);break;
              case '<<': linkDetailLembaga = "?page=1";break;
              default: linkDetailLembaga = "?page="+params.page;break;
          }
      }
      Get('perangkat-lembaga'+linkDetailLembaga, null, (dtkat) => {
        console.log('data DetailLembaga')
        console.log(dtkat)
        if (this._isMounted) {
          if (dtkat.results) {
            this.setState({dataDetailLembaga:dtkat.results.data, paginationDetailLembaga:dtkat.results, loadingDetailLembaga: false});
            this.paginationDetailLembaga(dtkat.results);
          }
        }
      });
  }

  // paginationLembaga
  paginationDetailLembaga = (data) => {
    // const data = this.state.paginationDetailLembaga;
    //console.log(data);
    let awalDetailLembaga = "";
    let halDetailLembaga = [];
    let sebelumDetailLembaga = "";
    let setelahDetailLembaga = "";
    let akhirDetailLembaga = "";
    if (data.total > 0)
    {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3)
        {   
            start = 1;
            end = 5;
            if (data.last_page > data.current_page)
            {
                n = data.current_page + 1;
            }
        }
        else 
        {
            p = data.current_page - 1;
            n = data.current_page + 1;
            start = data.current_page - 2;
            end = data.current_page + 2;
        }
        if (end >= data.last_page - 2)
        {
            p = data.current_page - 1;
            if (start >= 5)
            {
                start = data.last_page - 4;
            }
            end = data.last_page;
        }

        for (let i=start;i<=end;i++) 
        {
            let warna = "primary-custom";
            if (i === data.current_page)
            {
                warna = "danger"
            }
            halDetailLembaga.push(<a href={() => false} onClick={()=>this.fetchDetailLembaga({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        }
        if (p > 0)
        {
            sebelumDetailLembaga = (<a href={() => false} onClick={()=>this.fetchDetailLembaga({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        }
        if (n > 0)
        {
            setelahDetailLembaga = (<a href={() => false} onClick={()=>this.fetchDetailLembaga({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        }
        awalDetailLembaga = (<a href={() => false} onClick={()=>this.fetchDetailLembaga({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhirDetailLembaga = (<a href={() => false} onClick={()=>this.fetchDetailLembaga({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
    }
    this.setState({awalDetailLembaga,sebelumDetailLembaga,halDetailLembaga,setelahDetailLembaga,akhirDetailLembaga});
  }

  
  // ============================== Tambah Data ===============================
  
  tambahDetailLembaga = e => {
    this.setState({
      statusDetailLembaga: {
        ...this.state.statusDetailLembaga,
        form: true
      },
      judulDetailLembaga: "Tambah Detail Lembaga Kemasyarakatan",
      dtDetailLembaga: frmDetailLembaga,
    });
  };

  // END DATA DETAIL LEMBAGA



  // DATA TANDA TANGAN
    // ================== Sweet Alert ==================
    closeAlertTTD = () => {
      this.setState({
        showTTD: false
      });
    }

    onRecieveInputTTD = (value) => {
      this.setState({
        alertTTD: (
            <SweetAlert success title="Nice!" onConfirm={this.hideAlertTTD}>
                You wrote: {value}
            </SweetAlert>
        )
      });
    }

    hideAlertTTD = () => {
      this.setState({
          alertTTD: null
      });
    }

  // ================== Ambil data dari db untuk table TTD ==================
  fetchTTD = (params = {}) => 
  {    
      this.setState({ loadingTTD: true })
      let linkTTD = "";
      // let where = [];
      // if (params.where)
      // {
      //     where = params.where;
      // }
      if (params.page)
      {
          switch(params.page)
          {
              case '>>': linkTTD = "?page="+(Math.ceil(this.state.paginationTTD.total / this.state.paginationTTD.per_page));break;
              case '>': linkTTD = "?page="+(parseInt(this.state.paginationTTD.current_page) + 1);break;
              case '<': linkTTD = "?page="+(parseInt(this.state.paginationTTD.current_page) - 1);break;
              case '<<': linkTTD = "?page=1";break;
              default: linkTTD = "?page="+params.page;break;
          }
      }
      Get('tanda-tangan'+linkTTD, null, (dtkat) => {
        if (this._isMounted) {
          if (dtkat.results) {
            this.setState({dataTTD:dtkat.results.data, paginationTTD:dtkat.results, loadingTTD: false});
            this.paginationTTD(dtkat.results);
          }
        }
      });
  }

  // paginationTTD
  paginationTTD = (data) => {
    // const data = this.state.paginationTTD;
    //console.log(data);
    let awalTTD = "";
    let halTTD = [];
    let sebelumTTD = "";
    let setelahTTD = "";
    let akhirTTD = "";
    if (data.total > 0)
    {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3)
        {   
            start = 1;
            end = 5;
            if (data.last_page > data.current_page)
            {
                n = data.current_page + 1;
            }
        }
        else 
        {
            p = data.current_page - 1;
            n = data.current_page + 1;
            start = data.current_page - 2;
            end = data.current_page + 2;
        }
        if (end >= data.last_page - 2)
        {
            p = data.current_page - 1;
            if (start >= 5)
            {
                start = data.last_page - 4;
            }
            end = data.last_page;
        }

        for (let i=start;i<=end;i++) 
        {
            let warna = "primary-custom";
            if (i === data.current_page)
            {
                warna = "danger"
            }
            halTTD.push(<a href={() => false} onClick={()=>this.fetchTTD({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        }
        if (p > 0)
        {
            sebelumTTD = (<a href={() => false} onClick={()=>this.fetchTTD({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        }
        if (n > 0)
        {
            setelahTTD = (<a href={() => false} onClick={()=>this.fetchTTD({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        }
        awalTTD = (<a href={() => false} onClick={()=>this.fetchTTD({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhirTTD = (<a href={() => false} onClick={()=>this.fetchTTD({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
    }
    this.setState({awalTTD,sebelumTTD,halTTD,setelahTTD,akhirTTD});
  }

   // Cari Data Table
  cariDataTTD = e => {
    if (e.key === "Enter")
    {
        let addTandaTangan = {
            nama : e.target.value,
        };
        Post('tanda-tangan',null, addTandaTangan, (data) => {
            this.setState({dataTTD:data.data.results.data, paginationTTD:data.data.results});
        });
    }
  }

  // ============================== Tambah Data ===============================
  
  tambahData = e => {
    this.setState({
      statusTTD: {
        ...this.state.statusTTD,
        form: true
      },
      judul: "Tambah Tanda Tangan",
      dtTTD: null,
      frmPenggantiTTD:'none',
      statusStatTTD: [],
      prgStatTTD: [],
      prgStatTTD2: [],
    });
  };

  // ============================== Ubah Data ===============================
    
  ubahDataTTD = (id) => {
    this.setState({
        statusTTD:{
            ...this.state.statusTTD,
            btnAksi: true,
        },
        judulTTD: "Ubah Tanda Tangan",
    })
    this.forceUpdate();
    Get('tanda-tangan/find',id, (data) => {
        this.setState({
            dtTTD: data.results,
            prgStatTTD: "Kepala Desa",
            prgStatTTD2: {value:data.results.jabatan_pengganti, label:data.results.jabatan_pengganti},
            statusStatTTD: {value:data.results.status, label:data.results.status},
        })
        // this.state.prgStat = {value:data.results.jabatan, label:data.results.jabatan};
        if(data.results.jabatan_pengganti && data.results.status){
            this.setState({
                frmPenggantiTTD: "inherit",
            })
        }
        else{
            this.setState({
                frmPenggantiTTD: "none",
            })
        }
        this.setState({
            statusTTD:{
                ...this.state.statusTTD,
                btnAksi: false,
            }
        })
        this.bukaFormTTD();
    });
  }

  
  // ============================== Hapus Data ===============================

  konfirmHapusTTD = (id) => {
    this.setState({
        alertTTD: (
            <SweetAlert
                showCancel
                confirmBtnText="Hapus"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                type="danger"
                title="Yakin ingin hapus data ?"
                onCancel={this.hideAlertTTD}
                onConfirm={()=> this.hapusDataTTD(id)}
            >
                Proses ini tidak dapat dibatalkan!
            </SweetAlert>
        )
    });
  }

  hapusDataTTD = (id) => {
      this.hideAlertTTD();
      let psn = "Hapus";
      Delete('tanda-tangan/delete', id, (res) => {
          if (res === 200)
          {
              this.setState({ showTTD: true, basicTypeTTD:'success', basicTitleTTD:'Data Tanda Tangan', pesanAlertTTD:'Berhasil '+psn+' Data' });
          }
          else
          {
              this.setState({ showTTD: true, basicTypeTTD:'danger', basicTitleTTD:'Data Tanda Tangan', pesanAlertTTD:'Gagal '+psn+' Data' });
          }
          this.componentDidMount();
      })
  }

  tombolAksiTTD = (cell, row) => {
    return (
      <>
          <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataTTD(row.uuid)} disabled={this.state.statusTTD.btnAksi}><i className="fa fa-pencil"></i></Button> <br/>
          {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
          <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapusTTD(row.uuid)} disabled={this.state.statusTTD.btnAksi}><i className="fa fa-trash"></i></Button>  
          ) : ''}
      </>
    )
  }

  // ================== simpan data ==================
   
  bukaFormTTD = () => {
      this.setState({
          statusTTD:{
              ...this.state.statusTTD,
              form: true,
          }
      })
      this.forceUpdate();
  }

  // END DATA TTD

	render() {
    var dataTTD = this.state.dataTTD;
    var dataLembaga = this.state.dataLembaga;
    var dataDetailLembaga = this.state.dataDetailLembaga;

    const frmTTD = {
      uuid: '',
      jabatan  : '',
      jabatan_pengganti  : '',
      nama  : '',
      nip  : '',
      status  : '',
    }

    const columns = [
      {
          dataField: "no",
          text: "No",
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationTTD.current_page;
              let total_records_per_page = this.state.paginationTTD.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
          dataField: 'nip',
          text: 'NIP',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'nama',
          text: 'Nama',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'jabatan',
          text: 'Jabatan',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'status',
          text: 'Status',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'jabatan_pengganti',
          text: 'Jabatan Pengganti',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'aktif',
          text: 'Utama',
          isDummyField: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, data) => data.aktif==="1"?"Ya":"Tidak",
          sort: true
      },
      {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiTTD,
      },
    ];

    const columnsLembaga = [
      {
          dataField: "no",
          text: "No",
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationLembaga.current_page;
              let total_records_per_page = this.state.paginationLembaga.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
          dataField: 'nama_lembaga',
          text: 'Nama Lembaga',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'tahun_berdiri',
          text: 'Tanggal Berdiri',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
     
      {
          dataField: 'status',
          text: 'Status',
          isDummyField: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, data) => data.status===1?"Ya":"Tidak",
          sort: true
      },
      {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiLembaga,
      },
    ];

    const columnsDetailLembaga = [
      {
          dataField: "no",
          text: "No",
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationDetailLembaga.current_page;
              let total_records_per_page = this.state.paginationDetailLembaga.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
          dataField: 'nama',
          text: 'Nama Perangkat',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'tanggal_mulai',
          text: 'Tanggal Mulai',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'tanggal_selesai',
          text: 'Tanggal Selesai',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
     
      {
          dataField: 'status',
          text: 'Status',
          isDummyField: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, data) => data.status===1?"Ya":"Tidak",
          sort: true
      },
      {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiLembaga,
      },
    ];
    
    var htmlToReactParser = new HtmlToReactParser();

		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

        <SweetAlert
            show={this.state.showTTD}
            type={this.state.basicTypeTTD}
            title={this.state.basicTitleTTD}
            confirmBtnBsStyle="success"
            onConfirm={this.closeAlertTTD}
            style={{ maxWidth: "478px" }}
        >
            {this.state.pesanAlertTTD}
        </SweetAlert>

        {this.state.alertTTD}

        <SweetAlert
            show={this.state.showLembaga}
            type={this.state.basicTypeLembaga}
            title={this.state.basicTitleLembaga}
            confirmBtnBsStyle="success"
            onConfirm={this.closeAlertLembaga}
            style={{ maxWidth: "478px" }}
        >
            {this.state.pesanAlertLembaga}
        </SweetAlert>

        {this.state.alertLembaga}

				
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
									<div className="row">
										<div className="col-lg-3">
											<Nav  className={'nav flex-column nav-pills'}>
												<NavItem>
													<NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
															onClick={() => { this.toggle_vertical('1'); }}>
														Profil
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink className={classnames({ active: this.state.active_tab_vertical === '2' })}
															onClick={() => { this.toggle_vertical('2'); }}>
														Struktur Organisasi {JENIS_DESA}
													</NavLink>
												</NavItem>
                        {/* <NavItem>
													<NavLink className={classnames({ active: this.state.active_tab_vertical === '3' })}
															onClick={() => { this.toggle_vertical('3'); }}>
														Struktur Lembaga Kemasyarakatan {JENIS_DESA}
													</NavLink>
												</NavItem> */}
												<NavItem>
													<NavLink
														className={classnames({ active: this.state.active_tab_vertical === '4' })}
														onClick={() => { this.toggle_vertical('4'); }} >
														Tanda Tangan
													</NavLink>
												</NavItem>
											</Nav>
										</div>
										<div className="col-lg-9">
											<TabContent activeTab={this.state.active_tab_vertical}>
												<TabPane tabId="1">
													<Row>
														<Col sm="12">
															{this.state.loading ? (
                                <div align="center">
                                  <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
                                  <br />
                                  <img
                                    alt="loading"
                                    src={require("../../../assets/images/tes-loading.gif")}
                                    style={{
                                      // width: "100%",
                                      borderRadius: "10px",
                                      width: '150px'
                                    }}
                                  />
                                  <br /><br />
                                </div>
							  							) : (
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="card mb-0" style={{ borderRadius: '25px', boxShadow: "0 1px 11px 0 rgb(160 160 160)" }}>
                                        <div className="card-body" style={{padding: '19px'}}>
                                          <div className="row">
                                            <div className="col-md-12 text-right mb-0">
                                              {
                                                this.state.data.uuid
                                                  ?
                                                  <Button size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update', this.state.data.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Data Profil</Button>
                                                  :
                                                  <Button size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create', '')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Data Profil</Button>
                                              }
                                            </div>
                                          </div>
                                          <div
                                            className="row pt-4"
                                            style={{ padding: '20px', margin: '0' }}
                                          >
                                            {
                                              this.state.data.uuid
                                                ?
                                                <>
                                                  <div className="col-md-12">
																										<div className='d-flex mb-3' style={{ justifyContent: "center" }}>
																											<img alt="fotodesa" src={this.state.data.foto_desa} style={{ maxHeight: '200px', maxWidth: '100%', height: 'auto' }} />
																										</div>
                                                    <div className="table-responsive">
                                                      <table>
                                                        <thead style={{ fontSize: "1rem", verticalAlign: "top" }}>
                                                          <tr>
                                                            <th width="180">ID  {JENIS_DESA}</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.id_keldes}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Nama {JENIS_DESA}</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.nama_desa}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Alamat {JENIS_DESA}</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.alamat_desa}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Kode POS</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.kode_pos}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>No. Telp.</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.notelp}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>No. WhatsApp</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.ket.no_wa}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Email</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.email}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Website</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.website}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Link Facebook</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td>
                                                              <span>
                                                                <a target='_blank' href={this.state.data.link_fb ? this.state.data.link_fb : '#'}>{this.state.data.link_fb ? this.state.data.link_fb : ''}</a>
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <th>Link Instagram</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td>
                                                              <span>
                                                                <a target='_blank' href={this.state.data.link_ig ? this.state.data.link_ig : '#'}>{this.state.data.link_ig ? this.state.data.link_ig : ''}</a>
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <th>Link Youtube</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td>
                                                              <span>
                                                                <a target='_blank' href={this.state.data.link_youtube ? this.state.data.link_youtube : '#'}>{this.state.data.link_youtube ? this.state.data.link_youtube : ''}</a>
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <th>Batas Wilayah Utara</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.batas_utara}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Batas Wilayah Timur</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.batas_timur}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Batas Wilayah Selatan</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.batas_selatan}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Batas Wilayah Barat</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.data.batas_barat}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Testimoni Kepala {JENIS_DESA}</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.ket && this.state.ket.testimoni ? this.state.ket.testimoni : '-'}</span></td>
                                                          </tr>
                                                          <tr>
                                                            <th>Narasi Singkat {JENIS_DESA}</th>
                                                            <th width="50" className="text-center">:</th>
                                                            <td><span>{this.state.ket && this.state.ket.narasi ? this.state.ket.narasi : '-'}</span></td>
                                                          </tr>
                                                        </thead>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </>
                                                :
                                                (
                                                  <div className="col-md-12 text-center">
                                                    <h4 align="center">Data Tidak Dapat Ditemukan</h4>
                                                  </div>
                                                )
                                            }
                                          </div>
                                        </div>

                                        

                                      </div>
                                    </div>
                                  </div>
                                  <Modal
                                    open={this.state.status.form}
                                    onClose={this.tutupForm}
                                    styles={{ modal: { width: '75%', maxWidth: 'unset' } }}
                                    closeOnEsc={false}
                                    closeOnOverlayClick={false}
                                  >
                                    <Form className="theme-form" onSubmit={this.simpan}>
                                      <div className="modal-header">
                                        <h4 className="modal-title">{this.state.judul}</h4>
                                      </div>

                                      <div className="modal-body">
                                        <input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid} />

                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="row text-center" style={{ justifyContent: "center" }}>
                                              <div className="col-md-4">
                                                <Label className="col-form-label" htmlFor="inputEmail3">
                                                  Logo Kab/Kota
                                                </Label>
                                                <Dropzone
                                                  onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)}
                                                  maxFiles={1}
                                                >
                                                  {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                      <div
                                                        {...getRootProps()}
                                                        style={{
                                                          minHeight: '100px',
                                                          paddingTop: '40px',
                                                          padding: '5px 5px 5px 5px',
                                                          border: '1px solid #d2d2d2'
                                                        }}
                                                      >
                                                        <input {...getInputProps()} />
                                                        <p>
                                                          {this.state.foto_desa ? (
                                                            <img
                                                              alt="fotodesa2"
                                                              src={this.state.foto_desa}
                                                              style={{
                                                                minHeight: '50px',
                                                                minWidth: '50px',
                                                                width: '100%'
                                                              }}
                                                            />
                                                          ) : (
                                                            "Drag 'n' drop some files here, or click to select files"
                                                          )}
                                                          {this.state.foto_info ? (
                                                            this.state.foto_info
                                                          ) : (
                                                            ''
                                                          )}
                                                        </p>
                                                      </div>
                                                    </section>
                                                  )}
                                                </Dropzone>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Nama {JENIS_DESA}
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="nama_desa"
                                              type="text"
                                              placeholder={'Nama ' + JENIS_DESA}
                                              defaultValue={this.state.data.nama_desa}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Telp
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="notelp"
                                              type="text"
                                              placeholder={'Telp ' + JENIS_DESA}
                                              defaultValue={this.state.data.notelp}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Email
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="email"
                                              type="text"
                                              placeholder="Email"
                                              defaultValue={this.state.data.email}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Kode POS
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="kode_pos"
                                              type="text"
                                              placeholder="Email"
                                              defaultValue={this.state.data.kode_pos}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              No. WhatsApp
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="whatsapp"
                                              type="number"
                                              placeholder="Nomor WhatsApp"
                                              defaultValue={this.state.ket ? this.state.ket.no_wa : ''}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Website
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="website"
                                              type="text"
                                              placeholder="Website"
                                              defaultValue={this.state.data.website}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Link Facebook
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="link_fb"
                                              type="text"
                                              placeholder="Link Facebook"
                                              defaultValue={this.state.ket && this.state.data.link_fb ? this.state.data.link_fb : ''}
                                              // required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Link Instagram
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="link_ig"
                                              type="text"
                                              placeholder="Link Instagram"
                                              defaultValue={this.state.ket && this.state.data.link_ig ? this.state.data.link_ig : ''}
                                              // required
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Link Youtube
                                            </Label>
                                            <input
                                              className="form-control"
                                              id="link_youtube"
                                              type="text"
                                              placeholder="Link Youtube"
                                              defaultValue={this.state.ket && this.state.data.link_youtube ? this.state.data.link_youtube : ''}
                                              // required
                                            />
                                          </div>
                                          <div className="col-lg-12">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Alamat
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="alamat_desa"
                                              placeholder={'Alamat ' + JENIS_DESA}
                                              defaultValue={this.state.data.alamat_desa}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Batas Wilayah Utara
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="batas_utara"
                                              placeholder={'Batas Sebelah Utara ' + JENIS_DESA}
                                              defaultValue={this.state.data.batas_utara}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Batas Wilayah Timur
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="batas_timur"
                                              placeholder={'Batas Sebelah Timur ' + JENIS_DESA}
                                              defaultValue={this.state.data.batas_timur}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Batas Wilayah Selatan
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="batas_selatan"
                                              placeholder={'Batas Sebelah Selatan ' + JENIS_DESA}
                                              defaultValue={this.state.data.batas_selatan}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Batas Wilayah Barat
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="batas_barat"
                                              placeholder={'Batas Sebelah Barat ' + JENIS_DESA}
                                              defaultValue={this.state.data.batas_barat}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-12">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Narasi Singkat {JENIS_DESA}
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="narasi"
                                              type="text"
                                              rows={5}
                                              placeholder={"Narasi tentang " + JENIS_DESA}
                                              defaultValue={this.state.ket ? this.state.ket.narasi : ''}
                                              required
                                            />
                                          </div>
                                          <div className="col-lg-12">
                                            <Label className="col-form-label" htmlFor="inputEmail3">
                                              Testimoni
                                            </Label>
                                            <textarea
                                              className="form-control"
                                              id="testimoni"
                                              type="text"
                                              rows={5}
                                              placeholder="Testimoni"
                                              defaultValue={this.state.ket ? this.state.ket.testimoni : ''}
                                              required
                                            />
                                          </div>

                                        </div>
                                      </div>

                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-warning" onClick={this.tutupForm}>
                                          Batal
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                        // disabled={this.state.status.btnFormAcc}
                                        >
                                          Simpan
                                        </button>
                                      </div>
                                    </Form>
                                  </Modal>
                                </div>
                              )}
														</Col>
													</Row>
												</TabPane>
												<TabPane tabId="2">
                        <Row>
														<Col sm="12">
															{this.state.loading ? (
                                <div align="center">
                                  <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
                                  <br />
                                  <img
                                    alt="loading"
                                    src={require("../../../assets/images/tes-loading.gif")}
                                    style={{
                                      // width: "100%",
                                      borderRadius: "10px",
                                      width: '150px'
                                    }}
                                  />
                                  <br /><br />
                                </div>
							  							) : (
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="card mb-0" style={{ borderRadius: '25px', boxShadow: "0 1px 11px 0 rgb(160 160 160)" }}>
                                        <div className="card-body" style={{padding: '19px'}}>
                                          <div className="row">
                                            {/* <div className="col-md-12">
                                              <h5>Data Struktur Organisasi Pemerintah {JENIS_DESA}</h5>
                                            </div> */}
                                            <div className="col-md-12 text-right mb-3">
                                              <Button size="md" color="success" className="btn-icon" 
                                              onClick={() => this.tambahDataStrukturDesa()} 
                                              disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Data Struktur OPD</Button>
                                            </div>
                                          </div>
                                          <div
                                            className="row pt-4"
                                            // style={{ border: '3px outset ab8ce4', borderRadius: '25px', padding: '20px', margin: '0', paddingTop: '45px' }}
                                          >
                                            {
                                              this.state.data.uuid
                                                ?
                                                <>
                                                  <div className="col-md-12 text-center">
                                                    <img alt="fotodesa" src={this.state.data.struktur_opd} style={{ maxHeight: '330px', maxWidth: '100%', height: 'auto' }} />
                                                  </div>
                                                  <div className="col-md-12">
                                                    <br/><br/>
                                                    <b>Visi {JENIS_DESA}</b><br/>
                                                    {this.state.data.visi? this.state.data.visi : 'Visi belum ditambahkan'}
                                                  </div>
                                                  <div className="col-md-12">
                                                    <br/>
                                                    <b>Misi {JENIS_DESA}</b><br/>
                                                    {this.state.data.misi? htmlToReactParser.parse(this.state.data.misi) : 'Misi belum ditambahkan'}
                                                  </div>
                                                </>
                                                :
                                                (
                                                  <div className="col-md-12 text-center">
                                                    <h4 align="center">Data Tidak Dapat Ditemukan</h4>
                                                  </div>
                                                )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <ModalStrukturOrganisasiDesa
                                    data={this.state.data}
                                    isOpen={this.state.statusStrukturDesa.form}
                                    propsData={this.props}
                                    setAlert={(e) => {
                                      this.setState(e)
                                    }}
                                    setStatusRefresh={(e) => {
                                      if (e) {
                                        this.fetch({ page: null, where: [] });
                                      }
                                    }}
                                    setIsOpen={(e) => {
                                      // if (!e) {
                                      //   this.setState({ data: null })
                                      // }
                                      this.setState({
                                        statusStrukturDesa: {
                                          ...this.state.statusStrukturDesa,
                                          form: e
                                        }
                                      })
                                    }}
                                  />
                                </div>
                              )}
														</Col>
													</Row>
												</TabPane>
												{/* <TabPane tabId="3">
													<Row>
														<Col sm="12">
                              <div className="container-fluid">
                                <div className="row">
                                  {
                                    this.state.detailDataStat?
                                      <div className="col-sm-12">
                                        <div className="card mb-0">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                      <h5>Struktur Lembaga Kemasyarakatan {this.state.detailLembaga.nama_lembaga}</h5>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                      <button onClick={() => { 
                                                        this.setState({ 
                                                          detailDataStat: false,
                                                          detailLembaga:[],
                                                        });
                                                      }}
                                                      color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
                                                    </div>
                                                    <div className="col-sm-12 text-left">
                                                      &nbsp;
                                                    </div>
                                                    <div className="col-sm-12 text-left">
                                                      <Button
                                                        size="sm"
                                                        color="success"
                                                        onClick={() => {
                                                          this.tambahDetailLembaga();
                                                        }}
                                                      >
                                                        Tambah Data
                                                      </Button>       
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body datatable-react">
                                              {this.state.loadingDetailLembaga ? (
                                                <div className="row">
                                                  <div className="col-sm-12" align="center">
                                                    <img
                                                      alt="loading"
                                                      src={require("../../../assets/images/tes-loading.gif")}
                                                      style={{
                                                        borderRadius: "10px",
                                                        width: '100px'
                                                      }} />
                                                  </div>
                                                </div>
                                              ) : dataDetailLembaga.length > 0 ? (
                                                <>
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={ dataDetailLembaga }
                                                    columns={ columnsDetailLembaga }
                                                    />
                                                <div className="pull-right text-white">
                                                    {this.state.awalDetailLembaga}{this.state.sebelumDetailLembaga}{this.state.halDetailLembaga.map(dtDetailLembaga=> { return dtDetailLembaga})}{this.state.setelahDetailLembaga}{this.state.akhirDetailLembaga}
                                                </div>
                                                </>
                                              ) : (
                                                <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                              )}
                                            </div>
                                        </div>
                                      </div>
                                    :
                                      <div className="col-sm-12">
                                          <div className="card mb-0">
                                              <div className="card-header">
                                                  <div className="row">
                                                      <div className="col-sm-9">
                                                        <h5>Data Struktur Lembaga Kemasyarakatan {JENIS_DESA}</h5>
                                                      </div>
                                                      <div className="col-sm-3 text-right">
                                                        <Button
                                                          size="sm"
                                                          color="success"
                                                          onClick={() => {
                                                            this.tambahDataLembaga();
                                                          }}
                                                        >
                                                          Tambah Data
                                                        </Button>       
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="card-body datatable-react">
                                                {this.state.loadingLembaga ? (
                                                  <div className="row">
                                                    <div className="col-sm-12" align="center">
                                                      <img
                                                        alt="loading"
                                                        src={require("../../../assets/images/tes-loading.gif")}
                                                        style={{
                                                          borderRadius: "10px",
                                                          width: '100px'
                                                        }} />
                                                    </div>
                                                  </div>
                                                ) : dataLembaga.length > 0 ? (
                                                  <>
                                                  <BootstrapTable
                                                      keyField="id"
                                                      data={ dataLembaga }
                                                      columns={ columnsLembaga }
                                                      />
                                                  <div className="pull-right text-white">
                                                      {this.state.awalLembaga}{this.state.sebelumLembaga}{this.state.halLembaga.map(dtLembaga=> { return dtLembaga})}{this.state.setelahLembaga}{this.state.akhirLembaga}
                                                  </div>
                                                  </>
                                                ) : (
                                                  <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                )}
                                              </div>
                                          </div>
                                      </div>
                                  }
                                </div>
                              </div>
                              <br/>
                              <ModalLembaga
                                data={this.state.dtLembaga}
                                isOpen={this.state.statusLembaga.form}
                                propsData={this.props}
                                setAlert={(e) => {
                                  this.setState(e)
                                }}
                                setStatusRefresh={(e) => {
                                  if (e) {
                                    this.fetchLembaga({ page: null, where: [] });
                                  }
                                }}
                                setIsOpen={(e) => {
                                  if (!e) {
                                    this.setState({ dtLembaga: null })
                                  }
                                  this.setState({
                                    statusLembaga: {
                                      ...this.state.statusLembaga,
                                      form: e
                                    }
                                  })
                                }}
                              />

                              <ModalDetailLembaga
                                data={this.state.dtDetailLembaga}
                                additionalData={{
                                  detailLembaga: this.state.detailLembaga,
                                }}
                                isOpen={this.state.statusDetailLembaga.form}
                                propsData={this.props}
                                setAlert={(e) => {
                                  this.setState(e)
                                }}
                                setStatusRefresh={(e) => {
                                  if (e) {
                                    this.fetchLembaga({ page: null, where: [] });
                                  }
                                }}
                                setIsOpen={(e) => {
                                  if (!e) {
                                    this.setState({ dtDetailLembaga: null })
                                  }
                                  this.setState({
                                    statusDetailLembaga: {
                                      ...this.state.statusDetailLembaga,
                                      form: e
                                    }
                                  })
                                }}
                              />
                             
														</Col>
													</Row>
												</TabPane> */}
												<TabPane tabId="4">
													<Row>
														<Col sm="12">
                              <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card mb-0" style={{ borderRadius: '25px', boxShadow: "0 1px 11px 0 rgb(160 160 160)" }}>
                                            <div className="card-header" style={{ borderRadius: '25px 25px 0 0' }}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                      <h5>Data Tanda Tangan</h5>
                                                    </div>
                                                    <div className="col-sm-6 text-right">
                                                      <Button
                                                        size="sm"
                                                        color="success"
                                                        onClick={() => {
                                                          this.tambahData();
                                                        }}
                                                      >
                                                        Tambah Data
                                                      </Button>       
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body datatable-react">
                                              {this.state.loadingTTD ? (
                                                <div className="row">
                                                  <div className="col-sm-12" align="center">
                                                    <img
                                                      alt="loading"
                                                      src={require("../../../assets/images/tes-loading.gif")}
                                                      style={{
                                                        borderRadius: "10px",
                                                        width: '100px'
                                                      }} />
                                                  </div>
                                                </div>
                                              ) : dataTTD.length > 0 ? (
                                                <>
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={ dataTTD }
                                                    columns={ columns }
                                                    />
                                                <div className="pull-right text-white">
                                                    {this.state.awalTTD}{this.state.sebelumTTD}{this.state.halTTD.map(dtTTD=> { return dtTTD})}{this.state.setelahTTD}{this.state.akhirTTD}
                                                </div>
                                                </>
                                              ) : (
                                                <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                              )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <br/>
                              <ModalTandaTangan
                                data={this.state.dtTTD}
                                isOpen={this.state.statusTTD.form}
                                propsData={this.props}
                                optionData={{
                                  frmPrgTTD: this.state.frmPrgTTD,
                                  frmStatusTTD: this.state.frmStatusTTD,
                                  frmPengganti: this.state.frmPenggantiTTD,
                                }}
                                additionalData={{
                                  frmPengganti: this.state.frmPenggantiTTD,
                                  statusStatTTD: this.state.statusStatTTD,
                                  prgStatTTD: this.state.prgStatTTD,
                                  prgStatTTD2: this.state.prgStatTTD2,
                                }}
                                setAlert={(e) => {
                                  this.setState(e)
                                }}
                                setStatusRefresh={(e) => {
                                  if (e) {
                                    this.fetchTTD({ page: null, where: [] });
                                  }
                                }}
                                setIsOpen={(e) => {
                                  if (!e) {
                                    this.setState({ dtTTD: null })
                                  }
                                  this.setState({
                                    statusTTD: {
                                      ...this.state.statusTTD,
                                      form: e
                                    }
                                  })
                                }}
                              />
                             
														</Col>
													</Row>
												</TabPane>
											</TabContent>
										</div>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

export default BasicTable;