import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL, { Post, Delete, Get, JENIS_DESA, } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import Datetime from 'react-datetime';

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
import { numberSeparator } from '../../../function/Format';

let DefaultIcon = L.icon({
	iconSize: [25, 41],
	iconAnchor: [10, 41],
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const frmDex = {
	uuid: '',
	id_anggaran_pendapatan: '',
	uraian: '',
	kode_rekening: '',
	anggaran: '',
	keterangan: '',
}

const dateFormat = require('dateformat');

class MenuC1Detail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			tahun: '',
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			loading: false,
			loadingPreview: false,
			imagePreview: null,
			detailPreview: false,
			detailData: null,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDex,
			judul: 'Tambah Anggaran Pendapatan dan Belanja ' + JENIS_DESA,
			textCari: '',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			editMode: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}

		Post("buku-anggaran-pendapatan-belanja/detail/get/" + this.props.location.state.uuid + link, null, {
			q: this.state.textCari
		}, dtkat => {
			if (dtkat.data.results) {
				console.log('masuk');
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});

	};

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();

		this.setState({ loadingPreview: true })

		let bodyData = this.state.dt
		console.log(bodyData);
		bodyData = {
			...bodyData,
			id_anggaran_pendapatan: this.props.location.state.uuid,
			anggaran: document.getElementById('anggaran').value.replace(/[^\d]/g, ''),
		}

		let psn = "";
		let resstat = 204;
		let metode = "create";
		if (bodyData.uuid === "") {
			psn = "tambah";
			resstat = 201;
			bodyData.uuid = null;
		} else {
			psn = "ubah";
			resstat = 200;
			metode = "update";
		}

		console.log(bodyData);

		Post('buku-anggaran-pendapatan-belanja/detail/' + metode, bodyData.uuid, bodyData, res => {
			// console.log(res);
			if (res.status === resstat) {
				this.setState({
					loadingPreview: false,
					show: true,
					basicType: "success",
					basicTitle: "Data Anggaran Pendapatan dan Belanja " + JENIS_DESA,
					pesanAlert: "Berhasil " + psn + " Data"
				});

				document.getElementById('form-data').reset()
				this.fetch();
				this.setState({
					dt: frmDex,
					status: {
						...this.state.status,
						form: false
					}
				})
			} else {
				this.setState({
					loadingPreview: false,
					show: true,
					basicType: "danger",
					basicTitle: "Data Anggaran Pendapatan dan Belanja " + JENIS_DESA,
					pesanAlert: "Gagal " + psn + " Data"
				});
			}
		})

	});
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Anggaran Pendapatan dan Belanja " + JENIS_DESA,
			editMode: true
		})

		if (data) {
			console.log(data);
			this.setState({
				tmpImages: [],
				dt: {
					...this.state.dt,
					uuid: data.uuid,
					uraian: data.uraian,
					kode_rekening: data.kode_rekening,
					anggaran: data.anggaran,
					keterangan: data.keterangan,
				},
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	cariData = (e) => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	};

	downloadSurat = () => {
		let token = sessionStorage.getItem("access_token")
			? sessionStorage.getItem("access_token")
			: "public";

		fetch(API_URL + 'buku-anggaran-pendapatan-belanja/cetak/' + this.props.location.state.uuid, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer ' + token,
			},
		})
			// .then((response) => response.blob())
			.then((response) => {
				if (response.status == 200) {
					return response.blob()
				}
				this.setState({
					loading: false,
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Anggaran Pendapatan dan Belanja ' + JENIS_DESA,
					pesanAlert: 'Gagal cetak surat'
				});
				throw new Error('Something went wrong');
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`Anggaran Pendapatan dan Belanja ${JENIS_DESA}.pdf`,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
				this.setState({
					loading: false,
				})
			})
			.catch((e) => {
				// console.log(e);
			})
			;
	}

	componentDidMount() {

		if (this.props.location.state) {
			this.fetch()
			console.log(this.props.location);
			this.setState({
				tahun: this.props.location.state.tahun,
				dt: {
					...this.state.dt,
					id_anggaran_pendapatan: this.props.location.state.uuid
				}
			})
		}
	}

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'kode_rekening',
				text: 'KODE REKENING',
				headerAlign: 'center',
				align: 'center',
				sort: false,
			},
			{
				dataField: 'uraian',
				text: 'URAIAN',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'anggaran',
				text: 'ANGGARAN',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: function (cell, row) {
					return "Rp. " + numberSeparator(row.anggaran);
				}
			},
			{
				dataField: 'keterangan',
				text: 'KETERANGAN',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div style={{ minWidth: "61px" }}>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="danger"
								className="btn-icon"
								onClick={() => {
									this.setState({
										alert: (
											<SweetAlert
												showCancel
												confirmBtnText="Hapus"
												confirmBtnBsStyle="danger"
												cancelBtnBsStyle="success"
												type="danger"
												title="Yakin ingin hapus data ?"
												onCancel={this.hideAlert}
												onConfirm={() => {
													Delete('buku-anggaran-pendapatan-belanja/detail/delete', row.uuid, (res) => {
														if (res === 200) {
															this.setState({
																show: true,
																basicType: 'success',
																basicTitle: 'Data Anggaran Pendapatan dan Belanja ' + JENIS_DESA,
																pesanAlert: 'Berhasil hapus data'
															});
															this.fetch()
														} else {
															this.setState({
																show: true,
																basicType: 'danger',
																basicTitle: 'Data Anggaran Pendapatan dan Belanja ' + JENIS_DESA,
																pesanAlert: 'Gagal hapus data'
															});
														}
														this.setState({ alert: null })
													})
												}}
											>
												Proses ini tidak dapat dibatalkan!
											</SweetAlert>
										)
									});
								}}
								disabled={this.state.status.btnAksi}
							>
								<i className="fa fa-trash"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Anggaran Pendapatan dan Belanja Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													this.setState({ loading: true })
													this.downloadSurat()
												}}
											>
												Cetak
											</Button>{" "}
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Anggaran Pendapatan dan Belanja ' + JENIS_DESA,
														dt: frmDex,
														tmpImages: ['kosong'],
														editMode: false,
														currentLocation: this.state.curStaticLocation
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react custom-font-sm">
									{/* <div className="row">
										<div className="col-md-12"> */}
									{this.state.loading ? (
										<div className="row">
											<div className="col-sm-12" align="center">
												<img
													alt="loading"
													src={require("../../../assets/images/tes-loading.gif")}
													style={{
														borderRadius: "10px",
														width: '100px'
													}} />
											</div>
										</div>
									) : (
										<div className='row'>
											<div className="col-md-12">
												<h6>Tahun : {this.state.tahun}</h6>
											</div>
											<div className="col-md-5 text-right">
												<input
													type="text"
													className="form-control"
													style={{ marginBottom: '15px' }}
													id="cariTable"
													placeholder={"Cari berdasarkan uraian"}
													defaultValue={this.state.textCari}
													onChange={(e) => {
														this.setState({ textCari: e.target.value })
													}}
													onKeyPress={this.cariData}
												/>
												<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
													<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
														this.setState({
															textCari: '',
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
														document.getElementById('cariTable').value = ''
													}} ></span>
												</div>
											</div>
											<div className="col-md-7" style={{ marginBottom: '15px' }}>
												<Button
													size="sm"
													color="info"
													className="btn-square"
													onClick={() => {
														this.setState({
															textCari: document.getElementById("cariTable").value,
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
													}}
												>
													<i className="fa fa-search"></i>
												</Button>
											</div>
											{data.length > 0 ? (
												<>
													<div className="col-md-12">
														<BootstrapTable
															keyField="id"
															data={data}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal}
															{this.state.sebelum}
															{this.state.hal.map(dt => {
																return dt;
															})}
															{this.state.setelah}
															{this.state.akhir}
														</div>
													</div>
												</>
											) : (
												<div className="col-md-12">
													<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
												</div>
											)}
										</div>
									)}
									{/* </div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Detail Data Anggaran Pendapatan dan Belanja</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Tahun</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.tahun}</span></td>
													</tr>

													<tr>
														<th colSpan={'3'}><hr /></th>
													</tr>
													<tr>
														<th width="280">Tanggal</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tanggal}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor Bukti</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor_bukti}</span></td>
													</tr>
													<tr>
														<th width="280">Uraian</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.uraian}</span></td>
													</tr>

													<tr>
														<th colSpan={'3'}></th>
													</tr>
													<tr>
														<th><b>Penerimaan (Rp.)</b></th>
													</tr>
													<tr>
														<th width="280">Dari Bendahara</th>
														<th width="50" className="text-center">:</th>
														<td><span>Rp. {numberSeparator(this.state.detailData.volume)}</span></td>
													</tr>
													<tr>
														<th width="280">Swadaya Masyarakat</th>
														<th width="50" className="text-center">:</th>
														<td><span>Rp. {numberSeparator(this.state.detailData.anggaran)}</span></td>
													</tr>

													<tr>
														<th colSpan={'3'}></th>
													</tr>
													<tr>
														<th><b>Pengeluaran (Rp.)</b></th>
													</tr>
													<tr>
														<th width="280">Belanja Barang dan Jasa</th>
														<th width="50" className="text-center">:</th>
														<td><span>Rp. {numberSeparator(this.state.detailData.jumlah)}</span></td>
													</tr>
													<tr>
														<th width="280">Belanja Modal</th>
														<th width="50" className="text-center">:</th>
														<td><span>Rp. {numberSeparator(this.state.detailData.belanja_modal)}</span></td>
													</tr>

													<tr>
														<th colSpan={'3'}></th>
													</tr>

													<tr>
														<th width="280">Jumlah Pengembalian ke Bendahara</th>
														<th width="50" className="text-center">:</th>
														<td><span>Rp. {numberSeparator(this.state.detailData.jumlah_pengembalian)}</span></td>
													</tr>
													<tr>
														<th width="280">Saldo Kas</th>
														<th width="50" className="text-center">:</th>
														<td><span>Rp. {numberSeparator(this.state.detailData.saldo)}</span></td>
													</tr>
												</tbody>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}

							{/* <FormGroup className="row mb-0">
								<Label
									className="col-sm-12 col-form-label"
								>
									<b>Nomor dan tanggal ditetapkannya keputusan</b>
								</Label>
							</FormGroup> */}
							<div className="form-row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Kode Rekening</Label>
									<input
										className="form-control"
										id="kode_rekening"
										type="text"
										placeholder="Masukkan kode rekening"
										defaultValue={this.state.dt.kode_rekening}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													kode_rekening: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Anggaran (Rp.)</Label>
									<NumberFormat
										data-testid="anggaran"
										id="anggaran"
										name="anggaran"
										placeholder="Masukkan nominal anggaran"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										defaultValue={this.state.dt.anggaran}
										value={this.state.dt.anggaran}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													anggaran: e.target.value,
												}
											})

										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Uraian</Label>
									<textarea className='form-control' name="uraian" id="uraian" cols="30" placeholder={`Penjelasan mengenai uraian transaksi`} defaultValue={this.state.dt.uraian} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												uraian: e.target.value
											}
										})
									}} rows="4" required />
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Keterangan</Label>
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder={`Masukkan keterangan jika diperlukan`} defaultValue={this.state.dt.keterangan} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												keterangan: e.target.value
											}
										})
									}} rows="4" required />
								</Col>
							</div>

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuC1Detail;
