import React, { Component } from "react";


import BootstrapTable from "react-bootstrap-table-next";



import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import Datetime from "react-datetime";

// koneksi
import {
  PostStatus,
  Get,
  Delete,
  cekLogin,
  Post,
  JENIS_DESA,
  ID_DESA,
  JENIS_DUSUN
} from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DataToken } from "../function/Format";
// import DatePicker from "reactdatepicker";
const id_keldes = ID_DESA;


const frmDef = {
  uuid: "",
  id_jabatan_perangkat: "",
  nik: 0,
  nip: "",
  status_pegawai: "",
  tanggal_mulai: null,
  tanggal_selesai: null,
  status: null,
  status_tte: null,
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKel: [],
      kelStat: [],
      pendStat: [],
      frmPen: [],
      jpStat: [],
      frmJp: [],
      akun: DataToken(sessionStorage.getItem('access_token')),
      detailStat: null,
      statusAktifStat: null,
      statusTTEStat: null,
      
      // FItur Kelurahan
			isKelurahan: false,
    };
  }

  // ================== DatePicker ==================
  gantiTglMulai = date => {
    this.state.dt.tanggal_mulai = date;
    this.forceUpdate();
  };
  gantiTglSelesai = date => {
    this.state.dt.tanggal_selesai = date;
    this.forceUpdate();
  };
  // Datetime
  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_mulai":
        if (typeof date._isValid !== 'undefined') {
          // this.setState({ tanggal_mulai: date.format("YYYY-MM-DD HH:mm:ss") });
          let tgl = date.format('YYYY-MM-DD');
          this.state.dt.tanggal_mulai = new Date(tgl);
          this.forceUpdate();
        }
        break;
      case "tanggal_selesai":
        if (typeof date._isValid !== 'undefined') {
          // this.setState({ tanggal_selesai: date.format("YYYY-MM-DD HH:mm:ss") });
          let tgl = date.format('YYYY-MM-DD');
          this.state.dt.tanggal_selesai = new Date(tgl);
          this.forceUpdate();
        }
        break;
      default:
        // no default
        break;
    }
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("perangkat" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results, loading: false });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addRole = {
        nama_role: e.target.value
      };
      cekLogin("jenis-surat", addRole, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    if (
      this.state.dt.id_jabatan_perangkat !== "" &&
      this.state.detailStat !== null &&
      this.state.dt.nik !== 0 &&
      this.state.dt.status !== null && this.state.statusAktifStat !== null &&
      this.state.dt.status_tte !== null && this.state.statusTTEStat !== null &&
      this.state.dt.tanggal_mulai !== null &&
      this.state.dt.tanggal_selesai !== null &&
      this.state.dt.status_pegawai !== ""
    ) {
      this.state.status.btnForm = true;
      this.forceUpdate();
      var event = new Date(this.state.dt.tanggal_mulai);
      let tgl_mulai = JSON.stringify(event);
      let tgl_selesai = null;

      if (this.state.dt.tanggal_selesai !== null) {
        event = new Date(this.state.dt.tanggal_selesai);
        tgl_selesai = JSON.stringify(event);
        tgl_selesai = tgl_selesai.slice(1, 11);
      }

      const nik = this.state.dt.nik
      const nip = document.getElementById("nip").value.trim()

      let add = {
        uuid: this.state.dt.uuid,
        id_jabatan_perangkat: this.state.dt.id_jabatan_perangkat,
        nik: parseInt(nik),
        nip: nip,
        status_pegawai: this.state.dt.status_pegawai,
        tanggal_mulai: tgl_mulai.slice(1, 11),
        tanggal_selesai: tgl_selesai,
        status: this.state.dt.status,
        status_tte: this.state.dt.status_tte,
        detail: this.state.detailStat.value
      };
      //console.log(add);
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      PostStatus("perangkat/" + metode, add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Perangkat",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Perangkat",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Perangkat",
        pesanAlert: "Isi data perangkat dengan lengkap."
      });
    }
  };
  // ========================================================================

  ///modif

  getDetail = (e, sel) => {
    this.changeSelectValue(e, sel);
    if (e) {
      switch (e.value) {
        case "desa":
          Get("keldes/find", id_keldes, dt => {
            this.setState({
              frmDetail: [{ value: dt.results.id, label: dt.results.nama }]
            });
          });
          break;
        case "dusun":
          Get("dusun/kel/" + id_keldes + "/list", null, data => {
            let frmDetail = [];
            data.results.forEach(dt => {
              frmDetail.push({ value: dt.id, label: dt.nama });
            });
            this.setState({ frmDetail });
          });
          break;
        case "rw":
          Get("rw/kel/" + id_keldes + "/list", null, data => {
            let frmDetail = [];
            data.results.forEach(dt => {
              frmDetail.push({ value: dt.id, label: dt.nama });
            });
            this.setState({ frmDetail });
          });
          break;
        case "rt":
          Get("rt/kel/" + id_keldes + "/list", null, data => {
            let frmDetail = [];
            data.results.forEach(dt => {
              frmDetail.push({
                value: dt.id,
                label: "RT." + dt.nama + " Rw." + dt.data_rw.nama
              });
            });
            this.setState({ frmDetail });
          });
          break;
        default:
          // no default
          break;
      }
    } else {
      this.setState({ tingkatStat: [], detailStat: null });
    }
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    //console.log("jabataaan");
    Get("perangkat/find", id, data => {
      this.state.dt.uuid = data.results.uuid;
      this.state.dt.id_jabatan_perangkat = data.results.id_jabatan_perangkat;
      let a = {
        value: data.results.id_jabatan_perangkat
      };
      this.changeSelectValue(a, "jabatan");

      this.state.dt.nik = data.results.nik;
      this.state.dt.nip = data.results.nip;
      this.state.dt.detail = data.results.detail;
      this.state.dt.status_pegawai = data.results.status_pegawai;
      this.state.dt.tanggal_mulai = new Date(data.results.tanggal_mulai);
      this.state.dt.tanggal_selesai = new Date(data.results.tanggal_selesai);
      this.state.dt.status = data.results.status;
      this.state.dt.status_tte = data.results.status_tte;
      // let jbt =
      //   data.results.data_rt !== null
      //     ? data.results.data_rt.nama
      //     : data.results.data_rw !== null
      //       ? data.results.data_rw.nama
      //       : data.results.data_dusun !== null
      //         ? data.results.data_dusun.nama
      //         : data.results.data_keldes !== null
      //           ? data.results.data_keldes.nama
      //           : " ";
      //console.log("jb " + jbt);
      let curDetailStat = null
      if (data.results.data_rt !== null) {
        curDetailStat = {
          value: data.results.data_rt.id,
          label: data.results.data_rt.nama,
        }
      } else if (data.results.data_rw !== null) {
        curDetailStat = {
          value: data.results.data_rw.id,
          label: data.results.data_rw.nama,
        }
      } else if(data.results.data_dusun !== null){
        if (this.state.isKelurahan === false) {
          Get("dusun/find", data.results.detail, dt => {
            this.setState({
              detailStat: { value: dt.results.id, label: dt.results.nama }
            });
          });
        }
      } else if (data.results.data_keldes !== null) {
        curDetailStat = {
          value: data.results.data_keldes.id,
          label: data.results.data_keldes.nama,
        }
      }

      this.setState({
        statusAktifStat: {
          value: data.results.status,
          label: data.results.status === 0 ? "Aktif" : "Tidak Aktif"
        },
        detailStat: curDetailStat,
        pendStat: {
          value: data.results.data_penduduk.id,
          label: data.results.data_penduduk.nama
        },
        jpStat: {
          value: data.results.data_jabatan.uuid,
          label: data.results.data_jabatan.nama
        },
        statusTTEStat: {
          value: data.results.status_tte,
          label: data.results.status_tte == 0 ? 'Aktif' : 'Tidak Aktif',
        },
        statusPegawaiStat: {
          value: data.results.status_pegawai,
          label: data.results.status_pegawai
        }
      });
      this.state.status.btnAksi = false;
      this.forceUpdate();
      //console.log(data.results);
      // this.ubahPilihan(
      //   "keldes",
      //   data.results.data_jabatan.data_identitas_perangkat
      // );
      this.bukaForm();

      // this.getJabatanPerangkat(
      //   {
      //     value: data.results.data_jabatan.id_keldes,
      //     label: data.results.data_jabatan.data_identitas_perangkat.nama
      //   },
      //   "keldes",
      //   "kel",
      //   "jabatan-perangkat"
      // );
    });
  };
  ubahPilihan = (table, data) => {
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "kecamatan":
        this.getChild(
          {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          { value: data.data_kabkota.id, label: data.data_kabkota.nama },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.setState({
          prvStat: {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kabkota.id,
            label: data.data_kabkota.nama
          },
          kecStat: { value: data.id, label: data.nama }
        });
        break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );

        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kelStat: { value: data.id, label: data.nama }
        });
        // this.bukaForm();
        break;
      case "rt":
        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },
          "keldes",
          "kel",
          "dusun"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          "dusun",
          "dus",
          "rw"
        );

        this.getChild(
          { value: data.data_rw.id, label: data.data_rw.nama },
          "rw",
          "rw",
          "rt"
        );

        this.setState({
          prvStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          kbktStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          kecStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          kelStat: {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },

          dusStat: {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          rwStat: { value: data.data_rw.id, label: data.data_rw.nama },
          rtStat: { value: data.id, label: data.nama }
        });
        this.bukaForm();
        break;
      default:
        // no default
        break;
    }
    // });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("perangkat/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Perangkat",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Perangkat",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };

  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Perangkat"
            });
            this.ubahData(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
        ) : ''}

      </>
    );
  };

  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKec: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKel: frm });
            break;
          case "dusun":
            this.setState({ frmDus: frm });
            break;
          default:
            // no default
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };
  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.nama + " | " + dt.id });
          });
          this.setState({ frmPend });
        });
      } else {
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend2 = [];
          datanik.data.results.data.forEach(dt => {
            frmPend2.push({ value: dt.id, label: dt.nama + " | " + dt.id });
          });
          this.setState({ frmPend2 });
        });
      }
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "tingkat":
          this.setState({ tingkatStat: e, detailStat: null });
          break;
        case "detail":
          this.setState({ detailStat: e });
          break;
        case "provinsi":
          this.setState({ prvStat: e });
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [] });
          break;
        case "kab-kota":
          this.setState({ kbktStat: e });
          this.setState({ kecStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e });
          this.setState({ kelStat: [] });

          break;
        case "keldes":
          this.setState({ kelStat: e });
          this.setState({ dusStat: [] });

          break;

        case "jabatan":
          //console.log(e.value);
          Get("jabatan-perangkat/find", e.value, dt => {
            //console.log("tingakat");
            //console.log(dt.results.tingkat_perangkat);
            switch (dt.results.tingkat_perangkat) {
              case "desa":
                Get("keldes/find", id_keldes, dt => {
                  this.setState({
                    frmDetail: [
                      { value: dt.results.id, label: dt.results.nama }
                    ],
                    detailStat: { value: dt.results.id, label: dt.results.nama }
                  });
                });
                break;
              case "nagari":
                Get("keldes/find", id_keldes, dt => {
                  this.setState({
                    frmDetail: [
                      { value: dt.results.id, label: dt.results.nama }
                    ]
                  });
                });
                break;
              case "dusun":
                Get("dusun/kel/" + id_keldes + "/list", null, data => {
                  let frmDetail = [];
                  data.results.forEach(dt => {
                    frmDetail.push({ value: dt.id, label: dt.nama });
                  });
                  this.setState({ frmDetail });
                });
                break;
              case "rw":
                Get("rw/kel/" + id_keldes + "/list", null, data => {
                  let frmDetail = [];
                  data.results.forEach(dt => {
                    let label = "RW. " + dt.nama
                    if (dt.data_dusun) {
                      label += " | " + JENIS_DUSUN + " " + dt.data_dusun.nama 
                    }
                    frmDetail.push({ value: dt.id, label });
                  });
                  this.setState({ frmDetail });
                });
                break;
              case "rt":
                Get("rt/kel/" + id_keldes + "/list", null, data => {
                  let frmDetail = [];
                  data.results.forEach(dt => {
                    let label = "RT. " + dt.nama + " RW. " + dt.data_rw.nama
                    if (dt.data_rw_data_dusun) {
                      label = "RT. " + dt.nama + " RW. " + dt.data_rw.nama + " | " + JENIS_DUSUN + " " + dt.data_rw.data_dusun.nama
                    }
                    frmDetail.push({
                      value: dt.id,
                      label
                    });
                  });
                  this.setState({ frmDetail });
                });
                break;
              default:
                // no default
                break;
            }
            // this.setState({
            //   frmDetail: [{ value: dt.results.id, label: dt.results.nama }]
            // });
          });

          this.state.dt.id_jabatan_perangkat = e.value;
          this.setState({ jpStat: e, tingkatStat: [] });
          break;
        case "penduduk":
          this.state.dt.nik = e.value;
          this.setState({ pendStat: e });
          break;
        case "status_kepegawaian":
          this.state.dt.status_pegawai = e.value;
          this.setState({ statusPegawaiStat: e });
          break;
        case "status_aktif":
          this.state.dt.status = e.value;
          this.setState({ statusAktifStat: e });
          break;
        case "status_tte":
          this.state.dt.status_tte = e.value;
          this.setState({ statusTTEStat: e });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "tingkat":
          this.setState({ tingkatStat: [], detailStat: null });
          break;
        case "detail":
          this.setState({ detailStat: null });
          break;
        case "provinsi":
          this.setState({ prvStat: [] });
          this.setState({ kbktStat: [], frmKbkt: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "kab-kota":
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "kecamatan":
          this.setState({ kecStat: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "keldes":
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "jabatan":
          this.state.dt.id_jabatan_perangkat = "";
          this.setState({ jpStat: [], frmDetail: [], detailStat: null });
          break;
        case "penduduk":
          this.state.dt.nik = 0;
          this.setState({ pendStat: [] });
          break;
        case "status_kepegawaian":
          this.state.dt.status_pegawai = "";
          this.setState({ statusPegawaiStat: [] });
          break;
        case "status_aktif":
          this.state.dt.status = 0;
          this.setState({ statusAktifStat: null });
          break;
        case "status_tte":
          this.state.dt.status_tte = 0;
          this.setState({ statusTTEStat: null });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  getJabatanPerangkat = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.uuid, label: `${dt.nama}` });
        });

        //console.log("Data FOrm jabatan");
        //console.log(frm);
        this.setState({ frmJp: frm });
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };
  componentDidMount() {
    // let e = {
    //   value: ID_DESA
    // };
    if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
    }
    else{
    }

    this.fetch({ page: null, where: [] });
    Get("jabatan-perangkat/kel/" + ID_DESA + "/list", null, data => {
      let frm = [];
      data.results.forEach(dt => {
        frm.push({ value: dt.uuid, label: `${dt.nama} ` });
      });

      //console.log(frm);
      this.setState({ frmJp: frm });
    });
    Get("penduduk", null, data => {
      let frm = [];
      data.results.data.forEach(dt => {
        frm.push({ value: dt.id, label: `${dt.nama + " | " + dt.id}` });
      });

      this.setState({ frmPend: frm });
    });
    Get("provinsi/list", null, data => {
      let frmPrv = [];
      data.results.forEach(dt => {
        frmPrv.push({ value: dt.id, label: dt.nama });
      });
      this.setState({ frmPrv });
    });
    Get("select-enum/perangkat/status_pegawai", null, data => {
      let frmStatus = [];
      data.forEach(dt => {
        frmStatus.push({ value: dt, label: dt });
      });
      let status = [
        { value: 0, label: "Aktif" },
        { value: 1, label: "Tidak Aktif" }
      ];
      this.setState({ frmStatus, frmStatusAktif: status, frmStatusTTE: status });
    });
  }

  render() {
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      id_jabatan_perangkat: "",
      nik: 0,
      nip: "",
      status_pegawai: "",
      tanggal_mulai: null,
      tanggal_selesai: null,
      status: 0
    };
    // const { SearchBar } = Search;
    let columns = [];
    
    if(this.state.isKelurahan){
      columns = [
        {
            dataField: "no",
            text: "No",
            headerAlign: 'center',
            align: 'center',
            formatter: (key, obj, index) =>{          
                let current_pagenum = this.state.pagination.current_page;
                let total_records_per_page = this.state.pagination.per_page;
                let row_index = (index+1);
                let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                return serial_num; 
            }
        },
  
        {
          dataField: "nip",
          headerAlign: 'center',
          align: 'center',
          text: "NIP",
          sort: true
        },
        {
          dataField: "nama",
          headerAlign: 'center',
          align: 'center',
          text: "Nama",
          formatter: (cell, obj) => {
            if (obj.data_penduduk !== null) {
              let nama = obj.data_penduduk.nama;
              return nama;
            } else {
              return ''
            }
          },
          sort: true,
          isDummyField: true
        },
        {
          dataField: "data_jabatan",
          headerAlign: 'center',
          align: 'center',
          text: "Jabatan",
          formatter: (cell, obj) => {
            let jabatan = obj.data_jabatan.nama;
            let lokasiJabatan =
              obj.data_rt !== null
                ? obj.data_rt.nama
                : obj.data_rw !== null
                  ? obj.data_rw.nama
                    : obj.data_keldes !== null
                      ? obj.data_keldes.nama
                      : "";
  
            return jabatan + " " + lokasiJabatan;
          },
          sort: true,
          isDummyField: true
        },
        {
          dataField: "status_pegawai",
          headerAlign: 'center',
          align: 'center',
          text: "Status Perangkat",
  
          sort: true
        },
        {
          dataField: "masa_bakti",
          headerAlign: 'center',
          align: 'center',
          text: "Masa Bakti",
          formatter: (cell, obj) => {
            let tgl = new Date(obj.tanggal_mulai).toLocaleDateString("id-ID", {
              year: "numeric",
              month: "long",
              day: "numeric"
            });
            let tglSelesai =
              obj.tanggal_selesai !== null
                ? new Date(obj.tanggal_selesai).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })
                : "sekarang";
  
            return tgl + " s/d " + tglSelesai;
          },
          sort: true,
          isDummyField: true
        },
  
        {
          dataField: "status",
          headerAlign: 'center',
          align: 'center',
          text: "Status",
          formatter: (cell, obj) => {
            let status = obj.status === 0 ? "Aktif" : "Tidak Aktif";
  
            return status;
          },
          sort: true
        },
        {
          dataField: "aksi",
          headerAlign: 'center',
          align: 'center',
          text: "Aksi",
          isDummyField: true,
          csvExport: false,
          formatter: this.tombolAksi
        }
      ];
    }
    else{
      columns = [
        {
            dataField: "no",
            text: "No",
            headerAlign: 'center',
            align: 'center',
            formatter: (key, obj, index) =>{          
                let current_pagenum = this.state.pagination.current_page;
                let total_records_per_page = this.state.pagination.per_page;
                let row_index = (index+1);
                let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                return serial_num; 
            }
        },
        {
          dataField: "nip",
          headerAlign: 'center',
          align: 'center',
          text: "NIP",
          sort: true
        },
        // {
        //   dataField: "nik",
        //   text: "NIK",
        //   sort: true,
        // },
        {
          dataField: "nama",
          headerAlign: 'center',
          align: 'center',
          text: "Nama",
          formatter: (cell, obj) => {
            if (obj.data_penduduk !== null) {
              let nama = obj.data_penduduk.nama;
              return nama;
            } else {
              return ''
            }
          },
          sort: true,
          isDummyField: true
        },
        {
          dataField: "data_jabatan",
          headerAlign: 'center',
          align: 'center',
          text: "Jabatan",
          formatter: (cell, obj) => {
            let jabatan = obj.data_jabatan.nama;
            let lokasiJabatan =
              obj.data_rt !== null
                ? obj.data_rt.nama
                : obj.data_rw !== null
                  ? obj.data_rw.nama
                  : obj.data_dusun !== null
                    ? obj.data_dusun.nama
                    : obj.data_keldes !== null
                      ? obj.data_keldes.nama
                      : "";

            return jabatan + " " + lokasiJabatan;
          },
          sort: true,
          isDummyField: true
        },
        {
          dataField: "status_pegawai",
          headerAlign: 'center',
          align: 'center',
          text: "Status Perangkat",

          sort: true
        },
        {
          dataField: "masa_bakti",
          headerAlign: 'center',
          align: 'center',
          text: "Masa Bakti",
          formatter: (cell, obj) => {
            let tgl = new Date(obj.tanggal_mulai).toLocaleDateString("id-ID", {
              year: "numeric",
              month: "long",
              day: "numeric"
            });
            let tglSelesai =
              obj.tanggal_selesai !== null
                ? new Date(obj.tanggal_selesai).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })
                : "sekarang";

            return tgl + " s/d " + tglSelesai;
          },
          sort: true,
          isDummyField: true
        },

        {
          dataField: "status",
          headerAlign: 'center',
          align: 'center',
          text: "Status",
          formatter: (cell, obj) => {
            let status = obj.status === 0 ? "Aktif" : "Tidak Aktif";

            return status;
          },
          sort: true
        },
        // {
        //   dataField: "wilayah",
        //   text: "Wilayah",
        //   formatter: (cell, obj) => {
        //     let daerah =
        //       obj.data_jabatan.data_identitas_perangkat.nama +
        //       ", " +
        //       obj.data_jabatan.data_identitas_perangkat.data_kecamatan.nama +
        //       ", " +
        //       obj.data_jabatan.data_identitas_perangkat.data_kecamatan
        //         .data_kabkota.nama +
        //       ", " +
        //       obj.data_jabatan.data_identitas_perangkat.data_kecamatan
        //         .data_kabkota.data_provinsi.nama;
        //     return daerah;
        //   },
        //   sort: true,
        // },
        {
          dataField: "aksi",
          headerAlign: 'center',
          align: 'center',
          text: "Aksi",
          isDummyField: true,
          csvExport: false,
          formatter: this.tombolAksi
        }
      ];
    }

    // const defaultSorted = [
    //   {
    //     dataField: "nama_role",
    //     order: "asc"
    //   }
    // ];

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    return (
      <div>
        {/* <Breadcrumb title="Perangkat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Perangkat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span>< span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Perangkat",
                            dt: frmDef,
                            pfrmPrv: [],
                            prvStat: [],
                            frmKbkt: [],
                            kbktStat: [],
                            frmKec: [],
                            kecStat: [],
                            frmKel: [],
                            kelStat: [],
                            pendStat: [],
                            frmPen: [],
                            jpStat: [],
                            frmDetail: [],
                            statusAktifStat: null,
                            statusTTEStat: null,
                            detailStat: null,
                            statusPegawaiStat: []
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                  ) : data.length > 0 ? (
                    <>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                    </>
                  ) : (
                    <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              {/* <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Provinsi
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, "provinsi", "prov", "kab-kota");
                    }}
                    defaultValue={this.state.prvStat}
                    value={this.state.prvStat}
                    name="provinsi"
                    options={this.state.frmPrv}
                    placeholder="Pilih Provinsi"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kabupaten / Kota
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, "kab-kota", "kab", "kecamatan");
                    }}
                    defaultValue={this.state.kbktStat}
                    value={this.state.kbktStat}
                    name="kabkota"
                    options={this.state.frmKbkt}
                    placeholder="Pilih Kabupaten/Kota"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kecamatan
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, "kecamatan", "kec", "keldes");
                    }}
                    defaultValue={this.state.kecStat}
                    value={this.state.kecStat}
                    name="kecamatan"
                    options={this.state.frmKec}
                    placeholder="Pilih Kecamatan"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kelurahan / {JENIS_DESA}
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getJabatanPerangkat(
                        e,
                        "keldes",
                        "kel",
                        "jabatan-perangkat"
                      );
                    }}
                    defaultValue={this.state.kelStat}
                    value={this.state.kelStat}
                    name="keldes"
                    options={this.state.frmKel}
                    placeholder="Pilih Kelurahan/Desa"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
               */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jabatan Perangkat
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.setState({ detailStat: null })
                      this.changeSelectValue(e, "jabatan");
                    }}
                    defaultValue={this.state.jpStat}
                    value={this.state.jpStat}
                    name="dusun"
                    options={this.state.frmJp}
                    placeholder="Pilih Jabatan Perangkat"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Detail
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "detail");
                    }}
                    defaultValue={this.state.detailStat}
                    value={this.state.detailStat}
                    name="Detail"
                    options={this.state.frmDetail}
                    placeholder="Pilih Detail"
                    isClearable
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Penduduk
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "penduduk");
                    }}
                    defaultValue={this.state.pendStat}
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    value={this.state.pendStat}
                    name="dusun"
                    options={this.state.frmPend}
                    placeholder="Pilih Penduduk"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIP
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nip"
                    type="number"
                    min={0}
                    placeholder="masukan nomor NIP"
                    defaultValue={this.state.dt.nip}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                ></Label>
                <Col sm="9"></Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Kepegawaian
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "status_kepegawaian");
                    }}
                    defaultValue={this.state.statusPegawaiStat}
                    value={this.state.statusPegawaiStat}
                    name="dusun"
                    options={this.state.frmStatus}
                    placeholder="Pilih Status Kepegawaian"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Menjabat
                </Label>
                <Col sm="4">
                  {/* <Datepicker
                    id="tgl_order"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.dt.tanggal_mulai}
                    onChange={this.gantiTglMulai}
                    className="form-control"
                  /> */}
                  <Datetime
                    locale="id-ID"
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    initialValue={this.state.dt.tanggal_mulai}
                    onChange={e => {
                      this.gantiTgl(e, 'tanggal_mulai')
                    }}
                    inputProps={{
                      required: true,
                      placeholder: 'Tanggal Mulai',
                    }}
                  />
                </Col>
                <Label
                  className="col-sm-1 col-form-label"
                  htmlFor="inputEmail3"
                >
                  s/d
                </Label>
                <Col sm="4">
                  {/* <Datepicker
                    id="tgl_order"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.dt.tanggal_selesai}
                    onChange={this.gantiTglSelesai}
                    className="form-control"
                  /> */}
                  <Datetime
                    locale="id-ID"
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    initialValue={this.state.dt.tanggal_selesai}
                    onChange={e => {
                      this.gantiTgl(e, 'tanggal_selesai')
                    }}
                    inputProps={{
                      required: true,
                      placeholder: 'Tanggal Selesai',
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "status_aktif");
                    }}
                    defaultValue={this.state.statusAktifStat}
                    value={this.state.statusAktifStat}
                    name="status"
                    options={this.state.frmStatusAktif}
                    placeholder="Pilih Status"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanda Tangan Elektronik
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "status_tte");
                    }}
                    defaultValue={this.state.statusTTEStat}
                    value={this.state.statusTTEStat}
                    name="status"
                    options={this.state.frmStatusTTE}
                    placeholder="Pilih Status TTE"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;