import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { Post, Get, Delete, ID_DESA, JENIS_DESA } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';

import ModalLembaga from "./modal/lembaga";
import ModalDetailPerangkat from "./modal/detail_perangkat";


const frmLembaga = {
  uuid: '',
  nama_lembaga: '',
  tahun_berdiri: '',
  status: 1,
}

const frmPerangkat = {
  uuid: '',
  uuid_lembaga: '',
  nama: '',
  jabatan: '',
  tanggal_mulai: '',
  tanggal_selesai: '',
  status: 1,
  foto: ""
}

class BasicTable extends Component {
  _isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
      // DATA LEMBAGA
        dataLembaga:[],
        paginationLembaga: [],
        statusLembaga: {
          form:false,
          btnForm:false,
          btnAksi:false
        },
        awalLembaga: "",
        sebelumLembaga: "",
        halLembaga: [],
        setelahLembaga: "",
        akhirLembaga: "",
        dtLembaga:frmLembaga,
      // END DATA LEMBAGA
      
      // DATA PERANGKAT LEMBAGA
			dataPerangkat: [],
			loadingPerangkat: false,
			paginationPerangkat: [],
      statusPerangkat: {
        form:false,
        btnForm:false,
        btnAksi:false
      },
      awalPagePerangkat: "",
      sebelumPagePerangkat: "",
      halPagePerangkat: [],
      setelahPagePerangkat: "",
      akhirPagePerangkat: "",
      dtPerangkat:frmPerangkat,
      judulPerangkat: "Tambah Detail Perangkat",
      detailLembaga: [],

      detailPerangkat: [],
      statusDetailPerangkat: {
        form:false,
        btnForm:false,
        btnAksi:false
      },
      // END DATA PERANGKAT LEMBAGA

			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			input: 'none',
			view: 'inherit',
			judul: 'Tambah Profile',
			alert: null,
			show: false,
			loading: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			
			// Tab Vertical
        active_tab_vertical: 1,

		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// Toggle Vertical Tab
	toggle_vertical(tab, data) {
        if (this.state.active_tab_vertical !== tab) {
          this.fetchPerangkat(data.uuid,{page: null});

          this.setState({
              detailLembaga: data,
              active_tab_vertical: tab,
          });
        }
    }

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => 
  {    
		this.setState({ loading: true })

      let linkLembaga = "";
      if (params.page)
      {
          switch(params.page)
          {
              case '>>': linkLembaga = "?page="+(Math.ceil(this.state.paginationLembaga.total / this.state.paginationLembaga.per_page));break;
              case '>': linkLembaga = "?page="+(parseInt(this.state.paginationLembaga.current_page) + 1);break;
              case '<': linkLembaga = "?page="+(parseInt(this.state.paginationLembaga.current_page) - 1);break;
              case '<<': linkLembaga = "?page=1";break;
              default: linkLembaga = "?page="+params.page;break;
          }
      }
		Get('lembaga-kemasyarakatan'+linkLembaga, null, (dtkat) => {
			if (dtkat.results) {
        
        if(this.state.active_tab_vertical===1){
          this.setState({
            detailLembaga: dtkat.results.data[0],
            paginationLembaga:dtkat.results,
          })
		      this.fetchPerangkat(dtkat.results.data[0].uuid, { page: null, where: [] });
          this.paginationLembaga(dtkat.results);
        }
				this.setState({ 
					dataLembaga: dtkat.results.data,
          loading: false
				});
      }
		});
	};

  
  // paginationLembaga
  paginationLembaga = (data) => {
    // const data = this.state.paginationLembaga;
    //console.log(data);
    let awalLembaga = "";
    let halLembaga = [];
    let sebelumLembaga = "";
    let setelahLembaga = "";
    let akhirLembaga = "";
    if (data.total > 0)
    {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3)
        {   
            start = 1;
            end = 5;
            if (data.last_page > data.current_page)
            {
                n = data.current_page + 1;
            }
        }
        else 
        {
            p = data.current_page - 1;
            n = data.current_page + 1;
            start = data.current_page - 2;
            end = data.current_page + 2;
        }
        if (end >= data.last_page - 2)
        {
            p = data.current_page - 1;
            if (start >= 5)
            {
                start = data.last_page - 4;
            }
            end = data.last_page;
        }

        for (let i=start;i<=end;i++) 
        {
            let warna = "primary-custom";
            if (i === data.current_page)
            {
                warna = "danger"
            }
            halLembaga.push(<a href={() => false} onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        }
        if (p > 0)
        {
            sebelumLembaga = (<a href={() => false} onClick={()=>this.fetch({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        }
        if (n > 0)
        {
            setelahLembaga = (<a href={() => false} onClick={()=>this.fetch({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        }
        awalLembaga = (<a href={() => false} onClick={()=>this.fetch({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhirLembaga = (<a href={() => false} onClick={()=>this.fetch({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
    }
    this.setState({awalLembaga,sebelumLembaga,halLembaga,setelahLembaga,akhirLembaga});
  }

  
  bukaFormLembaga = () => {
    this.setState({
        statusLembaga:{
            ...this.state.statusLembaga,
            form: true,
        }
    })
    this.forceUpdate();
  }

  
  tambahDataLembaga = e => {
    this.setState({
      statusLembaga: {
        ...this.state.statusLembaga,
        form: true
      },
      judul: "Tambah Lembaga Kemasyarakatan",
      dtLembaga: frmLembaga,
    });
  };

  // ============================== Ubah Data ===============================
    
  ubahDataLembaga = (data) => {
    this.setState({
        statusLembaga:{
            ...this.state.statusLembaga,
            btnAksi: true,
        },
        judulLembaga: "Ubah Lembaga",
    })
    this.forceUpdate();
      this.setState({
          dtLembaga: data,
          statusLembaga:{
              ...this.state.statusLembaga,
              btnAksi: false,
          }
      })
      this.bukaFormLembaga();
  }

  // ============================== Ubah Data ===============================
    
  detailDataPerangkat = (data) => {
    this.setState({
        statusDetailPerangkat:{
            ...this.state.statusDetailPerangkat,
            btnAksi: true,
        },
        // judulLembaga: "Ubah Lembaga",
    })
    this.forceUpdate();
      this.setState({
          detailPerangkat: data,
          statusDetailPerangkat:{
              ...this.state.statusDetailPerangkat,
              btnAksi: false,
              form: true,
          }
      })
      // this.bukaFormLembaga();
  }

  
  // ============================== Hapus Data ===============================

  konfirmHapusLembaga = (id) => {
    this.setState({
        alert: (
            <SweetAlert
                showCancel
                confirmBtnText="Hapus"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                type="danger"
                title="Yakin ingin hapus data ?"
                onCancel={this.hideAlert}
                onConfirm={()=> this.hapusDataLembaga(id)}
            >
                Proses ini tidak dapat dibatalkan!
            </SweetAlert>
        )
    });
  }

  hapusDataLembaga = (id) => {
      this.hideAlert();
      let psn = "Hapus";
      Delete('lembaga-kemasyarakatan/delete', id, (res) => {
          if (res === 200)
          {
              this.setState({ show: true, basicType:'success', basicTitle:'Data Lembaga', pesanAlert:'Berhasil '+psn+' Data' });
          }
          else
          {
              this.setState({ show: true, basicType:'danger', basicTitle:'Data Lembaga', pesanAlert:'Gagal '+psn+' Data' });
          }
          this.componentDidMount();
      })
  }
  
  tombolAksiLembaga = (cell, row) => {
    return (
      <>
          {/* <Button  size="xs" color="btn btn-secondary" className="btn-icon" onClick={() => this.detailDataLembaga(row)} disabled={this.state.statusLembaga.btnAksi}><i className="fa fa-eye"></i></Button> <br/> */}
          <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataLembaga(row)} disabled={this.state.statusLembaga.btnAksi}><i className="fa fa-pencil"></i></Button> <br/>
          <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapusLembaga(row.uuid)} disabled={this.state.statusLembaga.btnAksi}><i className="fa fa-trash"></i></Button>  
      </>
    )
  }

  // DATA PERANGKAT LEMBAGA
    fetchPerangkat = (uuid, params = {}) => {
      this.setState({ loadingPerangkat: true })
      
      let linkPerangkat = "";
        if (params.page)
        {
            switch(params.page)
            {
                case '>>': linkPerangkat = "?page="+(Math.ceil(this.state.paginationPerangkat.total / this.state.paginationPerangkat.per_page));break;
                case '>': linkPerangkat = "?page="+(parseInt(this.state.paginationPerangkat.current_page) + 1);break;
                case '<': linkPerangkat = "?page="+(parseInt(this.state.paginationPerangkat.current_page) - 1);break;
                case '<<': linkPerangkat = "?page=1";break;
                default: linkPerangkat = "?page="+params.page;break;
            }
        }

      Get('perangkat-lembaga/findbyLembaga/'+uuid+linkPerangkat, null, (dtkat) => {
        if (dtkat.results) {
          this.setState({ 
            dataPerangkat: dtkat.results.data,
            paginationPerangkat:dtkat.results,
            loadingPerangkat: false
          });
          this.paginationPerangkat(dtkat.results);
        }
      });
    };

    // PAGINATION PERANGKAT
    paginationPerangkat = (data) => {
      let awalPagePerangkat = "";
      let halPagePerangkat = [];
      let sebelumPagePerangkat = "";
      let setelahPagePerangkat = "";
      let akhirPagePerangkat = "";
      if (data.total > 0)
      {
          let start = 1;
          let end = 5;
          let n = 0;
          let p = 0;
          if (data.current_page <= 3)
          {   
              start = 1;
              end = 5;
              if (data.last_page > data.current_page)
              {
                  n = data.current_page + 1;
              }
          }
          else 
          {
              p = data.current_page - 1;
              n = data.current_page + 1;
              start = data.current_page - 2;
              end = data.current_page + 2;
          }
          if (end >= data.last_page - 2)
          {
              p = data.current_page - 1;
              if (start >= 5)
              {
                  start = data.last_page - 4;
              }
              end = data.last_page;
          }

          for (let i=start;i<=end;i++) 
          {
              let warna = "primary-custom";
              if (i === data.current_page)
              {
                  warna = "danger"
              }
              halPagePerangkat.push(<a href={() => false} onClick={()=>this.fetchPerangkat(this.state.detailLembaga.uuid, {page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
          }
          if (p > 0)
          {
              sebelumPagePerangkat = (<a href={() => false} onClick={()=>this.fetchPerangkat(this.state.detailLembaga.uuid, {page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
          }
          if (n > 0)
          {
              setelahPagePerangkat = (<a href={() => false} onClick={()=>this.fetchPerangkat(this.state.detailLembaga.uuid, {page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
          }
          awalPagePerangkat = (<a href={() => false} onClick={()=>this.fetchPerangkat(this.state.detailLembaga.uuid, {page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
          akhirPagePerangkat = (<a href={() => false} onClick={()=>this.fetchPerangkat(this.state.detailLembaga.uuid, {page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
      }
      this.setState({awalPagePerangkat,sebelumPagePerangkat,halPagePerangkat,setelahPagePerangkat,akhirPagePerangkat});
    }

    // ============================== Tambah Data ===============================
  
    tambahPerangkat = e => {
      this.setState({
        statusPerangkat: {
          ...this.state.statusPerangkat,
          form: true
        },
        detailLembaga: this.state.detailLembaga,
        judulPerangkat: "Tambah Perangkat "+this.state.detailLembaga.nama_lembaga,
        dtPerangkat: frmPerangkat,
      });
    };

    ubahdataPerangkat = (data) => {
      this.setState({
        statusPerangkat: {
          ...this.state.statusPerangkat,
          // btnAksi: true,
          form: true
        },
        dtPerangkat: data,
        judulPerangkat: "Ubah Perangkat "+this.state.detailLembaga.nama_lembaga,
      });
    };
    
    tutupFormPerangkat = () => {
      this.setState({
        statusPerangkat:{
          ...this.state.statusPerangkat,
          form: false,
        }
      })
      this.forceUpdate();
    };

    simpanPerangkat = (e) => {
      e.preventDefault();
      if(this.state.dtPerangkat.foto){
        let bodyRaw = {
          uuid : document.getElementById("uuid").value,
          uuid_lembaga: this.state.detailLembaga.uuid,
          nama : document.getElementById("nama").value,
          jabatan: document.getElementById("jabatan").value,
          tanggal_mulai : document.getElementById("tanggal_mulai").value,
          tanggal_selesai : document.getElementById("tanggal_selesai").value,
          status : this.state.dtPerangkat.status,
          foto: this.state.dtPerangkat.foto,
        }

        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (bodyRaw.uuid === "") {
          psn = "Tambah";
          resstat = 201;
          bodyRaw.uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }
          
        Post('perangkat-lembaga/' + metode, bodyRaw.uuid, bodyRaw, (res) => {
          this.setState({
            statusPerangkat:{
              ...this.state.statusPerangkat,
              btnForm: false,
              form: false,
              btnAksi: false,
            }
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: 'success',
              basicTitle: "Data Perangkat "+ this.state.detailLembaga.nama_lembaga,
              pesanAlert: 'Berhasil ' + psn + ' Data'
            });
            this.componentDidMount();
          } else {
            this.setState({
              show: true,
              basicType: 'danger',
              basicTitle: "Data Perangkat "+ this.state.detailLembaga.nama_lembaga,
              pesanAlert: 'Gagal ' + psn + ' Data'
            });
            this.componentDidMount();
          }
        });
      } else {
        this.setState({
          show: true,
          basicType: 'info',
          basicTitle: "Data Belum Lengkap",
          pesanAlert: 'Mohon isi seluruh data dengan benar'
        });
      }
    };

    changeStatus = (e) => {
      e.preventDefault();
      if(this.state.dtPerangkat.status === 0){
        this.setState({
          dtPerangkat:{
            ...this.state.dtPerangkat,
            status: 1,
          }
        })
      }
      else{
        this.setState({
          dtPerangkat:{
            ...this.state.dtPerangkat,
            status: 0,
          }
        })
      }
    };

    processGbr = (d) => {
      let type = d[0].type.split('/');
      if (
        type[1] === 'jpg' ||
        type[1] === 'jpeg' ||
        type[1] === 'png' ||
        type[1] === 'bmp' ||
        type[1] === 'tiff' ||
        type[1] === 'webp'
      ) {
        
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

        Post('upload-file/perangkat-lembaga', null, bodyFormData, (data) => {
          Resizer.imageFileResizer(
            d[0],
            300,
            300,
            type[1],
            100,
            0,
            (uri) => {
              this.setState({
                dtPerangkat:{
                  ...this.state.dtPerangkat,
                  foto: uri,
                  foto_info: ''
                }
              })
            },
            'base64'
          );
        });
      } else {
        this.setState({
          dtPerangkat:{
            ...this.state.dtPerangkat,
            foto: '',
            foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
          }
        })
      }
    };
    
    tombolAksiPerangkat = (cell, row) => {
      return (
        <div>
          <Button  size="xs" color="btn btn-secondary" className="btn-icon" onClick={() => this.detailDataPerangkat(row)} disabled={this.state.statusPerangkat.btnAksi}><i className="fa fa-eye"></i></Button> {' '}&nbsp;&nbsp;
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => this.ubahdataPerangkat(row)}
            disabled={this.state.statusPerangkat.btnAksi}
          >
            <i className="fa fa-pencil" />
          </Button>{' '}
          &nbsp;&nbsp;
          <Button
            size="xs"
            color="danger"
            className="btn-icon"
            onClick={() => this.konfirmHapusPerangkat(row.uuid)}
            disabled={this.state.statusPerangkat.btnAksi}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
      );
    };

    
    // ============================= Hapus Data ===============================
    konfirmHapusPerangkat = (id) => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusDataPerangkat(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusDataPerangkat = (id) => {
      this.hideAlert();
      let psn = 'Hapus';
      Delete('perangkat-lembaga/delete', id, (res) => {
        if (res === 200) {
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Perangkat Lembaga',
            pesanAlert: 'Berhasil ' + psn + ' Data'
          });
          this.componentDidMount();
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Perangkat Lembaga',
            pesanAlert: 'Gagal ' + psn + ' Data'
          });
        }
      });
    };
  // END DATA PERANGKAT LEMBAGA


	componentDidMount() {
		this.fetch({ page: null, where: [] });
		this.fetchPerangkat(this.state.detailLembaga.uuid, { page: null, where: [] });
	}

	render() {
    var dataLembaga = this.state.dataLembaga;
    var dataPerangkat = this.state.dataPerangkat;
    
    const columnsPerangkat = [
      {
          dataField: "no",
          text: "No",
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationPerangkat.current_page;
              let total_records_per_page = this.state.paginationPerangkat.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
          dataField: 'nama',
          text: 'Nama Perangkat',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'tanggal_mulai',
          text: 'Tanggal Mulai',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'tanggal_selesai',
          text: 'Tanggal Selesai',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
     
      {
          dataField: 'status',
          text: 'Status',
          isDummyField: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, data) => data.status===1?"Ya":"Tidak",
          sort: true
      },
      {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiPerangkat,
      },
    ];

    
    const columnsLembaga = [
      {
          dataField: "no",
          text: "No",
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationLembaga.current_page;
              let total_records_per_page = this.state.paginationLembaga.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
          dataField: 'nama_lembaga',
          text: 'Nama Lembaga',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'tahun_berdiri',
          text: 'Tanggal Berdiri',
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
     
      {
          dataField: 'status',
          text: 'Status',
          isDummyField: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, data) => data.status===1?"Ya":"Tidak",
          sort: true
      },
      {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          formatter: this.tombolAksiLembaga,
      },
    ];

		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}
				
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <Nav className={'nav flex-column nav-pills'}>
                                        {this.state.dataLembaga.length !==  0 ? (
                                          this.state.dataLembaga.map((dt, index) => {
                                            return (
                                              <>
                                                <NavItem>
                                                  <NavLink className={classnames({ active: this.state.active_tab_vertical === index + 1 })}
                                                      onClick={() => { this.toggle_vertical(index + 1, dt); }}>
                                                    {dt.nama_lembaga}
                                                  </NavLink>
                                                </NavItem>
                                              </>
                                            );
                                          })
                                        ) : (
                                          <>
                                            <Label
                                              className="col-sm-12 col-form-label text-center"
                                              htmlFor="inputEmail3"
                                            >
                                              {/* Data Lembaga Belum Ada */}
                                            </Label>
                                          </>
                                        )}
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === 999999 })}
                                              onClick={() => { this.toggle_vertical(999999, 'semua lembaga'); }}>
                                            List Lembaga Kemasyarakatan
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                    <div className="col-lg-9">
                                      <TabContent activeTab={this.state.active_tab_vertical}>
                                      {this.state.dataLembaga.length !==  0 ? (
                                          this.state.dataLembaga.map((dt, index) => {
                                            return (
                                              <>
                                                <TabPane tabId={index + 1}>
                                                  <Row>
                                                    <Col sm="12">
                                                      <div className="col-sm-12 text-left">
                                                        <Button
                                                          size="sm"
                                                          color="success"
                                                          onClick={() => {
                                                            this.tambahPerangkat();
                                                            this.setState({
                                                              detailLembaga: this.state.detailLembaga
                                                            })
                                                          }}
                                                        >
                                                          Tambah Data
                                                        </Button>       
                                                      </div>
                                                      <div className="card-body datatable-react">
                                                        {this.state.loadingPerangkat ? (
                                                          <div className="row">
                                                            <div className="col-sm-12" align="center">
                                                              <img
                                                                alt="loading"
                                                                src={require("../../../assets/images/tes-loading.gif")}
                                                                style={{
                                                                  borderRadius: "10px",
                                                                  width: '100px'
                                                                }} />
                                                            </div>
                                                          </div>
                                                        ) : dataPerangkat.length > 0 ? (
                                                          <>
                                                          <BootstrapTable
                                                              keyField="id"
                                                              data={ dataPerangkat }
                                                              columns={ columnsPerangkat }
                                                              />
                                                          <div className="pull-right text-white">
                                                              {this.state.awalPagePerangkat}{this.state.sebelumPagePerangkat}{this.state.halPagePerangkat.map(dt=> { return dt})}{this.state.setelahPagePerangkat}{this.state.akhirPagePerangkat}
                                                          </div>
                                                          </>
                                                        ) : (
                                                          <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                        )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </TabPane>
                                              </>
                                            );
                                          })
                                        ) : (
                                          <>
                                            <Label
                                              className="col-sm-12 col-form-label text-center"
                                              htmlFor="inputEmail3"
                                            >
                                              {/* Data Lembaga Belum Ada */}
                                            </Label>
                                          </>
                                        )}
                                        <TabPane tabId={999999}>
                                          <Row>
                                            <Col sm="12">
                                              <div className="col-sm-12">
                                                <div className="card mb-0">
                                                    <div className="card-header">
                                                        <div className="row">
                                                            <div className="col-sm-9">
                                                              <h5>Data Lembaga Kemasyarakatan {JENIS_DESA}</h5>
                                                            </div>
                                                            <div className="col-sm-3 text-right">
                                                              <Button
                                                                size="sm"
                                                                color="success"
                                                                onClick={() => {
                                                                  this.tambahDataLembaga();
                                                                }}
                                                              >
                                                                Tambah Data
                                                              </Button>       
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body datatable-react">
                                                      {this.state.loading ? (
                                                        <div className="row">
                                                          <div className="col-sm-12" align="center">
                                                            <img
                                                              alt="loading"
                                                              src={require("../../../assets/images/tes-loading.gif")}
                                                              style={{
                                                                borderRadius: "10px",
                                                                width: '100px'
                                                              }} />
                                                          </div>
                                                        </div>
                                                      ) : dataLembaga.length > 0 ? (
                                                        <>
                                                        <BootstrapTable
                                                            keyField="id"
                                                            data={ dataLembaga }
                                                            columns={ columnsLembaga }
                                                            />
                                                        <div className="pull-right text-white">
                                                            {this.state.awalLembaga}{this.state.sebelumLembaga}{this.state.halLembaga.map(dtLembaga=> { return dtLembaga})}{this.state.setelahLembaga}{this.state.akhirLembaga}
                                                        </div>
                                                        </>
                                                      ) : (
                                                        <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                      )}
                                                    </div>
                                                </div>
                                            </div>
                                            <ModalLembaga
                                              data={this.state.dtLembaga}
                                              isOpen={this.state.statusLembaga.form}
                                              propsData={this.props}
                                              setAlert={(e) => {
                                                this.setState(e)
                                              }}
                                              setStatusRefresh={(e) => {
                                                if (e) {
                                                  this.fetch({ page: null, where: [] });
                                                }
                                              }}
                                              setIsOpen={(e) => {
                                                if (!e) {
                                                  this.setState({ dtLembaga: null })
                                                }
                                                this.setState({
                                                  statusLembaga: {
                                                    ...this.state.statusLembaga,
                                                    form: e
                                                  }
                                                })
                                              }}
                                            />

                                            <ModalDetailPerangkat
                                              data={this.state.detailPerangkat}
                                              isOpen={this.state.statusDetailPerangkat.form}
                                              propsData={this.props}
                                              setAlert={(e) => {
                                                this.setState(e)
                                              }}
                                              setStatusRefresh={(e) => {
                                                if (e) {
                                                  this.fetch({ page: null, where: [] });
                                                }
                                              }}
                                              setIsOpen={(e) => {
                                                if (!e) {
                                                  this.setState({ detailPerangkat: null })
                                                }
                                                this.setState({
                                                  statusDetailPerangkat: {
                                                    ...this.state.statusDetailPerangkat,
                                                    form: e
                                                  }
                                                })
                                              }}
                                            />
                                            </Col>
                                          </Row>
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                    
                                    <Modal
                                                        open={this.state.statusPerangkat.form}
                                                        onClose={this.tutupFormPerangkat}
                                                        closeOnEsc={false}
                                                        closeOnOverlayClick={false}
                                                        styles={{ modal: { width: '50%' } }}
                                                      >
                                                        <Form id="form-data" className="theme-form" onSubmit={this.simpanPerangkat}>
                                                          <div className="modal-header">
                                                            <h5 className="modal-title">{this.state.judulPerangkat}</h5>
                                                          </div>
                                                          <div className="modal-body">
                                                            <input
                                                              className="form-control"
                                                              id="uuid"
                                                              type="hidden"
                                                              defaultValue={this.state.dtPerangkat.uuid}
                                                            />
                                                            <FormGroup className="row">
                                                              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama</Label>
                                                              <Col sm='9'>
                                                                <input className="form-control" id="nama" type="text" placeholder="Masukkan Nama" defaultValue={this.state.dtPerangkat.nama} required/>
                                                              </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jabatan</Label>
                                                              <Col sm='9'>
                                                                <input className="form-control" id="jabatan" type="text" placeholder="Masukkan Jabatan" defaultValue={this.state.dtPerangkat.jabatan} required/>
                                                              </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Mulai Jabatan</Label>
                                                              <Col sm='9'>
                                                                <input className="form-control" id="tanggal_mulai" type="date" placeholder="Tanggal Mulai" defaultValue={this.state.dtPerangkat.tanggal_mulai} required/>
                                                              </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Selesai Jabatan</Label>
                                                              <Col sm='9'>
                                                                <input className="form-control" id="tanggal_selesai" type="date" placeholder="Tanggal Selesai" defaultValue={this.state.dtPerangkat.tanggal_selesai} required/>
                                                              </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Foto Perangkat</Label>
                                                              <Col sm='9'>
                                                                <Dropzone
                                                                  onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)}
                                                                  maxFiles={1}
                                                                >
                                                                  {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                      <div
                                                                        {...getRootProps()}
                                                                        style={{
                                                                          minHeight: '100px',
                                                                          paddingTop: '40px',
                                                                          padding: '5px 5px 5px 5px',
                                                                          border: '1px solid #d2d2d2'
                                                                        }}
                                                                      >
                                                                        <input {...getInputProps()} />
                                                                        <p>
                                                                          {this.state.dtPerangkat.foto ? (
                                                                            <img
                                                                              alt="Gambar Perangkat Kelembagaan"
                                                                              src={this.state.dtPerangkat.foto}
                                                                              style={{
                                                                                minHeight: '50px',
                                                                                minWidth: '50px',
                                                                                maxWidth:'100%', border:'none',align:'center'
                                                                              }}
                                                                            />
                                                                          ) : (
                                                                            "Drag 'n' drop some files here, or click to select files"
                                                                          )}
                                                                          {this.state.dtPerangkat.foto_info ? (
                                                                            this.state.dtPerangkat.foto_info
                                                                          ) : (
                                                                            ''
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                    </section>
                                                                  )}
                                                                </Dropzone>
                                                              </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Aktif</Label>
                                                              <Col sm='9'>
                                                                <select className="form-control" 
                                                                  id="status" 
                                                                  name='status' 
                                                                  defaultValue={this.state.dtPerangkat.status} 
                                                                  value={this.state.dtPerangkat.status} 
                                                                  onChange={e =>
                                                                    this.changeStatus(e)
                                                                  }
                                                                >
                                                                  <option value="1">Ya</option>
                                                                  <option value="0">Tidak</option>
                                                                </select>
                                                              </Col>
                                                            </FormGroup>
                                                          </div>
                                                          <div className="modal-footer">
                                                            <button
                                                              type="button"
                                                              className="btn btn-warning"
                                                              disabled={this.state.statusPerangkat.btnForm}
                                                              onClick={this.tutupFormPerangkat}
                                                            >
                                                              Tutup
                                                            </button>
                                                            <button type="submit" className="btn btn-success" disabled={this.state.statusPerangkat.btnForm}>
                                                              Simpan
                                                            </button>
                                                          </div>
                                                        </Form>
                                                      </Modal>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

export default BasicTable;