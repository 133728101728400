import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DESA, JENIS_DUSUN, ID_DESA, Post } from '../../../function/Koneksi';

const ModalRW = ({ data, optionData, additionalData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	
	
	const frmDusun = {
		id: 0,
		idbaru: '',
		id_keldes: '',
		nama: '',
	}

	const initFormData = {
		dusunList: optionData.dusunList,
        dusunSet: additionalData.dusunSet,

		isKelurahan: false,
		status: {
			form:false,
			btnForm:false,
			btnAksi:false,
			select:false
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDusun)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			setFormData({
				...formData,
				isKelurahan: true
			})
		}

		setDt(data ? data : frmDusun)

		// Get('keldes/find/'+ID_DESA, null, (data) => {
		// 	setFormData({
		// 		...formData,
		// 		dataWilayah: {
		// 			...formData.dataWilayah,
		// 			id_provinsi: data.results.data_kecamatan.data_kabkota.data_provinsi.id,
		// 			id_kabkota: data.results.data_kecamatan.data_kabkota.id,
		// 			id_kecamatan: data.results.data_kecamatan.id,
		// 			id_keldes: data.results.id,
		// 		}
		// 	})
		// })

		// Get('dusun/kel/'+ID_DESA+'/list', null, (data) => {
		// 	if(data.results.length!==0){
		// 		let akhir = data.results.length-1;
		// 		let getDataNext = data.results[akhir];
		// 		let nextID = getDataNext.id+1;
				
		// 		setFormData({
		// 			...formData,
		// 			dataWilayah: {
		// 				...formData.dataWilayah,
		// 				next_id: nextID,
		// 			}
		// 		})
		// 	}
		// 	else{
		// 		let nextID = ID_DESA+'001';
		// 		setFormData({
		// 			...formData,
		// 			dataWilayah: {
		// 				...formData.dataWilayah,
		// 				next_id: nextID,
		// 			}
		// 		})
		// 	}
		// });

		setFormData(data ? {
			...initFormData,
			dusunList: optionData.dusunList,
		} : initFormData)
	}, [isOpen])

	
	const tutupForm = () => {
		setDt(frmDusun)
		setIsOpen(false)
	};

	const simpan = (e) => {
		e.preventDefault();
		
		// Jika Kelurahan
		if(formData.isKelurahan){
			if (document.getElementById("nama").value!==''){
				setFormData({
					...formData,
					status:{
						...formData.status,
						btnForm: true,
					},
				})

				let id = parseInt(document.getElementById("id").value);
                let addRW = {
                    // id : formData.dataWilayah.next_id,
										id : ID_DESA + document.getElementById("nama").value,
                    id_keldes : ID_DESA,
                    nama : document.getElementById("nama").value,
                };
                
                let psn = '';
                let resstat = 204;
                let metode = "create";
                if (id === 0)
                {
                    psn = "Tambah"; 
                    resstat = 201;
                    id = null;
                    
                } 
                else 
                {
                    psn = "Ubah"; 
                    resstat = 200;
                    metode = "update";
                }
    
                Post('rw/'+metode,id,addRW, (res) => {
					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)

                    if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data RW",
							pesanAlert: "Berhasil " + psn + " Data",
							successAlert: true,
						}
						setAlert(alertData)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data RW",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}
					
					// Refresh list
					setStatusRefresh(true)
                    // this.componentDidMount();
                });
			} else {
				const alertData = {
					show: true,
					basicType: "info",
					basicTitle: "Data Belum Lengkap",
					pesanAlert: "Mohon isi seluruh data dengan benar"
				}
				setAlert(alertData)
			}
		} else {
			// Jika Tidak Kelurahan
			if (dt.id_dusun > 0 && document.getElementById("nama").value!==''){
				setFormData({
					...formData,
					status:{
						...formData.status,
						btnForm: true,
					},
				})

				let id = parseInt(document.getElementById("id").value);
                let addRW = {
                    // id : formData.dataWilayah.next_id,
										id : dt.id_dusun + document.getElementById("nama").value,
                    id_keldes : ID_DESA,
                    id_dusun : dt.id_dusun,
                    nama : document.getElementById("nama").value,
                };
                

                let psn = '';
                let resstat = 204;
                let metode = "create";
                if (id === 0)
                {
                    psn = "Tambah"; 
                    resstat = 201;
                    id = null;
                    
                } 
                else 
                {
                    psn = "Ubah"; 
                    resstat = 200;
                    metode = "update";
                }
    
                Post('rw/'+metode,id,addRW, (res) => {
                    setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)

                    if (res.status === resstat)
                    {
                        const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data RW",
							pesanAlert: "Berhasil " + psn + " Data",
							successAlert: true,
						}
						setAlert(alertData)
                    }
                    else
                    {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data RW",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
                    }
					
					// Refresh list
					setStatusRefresh(true)
                    // this.componentDidMount();
                });
			} else {
				const alertData = {
					show: true,
					basicType: "info",
					basicTitle: "Data Belum Lengkap",
					pesanAlert: "Mohon isi seluruh data dengan benar"
				}
				setAlert(alertData)
			}
		}
	};

	const changeSelectValue = (e, params) => {
		if (e){
			switch (params) {
				case 'dusun': 
					setFormData({
						...formData,
						status:{
							...formData.status,
							btnForm: true,
						},
					})
					Get('rw/dus/'+e.value+'/list', null, (data) => {
						if(data.results.length!==0){
							let akhir = data.results.length-1;
							let getDataNext = data.results[akhir];
							let setNextID = getDataNext.id+1;
							setFormData({
								...formData,
								dusunSet:e,
								dataWilayah: {
									...formData.dataWilayah,
									next_id: setNextID,
								},
								status:{
									...formData.status,
									btnForm: false,
								},
							})
						}
						else{
							let setnextID = e.value+'001';
							let nextID = parseInt(setnextID);
							let setNextID = parseInt(nextID);
							setFormData({
								...formData,
								dusunSet:e,
								dataWilayah: {
									...formData.dataWilayah,
									next_id: setNextID,
								},
								status:{
									...formData.status,
									btnForm: false,
								},
							})
						}
					});

					setDt({
						...dt,
						id_dusun: e.value,
					})
				break;
				default:
					// no default
					break;
			}
		}
		else{
			switch (params) {
				case 'dusun': 
					setFormData({
						...formData,
						dusunSet:[],
					})
					setDt({
						...dt,
						id_dusun: 0,
					})
				break;
				default:
					// no default
					break;
			}
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			styles={{ modal: { width: "35%" } }}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} 
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.id == '' ? 'Tambah' : 'Edit'} Data RW</h5>
				</div>
				<div className="modal-body">
					<input
						className="form-control"
						id="id"
						type="hidden"
						placeholder=""
						defaultValue={dt.id}
					/>
					{
						formData.isKelurahan?
						<></>
						:
						<>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Dusun</Label>
								<Col sm='9'>
									<Select
										isDisabled={formData.status.select}
										classNamePrefix="select"
										onChange={((e)=>{ changeSelectValue(e,'dusun')})}
										defaultValue={formData.dusunSet} 
										value={formData.dusunSet} 
										name="dusun"
										options={formData.dusunList}
										placeholder={"Pilih " + JENIS_DUSUN}
										isClearable
										/>
								</Col>
							</FormGroup>
						</>
					}
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"> RW</Label>
						<Col sm='9'>
							<input className="form-control" id="nama" type="text" placeholder="Contoh: 001" defaultValue={dt.nama}/>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setDt(frmDusun)
							tutupForm()
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalRW;
