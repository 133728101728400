import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DUSUN, JENIS_DESA, Post } from '../../../../function/Koneksi';

const ModalTandaTangan = ({ data, optionData, additionalData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	const frmTTD = {
		uuid: '',
		jabatan  : '',
		jabatan_pengganti  : '',
		nama  : '',
		nip  : '',
		status  : '',
	}

	const initFormData = {
		frmPrgTTD: optionData.frmPrgTTD,
		frmStatusTTD: optionData.frmStatusTTD,
		frmPengganti: additionalData.frmPengganti,
        statusStatTTD: additionalData.statusStatTTD,
		prgStatTTD: additionalData.prgStatTTD,
        prgStatTTD2: additionalData.prgStatTTD2,
		isKelurahanTTD: false,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const changeFrmPengganti = (e) => {
		e.preventDefault();
		if(formData.frmPengganti === 'inherit'){
			setFormData({
				...formData,
				frmPengganti:'none',
				statusStatTTD:[],
				prgStatTTD2: [],
			})
		}
		else{
			setFormData({
				...formData,
				frmPengganti:'inherit',
				statusStatTTD:[],
				prgStatTTD2: [],
			})
		}
	};

	const changeSelectValueTTD = (e, params) => {
		switch (params) {
			case "status":
				setFormData({
					...formData,
					statusStatTTD:e
				})
				break;
			case "jabatan_pengganti":
				setFormData({
					...formData,
					prgStatTTD2:e
				})
				break;
			default:
				// no default
				break;
		}
	};

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmTTD)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}
		
		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			setFormData({
				...formData,
				isKelurahanTTD: true
			})
		}

		setDt(data ? data : frmTTD)
		setFormData(data ? {
			...initFormData,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	
	const tutupForm = () => {
		setFormData({
			...formData,
			frmPengganti:'none',
			statusStatTTD: [],
			prgStatTTD: [],
			prgStatTTD2: [],
		})
		setIsOpen(false)
	};

	const simpan = (e) => {
		e.preventDefault();
		let jabatan_ttd = '';
        if(formData.isKelurahanTTD){
            jabatan_ttd = "Kepala Kelurahan";
        }
        else{
            jabatan_ttd = "Kepala Desa";
        }

	
		if(jabatan_ttd){
			let bodyRaw = {
				...dt,
				uuid : document.getElementById("uuid").value,
				nip : document.getElementById("nip").value,
				nama : document.getElementById("nama").value,
				aktif : document.getElementById("aktif").value,
				status : formData.statusStatTTD?formData.statusStatTTD.value:null,
				jabatan : jabatan_ttd,
				jabatan_pengganti : formData.prgStatTTD2?formData.prgStatTTD2.value:null,
			}

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			
			Post("tanda-tangan/" + metode, bodyRaw.uuid, bodyRaw,res => {
				setFormData({
					...formData,
					status: {
						...formData.status,
						btnForm: false,
						form: false,
					}
				})
				setIsOpen(false)
				if (res.status === resstat) {
					const alertData = {
						show: true,
						basicType: "success",
						basicTitle: "Data Tanda Tangan",
						pesanAlert: "Berhasil " + psn + " Data",
						successAlert: true,
					}
					setAlert(alertData)
				} else {
					const alertData = {
						show: true,
						basicType: "danger",
						basicTitle: "Data Tanda Tangan",
						pesanAlert: "Gagal " + psn + " Data"
					}
					setAlert(alertData)
				}

				// Refresh list
				setStatusRefresh(true)
			});
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} 
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Tanda Tangan</h5>
				</div>
				<div className="modal-body">
					<p>Nama Penanda Tangan otomatis terisi saat memilih jabatan dari perangkat {JENIS_DESA.toLowerCase()}. Namun nama ini bisa diubah sesuai kebutuhan. Misalkan untuk menambahkan gelar depan dan belakang</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jabatan</Label>
						<Col sm='9'>
							<input className="form-control" id="jabatan_ttd" type="text" placeholder="Nama Penanda Tangan" defaultValue={formData.isKelurahanTTD?"Kepala Kelurahan":"Kepala Desa"} required readOnly/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Col sm={12}>
							<a href={() => false} onClick={e => changeFrmPengganti(e)} className="btn btn-xs btn-success">Pengganti</a>
						</Col>
					</FormGroup>
					<div style={{display:formData.frmPengganti,border:"1px #d2d2d2 solid"}} className="p-3 mb-3 mt-3">
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
							<Col sm='9'>
								<Select
									classNamePrefix="select"
									onChange={((e)=>{ changeSelectValueTTD(e,'status')})}
									defaultValue={formData.statusStatTTD} 
									value={formData.statusStatTTD} 
									name="status"
									options={formData.frmStatusTTD}
									placeholder="Pilih Jabatan Penanda Tangan"
									isClearable
									/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jabatan Pengganti</Label>
							<Col sm='9'>
								<Select
									classNamePrefix="select"
									onChange={((e)=>{ changeSelectValueTTD(e,'jabatan_pengganti')})}
									defaultValue={formData.prgStatTTD2} 
									value={formData.prgStatTTD2} 
									name="jabatan_pengganti"
									options={formData.frmPrgTTD}
									placeholder="Pilih Jabatan Pengganti Penanda Tangan"
									isClearable
									/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Penanda Tangan</Label>
						<Col sm='9'>
							<input className="form-control" id="nama" type="text" placeholder="Nama Penanda Tangan" defaultValue={dt.nama} required/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIP Penanda Tangan</Label>
						<Col sm='9'>
							<input className="form-control" id="nip" type="text" placeholder="Kosongkan Jika Non PNS" defaultValue={dt.nip}/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanda Tangan Utama</Label>
						<Col sm='9'>
							<select className="form-control" id="aktif" name='aktif' defaultValue={dt.aktif}>
								<option value="1">Ya</option>
								<option value="0">Tidak</option>
							</select>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							tutupForm()
							// Tutup modal
							// setFormData({
							// 	...formData,
							// 	status: {
							// 		...formData.status,
							// 		form: false
							// 	},
							// })
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalTandaTangan;
