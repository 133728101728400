import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, JENIS_DESA, JENIS_DUSUN, ID_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';




const frmDef = {
	id: 0,
	idbaru: '',
	id_rw: '',
	id_rt: '',
	nama: '',
}
class BasicTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				select: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah RT",
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: [],
			frmKbkt: [],
			kbktStat: [],
			frmKec: [],
			kecStat: [],
			frmKlds: [],
			kldsStat: [],
			frmDsn: [],
			dsnStat: [],
			frmRW: [],
			rwStat: [],
			// =================================
			// =========== Alert ============
			alert: null,
			show: false,
			loading: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			// ==============================
			dataWilayah: {
				next_id: '',
			},
			isKelurahan: false,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		// let where = [];
		// if (params.where)
		// {
		//     where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('rt' + link, null, (dtkat) => {
			this.setState({ data: dtkat.results.data, pagination: dtkat.results, loading: false });
			this.pagination(dtkat.results);
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger"
				}
				hal.push(<a href={() => false} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a href={() => false} onClick={() => this.fetch({ page: p })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a href={() => false} onClick={() => this.fetch({ page: n })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a href={() => false} onClick={() => this.fetch({ page: 1 })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a href={() => false} onClick={() => this.fetch({ page: data.last_page })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}
	// Cari Data Table
	cariData = e => {
		if (e.key === "Enter") {
			let addRT = {
				nama: e.target.value,
			};
			Post('rt', null, addRT, (data) => {
				this.setState({ data: data.data.results.data, pagination: data.data.results });
			});
		}
	}

	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		if (this.state.dt.id_rw > 0 && document.getElementById("nama").value !== '') {
			this.setState({
				status: {
					...this.state.status,
					btnForm: true,
				},
			})
			this.forceUpdate();
			let id = document.getElementById("id").value;
			let addRT = {
				// id: this.state.dataWilayah.next_id,
				id : this.state.dt.id_rw + document.getElementById("nama").value,
				id_rw: this.state.dt.id_rw,
				nama: document.getElementById("nama").value,
			};

			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (id === '0') {
				psn = "Tambah";
				resstat = 201;
				id = null;

			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post('rt/' + metode, id, addRT, (res) => {
				this.setState({
					status: {
						...this.state.status,
						btnForm: false,
						form: false,
					},
				})
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ show: true, basicType: 'success', basicTitle: 'Data RT', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.tutupForm();
				}
				else {
					this.setState({ show: true, basicType: 'danger', basicTitle: 'Data RT', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			});
		}
		else {
			this.setState({ show: true, basicType: 'danger', basicTitle: 'Data RT', pesanAlert: "Data Tidak Lengkap" });
		}
	}
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log('Data Ubah')
		console.log(data)
		this.setState({
			status: {
				...this.state.status,
				btnAksi: true,
			},
			judul: "Ubah RT",
		})
		this.forceUpdate();
		// Get('rt/find',id, (data) => {
		this.setState({
			dt: {
				...this.state.dt,
				id: data.id,
				id_rw: data.id_rw,
				nama: data.nama,
			},
			status: {
				...this.state.status,
				btnAksi: false,
			},
			dataWilayah: {
				next_id: data.id,
			},
		})
		this.ubahPilihan('rw', data.data_rw);
		this.forceUpdate();
		// });
	}
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('rt/delete', id, (res) => {
			if (res === 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data RT', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data RT', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	}

	tutupForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: false,
			},
			dsnStat: [],
			rwStat: [],
			dataWilayah: {
				next_id: '',
			},
		})
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button>
				{/* <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.id)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>   */}
			</>
		)
	}

	getChild = (e, self, parent, child) => {
		this.setState({
			status: {
				...this.state.status,
				select: true,
			},
		})
		this.forceUpdate();
		this.changeSelectValue(e, self);
		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota': this.setState({ frmKbkt: frm, frmKec: [], frmKlds: [], frmDsn: [], frmRW: [] }); break;
					case 'kecamatan': this.setState({ frmKec: frm, frmKlds: [], frmDsn: [], frmRW: [] }); break;
					case 'keldes': this.setState({ frmKlds: frm, frmDsn: [], frmRW: [] }); break;
					case 'dusun': this.setState({ frmDsn: frm, frmRW: [] }); break;
					case 'rw': this.setState({ frmRW: frm }); break;
					default:
						// no default
						break;
				}
				this.setState({
					status: {
						...this.state.status,
						select: false,
					},
				})
				this.forceUpdate();
			});
		}
	}

	ubahPilihan = (table, data) => {
		switch (table) {
			case 'rw':
				if (this.state.isKelurahan) {
					this.setState({
						rwStat: { value: data.id, label: data.nama }
					});
				}
				else {
					this.getChild({ value: data.data_dusun.id, label: data.data_dusun.nama }, 'dusun', 'dus', 'rw');

					/////////////// NEW /////////

					// this.getChild({ value: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id, label: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama }, 'provinsi', 'prov', 'kab-kota');

					// this.getChild({ value: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.id, label: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama }, 'kab-kota', 'kab', 'kecamatan');

					// this.getChild({ value: data.data_dusun.data_keldes.data_kecamatan.id, label: data.data_dusun.data_keldes.data_kecamatan.nama }, 'kecamatan', 'kec', 'keldes');

					// this.getChild({ value: data.data_dusun.data_keldes.id, label: data.data_dusun.data_keldes.nama }, 'keldes', 'kel', 'rw');

					// this.getChild({ value: data.data_dusun.id, label: data.data_dusun.nama }, 'dusun', 'dus', 'rw');

					this.setState({ prvStat: { value: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id, label: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama }, kbktStat: { value: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.id, label: data.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama }, kecStat: { value: data.data_dusun.data_keldes.data_kecamatan.id, label: data.data_dusun.data_keldes.data_kecamatan.nama }, kldsStat: { value: data.data_dusun.data_keldes.id, label: data.data_dusun.data_keldes.nama }, dsnStat: { value: data.data_dusun.id, label: data.data_dusun.nama }, rwStat: { value: data.id, label: data.nama } });
				}
				this.bukaForm();
				break;
			default:
				// no default
				break;
		}
	}

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi': this.setState({ prvStat: e, kbktStat: [], kecStat: [], kldsStat: [], dsnStat: [], rwStat: [] }); break;
				case 'kab-kota': this.setState({ kbktStat: e, kecStat: [], kldsStat: [], dsnStat: [], rwStat: [] }); break;
				case 'kecamatan': this.setState({ kecStat: e, kldsStat: [], dsnStat: [], rwStat: [] }); break;
				case 'keldes': this.setState({ kldsStat: e, dsnStat: [], rwStat: [] }); break;
				case 'dusun': this.setState({ dsnStat: e, rwStat: [] }); break;
				case 'rw':
					this.setState({
						rwStat: e,
						dt: {
							...this.state.dt,
							id_rw: e.value,
						},
					});
					Get('rt/rw/' + e.value + '/list', null, (data) => {
						console.log('next id data')
						console.log(data)
						if (data.results.length !== 0) {
							let akhir = data.results.length - 1;
							let getDataNext = data.results[akhir];
							let idRW = getDataNext.id.substring(0, 16);
							let idRT = getDataNext.id.substring(16, 19);
							let nextIDRT = parseInt(idRT) + 1;
							let normRT = nextIDRT.toString();
							let nextID = '';
							if (normRT.length === 1) {
								nextID = (getDataNext.id.slice(0, -3)) + '00' + normRT
								console.log(nextID)
							}
							else if (normRT.length === 2) {
								nextID = (getDataNext.id.slice(0, -3)) + '0' + normRT
							}
							else if (normRT.length === 3) {
								nextID = (getDataNext.id.slice(0, -3)) + normRT
							}

							this.setState({
								dataWilayah: {
									next_id: nextID,
								}
							})
						}
						else {
							let setnextID = e.value + '001';
							let nextID = setnextID;
							this.setState({
								dataWilayah: {
									next_id: nextID,
								}
							})
						}

					});
					break;
				default:
					// no default
					break;
			}
		}
		else {
			switch (sel) {
				case 'provinsi': this.setState({ prvStat: [], kbktStat: [], kecStat: [], kldsStat: [], dsnStat: [], rwStat: [], frmKbkt: [], frmKec: [], frmKlds: [], frmDsn: [], frmRW: [] }); break;
				case 'kab-kota': this.setState({ kbktStat: [], kecStat: [], kldsStat: [], dsnStat: [], rwStat: [], frmKec: [], frmKlds: [], frmDsn: [], frmRW: [] }); break;
				case 'kecamatan': this.setState({ kecStat: [], kldsStat: [], dsnStat: [], rwStat: [], frmKlds: [], frmDsn: [], frmRW: [] }); break;
				case 'keldes': this.setState({ kldsStat: [], dsnStat: [], rwStat: [], frmDsn: [], frmRW: [] }); break;
				case 'dusun': this.setState({ dsnStat: [], rwStat: [], frmRW: [] }); break;
				case 'rw':
					this.setState({
						rwStat: [],
						dt: {
							...this.state.dt,
							id_rw: 0,
						},
					});
					break;
				default:
					// no default
					break;
			}
		}

		this.setState({
			status: {
				...this.state.status,
				select: false,
			},
		})
		this.forceUpdate();
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });

		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
			Get('rt/kel/' + ID_DESA + '/list', null, (data) => {
				console.log('Data RT Didmount')
				console.log(data)
				if (data.results.length !== 0) {
					let akhir = data.results.length - 1;
					let getDataNext = data.results[akhir];
					let nextID = getDataNext.id + 1;
					this.setState({
						dataWilayah: {
							next_id: nextID,
						}
					})
				}
				else {
					let setnextID = ID_DESA + '000' + '001';
					let nextID = parseInt(setnextID);
					this.setState({
						dataWilayah: {
							next_id: parseInt(nextID),
						}
					})
				}
			});

			Get('rw/kel/' + ID_DESA + '/list', null, (data) => {
				console.log('list rw didmount')
				console.log(data)
				let frmRW = [];
				data.results.forEach((dt) => {
					frmRW.push({ value: dt.id, label: dt.nama });
				});
				this.setState({ frmRW });
			})
		}
		else {
			Get('dusun/kel/' + ID_DESA + '/list', null, (data) => {
				console.log('list dusun')
				console.log(data)
				let frmDsn = [];
				data.results.forEach((dt) => {
					frmDsn.push({ value: dt.id, label: dt.nama });
				});
				this.setState({ frmDsn });
			})
		}
	}

	render() {
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			id: 0,
			idbaru: '',
			id_keldes: '',
			id_rw: '',
			id_rt: '',
			nama: '',
		}
		// const { SearchBar } = Search;
		let columns = [];
		if (this.state.isKelurahan) {
			columns = [
				{
					dataField: "no",
					text: "No",
					headerAlign: 'center',
					align: 'center',
					style: {
						width: '80px'
					},
					formatter: (key, obj, index) => {
						let current_pagenum = this.state.pagination.current_page;
						let total_records_per_page = this.state.pagination.per_page;
						let row_index = (index + 1);
						let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
						return serial_num;
					}
				},
				{
					dataField: 'nama_rw',
					text: 'RW',
					headerAlign: 'center',
					align: 'center',
					formatter: (cell, obj) => { return obj.data_rw ? obj.data_rw.nama : '' },
				},
				{
					dataField: 'nama',
					text: 'RT',
					headerAlign: 'center',
					align: 'center',
					sort: true
				},
				{
					dataField: 'aksi',
					text: 'Aksi',
					style: {
						width: '130px'
					},
					headerAlign: 'center',
					align: 'center',
					formatter: this.tombolAksi,
				},
			];
		}
		else {
			columns = [
				{
					dataField: "no",
					text: "No",
					style: {
						width: '80px'
					},
					headerAlign: 'center',
					align: 'center',
					formatter: (key, obj, index) => {
						let current_pagenum = this.state.pagination.current_page;
						let total_records_per_page = this.state.pagination.per_page;
						let row_index = (index + 1);
						let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
						return serial_num;
					}
				},
				// {
				//     dataField: 'id',
				//     text: 'ID',
				//     sort: false
				// },
				{
					dataField: 'nama_rw',
					text: 'Wilayah',
					isDummyField: true,
					headerAlign: 'center',
					align: 'center',
					formatter: (cell, obj) => {
						console.log(obj)
						let rw = ''
						let kelurahan = ''
						// let dusun = ''
						if (JENIS_DESA.toLowerCase() === 'kelurahan') {
							rw = obj.data_rw
							kelurahan = rw.data_keldes
							let kec = kelurahan.data_kecamatan
							let kab = kec.data_kabkota
							let prov = kab.data_provinsi
							return ` ${JENIS_DESA.toLowerCase()} ${kelurahan.nama}, Kec. ${kec.nama}, ${kab.nama}, ${prov.nama}`
						} else {
							let dusun = obj.data_rw ? obj.data_rw.data_dusun : ''
							kelurahan = dusun ? dusun.data_keldes : ''
							let kec = kelurahan ? kelurahan.data_kecamatan : ''
							let kab = kec ? kec.data_kabkota : ''
							let prov = kab ? kab.data_provinsi : ''
							return `${dusun ? 'Dusun ' + dusun.nama : ''}`
							return `${dusun ? dusun.nama : ''}, ${JENIS_DESA.toLowerCase()} ${kelurahan ? kelurahan.nama : ''}, Kec. ${kec ? kec.nama : ''}, ${kab ? kab.nama : ''}, ${prov ? prov.nama : ''}`
						}
					},
				},
				{
					dataField: 'nama_rw',
					text: 'RW',
					isDummyField: true,
					headerAlign: 'center',
					align: 'center',
					formatter: (cell, obj) => { return obj.data_rw ? obj.data_rw.nama : '' },
				},
				{
					dataField: 'nama',
					text: 'RT',
					headerAlign: 'center',
					align: 'center',
					sort: true
				},
				{
					dataField: 'aksi',
					text: 'Aksi',
					style: {
						width: '130px'
					},
					headerAlign: 'center',
					align: 'center',
					formatter: this.tombolAksi,
				},
			];
		}

		// const defaultSorted = [{
		//     dataField: 'nama',
		//     order: 'asc'
		// }];

		// const pageButtonRenderer = ({
		//                                 page,
		//                                 active,
		//                                 disable,
		//                                 title,
		//                                 onPageChange
		//                             }) => {
		//     const handleClick = (e) => {
		//         e.preventDefault();
		//         this.fetch({where:[],page:page});
		//     };
		//     var classname = '';
		//         classname = 'btn btn-success';
		//     return (
		//         <li className="page-item pl-1" key={page}>
		//             <a href="#" onClick={ handleClick } className={ classname }>{ page }</a>
		//         </li>
		//     );
		// };

		// const options = {
		//     alwaysShowAllBtns:true,
		//     pageButtonRenderer,
		//     // custom: true,
		//     paginationTotalRenderer: () => (
		//         <span className="react-bootstrap-table-pagination-total">
		//             Showing { from } to { to } of { total } Results
		//         </span>
		//     ),
		//     paginationSize: size, 
		//     totalSize: total
		//   };

		return (
			<div>
				{/* <Breadcrumb title="Data RT" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <h5>Data RT</h5> */}
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => {
												this.setState({
													judul: "Tambah RT",
													dt: frmDef,
													prvStat: [],
													kbktStat: [],
													frmRW: [],
													kecStat: []
												});
												this.bukaForm();
											}}>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									{this.state.loading ? (
										<div className="row">
											<div className="col-sm-12" align="center">
												<img
													alt="loading"
													src={require("../assets/images/tes-loading.gif")}
													style={{
														borderRadius: "10px",
														width: '100px'
													}} />
											</div>
										</div>
									) : data.length > 0 ? (
										<>
											<BootstrapTable
												keyField="id"
												data={data}
												columns={columns}
											/>
											<div className="pull-right text-white">
												{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
											</div>
										</>
									) : (
										<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false} styles={{ modal: { width: "30%" } }}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="id" type="hidden" placeholder="RT" defaultValue={this.state.dt.id} />
							{/* <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">ID RT</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="idbaru" type="text" placeholder="ID RT" defaultValue={this.state.dt.idbaru}/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Provinsi</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled = {this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'provinsi', 'prov','kab-kota')})}
                                        defaultValue={this.state.prvStat} 
                                        value={this.state.prvStat} 
                                        name="provinsi"
                                        options={this.state.frmPrv}
                                        placeholder="Pilih Provinsi"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kabupaten / Kota</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled={this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'kab-kota', 'kab','kecamatan')})}
                                        defaultValue={this.state.kbktStat} 
                                        value={this.state.kbktStat} 
                                        name="kabkota"
                                        options={this.state.frmKbkt}
                                        placeholder="Pilih Kabupaten/Kota"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kecamatan</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled={this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'kecamatan', 'kec','keldes')})}
                                        defaultValue={this.state.kecStat} 
                                        value={this.state.kecStat} 
                                        name="kecamatan"
                                        options={this.state.frmKec}
                                        placeholder="Pilih Kecamatan"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelurahan / {JENIS_DESA}</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled={this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'keldes', 'kel','dusun')})}
                                        defaultValue={this.state.kldsStat} 
                                        value={this.state.kldsStat} 
                                        name="keldes"
                                        options={this.state.frmKlds}
                                        placeholder={"Pilih Kelurahan/" + JENIS_DESA}
                                        isClearable
                                        />
                                </Col>
                            </FormGroup> */}
							{
								this.state.isKelurahan ?
									<></>
									:
									<>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">{JENIS_DUSUN}</Label>
											<Col sm='9'>
												<Select
													isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={((e) => { this.getChild(e, 'dusun', 'dus', 'rw') })}
													defaultValue={this.state.dsnStat}
													value={this.state.dsnStat}
													name="dusun"
													options={this.state.frmDsn}
													placeholder={"Pilih " + JENIS_DUSUN}
													isClearable
												/>
											</Col>
										</FormGroup>
									</>
							}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">RW</Label>
								<Col sm='9'>
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={((e) => { this.changeSelectValue(e, 'rw') })}
										defaultValue={this.state.rwStat}
										value={this.state.rwStat}
										name="rw"
										options={this.state.frmRW}
										placeholder="Pilih RW"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">RT</Label>
								<Col sm='9'>
									<input className="form-control" id="nama" type="text" placeholder="RT" defaultValue={this.state.dt.nama} />
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>

			</div>
		)
	}
}


export default BasicTable;
