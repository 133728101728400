import React, { Component } from "react";

import { Link} from "react-router-dom";
// import { DataToken } from "../../../function/Format";
import { JENIS_DESA } from "../../../function/Koneksi";

class MenuOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="mb-2 menuu"
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          width: "100%"
        }}
      >
        <div className="dropdown-basic">
          {/* Dashboard */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-home"></i> Dashboard
                </button>
              </div>
            </Link>
          </div>
          {/* Kependudukan */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-primary-custom"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-users-alt-4"></i> Kependudukan
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/penduduk"}>
                  <i className="fa fa-user"></i> Penduduk
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/kartu-keluarga"}>
                  <i className="fa fa-users"></i> Kartu Keluarga
                </Link>
              </div>
            </div>
          </div>
          {/* Data Potensi */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/data-potensi"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px"}}
                >
                  <i className="fa fa-bar-chart-o"></i> Data Potensi
                </button>
              </div>
            </Link>
          </div>
          {/* User */}
          {/* <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px"}}
                >
                  <i className="icofont icofont-users-alt-4"></i> Pengguna
                </button>
              </div>
            </Link>
          </div> */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-primary-custom"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-users-alt-4"></i> User
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
                  <i className="fa fa-user"></i> Pengguna
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/operator"}>
                  <i className="fa fa-user"></i> Operator
                </Link>
              </div>
            </div>
          </div>
          {/* Lembaga Kemasyarakatan */}
          {/* <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/lembaga-masyarakat"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px", width:'170px'}}
                >
                  <i className="icofont icofont-building"></i> Kelembagaan
                </button>
              </div>
            </Link>
          </div> */}
          {/* Wilayah */}
          {/* <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-primary-custom"
                style={{ borderRadius: "10px" }}
              >
                <i className="fa fa-globe"></i> Wilayah
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                {(function() {
                  if (JENIS_DESA.toLowerCase() !== "kelurahan") {
                    return (
                      <Link to={process.env.PUBLIC_URL + "/admin/dusun"}>
                        <i className="fa fa-globe"></i> Dusun
                      </Link>
                    );
                  }
                })()}
                <Link to={process.env.PUBLIC_URL + "/admin/rw"}>
                  <i className="fa fa-globe"></i> RW
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/rt"}>
                  <i className="fa fa-globe"></i> RT
                </Link>
              </div>
            </div>
          </div> */}
          {/* Perangkat */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-primary-custom"
                style={{ borderRadius: "10px"}}
              >
                <i className="fa fa-building"></i> Perangkat
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/jabatan-perangkat"}>
                  <i className="fa fa-building"></i> Jabatan Perangkat
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/perangkat"}>
                  <i className="fa fa-building"></i> Perangkat {JENIS_DESA}
                </Link>
              </div>
            </div>
          </div>
          {/* Pengaturan */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-primary-custom"
                style={{ borderRadius: "10px"}}
              >
                <i className="fa fa-wrench"></i> Pengaturan
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                {/* <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/jenis-surat",
                    state: "kode"
                  }}
                >
                  <i className="fa fa-wrench"></i> Kode Surat
                </Link> */}
                {/* <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/nomor-surat",
                    state: "kode"
                  }}
                >
                  <i className="fa fa-wrench"></i> Nomor Surat
                </Link> */}
                <Link
                  to={process.env.PUBLIC_URL + "/admin/wilayah"}
                >
                  <i className="fa fa-globe"></i> Wilayah
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/profile-" + JENIS_DESA.toLowerCase()}
                >
                  <i className="fa fa-wrench"></i> Profile {JENIS_DESA}
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/absensi"}
                >
                  <i className="fa fa-wrench"></i> Absensi
                </Link>
                {/* Pengaturan Aplikasi */}
                <Link
                  to={process.env.PUBLIC_URL + "/admin/aplikasi"}
                >
                  <i className="fa fa-wrench"></i> Aplikasi
                </Link>
                {/* <Link
                  to={process.env.PUBLIC_URL + "/admin/struktur-organisasi-" + JENIS_DESA.toLowerCase()}
                >
                  <i className="fa fa-wrench"></i> Struktur Organisasi {JENIS_DESA}
                </Link> */}
                {/* <Link to={process.env.PUBLIC_URL + "/admin/tanda-tangan"}>
                  <i className="fa fa-wrench"></i> Tanda Tangan
                </Link> */}
                {/* <Link
                  to={process.env.PUBLIC_URL + "/admin/banner"}
                >
                  <i className="fa fa-wrench"></i> Banner Mobile
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/banner-dashboard"}
                >
                  <i className="fa fa-wrench"></i> Banner Web
                </Link> */}
                {/* <Link
                  to={process.env.PUBLIC_URL + "/admin/konfigurasi-kiosk"}
                >
                  <i className="fa fa-wrench"></i> Layanan KiosK
                </Link> */}
                {/* End Pengaturan Aplikasi */}
                {/* <Link
                  to={process.env.PUBLIC_URL + "/admin/hari-libur"}
                >
                  <i className="fa fa-wrench"></i> Hari Libur
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/jam-kerja"}
                >
                  <i className="fa fa-wrench"></i> Jam Kerja
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/rekap-absensi"}
                >
                  <i className="fa fa-wrench"></i> Rekap Absensi
                </Link> */}
                {/* <div className="liyu">
                  <a href='#'>
                    <i className="icon-calendar"></i> Absensi
                    <span style={{ float: "right" }}>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </a>
                  <div className="liyu1">
                    <Link
                      to={process.env.PUBLIC_URL + "/admin/hari-libur"}
                    >
                      <i className="fa fa-wrench"></i> Hari Libur
                    </Link>
                    <Link
                      to={process.env.PUBLIC_URL + "/admin/jam-kerja"}
                    >
                      <i className="fa fa-wrench"></i> Jam Kerja
                    </Link>
                    <Link
                      to={process.env.PUBLIC_URL + "/admin/rekap-absensi"}
                    >
                      <i className="fa fa-wrench"></i> Rekapitulasi
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuOperator;
