import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DUSUN, JENIS_DESA, Post } from '../../../../function/Koneksi';

const ModalLembaga = ({ data, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {

	console.log('dataaaa')
	console.log(data)
	
	const frmLembaga = {
		uuid: '',
		nama_lembaga: '',
		tahun_berdiri: '',
		status: 1,
	}

	const initFormData = {
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmLembaga)

	
	const changeStatus = (e) => {
		e.preventDefault();
		if(dt.status === 0){
			setDt({
				...dt,
				status: 1,
			})
		}
		else{
			setDt({
				...dt,
				status: 0,
			})
		}
	};

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmLembaga)
		setFormData(data ? {
			...initFormData,
		} : initFormData)
	}, [isOpen])

	const simpan = (e) => {
		e.preventDefault();
		let bodyRaw = {
			...dt,
			uuid : document.getElementById("uuid").value,
			nama_lembaga : document.getElementById("nama_lembaga").value,
			tahun_berdiri : document.getElementById("tahun_berdiri").value,
			status : document.getElementById("status").value,
		}

		let psn = "";
		let resstat = 204;
		let metode = "create";
		if (bodyRaw.uuid === "") {
			psn = "Tambah";
			resstat = 201;
			bodyRaw.uuid = null;
		} else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}
			
		Post("lembaga-kemasyarakatan/" + metode, bodyRaw.uuid, bodyRaw,res => {
			setFormData({
				...formData,
				status: {
					...formData.status,
					btnForm: false,
					form: false,
				}
			})
			setIsOpen(false)
			if (res.status === resstat) {
				const alertData = {
					show: true,
					basicType: "success",
					basicTitle: "Data Lembaga",
					pesanAlert: "Berhasil " + psn + " Data",
					successAlert: true,
				}
				setAlert(alertData)
			} else {
				const alertData = {
					show: true,
					basicType: "danger",
					basicTitle: "Data Lembaga",
					pesanAlert: "Gagal " + psn + " Data"
				}
				setAlert(alertData)
			}

			// Refresh list
			setStatusRefresh(true)
		});
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} 
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Lembaga</h5>
				</div>
				<div className="modal-body">
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Lembaga</Label>
						<Col sm='9'>
							<input className="form-control" id="nama_lembaga" type="text" placeholder="Nama Lembaga" defaultValue={dt.nama_lembaga} required/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Berdiri Lembaga</Label>
						<Col sm='9'>
							<input className="form-control" id="tahun_berdiri" type="date" placeholder="Tahun Berdiri" defaultValue={dt.tahun_berdiri} required/>
							{/* <input className="form-control" pattern="\d*" maxlength="4" id="tahun_berdiri" type="text" placeholder="Tahun Berdiri" defaultValue={dt.tahun_berdiri} required/> */}
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
						<Col sm='9'>
							<select className="form-control" 
								id="status" 
								name='status' 
								defaultValue={dt.status} 
								value={dt.status} 
								onChange={e =>
									changeStatus(e)
								}
							>
								<option value="1">Ya</option>
								<option value="0">Tidak</option>
							</select>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalLembaga;
