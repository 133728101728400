import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { Post, Get, Delete, ID_DESA, JENIS_DESA, GetMarketplace, PostMarketplace } from '../../../function/Koneksi';
import { DataToken } from '../../../function/Format';
import axios from 'axios';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
//slider
import Slider from 'react-slick';
// Toggle Button
import Switch from "react-switch";

const frmDef = {
	uuid: '',
	link_gambar: ''
};

const frmDefBannerWeb = {
	uuid: '',
	link_gambar: ''
};

class BasicTable extends Component {
  _isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
      // DATA BANNER MOBILE
        data: [],
        data_loading: [],
        pagination: [],
        status: {
          form: false,
          btnForm: false,
          btnAksi: false,
          formLoading: false
        },
        awal: '',
        sebelum: '',
        hal: [],
        setelah: '',
        akhir: '',
        dt: frmDef,
        judul: 'Tambah Banner Desa',
        alert: null,
        show: false,
        basicTitle: '',
        basicType: 'default',
        pesanAlert: '',
        konten: false,
        content: '',
        akun: DataToken(sessionStorage.getItem('access_token')),

        // BANNER
        foto_desa: '',
        link_gambar: '',
        link_gambar_show: null,
        get_link_gambar: null,
        data_cek: '',

        // LOADING CAMPAIGN
        foto_desaLoading: '',
        link_gambar_loading: '',
        link_gambar_show_loading: null,
        link_web: null,
        get_link_gambar_loading: null,
        data_cek_loading: '',
      // END DATA BANNER MOBILE

      // DATA BANNER WEB
        dataBannerWeb: [],
        statusBannerWeb: {
          form: false,
          btnForm: false,
          btnAksi: false,
        },
        dtBannerWeb: frmDefBannerWeb,
        judulBannerWeb: 'Tambah Banner Dashboard',

        // BANNER
        fotoDesaBannerWeb: '',
        linkGambarBannerWeb: '',
        linkGambarShowBannerWeb: null,
        getLinkGambarBannerWeb: null,
        dataCekBannerWeb: '',
              
        loadingBannerWeb: true,
      
      // END DATA BANNER WEB

      // DATA KONFIGURASI KIOSK
        statusKonfigurasiKiosk: {
          form: false,
          btnForm: false,
          btnAksi: false
        },
        data_layanan: null,
        list_layanan_desa_web: [],
        list_layanan_kiosk: [],
        list_cetak_layanan_kiosk: [],
        konfig_umum: "none",
        // loadingKonfigurasiKiosk
        loadingKonfigurasiKiosk: true,
        data_cek_kiosk: '',

      // END DATA KONFIGURASI KIOSK


			
			// Tab Vertical
        active_tab_vertical: '1',
        active_tab_icon: '1',
		};
    // KONFIG KIOSK
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCetak = this.handleChangeCetak.bind(this);
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// Toggle Vertical Tab
	toggle_vertical(tab) {
        if (this.state.active_tab_vertical !== tab) {
            this.setState({
                active_tab_vertical: tab,
                detailDataStat: false,
                detailLembaga: [],
            });
        }
    }

  // Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}


  // DATA BANNER MOBILE
  	/// upload file Banner
    processGbr = (d) => {
      if (d.length === 1) {
        let type = d[0].type.split('/');
        if (
          type[1] === 'jpg' ||
          type[1] === 'jpeg' ||
          type[1] === 'png' ||
          type[1] === 'bmp' ||
          type[1] === 'tiff' ||
          type[1] === 'webp'
        ) {
          let bodyFormData = new FormData();
          bodyFormData.append('files', d[0]);

          Post('upload-file/banner', null, bodyFormData, (data) => {
            Resizer.imageFileResizer(
              d[0],
              300,
              300,
              type[1],
              100,
              0,
              (uri) => {
                this.setState({
                  foto_desa: uri,
                  foto_info: '',
                  link_gambar: data.data.results
                });
              },
              'base64'
            );
          });
        } else {
          this.setState({
            foto_desa: '',
            foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
      } else {
        this.setState({
          foto_desa: '',
          foto_info: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
    };

    /// upload file Loading
    processGbrLoading = (d) => {
      if (d.length === 1) {
        let type = d[0].type.split('/');
        if (
          type[1] === 'jpg' ||
          type[1] === 'jpeg' ||
          type[1] === 'png' ||
          type[1] === 'bmp' ||
          type[1] === 'tiff' ||
          type[1] === 'webp'
        ) {
          let bodyFormData = new FormData();
          bodyFormData.append('files', d[0]);

          Post('upload-file/banner', null, bodyFormData, (data) => {
            Resizer.imageFileResizer(
              d[0],
              300,
              300,
              type[1],
              100,
              0,
              (uri) => {
                this.setState({
                  foto_desa_loading: uri,
                  foto_info_loading: '',
                  link_gambar_loading: data.data.results
                });
              },
              'base64'
            );
          });
        } else {
          this.setState({
            foto_desa_loading: '',
            foto_info_loading: <div className="text-danger font-size-10">Type file tidak valid</div>
          });
        }
      } else {
        this.setState({
          foto_desa_loading: '',
          foto_info_loading: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
    };

    // ================== simpan data banner==================
    simpan = (e) => {
      e.preventDefault();

      let link_loading = null;

      if (this.state.data_cek_loading === 'Kosong') {
        link_loading = JSON.stringify([]);
        // link_loading = '[]';
      } else {
        link_loading = JSON.stringify(this.state.link_gambar_show_loading);
      }

      let link_lama = JSON.parse(this.state.get_link_gambar);
      let link_baru = this.state.link_gambar;
      let newGetLinkALL = [];

      if (this.state.link_gambar === '') {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Banner Desa',
          pesanAlert: 'Silahkan lengkapi data!'
        });
      } else {
        this.setState({
          status:{
            ...this.state.status,
            btnForm: true
          },
        })
        this.forceUpdate();
        let linknya = '';
        let psn = '';
        let resstat = '204';
        let metode = '';
        let uuidnya = '';
        if (link_lama === null) {
          // linknya = this.state.link_gambar;
          // newGetLinkALL = [ link_baru ];
          // JSON.stringify(newGetLinkALL);

          let linkStr = JSON.stringify([ link_baru ]);

          linknya = linkStr;
          metode = 'create';
          psn = 'Tambah';
          resstat = 201;
          uuidnya = null;
        } else {
          // linknya = '' + this.state.link_gambar + ',' + this.state.link_gambar_show + '';
          newGetLinkALL = [ ...link_lama, link_baru ];

          let linkStr = JSON.stringify(newGetLinkALL);

          linknya = linkStr;
          metode = 'update';
          psn = 'Ubah';
          resstat = 200;
          uuidnya = this.state.data.uuid;
        }

        let addData = {
          uuid: uuidnya,
          link: linknya,
          link_loading: link_loading,
          link_web: this.state.link_web,
        };

        Post('banner/' + metode, addData.uuid, addData, (res) => {
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            }
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: 'success',
              basicTitle: 'Data Banner Desa',
              pesanAlert: 'Berhasil ' + psn + ' Data'
            });
            this.componentDidMount();
          } else {
            this.setState({
              show: true,
              basicType: 'danger',
              basicTitle: 'Data Banner Desa',
              pesanAlert: 'Gagal ' + psn + ' Data'
            });
            this.componentDidMount();
          }
        });
      }
    };

    // ================== simpan data loading ==================
    simpanLoading = (e) => {
      e.preventDefault();

      let link_banner = null;

      if (this.state.data_cek === 'Kosong') {
        link_banner = JSON.stringify([]);
        // link_loading = '[]';
      } else {
        link_banner = JSON.stringify(this.state.link_gambar_show);
      }

      let link_lama = JSON.parse(this.state.get_link_gambar_loading);
      let link_baru = this.state.link_gambar_loading;
      let newGetLinkALL = [];

      if (this.state.link_gambar_loading === '') {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Banner Desa',
          pesanAlert: 'Silahkan lengkapi data!'
        });
      } else {
        this.setState({
          status:{
            ...this.state.status,
            btnForm: true,
          }
        })
        this.forceUpdate();
        let linknya = '';
        let psn = '';
        let resstat = '204';
        let metode = '';
        let uuidnya = '';
        if (this.state.data.uuid === null) {
          // linknya = this.state.link_gambar;
          let linkStr = JSON.stringify([ link_baru ]);

          linknya = linkStr;
          metode = 'create';
          psn = 'Tambah';
          resstat = 201;
          uuidnya = null;
        } else {
          // linknya = '' + this.state.link_gambar + ',' + this.state.link_gambar_show + '';
          newGetLinkALL = [ ...link_lama, link_baru ];

          let linkStr = JSON.stringify(newGetLinkALL);

          linknya = linkStr;
          metode = 'update';
          psn = 'Ubah';
          resstat = 200;
          uuidnya = this.state.data.uuid;
        }

        let addData = {
          uuid: uuidnya,
          link: link_banner,
          link_loading: linknya,
          link_web: this.state.link_web,
        };

        Post('banner/' + metode, addData.uuid, addData, (res) => {
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            }
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: 'success',
              basicTitle: 'Data Loading Campaign',
              pesanAlert: 'Berhasil ' + psn + ' Data',
              status:{
                ...this.state.status,
                formLoading: false,
              }
            });
            this.componentDidMount();
            this.forceUpdate();
          } else {
            this.setState({
              show: true,
              basicType: 'danger',
              basicTitle: 'Data Loading Campaign',
              pesanAlert: 'Gagal ' + psn + ' Data',
              status:{
                ...this.state.status,
                formLoading: false,
              }
            });
            this.componentDidMount();
            this.forceUpdate();
          }
        });
      }
    };

    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusData(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusData = (id) => {
      this.hideAlert();
      let linknya = '';
      let psn = '';
      let resstat = '204';
      let metode = '';
      let uuidnya = '';

      let link_loading = null;

      if (this.state.data_cek_loading === 'Kosong') {
        link_loading = JSON.stringify([]);
        // link_loading = '[]';
      } else {
        link_loading = JSON.stringify(this.state.link_gambar_show_loading);
      }

      let newArray = [ ...this.state.link_gambar_show, id ];
      if (this.state.link_gambar_show.includes(id)) {
        newArray = newArray.filter((day) => day !== id);
      }

      let linkStr = JSON.stringify(newArray);

      linknya = linkStr;
      metode = 'update';
      psn = 'Hapus';
      resstat = 200;
      uuidnya = this.state.data.uuid;

      let addData = {
        uuid: uuidnya,
        link: linknya,
        link_loading: link_loading,
        link_web: this.state.link_web,
      };

      Post('banner/' + metode, addData.uuid, addData, (res) => {
        this.setState({
          status:{
            ...this.state.status,
            btnForm: false,
            form: false,
          }
        })
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Banner Desa',
            pesanAlert: 'Berhasil ' + psn + ' Gambar'
          });
          this.componentDidMount();
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Banner Desa',
            pesanAlert: 'Gagal ' + psn + ' Gambar'
          });
          this.componentDidMount();
        }
      });

      this.forceUpdate();
    };

    konfirmHapusLoading = (id) => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusDataLoading(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusDataLoading = (id) => {
      this.hideAlert();
      let linknya = '';
      let psn = '';
      let resstat = '204';
      let metode = '';
      let uuidnya = '';

      let link_banner = null;

      if (this.state.data_cek === 'Kosong') {
        link_banner = JSON.stringify([]);
      } else {
        link_banner = JSON.stringify(this.state.link_gambar_show);
      }

      let newArray = [ ...this.state.link_gambar_show_loading, id ];
      if (this.state.link_gambar_show_loading.includes(id)) {
        newArray = newArray.filter((day) => day !== id);
      }

      let linkStr = JSON.stringify(newArray);

      linknya = linkStr;
      metode = 'update';
      psn = 'Hapus';
      resstat = 200;
      uuidnya = this.state.data.uuid;

      let addData = {
        uuid: uuidnya,
        link: link_banner,
        link_loading: linknya,
        link_web: this.state.link_web,
      };

      Post('banner/' + metode, addData.uuid, addData, (res) => {
        this.setState({
          status:{
            ...this.state.status,
            btnForm: false,
            form: false,
          }
        })
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Loading Campaign',
            pesanAlert: 'Berhasil ' + psn + ' Gambar'
          });
          this.componentDidMount();
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Loading Campaign',
            pesanAlert: 'Gagal ' + psn + ' Gambar'
          });
          this.componentDidMount();
        }
      });
      this.forceUpdate();
    };

    bukaForm = () => {
      this.setState({
        status:{
          ...this.state.status,
          form: true,
        }
          
      })
      this.forceUpdate();
    };

    tutupForm = () => {
      this.setState({
        status:{
          ...this.state.status,
          formLoading: false,
          form: false,
        }
      })
      this.forceUpdate();
    };

  // END DATA BANNER MOBILE

  // DATA BANNER WEB
    /// upload file Banner
    processGbrBannerWeb = (d) => {
      if (d.length === 1) {
        let type = d[0].type.split('/');
        if (
          type[1] === 'jpg' ||
          type[1] === 'jpeg' ||
          type[1] === 'png' ||
          type[1] === 'bmp' ||
          type[1] === 'tiff' ||
          type[1] === 'webp'
        ) {
          let bodyFormData = new FormData();
          bodyFormData.append('files', d[0]);

          Post('upload-file/banner', null, bodyFormData, (data) => {
            Resizer.imageFileResizer(
              d[0],
              300,
              300,
              type[1],
              100,
              0,
              (uri) => {
                this.setState({
                  fotoDesaBannerWeb: uri,
                  foto_info_web: '',
                  linkGambarBannerWeb: data.data.results
                });
              },
              'base64'
            );
          });
        } else {
          this.setState({
            fotoDesaBannerWeb: '',
            foto_info_web: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
      } else {
        this.setState({
          fotoDesaBannerWeb: '',
          foto_info_web: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
    };
    // ================== simpanBannerWeb data banner==================
    simpanBannerWeb = (e) => {
      e.preventDefault();
  
      // let link_lama = this.state.getLinkGambarBannerWeb;
  
      let link_baru = {
          banner_dashboard: this.state.linkGambarBannerWeb
      }
  
      if (this.state.linkGambarBannerWeb === '') {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Data Banner Dashboard',
          pesanAlert: 'Silahkan lengkapi data!'
        });
      } else {
        this.setState({
          statusBannerWeb:{
            ...this.state.statusBannerWeb,
            btnForm: true,
          }
        })
        this.forceUpdate();
        // let linknya = '';
        let psn = '';
        let resstat = '204';
        let metode = '';
        // let uuidnya = '';
        if (this.state.dataBannerWeb.uuid === null) {
                  // let linkStr = link_baru
          // linknya = linkStr;
          this.state.dataBannerWeb.link_web = JSON.stringify(link_baru)
  
          metode = 'create';
          psn = 'Tambah';
          resstat = 201;
          // uuidnya = document.getElementById("uuid").value;
        } else {
          // linknya = '' + this.state.linkGambarBannerWeb + ',' + this.state.linkGambarShowBannerWeb + '';
          
                  // let linkStr = link_baru
          // linknya = linkStr;
          this.state.dataBannerWeb.link_web = JSON.stringify(link_baru)
  
          metode = 'update';
          psn = 'Ubah';
          resstat = 200;
          // uuidnya = document.getElementById("uuid").value;
        }
              // let addData = {
              //     uuid: uuidnya,
              //     link_web: linknya,
              // };
           
              
        Post('banner/' + metode, this.state.dataBannerWeb.uuid, this.state.dataBannerWeb, (res) => {
          this.setState({
            statusBannerWeb:{
              ...this.state.statusBannerWeb,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: 'success',
              basicTitle: 'Data Banner Dashboard',
              pesanAlert: 'Berhasil ' + psn + ' Data'
            });
            this.componentDidMount();
          } else {
            this.setState({
              show: true,
              basicType: 'danger',
              basicTitle: 'Data Banner Dashboard',
              pesanAlert: 'Gagal ' + psn + ' Data'
            });
            this.componentDidMount();
          }
        });
      }
    };
    // ============================= Hapus Data ===============================
    konfirmHapusBannerWeb = (id) => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusDataBannerWeb(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusDataBannerWeb = (id) => {
      this.hideAlert();

      let metode = 'update';
      let psn = 'Hapus';
      let resstat = 200;
      let newArray = [];

      let data_all = JSON.parse(this.state.dataBannerWeb.link_web)
      data_all.banner_dashboard = JSON.stringify(newArray);
      
      this.state.dataBannerWeb.link_web = JSON.stringify(data_all)

      Post('banner/' + metode, this.state.dataBannerWeb.uuid, this.state.dataBannerWeb, (res) => {
        this.setState({
          statusBannerWeb:{
            ...this.state.statusBannerWeb,
            btnForm: false,
            form: false,
          }
        })
        this.forceUpdate();
        if (res.status === resstat) {
          axios.get(`${this.state.linkGambarShowBannerWeb}/delete`).then(resp => {
          });
          this.setState({
            show: true,
            basicType: 'success',
            basicTitle: 'Data Banner Dashboard',
            pesanAlert: 'Berhasil ' + psn + ' Gambar'
          });
          this.componentDidMount();
        } else {
          this.setState({
            show: true,
            basicType: 'danger',
            basicTitle: 'Data Banner Dashboard',
            pesanAlert: 'Gagal ' + psn + ' Gambar'
          });
          this.componentDidMount();
        }
      });

      this.forceUpdate();
    };
    
    bukaFormBannerWeb = () => {
      this.setState({
        statusBannerWeb:{
          ...this.state.statusBannerWeb,
          form: true,
        }
      })
      this.forceUpdate();
    };

    tutupFormBannerWeb = () => {
      this.setState({
        statusBannerWeb:{
          ...this.state.statusBannerWeb,
          form: false,
        }
      })
      this.forceUpdate();
    };
  // END DATA BANNER WEB

  
  // DATA KONFIGURASI KIOSK
  
  // ================== Button Toggle ==================
  
  handleChange(checked, jenis_surat) {
		//console.log("CHANGE JENIS SURAT")
		//console.log(jenis_surat)
		switch (jenis_surat) {
			// LAYANAN DESA KiosK
			case "tidak_mampu":
				if(this.state.list_layanan_kiosk.tidak_mampu === true){
					this.state.list_layanan_kiosk.tidak_mampu = checked
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
				else{
					this.state.list_layanan_kiosk.tidak_mampu = checked
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
			  break;
			case "domisili":
				if(this.state.list_layanan_kiosk.domisili === true){
					this.state.list_layanan_kiosk.domisili = checked
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
				else{
					this.state.list_layanan_kiosk.domisili = checked
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
			  break;
			case "kelahiran":
				if(this.state.list_layanan_kiosk.kelahiran === true){
					this.state.list_layanan_kiosk.kelahiran = checked
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
				else{
					this.state.list_layanan_kiosk.kelahiran = checked
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
			  break;
			case "belum_memiliki_rumah":
				if(this.state.list_layanan_kiosk.belum_memiliki_rumah === true){
					this.state.list_layanan_kiosk.belum_memiliki_rumah = checked
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
				else{
					this.state.list_layanan_kiosk.belum_memiliki_rumah = checked
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
			  break;
			case "jalan":
				if(this.state.list_layanan_kiosk.jalan === true){
					this.state.list_layanan_kiosk.jalan = checked
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
				else{
					this.state.list_layanan_kiosk.jalan = checked
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
			  break;
			case "kematian":
				if(this.state.list_layanan_kiosk.kematian === true){
					this.state.list_layanan_kiosk.kematian = checked
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
				else{
					this.state.list_layanan_kiosk.kematian = checked
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
			  break;
			case "kepemilikan_rumah":
				if(this.state.list_layanan_kiosk.kepemilikan_rumah === true){
					this.state.list_layanan_kiosk.kepemilikan_rumah = checked
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
				else{
					this.state.list_layanan_kiosk.kepemilikan_rumah = checked
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
			break;
			case "penghasilan":
				if(this.state.list_layanan_kiosk.penghasilan === true){
					this.state.list_layanan_kiosk.penghasilan = checked
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
				else{
					this.state.list_layanan_kiosk.penghasilan = checked
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
			break;
			case "skck":
				if(this.state.list_layanan_kiosk.skck === true){
					this.state.list_layanan_kiosk.skck = checked
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
				else{
					this.state.list_layanan_kiosk.skck = checked
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
			break;
			case "kehilangan":
				if(this.state.list_layanan_kiosk.kehilangan === true){
					this.state.list_layanan_kiosk.kehilangan = checked
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
				else{
					this.state.list_layanan_kiosk.kehilangan = checked
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
			break;
			case "usaha":
				if(this.state.list_layanan_kiosk.usaha === true){
					this.state.list_layanan_kiosk.usaha = checked
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
				else{
					this.state.list_layanan_kiosk.usaha = checked
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
			break;
			case "skgr":
				if(this.state.list_layanan_kiosk.skgr === true){
					this.state.list_layanan_kiosk.skgr = checked
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
				else{
					this.state.list_layanan_kiosk.skgr = checked
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
			break;
			case "belum_menikah":
				if(this.state.list_layanan_kiosk.belum_menikah === true){
					this.state.list_layanan_kiosk.belum_menikah = checked
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.belum_menikah = checked
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
			break;
			case "tidak_menikah":
				if(this.state.list_layanan_kiosk.tidak_menikah === true){
					this.state.list_layanan_kiosk.tidak_menikah = checked
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.tidak_menikah = checked
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
			break;
			case "pindah_menikah":
				if(this.state.list_layanan_kiosk.pindah_menikah === true){
					this.state.list_layanan_kiosk.pindah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.pindah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
			break;
			case "pernah_menikah":
				if(this.state.list_layanan_kiosk.pernah_menikah === true){
					this.state.list_layanan_kiosk.pernah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.pernah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
			break;
			case "keramaian":
				if(this.state.list_layanan_kiosk.keramaian === true){
					this.state.list_layanan_kiosk.keramaian = checked
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
				else{
					this.state.list_layanan_kiosk.keramaian = checked
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
			break;
			case "ahli_waris":
				if(this.state.list_layanan_kiosk.ahli_waris === true){
					this.state.list_layanan_kiosk.ahli_waris = checked
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
				else{
					this.state.list_layanan_kiosk.ahli_waris = checked
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
			break;
			default:
				// no default
				break;

		  }

		this.forceUpdate();
		this.setState({ checked });
		// this.simpanLayanan(checked);
	  }
  
  handleChangeCetak(checked, jenis_surat) {
		//console.log("CHANGE JENIS SURAT")
		//console.log(jenis_surat)
		switch (jenis_surat) {
			// LAYANAN DESA KiosK
			case "tidak_mampu":
				if(this.state.list_cetak_layanan_kiosk.tidak_mampu === true){
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
			  break;
			case "domisili":
				if(this.state.list_cetak_layanan_kiosk.domisili === true){
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
			  break;
			case "kelahiran":
				if(this.state.list_cetak_layanan_kiosk.kelahiran === true){
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
			  break;
			case "belum_memiliki_rumah":
				if(this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah === true){
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
			  break;
			case "jalan":
				if(this.state.list_cetak_layanan_kiosk.jalan === true){
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
			  break;
			case "kematian":
				if(this.state.list_cetak_layanan_kiosk.kematian === true){
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
			  break;
			case "kepemilikan_rumah":
				if(this.state.list_cetak_layanan_kiosk.kepemilikan_rumah === true){
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
			break;
			case "penghasilan":
				if(this.state.list_cetak_layanan_kiosk.penghasilan === true){
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
			break;
			case "skck":
				if(this.state.list_cetak_layanan_kiosk.skck === true){
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
			break;
			case "kehilangan":
				if(this.state.list_cetak_layanan_kiosk.kehilangan === true){
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
			break;
			case "usaha":
				if(this.state.list_cetak_layanan_kiosk.usaha === true){
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
			break;
			case "skgr":
				if(this.state.list_cetak_layanan_kiosk.skgr === true){
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
			break;
			case "belum_menikah":
				if(this.state.list_cetak_layanan_kiosk.belum_menikah === true){
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
			break;
			case "tidak_menikah":
				if(this.state.list_cetak_layanan_kiosk.tidak_menikah === true){
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
			break;
			case "pindah_menikah":
				if(this.state.list_cetak_layanan_kiosk.pindah_menikah === true){
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
			break;
			case "pernah_menikah":
				if(this.state.list_cetak_layanan_kiosk.pernah_menikah === true){
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
			break;
			case "keramaian":
				if(this.state.list_cetak_layanan_kiosk.keramaian === true){
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
			break;
			case "ahli_waris":
				if(this.state.list_cetak_layanan_kiosk.ahli_waris === true){
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
			break;
			default:
				// no default
				break;

		  }

		this.forceUpdate();
		this.setState({ checked });
		// this.simpanLayanan(checked);
	  }

  // ======================= SIMPAN Layanan =====================
	simpanLayanan = e => {
		// e.preventDefault();
		this.state.statusKonfigurasiKiosk.btnForm = true;
		this.forceUpdate();
		let cekLayanan = this.state.data_layanan
		let layanan_web = this.state.list_layanan_desa_web
		let layanan_kiosk = this.state.list_layanan_kiosk
		let cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk
    
    layanan_web.layanan_kiosk = layanan_kiosk
    layanan_web.cetak_layanan_kiosk = cetak_layanan_kiosk
    cekLayanan.list_layanan_desa_web = JSON.stringify(layanan_web)

    let psn = "Ubah";
    let resstat = 200;
    let metode = "update";

		PostMarketplace('list-layanan-desa/' + metode, cekLayanan.uuid, cekLayanan, (res) => {
			//console.log("Berhasil Simpan Bosku")
			//console.log(res)
			this.forceUpdate();
			if (res.status === resstat) {
				if(this.state.konfig_umum === "inherit"){
					this.setState({ show: true, basicType: 'success', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.state.konfig_umum = "none"
				}
				if(this.state.konfig_web === "inherit"){
					this.setState({ show: true, basicType: 'success', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.state.konfig_web = "none"
				}
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	// ============================== Ubah Konfig ===============================
	ubahKonfig = (e) => {
		e.preventDefault();
		this.setState({ konfig_umum: 'inherit'});
	};

  // END DATA KONFIGURASI KIOSK

	// ========================================================================

	componentDidMount() {
    // DATA BANNER MOBILE
      if (typeof this.props.location.tabProps !== 'undefined') {
        this.setState({
          active_tab_icon: this.props.location.tabProps,
        })
      }
      if (typeof this.props.location.state !== 'undefined') {
        this.setState({
          active_tab_icon: this.props.location.state.tab,
        })
      }

      Get('banner', null, (dtkat) => {
        if (dtkat.results === null || dtkat.results === 'undefined') {
          this.setState({
            // banner mobile
            data_cek: 'Kosong',
            data_cek_loading: 'Kosong',
            // banner web
            dataCekBannerWeb: 'Kosong',
            loadingBannerWeb: false,
            dataBannerWeb:{
              uuid: null
            }
          });
        } else {
          let statData = '';
          let statDataLoading = '';
          let ambil = JSON.parse(dtkat.results.link);
          let ambilLoading = JSON.parse(dtkat.results.link_loading);
          // let dataSplit = ambil.split(',');

          if (dtkat.results.link === null || dtkat.results.link === '[]') {
            statData = 'Kosong';
          } else {
            statData = 'Tidak Kosong';
          }
          if (dtkat.results.link_loading === null || dtkat.results.link_loading === '[]') {
            statDataLoading = 'Kosong';
          } else {
            statDataLoading = 'Tidak Kosong';
          }

          let statDataBannerWeb = '';
          let banner_dashboard = null

          if(dtkat.results.link_web){
            let ambil = JSON.parse(dtkat.results.link_web);
      
            if (dtkat.results.link_web === null || dtkat.results.link_web === '[]') {
                statDataBannerWeb = 'Kosong';
            } else {
              if(ambil.banner_dashboard === null || ambil.banner_dashboard === '[]'){
                statDataBannerWeb = 'Kosong';
              }
              else{
                statDataBannerWeb = 'Tidak Kosong';
              }
            }
      
            if(dtkat.results.link_web){
              banner_dashboard = ambil.banner_dashboard
            }
          }
          else{
            statDataBannerWeb = 'Kosong';
          }

          this.setState({
            // banner mobile
            data: dtkat.results,
            data_loading: dtkat.results,
            link_web: dtkat.results.link_web,
            link_gambar_show: ambil,
            link_gambar_show_loading: ambilLoading,
            get_link_gambar: dtkat.results.link,
            get_link_gambar_loading: dtkat.results.link_loading,
            data_cek: statData,
            data_cek_loading: statDataLoading,
            // banner web
            dataBannerWeb: dtkat.results,
            linkGambarShowBannerWeb: banner_dashboard,
            getLinkGambarBannerWeb: banner_dashboard,
            dataCekBannerWeb: statDataBannerWeb,
            loadingBannerWeb: false,
          });
        }
      });
    // END DATA BANNER MOBILE

    // DATA KONFIGURASI KIOSK
      GetMarketplace('list-layanan-desa/' + ID_DESA, null, (data) => {
        //console.log("Data Layanan Desa abc")
        console.log(data)
        let data_layanan = []
        let layananDesaWebnya = []
        let layananKiosk = []
        let layananCetakKiosk = {}
        let data_cek_kiosk = ""

        if(data.result){
          data_layanan = data.result
          layananDesaWebnya = JSON.parse(data.result.list_layanan_desa_web)
          console.log(layananDesaWebnya);
          if(layananDesaWebnya.layanan_kiosk){
            layananKiosk = layananDesaWebnya.layanan_kiosk
            data_cek_kiosk = "Tidak Kosong"

            // if(layananDesaWebnya.cetak_layanan_kiosk){
              // layananCetakKiosk = layananDesaWebnya.cetak_layanan_kiosk
            if(typeof layananDesaWebnya.cetak_layanan_kiosk === 'object' && layananDesaWebnya.cetak_layanan_kiosk !== null){
              Object.keys(layananDesaWebnya.cetak_layanan_kiosk).map((val, index) => {
                layananCetakKiosk[val] = layananDesaWebnya.cetak_layanan_kiosk[val]
              })
            }
            else{
              layananCetakKiosk = {
                tidak_mampu: layananDesaWebnya.layanan_kiosk.tidak_mampu === true? true: false,
                domisili: layananDesaWebnya.layanan_kiosk.domisili === true? true: false,
                kelahiran: layananDesaWebnya.layanan_kiosk.kelahiran === true? true: false,
                belum_memiliki_rumah: layananDesaWebnya.layanan_kiosk.belum_memiliki_rumah === true? true: false,
                jalan: layananDesaWebnya.layanan_kiosk.jalan === true? true: false,
                kematian: layananDesaWebnya.layanan_kiosk.kematian === true? true: false,
                kepemilikan_rumah: layananDesaWebnya.layanan_kiosk.kepemilikan_rumah === true? true: false,
                penghasilan: layananDesaWebnya.layanan_kiosk.penghasilan === true? true: false,
                skck: layananDesaWebnya.layanan_kiosk.skck === true? true: false,
                kehilangan: layananDesaWebnya.layanan_kiosk.kehilangan === true? true: false,
                usaha: layananDesaWebnya.layanan_kiosk.usaha === true? true: false,
                skgr: layananDesaWebnya.layanan_kiosk.skgr === true? true: false,
                belum_menikah: layananDesaWebnya.layanan_kiosk.belum_menikah === true? true: false,
                tidak_menikah: layananDesaWebnya.layanan_kiosk.tidak_menikah === true? true: false,
                pindah_menikah: layananDesaWebnya.layanan_kiosk.pindah_menikah === true? true: false,
                pernah_menikah: layananDesaWebnya.layanan_kiosk.pernah_menikah === true? true: false,
                keramaian: layananDesaWebnya.layanan_kiosk.keramaian === true? true: false,
                ahli_waris: layananDesaWebnya.layanan_kiosk.ahli_waris === true? true: false,
              }
            }
          }
          else{
            data_cek_kiosk = "Kosong"
            layananKiosk = {
              tidak_mampu: true,
              domisili: true,
              kelahiran: true,
              belum_memiliki_rumah: true,
              jalan: true,
              kematian: true,
              kepemilikan_rumah: true,
              penghasilan: true,
              skck: true,
              kehilangan: true,
              usaha: true,
              skgr: true,
              belum_menikah: true,
              tidak_menikah: true,
              pindah_menikah: true,
              pernah_menikah: true,
              keramaian: true,
              ahli_waris: true,
            }
            layananCetakKiosk = {
              tidak_mampu: true,
              domisili: true,
              kelahiran: true,
              belum_memiliki_rumah: true,
              jalan: true,
              kematian: true,
              kepemilikan_rumah: true,
              penghasilan: true,
              skck: true,
              kehilangan: true,
              usaha: true,
              skgr: true,
              belum_menikah: true,
              tidak_menikah: true,
              pindah_menikah: true,
              pernah_menikah: true,
              keramaian: true,
              ahli_waris: true,
            }
          }
        }

        this.setState({
          data_layanan: data_layanan,
          list_layanan_desa_web: layananDesaWebnya,
          list_layanan_kiosk: layananKiosk,
          list_cetak_layanan_kiosk: layananCetakKiosk,
          loadingKonfigurasiKiosk: false,
          data_cek_kiosk: data_cek_kiosk,
        });
        console.log(layananKiosk);

        this.forceUpdate();
      });
    // END DATA KONFIGURASI KIOSK

	}

  
  componentWillUnmount() {
    this._isMounted = false;
  }


	render() {
    // BANNER MOBILE
    let gambarList = [];
		let listGambarnya = this.state.link_gambar_show;

		if (this.state.link_gambar_show) {
			gambarList = listGambarnya.map((dt) => {
				return (
					<div>
						<div className="p-2" align="center">
							<img className="p-2" style={{ width: '500px' }} src={dt} alt="" />
						</div>
						<div align="center">
              {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
							<Button size="md" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(dt)}>
								<i className="fa fa-trash" />
								&nbsp; Hapus
							</Button>
              ) : ''}
						</div>
					</div>
				);
			});
		}

		let gambarListLoading = [];
		let listGambarnyaLoading = this.state.link_gambar_show_loading;

		if (this.state.link_gambar_show_loading) {
			gambarListLoading = listGambarnyaLoading.map((dt) => {
				return (
					<div>
						<div className="p-2" align="center">
							<img className="p-2" style={{ width: '200px' }} src={dt} alt="" />
						</div>
						<div align="center">
              {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
							<Button
								size="md"
								color="danger"
								className="btn-icon"
								onClick={() => this.konfirmHapusLoading(dt)}
							>
								<i className="fa fa-trash" />
								&nbsp; Hapus
							</Button>
              ) : ''}
						</div>
					</div>
				);
			});
		}

    const frmDef = {
			uuid: '',
			link_gambar: ''
		};

    // BANNER WEB
    let gambarListBannerWeb = [];

    if (this.state.linkGambarShowBannerWeb) {
			gambarListBannerWeb = 
					<div>
						<div className="p-2" align="center">
							{/* <img className="p-2" style={sliderStyle} src={dt} alt="" /> */}
							<img className="p-2" style={{ width: '500px' }} src={this.state.linkGambarShowBannerWeb} alt="" />
						</div>
						<div align="center">
              {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
							<Button size="md" color="danger" className="btn-icon" onClick={() => this.konfirmHapusBannerWeb(this.state.linkGambarShowBannerWeb)}>
								<i className="fa fa-trash" />
								&nbsp; Hapus
							</Button>
              ) : ''}
						</div>
					</div>
		}

    const frmDefBannerWeb = {
			uuid: '',
			link_gambar: ''
		};


    
		var Merge_settings = {
			dots: true,
			infinite: true,
			speed: 100,
			slidesToShow: 1,
			slidesToScroll: 1,
			margin: 0,
			padding: 0
			// centerMode: true,
			// variableWidth: true,
			// adaptiveHeight:true,
		};

		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}
				
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <Nav  className={'nav flex-column nav-pills'}>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
                                              onClick={() => { this.toggle_vertical('1'); }}>
                                            Banner Mobile
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '2' })}
                                              onClick={() => { this.toggle_vertical('2'); }}>
                                            Banner Web
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '3' })}
                                              onClick={() => { this.toggle_vertical('3'); }}>
                                            Layanan KiosK
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                    <div className="col-lg-9">
                                      <TabContent activeTab={this.state.active_tab_vertical}>
                                        <TabPane tabId="1">
                                          <Row>
                                            <Col sm="12">
                                              <div className="card-body datatable-react">
                                                <Nav tabs className="tabs-color">
                                                  <NavItem style={{ cursor: 'pointer' }}>
                                                    <NavLink
                                                      className={classnames({
                                                        active: this.state.active_tab_icon === '1'
                                                      })}
                                                      onClick={() => {
                                                        this.toggle_icon('1');
                                                      }}
                                                    >
                                                      <i className="icofont icofont-ui-home" />
                                                      Banner Beranda
                                                    </NavLink>
                                                  </NavItem>
                                                  <NavItem style={{ cursor: 'pointer' }}>
                                                    <NavLink
                                                      className={classnames({
                                                        active: this.state.active_tab_icon === '2'
                                                      })}
                                                      onClick={() => {
                                                        this.toggle_icon('2');
                                                      }}
                                                    >
                                                      <i className="icofont icofont-man-in-glasses" />
                                                      Loading Campaign
                                                    </NavLink>
                                                  </NavItem>
                                                </Nav>
                                                <TabContent activeTab={this.state.active_tab_icon}>
                                                  <TabPane tabId="1">
                                                    <div style={{ marginTop: 15 }}>
                                                      <Row>
                                                        <Col sm="12">
                                                          <div className="row">
                                                            <div className="col-sm-6">
                                                              <b><font color='red'>CATATAN:</font></b> <br/>
                                                              untuk menghasilkan banner dengan kualitas terbaik, mohon perhatikan anjuran gambar yang akan diupload yaitu menggunakan gambar dengan <b>format .JPG</b>, dan <b>resolusi 500x167 px</b>
                                                            </div>
                                                            <div className="col-sm-6 text-right">
                                                              <Button
                                                                size="sm"
                                                                color="success"
                                                                className="btn-square"
                                                                onClick={() => {
                                                                  this.setState({
                                                                    judul: 'Tambah Banner Desa',
                                                                    dt: frmDef,
                                                                    link_gambar: ''
                                                                  });
                                                                  this.bukaForm();
                                                                }}
                                                              >
                                                                Tambah Data
                                                              </Button>
                                                            </div>
                                                          </div>

                                                          <div className="row">
                                                            {/* <Col md={1} /> */}
                                                            <Col md={12}>
                                                              {this.state.data_cek === 'Kosong' ? (
                                                                <div>
                                                                  <h3 align="center">Data Banner Belum Ada</h3>
                                                                  <br />
                                                                  <br />
                                                                </div>
                                                              ) : (
                                                                <Slider {...Merge_settings}>{gambarList}</Slider>
                                                              )}
                                                            </Col>
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </TabPane>
                                                  <TabPane tabId="2">
                                                    <div style={{ marginTop: 15 }}>
                                                      <Row>
                                                        <Col sm="12">
                                                          <div className="row">
                                                            <div className="col-sm-6">
                                                              <b><font color='red'>CATATAN:</font></b> <br/>
                                                              untuk menghasilkan banner dengan kualitas terbaik, mohon perhatikan anjuran gambar yang akan diupload yaitu menggunakan gambar dengan <b>format .PNG</b>, dan <b>resolusi 250x250 px</b> 
                                                            </div>
                                                            <div className="col-sm-6 text-right">
                                                              <Button
                                                                size="sm"
                                                                color="success"
                                                                className="btn-square"
                                                                onClick={() => {
                                                                  this.setState({
                                                                    judul: 'Tambah Loading Campaign',
                                                                    dt: frmDef,
                                                                    link_gambar_loading: '',
                                                                    status:{
                                                                      ...this.state.status,
                                                                      formLoading: true,
                                                                    }
                                                                  });
                                                                  this.forceUpdate();
                                                                }}
                                                              >
                                                                Tambah Data
                                                              </Button>
                                                            </div>
                                                          </div>

                                                          <div className="row">
                                                            <Col md={12}>
                                                              {this.state.data_cek_loading === 'Kosong' ? (
                                                                <div>
                                                                  <h3 align="center">
                                                                    Data Loading Campaign Belum Ada
                                                                  </h3>
                                                                  <br />
                                                                  <br />
                                                                </div>
                                                              ) : (
                                                                <Slider {...Merge_settings}>
                                                                  {gambarListLoading}
                                                                </Slider>
                                                              )}
                                                            </Col>
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </TabPane>
                                                </TabContent>
                                              </div>
                                              <br />
                                              <Modal
                                                open={this.state.status.form}
                                                onClose={this.tutupForm}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                                styles={{ modal: { width: '80%' } }}
                                              >
                                                <Form className="theme-form" onSubmit={this.simpan}>
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.judul}</h5>
                                                  </div>
                                                  <div className="modal-body">
                                                    <input
                                                      className="form-control"
                                                      id="uuid"
                                                      type="hidden"
                                                      placeholder="UUID"
                                                      defaultValue={this.state.dt.uuid}
                                                      value={this.state.dt.uuid}
                                                    />

                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                        Foto Banner
                                                      </Label>
                                                      <Col sm="9">
                                                        <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)} maxFiles={12}>
                                                          {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                              <div
                                                                {...getRootProps()}
                                                                style={{
                                                                  minHeight: '100px',
                                                                  paddingTop: '40px',
                                                                  padding: '5px 5px 5px 5px',
                                                                  border: '1px solid #d2d2d2'
                                                                }}
                                                              >
                                                                <input {...getInputProps()} />
                                                                <div
                                                                  style={{
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                  }}
                                                                >
                                                                  <p className="text-center">
                                                                    {this.state.link_gambar ? (
                                                                      <img
                                                                        src={this.state.link_gambar}
                                                                        alt=""
                                                                        style={{
                                                                          // minHeight: '50px',
                                                                          // minWidth: '50px'
                                                                          width: '470px'
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      <Label
                                                                        className="col-form-label text-center"
                                                                        htmlFor="inputEmail3"
                                                                      >
                                                                        Drag 'n' drop some files here, or click to select
                                                                        files
                                                                      </Label>
                                                                    )}
                                                                    {this.state.foto_info ? this.state.foto_info : ''}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          )}
                                                        </Dropzone>
                                                      </Col>
                                                    </FormGroup>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      disabled={this.state.status.btnForm}
                                                      onClick={this.tutupForm}
                                                    >
                                                      Tutup
                                                    </button>
                                                    <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>

                                              <Modal
                                                open={this.state.status.formLoading}
                                                onClose={this.tutupForm}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                                styles={{ modal: { width: '80%' } }}
                                              >
                                                <Form className="theme-form" onSubmit={this.simpanLoading}>
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.judul}</h5>
                                                  </div>
                                                  <div className="modal-body">
                                                    <input
                                                      className="form-control"
                                                      id="uuid"
                                                      type="hidden"
                                                      placeholder="UUID"
                                                      defaultValue={this.state.dt.uuid}
                                                      value={this.state.dt.uuid}
                                                    />

                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                        Foto Banner
                                                      </Label>
                                                      <Col sm="9">
                                                        <Dropzone
                                                          onDrop={(acceptedFiles) => this.processGbrLoading(acceptedFiles)}
                                                          maxFiles={12}
                                                        >
                                                          {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                              <div
                                                                {...getRootProps()}
                                                                style={{
                                                                  minHeight: '100px',
                                                                  paddingTop: '40px',
                                                                  padding: '5px 5px 5px 5px',
                                                                  border: '1px solid #d2d2d2'
                                                                }}
                                                              >
                                                                <input {...getInputProps()} />
                                                                <div
                                                                  style={{
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                  }}
                                                                >
                                                                  <p className="text-center">
                                                                    {this.state.link_gambar_loading ? (
                                                                      <img
                                                                        src={this.state.link_gambar_loading}
                                                                        alt=""
                                                                        style={{
                                                                          // minHeight: '50px',
                                                                          // minWidth: '50px'
                                                                          width: '200px'
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      <Label
                                                                        className="col-form-label text-center"
                                                                        htmlFor="inputEmail3"
                                                                      >
                                                                        Drag 'n' drop some files here, or click to select
                                                                        files
                                                                      </Label>
                                                                    )}
                                                                    {this.state.foto_info_loading ? (
                                                                      this.state.foto_info_loading
                                                                    ) : (
                                                                      ''
                                                                    )}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          )}
                                                        </Dropzone>
                                                      </Col>
                                                    </FormGroup>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      disabled={this.state.status.btnForm}
                                                      onClick={this.tutupForm}
                                                    >
                                                      Tutup
                                                    </button>
                                                    <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                        <Row>
                                            <Col sm="12">
								                              <div className="card-body datatable-react">
                                                {this.state.loadingBannerWeb ? (
                                                  <div className="row">
                                                    <div className="col-sm-12" align="center">
                                                      <img 
                                                        src={require("../../../assets/images/tes-loading.gif")} alt="" style={{
                                                        // width: "100%",
                                                        borderRadius: "10px",
                                                        width: '100px'
                                                      }}/> 
                                                    </div>
                                                  </div>
                                                )
                                                : 
                                                <>
                                                  <div className="row">
                                                    <div className="col-sm-6">
                                                      <b><font color='red'>CATATAN:</font></b> <br/>
                                                      untuk menghasilkan banner dengan kualitas terbaik, mohon perhatikan anjuran gambar yang akan diupload yaitu menggunakan gambar dengan <b>format .JPG</b>, dan <b>resolusi 500x265 px</b> 
                                                    </div>
                                                    <div className="col-sm-6 text-right">
                                                      {this.state.dataCekBannerWeb === 'Kosong' 
                                                        ?
                                                        <>    
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                      }
                                                    </div>
                                                  </div>
                                                  <br/><br/>

                                                  <div className="row">
                                                      <Col md={12}>
                                                          {this.state.dataCekBannerWeb === 'Kosong' ? (
                                                              <div>
                                                                  <h3 align="center">Data Banner Belum Ada</h3>
                                                                  <br />
                                                                  <div align="center">
                                                                      <Button
                                                                          size="sm"
                                                                          color="success"
                                                                          className="btn-square"
                                                                          onClick={() => {
                                                                              this.setState({
                                                                                  judulBannerWeb: 'Tambah Banner Dashboard',
                                                                                  dtBannerWeb: frmDefBannerWeb,
                                                                                  linkGambarBannerWeb: ''
                                                                              });
                                                                              this.bukaFormBannerWeb();
                                                                          }}
                                                                      >
                                                                          Tambah Data Sekarang
                                                                      </Button>
                                                                  </div>
                                                                  <br />
                                                              </div>
                                                          ) : (
                                                              <Slider {...Merge_settings}>{gambarListBannerWeb}</Slider>
                                                          )}
                                                      </Col>
                                                  </div>
                                                </>
                                                }
                                              </div>
                                              <Modal
                                                open={this.state.statusBannerWeb.form}
                                                onClose={this.tutupFormBannerWeb}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                                styles={{ modal: { width: '80%' } }}
                                              >
                                                <Form className="theme-form" onSubmit={this.simpanBannerWeb}>
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.judulBannerWeb}</h5>
                                                  </div>
                                                  <div className="modal-body">
                                                    <input
                                                      className="form-control"
                                                      id="uuid"
                                                      type="hidden"
                                                      placeholder="UUID"
                                                      defaultValue={this.state.dataBannerWeb.uuid}
                                                      value={this.state.dataBannerWeb.uuid}
                                                    />

                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                        Foto Banner
                                                      </Label>
                                                      <Col sm="9">
                                                        <Dropzone onDrop={(acceptedFiles) => this.processGbrBannerWeb(acceptedFiles)} maxFiles={12}>
                                                          {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                              <div
                                                                {...getRootProps()}
                                                                style={{
                                                                  minHeight: '100px',
                                                                  paddingTop: '40px',
                                                                  padding: '5px 5px 5px 5px',
                                                                  border: '1px solid #d2d2d2'
                                                                }}
                                                              >
                                                                <input {...getInputProps()} />
                                                                <div
                                                                  style={{
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                  }}
                                                                >
                                                                  <p className="text-center">
                                                                    {this.state.linkGambarBannerWeb ? (
                                                                      <img
                                                                        src={this.state.linkGambarBannerWeb}
                                                                        style={{
                                                                          // minHeight: '50px',
                                                                          // minWidth: '50px'
                                                                          width: '470px'
                                                                        }}
                                                                        alt=""
                                                                      />
                                                                    ) : (
                                                                      <Label
                                                                        className="col-form-label text-center"
                                                                        htmlFor="inputEmail3"
                                                                      >
                                                                        Drag 'n' drop some files here, or click to select
                                                                        files
                                                                      </Label>
                                                                    )}
                                                                    {this.state.foto_info_web ? this.state.foto_info_web : ''}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          )}
                                                        </Dropzone>
                                                      </Col>
                                                    </FormGroup>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      disabled={this.state.statusBannerWeb.btnForm}
                                                      onClick={this.tutupFormBannerWeb}
                                                    >
                                                      Tutup
                                                    </button>
                                                    <button type="submit" className="btn btn-success" disabled={this.state.statusBannerWeb.btnForm}>
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                          <Row>
                                            <Col sm="12">
                                              <div className="card mb-0">
                                                <div className="card-body datatable-react">
                                                {this.state.loadingKonfigurasiKiosk ? (
                                                  <div className="row">
                                                    <div className="col-sm-12" align="center">
                                                      <img 
                                                      alt="loading"
                                                      src={require("../../../assets/images/tes-loading.gif")} 
                                                      style={{
                                                        // width: "100%",
                                                        borderRadius: "10px",
                                                        width: '100px'
                                                      }}/> 
                                                    </div>
                                                  </div>
                                                ) : 
                                                <>
                                                  { 
                                                    this.state.konfig_umum === 'none'
                                                    ?
                                                    <>
                                                      {
                                                        this.state.data_cek_kiosk === "Kosong"
                                                        ?
                                                        <>
                                                        <div className="col-md-12 text-center">
                                                          <br/><br/><br/>
                                                          <h4>Layanan KiosK Belum Dikonfigurasi</h4>
                                                          <br/>
                                                          <Button  size="md" color="info" className="btn-icon" onClick={this.ubahKonfig}><i className="fa fa-wrench"></i> Konfigurasi Sekarang</Button> 
                                                        </div>
                                                        <br/> <br/> <br/>
                                                        </>
                                                        :
                                                        <>
                                                          <div className="col-md-12 text-right">
                                                            <Button  size="md" color="info" className="btn-icon btn-warning" onClick={this.ubahKonfig}><i className="fa fa-pencil"></i> Ubah Konfigurasi</Button> 
                                                          </div>
                                                          <br/> <br/> <br/>


                                                          <div className="row">
                                                            <div className="col-sm-2" align="center">
                                                              &nbsp;
                                                            </div>
                                                            <div className="col-sm-3" align="center">
                                                              <h5>Layanan</h5>
                                                            </div>
                                                            <div className="col-sm-2" align="center">
                                                              <h5>Status Layanan</h5>
                                                            </div>
                                                            <div className="col-sm-3" align="center">
                                                              <h5>Status Cetak Layanan</h5>
                                                            </div>
                                                            <div className="col-sm-2" align="center">
                                                              &nbsp;
                                                            </div>
                                                          </div>

                                                          {
                                                            Object.keys(this.state.list_layanan_kiosk).map((val, index) => {
                                                              // let list_layanan_kiosk = this.state.list_layanan_kiosk
                                                              let jenis_layanan_desa_kiosk = 'Jenis Layanan Desa Mobile'
                                                              let value_jenis_layanan_kiosk = ''
                                                              let value_cetak_layanan_kiosk = ''
                                                              switch (val) {
                                                                case 'tidak_mampu':
                                                                  jenis_layanan_desa_kiosk = "Surat Tidak Mampu"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_mampu

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_mampu
                                                                  break;
                                                                case 'domisili':
                                                                  jenis_layanan_desa_kiosk = "Surat Domisili"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.domisili

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.domisili
                                                                  break;
                                                                case 'kelahiran':
                                                                  jenis_layanan_desa_kiosk = "Surat Kelahiran"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kelahiran

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kelahiran
                                                                  break;
                                                                case 'belum_memiliki_rumah':
                                                                  jenis_layanan_desa_kiosk = "Surat Belum Memiliki Rumah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_memiliki_rumah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah
                                                                  break;
                                                                case 'jalan':
                                                                  jenis_layanan_desa_kiosk = "Surat Jalan"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.jalan

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.jalan
                                                                  break;
                                                                case 'kematian':
                                                                  jenis_layanan_desa_kiosk = "Surat Kematian"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kematian

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kematian
                                                                  break;
                                                                case 'kepemilikan_rumah':
                                                                  jenis_layanan_desa_kiosk = "Surat Kepemilikan Rumah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kepemilikan_rumah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kepemilikan_rumah
                                                                  break;
                                                                case 'penghasilan':
                                                                  jenis_layanan_desa_kiosk = "Surat Penghasilan"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.penghasilan

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.penghasilan
                                                                  break;
                                                                case 'skck':
                                                                  jenis_layanan_desa_kiosk = "Surat SKCK"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skck

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skck
                                                                  break;
                                                                case 'kehilangan':
                                                                  jenis_layanan_desa_kiosk = "Surat Kehilangan"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kehilangan

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kehilangan
                                                                  break;
                                                                case 'usaha':
                                                                  jenis_layanan_desa_kiosk = "Surat Usaha"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.usaha

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.usaha
                                                                  break;
                                                                case 'skgr':
                                                                  jenis_layanan_desa_kiosk = "Surat SKGR"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skgr
                                                                  
                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skgr
                                                                  break;
                                                                case 'belum_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Belum Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_menikah
                                                                  break;
                                                                case 'tidak_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Tidak Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_menikah
                                                                  break;
                                                                case 'pindah_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Pindah Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pindah_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pindah_menikah
                                                                  break;
                                                                case 'pernah_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Pernah Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pernah_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pernah_menikah
                                                                  break;
                                                                case 'keramaian':
                                                                  jenis_layanan_desa_kiosk = "Surat Izin Keramaian"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.keramaian

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.keramaian
                                                                  break;
                                                                case 'ahli_waris':
                                                                  jenis_layanan_desa_kiosk = "Surat Keterangan Ahli Waris"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.ahli_waris

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.ahli_waris
                                                                  break;
                                                                default:
                                                                  // no default
                                                                  break;
                                                              }

                                                              return (
                                                                
                                                              <div className="row">
                                                                <div className="col-sm-2" align="center">
                                                                  &nbsp;
                                                                </div>
                                                                <div className="col-sm-3" align="center">
                                                                  {jenis_layanan_desa_kiosk}
                                                                </div>
                                                                <div className="col-sm-2" align="center">
                                                                  <Switch 
                                                                    onChange={(e) => {
                                                                    this.handleChange(e, val);
                                                                    }}
                                                                    checked={value_jenis_layanan_kiosk} 
                                                                    disabled="true"
                                                                  />
                                                                </div>
                                                                <div className="col-sm-3" align="center">
                                                                  <Switch 
                                                                    onChange={(e) => {
                                                                    this.handleChangeCetak(e, val);
                                                                    }}
                                                                    checked={value_cetak_layanan_kiosk} 
                                                                    disabled="true"
                                                                  />
                                                                </div>
                                                                <div className="col-sm-2" align="center">
                                                                  &nbsp;
                                                                </div>
                                                              </div>
                                                              )
                                                            })
                                                          }
                                                        </>
                                                      }
                                                    </>
                                                    :
                                                    <>
                                                      <div className="col-md-12 text-right">
                                                          <Button  size="md" color="success" className="btn-icon" onClick={() => this.simpanLayanan()}><i className="fa fa-save"></i> Simpan</Button> 
                                                      </div>
                                                      <br/> <br/> <br/>
                                                      

                                                      <div className="row">
                                                        <div className="col-sm-3" align="center">
                                                          &nbsp;
                                                        </div>
                                                        <div className="col-sm-3" align="center">
                                                          <h5>Layanan</h5>
                                                        </div>
                                                        <div className="col-sm-2" align="center">
                                                          <h5>Status Layanan</h5>
                                                        </div>
                                                        <div className="col-sm-2" align="center">
                                                          <h5>Status Cetak Layanan</h5>
                                                        </div>
                                                        <div className="col-sm-2" align="center">
                                                          &nbsp;
                                                        </div>
                                                      </div>
                                                      {
                                                        Object.keys(this.state.list_layanan_kiosk).map((val, index) => {
                                                          // let list_layanan_kiosk = this.state.list_layanan_kiosk
                                                          let jenis_layanan_desa_kiosk = 'Jenis Layanan Desa Mobile'
                                                          let value_jenis_layanan_kiosk = ''
                                                          let value_cetak_layanan_kiosk = ''
                                                          let stat_disable = ''
                                                              switch (val) {
                                                                case 'tidak_mampu':
                                                                  jenis_layanan_desa_kiosk = "Surat Tidak Mampu"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_mampu

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_mampu

                                                                  if(this.state.list_layanan_kiosk.tidak_mampu===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'domisili':
                                                                  jenis_layanan_desa_kiosk = "Surat Domisili"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.domisili

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.domisili

                                                                  if(this.state.list_layanan_kiosk.domisili===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'kelahiran':
                                                                  jenis_layanan_desa_kiosk = "Surat Kelahiran"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kelahiran

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kelahiran

                                                                  if(this.state.list_layanan_kiosk.kelahiran===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'belum_memiliki_rumah':
                                                                  jenis_layanan_desa_kiosk = "Surat Belum Memiliki Rumah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_memiliki_rumah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah

                                                                  if(this.state.list_layanan_kiosk.belum_memiliki_rumah===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'jalan':
                                                                  jenis_layanan_desa_kiosk = "Surat Jalan"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.jalan

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.jalan

                                                                  if(this.state.list_layanan_kiosk.jalan===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'kematian':
                                                                  jenis_layanan_desa_kiosk = "Surat Kematian"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kematian

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kematian

                                                                  if(this.state.list_layanan_kiosk.kematian===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'kepemilikan_rumah':
                                                                  jenis_layanan_desa_kiosk = "Surat Kepemilikan Rumah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kepemilikan_rumah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kepemilikan_rumah

                                                                  if(this.state.list_layanan_kiosk.kepemilikan_rumah===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'penghasilan':
                                                                  jenis_layanan_desa_kiosk = "Surat Penghasilan"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.penghasilan

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.penghasilan

                                                                  if(this.state.list_layanan_kiosk.penghasilan===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'skck':
                                                                  jenis_layanan_desa_kiosk = "Surat SKCK"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skck

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skck

                                                                  if(this.state.list_layanan_kiosk.skck===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'kehilangan':
                                                                  jenis_layanan_desa_kiosk = "Surat Kehilangan"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kehilangan

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kehilangan

                                                                  if(this.state.list_layanan_kiosk.kehilangan===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'usaha':
                                                                  jenis_layanan_desa_kiosk = "Surat Usaha"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.usaha

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.usaha

                                                                  if(this.state.list_layanan_kiosk.usaha===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'skgr':
                                                                  jenis_layanan_desa_kiosk = "Surat SKGR"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skgr
                                                                  
                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skgr

                                                                  if(this.state.list_layanan_kiosk.skgr===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'belum_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Belum Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_menikah

                                                                  if(this.state.list_layanan_kiosk.belum_menikah===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'tidak_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Tidak Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_menikah

                                                                  if(this.state.list_layanan_kiosk.tidak_menikah===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'pindah_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Pindah Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pindah_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pindah_menikah

                                                                  if(this.state.list_layanan_kiosk.pindah_menikah===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'pernah_menikah':
                                                                  jenis_layanan_desa_kiosk = "Surat Pernah Menikah"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pernah_menikah

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pernah_menikah

                                                                  if(this.state.list_layanan_kiosk.pernah_menikah===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'keramaian':
                                                                  jenis_layanan_desa_kiosk = "Surat Izin Keramaian"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.keramaian

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.keramaian

                                                                  if(this.state.list_layanan_kiosk.keramaian===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                case 'ahli_waris':
                                                                  jenis_layanan_desa_kiosk = "Surat Keterangan Ahli Waris"
                                                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.ahli_waris

                                                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.ahli_waris

                                                                  if(this.state.list_layanan_kiosk.ahli_waris===false){
                                                                    stat_disable = true
                                                                  }
                                                                  else{
                                                                    stat_disable = false
                                                                  }
                                                                  break;
                                                                default:
                                                                  // no default
                                                                  break;
                                                          }

                                                          return (
                                                            
                                                          <div className="row">
                                                            <div className="col-sm-3" align="center">
                                                              &nbsp;
                                                            </div>
                                                            <div className="col-sm-3" align="center">
                                                              {jenis_layanan_desa_kiosk}
                                                            </div>
                                                            <div className="col-sm-2" align="center">
                                                              <Switch 
                                                                onChange={(e) => {
                                                                this.handleChange(e, val);
                                                                }}
                                                                checked={value_jenis_layanan_kiosk} 
                                                              />
                                                            </div>
                                                            <div className="col-sm-2" align="center">
                                                              <Switch 
                                                                onChange={(e) => {
                                                                this.handleChangeCetak(e, val);
                                                                }}
                                                                checked={value_cetak_layanan_kiosk} 
                                                                disabled={stat_disable}
                                                              />
                                                            </div>
                                                            <div className="col-sm-2" align="center">
                                                              &nbsp;
                                                            </div>
                                                          </div>
                                                          )
                                                        })
                                                      }
                                                  </>
                                                  }
                                                </>
                                                }
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

export default BasicTable;