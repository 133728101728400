import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";


// koneksi
import { PostStatus, Post, Get, Delete, cekLogin } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DataToken } from "../function/Format";
// import DatePicker from "reactdatepicker";


const frmDef = {
  uuid: "",
  username: "",
  password: "",
  nik: 0,
  id_role: "",
  status: null,
  no_hp: null,
  email: null
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false,
        formReset: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKel: [],
      kelStat: [],
      pendStat: [],
      frmPen: [],
      jpStat: [],
      frmJp: [],
      formPenduduk: "none",
      dataVerifikasi: [],
      photoIndex: 0,
      isOpen: false,
      idVerifikasi: null,
      dataReset: {
        uuid: '',
        username: '',
      },
      akun: DataToken(sessionStorage.getItem('access_token')),
    };
  }

  // ================== DatePicker ==================
  gantiTglMulai = date => {
    this.state.dt.tanggal_mulai = date;
    this.forceUpdate();
  };
  gantiTglSelesai = date => {
    this.state.dt.tanggal_selesai = date;
    this.forceUpdate();
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("pengguna" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results, loading: false });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination; 
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addRole = {
        q: e.target.value
      };
      cekLogin("jenis-surat", addRole, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log(e);
    if (this.state.dt.id_role !== "") {
      this.state.status.btnForm = true;
      this.forceUpdate();

      let add = {
        uuid: this.state.dt.uuid,
        username: document.getElementById("username").value,
        nik: this.state.dt.nik,
        status: this.state.dt.status,
        id_role: '2bc4b424-d264-4899-a104-993859f386ff',
        no_hp: document.getElementById("nohp").value,
        email: document.getElementById("email").value
      };
      if (document.getElementById("password").value !== "") {
        add = {
          uuid: this.state.dt.uuid,
          username: document.getElementById("username").value,
          password: document.getElementById("password").value,
          nik: this.state.dt.nik,
          status: this.state.dt.status,
          id_role: '2bc4b424-d264-4899-a104-993859f386ff',
          no_hp: document.getElementById("nohp").value,
          email: document.getElementById("email").value
        };
      }

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      //console.log(add);
      PostStatus("pengguna/" + metode, add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        //console.log(res);
        if (res === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengguna",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengguna",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pengguna",
        pesanAlert: "Data tidak boleh kosong "
      });
    }
  };
  // ========================================================================


  // ================== simpan data ==================
  simpanReset = id => {
    //console.log(id);
    if (id) {
      Get("pengguna/reset-password/" + id, null, res => {
        this.forceUpdate();
        //console.log(res);
        if (res) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengguna",
            pesanAlert: "Berhasil reset Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengguna",
            pesanAlert: "Gagal reset Data"
          });
        }
        this.tutupForm();

        this.componentDidMount();
      });
    }
    else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pengguna",
        pesanAlert: "Gagal reset Data"
      });
    }


  };
  // =
  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    Get("pengguna/find", id, data => {
      //console.log(data);
      this.state.dt.uuid = data.results.uuid;
      this.state.dt.username = data.results.username;
      this.state.dt.password = data.results.password;
      this.state.dt.nik = data.results.nik;
      this.state.dt.status = data.results.status;
      this.state.dt.id_role = data.results.id_role;
      this.state.dt.no_hp = data.results.no_hp;
      this.state.dt.email = data.results.email;
      if (data.results.data_role.nama_role !== "Super Admin") {
        this.setState({
          formPenduduk: "",
          pendStat: {
            value: data.results.data_penduduk.id,
            label: data.results.data_penduduk.nama
          }
        });
      } else {
        this.setState({ formPenduduk: "none" });
      }

      this.setState({
        statusAktifStat: {
          value: data.results.status,
          label: data.results.status === 0 ? "Belum Verifikasi" : data.results.status === 1 ? "Menunggu Verifikasi" : data.results.status === 2 ? "Verifikasi Ditolak" : data.results.status === 3 ? "Sudah Aktif" : "error"
        },
        statusPegawaiStat: {
          value: data.results.status_pegawai,
          label: data.results.status_pegawai
        },
        roleStat: {
          value: data.results.data_role.uuid,
          label: data.results.data_role.nama_role
        }
      });
      this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm();
    });
  };
  resetPassword = data => {
    //console.log(data)

    this.setState({
      dataReset: data,
      status: {
        ...this.state.status,
        formReset: true,
      }
    })
  };
  verifikasiBerkas = id => {
    //console.log(id);
    this.setState({
      status: {
        ...this.state.status,
        formVerifikasi: true,
        statGet: true
      },
      judul: "Verifikasi Penduduk",
      statUbah: false
    });
    Get("pengguna/find", id, data => {
      this.setState({
        status: {
          ...this.state.status,
          statGet: false
        },
        idVerifikasi: id,
        dataVerifikasi: data.results.data_verkas
      });
    });
  };
  verifikasi = (jenis, id) => {
    //console.log(jenis)
    //console.log(id)
    Get("pengguna/verifikasi/" + jenis + "/" + id, null, dtkat => {
      //console.log(dtkat)
      if (dtkat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Verifikasi  Pengguna",
          pesanAlert: "Berhasil " + jenis + " Verifikasi Pengguna"
        });
        this.tutupForm()
        this.componentDidMount();

      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Verifikasi Pengguna",
          pesanAlert: "Gagal " + jenis + " Verifikasi Pengguna"
        });
        this.componentDidMount();

      }

    });
  }

  ubahPilihan = (table, data) => {
    //console.log("data");

    //console.log(data);
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "kecamatan":
        this.getChild(
          {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          { value: data.data_kabkota.id, label: data.data_kabkota.nama },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.setState({
          prvStat: {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kabkota.id,
            label: data.data_kabkota.nama
          },
          kecStat: { value: data.id, label: data.nama }
        });
        break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );

        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kelStat: { value: data.id, label: data.nama }
        });
        //console.log(data);
        this.bukaForm();
        break;
      case "rt":
        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },
          "keldes",
          "kel",
          "dusun"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          "dusun",
          "dus",
          "rw"
        );

        this.getChild(
          { value: data.data_rw.id, label: data.data_rw.nama },
          "rw",
          "rw",
          "rt"
        );

        this.setState({
          prvStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          kbktStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          kecStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          kelStat: {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },

          dusStat: {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          rwStat: { value: data.data_rw.id, label: data.data_rw.nama },
          rtStat: { value: data.id, label: data.nama }
        });
        this.bukaForm();
        break;
      default:
        // no default
        break;
    }
    // });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("pengguna/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Pengguna",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Pengguna",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.state.status.formReset = false
    this.state.status.formVerifikasi = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        {this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Reset Password",
              statReset: false
            });
            this.resetPassword(row);
          }}
          disabled={this.state.status.btnAksi}
        >
          Reset Password
        </Button>
        ) : ''}

        {/* {row.status === 1 ?
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => {
              this.verifikasiBerkas(row.uuid);
            }}
            disabled={this.state.status.btnAksi}
          >
            <i className="fa fa-tasks"></i>
          </Button>
          : <></>} */}
      </>
    );
  };

  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKec: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKel: frm });
            break;
          case "dusun":
            this.setState({ frmDus: frm });
            break;
          default:
            // no default
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: e });
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [] });
          break;
        case "kab-kota":
          //console.log(e);
          this.setState({ kbktStat: e });
          this.setState({ kecStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e });
          this.setState({ kelStat: [] });

          break;
        case "keldes":
          this.setState({ kelStat: e });
          this.setState({ dusStat: [] });

          break;

        case "jabatan":
          this.state.dt.id_jabatan_perangkat = e.value;
          this.setState({ jpStat: e });
          break;
        case "penduduk":
          this.state.dt.nik = e.value;
          this.setState({ pendStat: e });
          break;
        case "status_kepegawaian":
          this.state.dt.status_pegawai = e.value;
          this.setState({ statusPegawaiStat: e });
          break;
        case "status_aktif":
          this.state.dt.status = e.value;
          this.setState({ statusAktifStat: e });
          break;

        case "role":
          this.state.dt.id_role = e.value;
          //console.log(e);
          if (e.label !== "Super Admin") {
            this.setState({ roleStat: e, formPenduduk: "" });
          } else {
            this.setState({ roleStat: e, formPenduduk: "none" });
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: [] });
          this.setState({ kbktStat: [], frmKbkt: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "kab-kota":
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "kecamatan":
          this.setState({ kecStat: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "keldes":
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "jabatan":
          this.state.dt.id_jabatan_perangkat = "";
          this.setState({ jpStat: [] });
          break;
        case "penduduk":
          this.state.dt.nik = 0;
          this.setState({ pendStat: [] });
          break;
        case "status_kepegawaian":
          this.state.dt.status_pegawai = "";
          this.setState({ statusPegawaiStat: [] });
          break;
        case "status_aktif":
          this.state.dt.status = 0;
          this.setState({ statusAktifStat: [] });
          break;
        case "role":
          this.state.dt.id_role = "";
          this.setState({ roleStat: [] });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  getJabatanPerangkat = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get("penduduk/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        //console.log(data);
        data.results.forEach(dt => {
          //console.log(dt);
          frm.push({ value: dt.id, label: `${dt.nama}` });
        });

        this.setState({ frmPend: frm });
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };
  //nik

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend });
        });
      } else {
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend2 = [];
          datanik.data.results.data.forEach(dt => {
            frmPend2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend2 });
        });
      }
    }
  };

  componentDidMount() {
    console.log(this.state.akun.sub.data_role.nama_role);
    this.fetch({ page: null, where: [] });
    Get("penduduk", null, data => {
      let frm = [];
      data.results.data.forEach(dt => {
        frm.push({ value: dt.id, label: `${dt.id}` });
      });

      this.setState({ frmPend: frm });
    });
    Get("provinsi/list", null, data => {
      let frmPrv = [];
      data.results.forEach(dt => {
        frmPrv.push({ value: dt.id, label: dt.nama });
      });
      this.setState({ frmPrv });
    });
    Get("role/list", null, data => {
      let frmRole = [];
      data.results.forEach(dt => {
        if (dt.nama_role !== "Super Admin") {
          frmRole.push({ value: dt.uuid, label: dt.nama_role });
        }
      });

      let status = [
        { value: 0, label: "Belum Verifikasi" },
        { value: 1, label: "Menunggu Verifikasi" },
        { value: 2, label: "Verifikasi Ditolak" },
        { value: 3, label: "Sudah Aktif" },
      ];
      this.setState({ frmRole, frmStatusAktif: status });
    });
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    // var data = require('../assets/json/company');

    // const frmDef = {
    //   uuid: "",
    //   username: "",
    //   password: "",
    //   nik: "",
    //   id_role: "",
    //   status: null,
    //   no_hp: null,
    //   email: null
    // };
    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "username",
        text: "Username",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "nama",
        text: "Nama Lengkap",
        formatter: (cell, obj) => {
          let role = obj.data_penduduk === null ? "" : obj.data_penduduk.nama;
          return role;
        },
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "email",
        text: "Email",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },

      {
        dataField: "no_hp",
        text: "No Hp",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },

      {
        dataField: "role",
        text: "Role",
        headerAlign: 'center',
        align: 'center',
        formatter: (cell, obj) => {
          let role = obj.data_role.nama_role;
          return role;
        },
        sort: true
      },
      // {
      //   dataField: "wilayah",
      //   text: "Wilayah",
      //   isDummyField: true,
      //   headerAlign: 'center',
      //   formatter: (cell, obj) => {
      //     let daerah =
      //       obj.data_penduduk === null
      //         ? ""
      //         : obj.data_penduduk.data_keldes.nama +
      //         ", " +
      //         obj.data_penduduk.data_keldes.data_kecamatan.nama +
      //         ", " +
      //         obj.data_penduduk.data_keldes.data_kecamatan.data_kabkota.nama +
      //         ", " +
      //         obj.data_penduduk.data_keldes.data_kecamatan.data_kabkota
      //           .data_provinsi.nama;
      //     return daerah;
      //   },
      //   sort: true
      // },

      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        headerAlign: 'center',
        align: 'center',
        formatter: (cell, obj) => {
          let status =
            obj.status === 0
              ? "Belum Verifikasi"
              : obj.status === 1
                ? "Menunggu Verifikasi"
                : obj.status === 2
                  ? "Verifikasi ditolak"
                  : "Sudah Aktif";

          return status;
        },
        sort: true
      },
      // {
      //   dataField: "aksi",
      //   text: "Aksi",
      //   headerAlign: 'center',
      //   align: 'center',
      //   formatter: this.tombolAksi
      // }
    ];

    if (this.state.akun.sub.data_role.nama_role !== "Super Operator") {
			columns.push({
				dataField: 'aksi',
				headerAlign: 'center',
				align: 'center',
				text: 'Aksi',
				formatter: this.tombolAksi
			})
		}

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                  ) : data.length > 0 ? (
                    <>
                      <BootstrapTable keyField="id" data={data} columns={columns} />
                      <div className="pull-right text-white">
                        {this.state.awal}
                        {this.state.sebelum}
                        {this.state.hal.map(dt => {
                          return dt;
                        })}
                        {this.state.setelah}
                        {this.state.akhir}
                      </div>
                    </>
                  ) : (
                    <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          size="lg"
          open={this.state.status.formVerifikasi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" >
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              {this.state.status.statGet === false ? (
                this.state.dataVerifikasi.length === 0 ? (
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Berkas Kosong
                  </Label>
                ) : (
                  <>
                    <div className="my-gallery card-body row">
                      {this.state.dataVerifikasi.map((pj, index) => {
                        return (
                          <figure className="col-xl-4 col-md-4 col-6">
                            <img
                              src={pj.link}
                              alt="Gallery"
                              className="img-thumbnail"
                              onClick={() =>
                                this.setState({ photoIndex: 0, isOpen: true })
                              }
                            />
                            <Label
                              className="col-sm-12 col-form-label text-center"
                              htmlFor="inputEmail3"
                            >
                              {pj.jenis}
                            </Label>
                          </figure>
                        );
                      })}
                    </div>

                    {isOpen && (
                      <Lightbox
                        mainSrc={
                          this.state.dataVerifikasi[photoIndex].link
                        }
                        nextSrc={
                          this.state.dataVerifikasi[
                          (photoIndex + 1) % this.state.dataVerifikasi.length
                          ]
                        }
                        prevSrc={
                          this.state.dataVerifikasi[
                          (photoIndex +
                            this.state.dataVerifikasi.length -
                            1) %
                          this.state.dataVerifikasi.length
                          ]
                        }
                        imageTitle={
                          0 +
                          1 +
                          "/" +
                          this.state.dataVerifikasi[photoIndex].link.length
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex +
                                this.state.dataVerifikasi.length -
                                1) %
                              this.state.dataVerifikasi.length
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex + 1) %
                              this.state.dataVerifikasi.length
                          })
                        }
                      />
                    )}
                  </>
                )
              ) : (
                <>
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Menunggu data
                  </Label>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              {" "}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  this.verifikasi('tolak', this.state.idVerifikasi)
                }}
                disabled={this.state.status.btnForm}
              >
                Tolak Berkas

              </button>
              {this.state.dataVerifikasi.length > 0 ? (
                <>

                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      this.verifikasi('terima', this.state.idVerifikasi)
                    }}
                    disabled={this.state.status.btnForm}
                  >
                    Verifikasi Berkas
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </Form>
        </Modal>
        <Modal
          size="lg"
          open={this.state.status.formReset}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" >
            <div className="modal-body text-center">
              <p>Anda akan melakukan reset password terhadap {this.state.dataReset.username}?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              {" "}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  this.simpanReset(this.state.dataReset.uuid)
                }}
                disabled={this.state.status.btnForm}
              >
                Reset Password
              </button>
            </div>
          </Form>
        </Modal>

        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Role
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "role");
                    }}
                    defaultValue={this.state.roleStat}
                    value={this.state.roleStat}
                    name="role"
                    options={this.state.frmRole}
                    placeholder="Pilih Role"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup
                className="row"
              // style={{ display: this.state.formPenduduk }}
              >
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Penduduk
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "penduduk");
                    }}
                    defaultValue={this.state.pendStat}
                    value={this.state.pendStat}
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    name="dusun"
                    options={this.state.frmPend}
                    placeholder="Pilih Penduduk"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Username
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="masukan username"
                    required
                    defaultValue={this.state.dt.username}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Email
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="masukan email"
                    required
                    defaultValue={this.state.dt.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Password{" "}
                  {this.state.statUbah === false
                    ? "( Silahkan di isi jika ubah password )"
                    : ""}
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    placeholder="masukan Password"
                    required={this.state.statUbah}
                    defaultValue={this.state.dt.password}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  No Hp
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nohp"
                    type="number"
                    placeholder="masukan Nomor Hp"
                    required
                    defaultValue={this.state.dt.no_hp}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "status_aktif");
                    }}
                    defaultValue={this.state.statusAktifStat}
                    value={this.state.statusAktifStat}
                    name="status"
                    options={this.state.frmStatusAktif}
                    placeholder="Pilih Status"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
