import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL, { Post, Delete, Get, JENIS_DESA, ID_DESA, } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import Datetime from 'react-datetime';

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ReactSelect from 'react-select';

let DefaultIcon = L.icon({
	iconSize: [25, 41],
	iconAnchor: [10, 41],
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const currentDate = new Date();
Date.prototype.addDays = function (days) {
	var date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}

const frmDex = {
	uuid: '',
	id_penduduk: null,
	nama: "",
	jenis_kelamin: null,
	nomor_indentitas: "",
	tempat_lahir: "",
	tanggal_lahir: null,
	pekerjaan: null,
	kebangsaan: null,
	keturunan: "",
	datang_dari: "",
	tujuan_kedatangan: "",
	nama_yang_didatangi: "",
	alamat_yang_didatangi: "",
	tanggal_datang: new Date(),
	tanggal_pergi: new Date(),
	keterangan: ""
}
const dateFormat = require('dateformat');

const optionKewarganegaraan = {
	WNI: "Warga Negara Indonesia ( WNI )",
	WNA: "Warga Negara Asing ( WNA )"
}
const optionJenisKelamin = {
	L: "Laki-Laki",
	P: "Perempuan"
}
const optionDapatMembaca = {
	L: "Latin",
	D: "Daerah",
	A: "Arab",
	AL: "Arab dan Latin",
	ALD: "Arab, Latin dan Daerah",
}

class MenuB4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			listPenduduk: [],
			tmplistPenduduk: {},
			detailPenduduk: null,
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			loading: false,
			loadingPreview: false,
			imagePreview: null,
			cetakPreview: false,
			detailPreview: false,
			detailData: null,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDex,
			judul: 'Tambah Penduduk ' + JENIS_DESA,
			optionKewarganegaraan : optionKewarganegaraan,
			optionDapatMembaca: optionDapatMembaca,
			frmPkr: [],
			textCari: '',
			listTahun: [],
			// tahunCetak: new Date().getFullYear(),
			tahunCetak: null,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			editMode: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}

		// if (this.state.textCari === '') {
		// 	Get("penduduk" + link, null, dtkat => {
		// 		console.log("dtkat", dtkat);

		// 		if (dtkat.results) {
		// 			this.setState({
		// 				data: dtkat.results.data,
		// 				pagination: dtkat.results,
		// 				loading: false
		// 			})
		// 			this.pagination(dtkat.results);
		// 		}

		// 	});
		// } else { // Jika pencarian
		Post("buku-penduduk-sementara" + link, null, {
			q: this.state.textCari
		}, dtkat => {
			console.log(dtkat);
			if (dtkat.data.results) {
				console.log('masuk');
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
		// }

	};

	changeSelectValue = (e, fld) => {
		this.setState({
			dt: {
				...this.state.dt,
				[fld]: e
			}
		})
	}

	changeInputText = (e, fld) => {
		this.setState({
			dt: {
				...this.state.dt,
				[fld]: e.target.value
			}
		})
	};

	gantiTgl = (date, fld) => {
		let value = null
		if (typeof date._isValid !== 'undefined') {
			value = new Date(date.format('YYYY-MM-DD'))
		}
		this.setState({
			dt: {
				...this.state.dt,
				[fld]: value
			}
		})
	}

	handleCetak = ((e) => {
		e.preventDefault()

		if (this.state.tahunCetak) {
			this.setState({ loading: false, cetakPreview: false })
			this.downloadSurat()
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			this.setState(alertData)
		}
	})

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();

		if (this.state.dt.jenis_kelamin && this.state.dt.tanggal_lahir && this.state.dt.pekerjaan && this.state.dt.kebangsaan && this.state.dt.tanggal_datang && this.state.dt.tanggal_pergi ) {
			this.setState({ loadingPreview: true })

			let bodyData = this.state.dt
			bodyData = {
				...bodyData,
				tanggal_lahir: dateFormat(new Date(bodyData.tanggal_lahir), 'yyyy-mm-dd'),
				tanggal_datang: dateFormat(new Date(bodyData.tanggal_datang), 'yyyy-mm-dd'),
				tanggal_pergi: dateFormat(new Date(bodyData.tanggal_pergi), 'yyyy-mm-dd'),
				jenis_kelamin: bodyData.jenis_kelamin.value,
				pekerjaan: bodyData.pekerjaan.value,
				kebangsaan: bodyData.kebangsaan.value,
			}
			let psn = "ubah";
			let resstat = 201;
			let metode = "create";
			if (bodyData.uuid === "") {
				psn = "tambah";
				resstat = 201;
				bodyData.uuid = null;
			} else {
				psn = "ubah";
				resstat = 200;
				metode = "update";
			}

			console.log(bodyData);

			Post('buku-penduduk-sementara/' + metode, bodyData.uuid, bodyData, res => {
				// console.log(res);
				// console.log(resstat);
				if (res.status == resstat) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "success",
						basicTitle: "Data Penduduk Sementara",
						pesanAlert: "Berhasil " + psn + " Data"
					});

					document.getElementById('form-data').reset()
					this.fetch();
					this.setState({
						dt: frmDex,
						status: {
							...this.state.status,
							form: false
						}
					})
				} else {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Penduduk Sementara",
						pesanAlert: "Gagal " + psn + " Data"
					});
				}
			})

		} else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: "Data Penduduk Sementara",
				pesanAlert: "Isi seluruh data dengan benar"
			});
		}

	});
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status: {
				...this.state.status,
				form: false,
			},
		})
		// document.getElementById('form-data').reset()
		this.forceUpdate();
	};



	ubahData = data => {
		// console.log(data);
		this.setState({
			judul: "Ubah Data Penduduk Sementara",
			editMode: true
		})

		if (data) {
			this.setState({
				// tmpImages: [],
				dt: {
					...this.state.dt,
					uuid: data.uuid,
					id_penduduk: data.id_penduduk,
					nama: data.nama,
					jenis_kelamin: {
						value: data.jenis_kelamin,
						label: data.jenis_kelamin,
					},
					nomor_indentitas: data.nomor_indentitas,
					tempat_lahir: data.tempat_lahir,
					tanggal_lahir: data.tanggal_lahir,
					pekerjaan: {
						value: data.pekerjaan,
						label: data.pekerjaan,
					},
					kebangsaan: {
						value: data.kebangsaan,
						label: data.kebangsaan,
					},
					keturunan: data.keturunan,
					datang_dari: data.datang_dari,
					tujuan_kedatangan: data.tujuan_kedatangan,
					nama_yang_didatangi: data.nama_yang_didatangi,
					alamat_yang_didatangi: data.alamat_yang_didatangi,
					tanggal_datang: data.tanggal_datang,
					tanggal_pergi: data.tanggal_pergi,
					keterangan: data.keterangan,
				},
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	cariData = (e) => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	};

	downloadSurat = () => {
		let token = sessionStorage.getItem("access_token")
			? sessionStorage.getItem("access_token")
			: "public";

		fetch(API_URL + 'buku-penduduk-sementara/cetak?tahun=' + this.state.tahunCetak.value, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer ' + token,
			},
		})
			// .then((response) => response.blob())
			.then((response) => {
				if (response.status == 200) {
					return response.blob()
				}
				this.setState({
					loading: false,
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Penduduk Sementara',
					pesanAlert: 'Gagal cetak surat'
				});
				throw new Error('Something went wrong');
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`Buku Penduduk Sementara Tahun ${this.state.tahunCetak.value}.pdf`,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
				this.setState({
					loading: false,
				})
			})
			.catch((e) => {
				// console.log(e);
			})
			;
	}
	handleInputChangeNIK = (e, fld) => {
		Post("penduduk", null, { nik: e }, datanik => {
			let frmNik = [];
			let tmplistPenduduk = {}
			datanik.data.results.data.forEach(dt => {
				frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				tmplistPenduduk = {
					...tmplistPenduduk,
					[dt.id]: dt
				}
			});

		});
	};

	changeSelectValue2 = (e, sel) => {
		this.setState({
			[sel]: e
		})
	}

	componentDidMount() {
		this.fetch()
		Get("buku-penduduk-sementara", null, dtkat => {
			console.log(dtkat);
			if (dtkat.results.data) {
				// this.setState({ listPerangkat: dtkat.results })
				let frm = [];
				let tmplistPenduduk = {}
				dtkat.results.data.forEach(dt => {
					// if (dt.data_penduduk) {
					frm.push({ value: dt.id, label: `${dt.id} | ${dt.nama}` });
					tmplistPenduduk = {
						...tmplistPenduduk,
						[dt.id]: dt
					}
					// }
				});
				console.log(frm);
				this.setState({ listPenduduk: frm, tmplistPenduduk });
			}
		});
		Get("pekerjaan/list", null, data => {
			let frmPkr = [];
			data.results.forEach(dt => {
				frmPkr.push({ value: dt.nama, label: dt.nama });
			});
			this.setState({ frmPkr });
		});
		Get("buku-penduduk-sementara/list-tahun", null, dtkat => {
			if (dtkat.results && dtkat.results.length > 0) {
				let listTahun = []
				dtkat.results.map((e) => {
					listTahun.push({ value: e, label: e })
				})

				this.setState({
					listTahun
				})

			}
		});
	}

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'tanggal_datang',
				text: 'TANGGAL DATANG',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'nama',
				text: 'NAMA',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			// {
			// 	dataField: 'niap',
			// 	text: 'NIAP',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'datang_dari',
				text: 'DATANG DARI',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'nama_yang_didatangi',
				text: 'NAMA YANG DIDATANGI',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'tujuan_kedatangan',
				text: 'TUJUAN KEDATANGAN',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			// {
			// 	dataField: 'data_penduduk_detail.dapat_membaca',
			// 	text: 'DAPAT MEMBACA',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// 	formatter: function (cell, row) {
			// 		let text = ''
			// 		if (row.data_penduduk_detail) {
			// 			text = optionDapatMembaca[row.data_penduduk_detail.dapat_membaca]
			// 		}
					
			// 		return text
			// 	}
			// },
			// {
			// 	dataField: 'data_penduduk_detail.kewarganegaraan',
			// 	text: 'KEWARGANEGARAAN',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// 	formatter: function (cell, row) {
			// 		let text = ''
			// 		if (row.data_penduduk_detail) {
			// 			text = optionKewarganegaraan[row.data_penduduk_detail.kewarganegaraan]
			// 		}
					
			// 		return text
			// 	}
			// },
			// {
			// 	dataField: 'data_penduduk_detail.keterangan',
			// 	text: 'Ket.',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div style={{ minWidth: "61px" }}>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="info"
								className="btn-icon mr-2"
								onClick={() => {
									this.setState({
										detailPreview: true,
										detailData: row
									})
								}}
							>
								<i className="fa fa-eye"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Penduduk Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													// this.setState({ loading: true })
													// this.downloadSurat()
													this.setState({ cetakPreview: true })
												}}
											>
												Cetak
											</Button>{" "}
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Penduduk Sementara',
														dt: frmDex,
														tmpImages: ['kosong'],
														editMode: false,
														detailPenduduk: null,
														currentLocation: this.state.curStaticLocation
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react custom-font-sm">
									{/* <div className="row">
										<div className="col-md-12"> */}
									{this.state.loading ? (
										<div className="row">
											<div className="col-sm-12" align="center">
												<img
													alt="loading"
													src={require("../../../assets/images/tes-loading.gif")}
													style={{
														borderRadius: "10px",
														width: '100px'
													}} />
											</div>
										</div>
									) : (
										<div className='row'>
											<div className="col-md-5 text-right">
												<input
													type="text"
													className="form-control"
													style={{ marginBottom: '15px' }}
													id="cariTable"
													placeholder={"Cari berdasarkan nama, nik, tgl. datang, atau nama yg didatangi"}
													defaultValue={this.state.textCari}
													onChange={(e) => {
														this.setState({ textCari: e.target.value })
													}}
													onKeyPress={this.cariData}
												/>
												<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
													<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
														this.setState({
															textCari: '',
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
														document.getElementById('cariTable').value = ''
													}} ></span>
												</div>
											</div>
											<div className="col-md-7" style={{ marginBottom: '15px' }}>
												<Button
													size="sm"
													color="info"
													className="btn-square"
													onClick={() => {
														this.setState({
															textCari: document.getElementById("cariTable").value,
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
													}}
												>
													<i className="fa fa-search"></i>
												</Button>
											</div>
											{data.length > 0 ? (
												<>
													<div className="col-md-12">
														<BootstrapTable
															keyField="id"
															data={data}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal}
															{this.state.sebelum}
															{this.state.hal.map(dt => {
																return dt;
															})}
															{this.state.setelah}
															{this.state.akhir}
														</div>
													</div>
												</>
											) : (
												<div className="col-md-12">
													<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
												</div>
											)}
										</div>
									)}
									{/* </div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.cetakPreview ? true : false}
					onClose={() => {
						this.setState({ cetakPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '40%', maxWidth: 'unset' } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.handleCetak(e) }}>
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Cetak Data Kas Umum</h5>
						</div>
						<div className="modal-body">
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tahun</Label>
									<ReactSelect
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue2(e, "tahunCetak")
										}}
										defaultValue={this.state.tahunCetak}
										value={this.state.tahunCetak}
										name="nik"
										options={this.state.listTahun}
										placeholder="Pilih Tahun"
										// isClearable
									/>
									{/* <input
										className="form-control"
										id="tahun"
										type="number"
										min={2010}
										placeholder="Masukkan tahun"
										defaultValue={this.state.tahunCetak}
										onChange={(e) => {
											this.setState({
												tahunCetak: e.target.value
											})
										}}
										required
									/> */}
								</Col>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								onClick={() => {
									this.setState({ cetakPreview: false })
								}}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success">
								Cetak
							</button>
						</div>
					</Form>
				</Modal>
				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Detail Data Penduduk Sementara </h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Nama</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nama}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor Identitas</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor_indentitas}</span></td>
													</tr>
													<tr>
														<th width="280">Jenis Kelamin</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.jenis_kelamin}</span></td>
													</tr>
													<tr>
														<th width="280">Tempat / Tanggal Lahir</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tempat_lahir + ", " + this.state.detailData.tanggal_lahir}</span></td>
													</tr>
													<tr>
														<th width="280">Pekerjaan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.pekerjaan}</span></td>
													</tr>
													<tr>
														<th width="280">Kebangsaan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.kebangsaan}</span></td>
													</tr>
													<tr>
														<th width="280">Keturunan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.keturunan}</span></td>
													</tr>
													<tr>
														<th width="280">Datang dari</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.datang_dari}</span></td>
													</tr>
													<tr>
														<th width="280">Tujuan kedatangan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tujuan_kedatangan}</span></td>
													</tr>

													<tr>
														<th width="280">Nama yang didatangi</th>
														<th width="50" className="text-center">:</th>
														<td><span>{ this.state.detailData.nama_yang_didatangi}</span></td>
													</tr>
													<tr>
														<th width="280">Alamat yang didatangi</th>
														<th width="50" className="text-center">:</th>
														<td><span>{ this.state.detailData.alamat_yang_didatangi}</span></td>
													</tr>
													<tr>
														<th width="280">Tanggal datang</th>
														<th width="50" className="text-center">:</th>
														<td><span>{ this.state.detailData.tanggal_datang}</span></td>
													</tr>
													<tr>
														<th width="280">Tanggal pergi</th>
														<th width="50" className="text-center">:</th>
														<td><span>{ this.state.detailData.tanggal_pergi}</span></td>
													</tr>
													
													<tr>
														<th width="280">Keterangan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.keterangan}</span></td>
													</tr>
												</tbody>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}

							{/* <div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Penduduk {JENIS_DESA}</Label>
									<ReactSelect
										inputId="detail_penduduk"
										classNamePrefix="select"
										isClearable
										onChange={e => {
											this.setState({ detailPenduduk: e })
											if (e) {
												const pendudukSelected = this.state.tmplistPenduduk[e.value]
												// console.log(pendudukSelected);

												this.setState({
													dt: {
														...this.state.dt,
														id: pendudukSelected.id,
														id_kk: pendudukSelected.id_kk,
														nama: pendudukSelected.nama,
														alamat: pendudukSelected.alamat,
														id_keldes: pendudukSelected.id_keldes,
														tempat_lahir: pendudukSelected.tempat_lahir,
														tanggal_lahir: pendudukSelected.tanggal_lahir,
														jk: pendudukSelected.jk,
														status_perkawinan: pendudukSelected.status_perkawinan,
														shdk: pendudukSelected.shdk,
														agama: pendudukSelected.agama,
														pendidikan_terakhir: pendudukSelected.pendidikan_terakhir,
														pekerjaan: pendudukSelected.pekerjaan,
														nama_ibu_kandung: pendudukSelected.nama_ibu_kandung,
														status_penduduk: pendudukSelected.status_penduduk,
														nama_ayah_kandung: pendudukSelected.nama_ayah_kandung,
													}
												})
											}
										}}
										onInputChange={e => this.handleInputChangeNIK(e, 'nik')}
										defaultValue={this.state.detailPenduduk}
										value={this.state.detailPenduduk}
										options={this.state.listPenduduk}
										placeholder={"Pilih Penduduk " + JENIS_DESA}
									/>
								</Col>
							</div> */}

							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Nama</Label>
									<input
										className="form-control placeholder-sm"
										id="nama"
										type="text"
										defaultValue={this.state.dt.nama}
										value={this.state.dt.nama}
										placeholder="Masukkan nama penduduk"
										onChange={(e) => {
											this.changeInputText(e, "nama")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Nomor Identitas</Label>
									<input
										className="form-control placeholder-sm"
										id="nomor_indentitas"
										type="text"
										defaultValue={this.state.dt.nomor_indentitas}
										value={this.state.dt.nomor_indentitas}
										placeholder="Masukkan nomor identitas penduduk"
										onChange={(e) => {
											this.changeInputText(e, "nomor_indentitas")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Jenis Kelamin</Label>
									<ReactSelect
										inputId="jenis_kelamin"
										classNamePrefix="select"
										// isClearable
										onChange={e => {
											this.changeSelectValue(e, "jenis_kelamin")
										}}
										defaultValue={this.state.dt.jenis_kelamin}
										value={this.state.dt.jenis_kelamin}
										options={[
											{
												value: "Laki-Laki",
												label: "Laki-Laki"
											},
											{
												value: "Perempuan",
												label: "Perempuan"
											}
										]}
										placeholder={"Pilih Jenis Kelamin "}
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tempat Lahir</Label>
									<input
										className="form-control placeholder-sm"
										id="tempat_lahir"
										type="text"
										defaultValue={this.state.dt.tempat_lahir}
										value={this.state.dt.tempat_lahir}
										placeholder="Masukkan tempat lahir. Contoh : Pekanbaru"
										onChange={(e) => {
											this.changeInputText(e, "tempat_lahir")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tanggal Lahir</Label>
									<Datetime
										inputProps={{ "data-testid": "tanggal_lahir", placeholder: 'Klik disini untuk memilih tanggal', required: true }}
										inputId="tanggal_lahir"
										name="tanggal_lahir"
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
										dateFormat="yyyy-MM-DD"
										initialValue={this.state.dt.tanggal_lahir}
										value={this.state.dt.tanggal_lahir}
										onChange={(e) => {
											this.gantiTgl(e, 'tanggal_lahir');
										}}
									/>
								</Col>
								{/* Pekerjaan */}
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Pekerjaan</Label>
									<ReactSelect
										inputId="pekerjaan"
										classNamePrefix="select"
										// isClearable
										onChange={e => {
											this.changeSelectValue(e, "pekerjaan")
										}}
										defaultValue={this.state.dt.pekerjaan}
										value={this.state.dt.pekerjaan}
										options={this.state.frmPkr}
										placeholder={"Pilih Pekerjaan Penduduk "}
									/>
								</Col>

								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Kebangsaan</Label>
									<ReactSelect
										inputId="kebangsaan"
										classNamePrefix="select"
										// isClearable
										onChange={e => {
											this.changeSelectValue(e, "kebangsaan")
										}}
										defaultValue={this.state.dt.kebangsaan}
										value={this.state.dt.kebangsaan}
										options={[
											{
												value: "WNI",
												label: "Warga Negara Indonesia ( WNI )"
											},
											{
												value: "WNA",
												label: "Warga Negara Asing ( WNA )"
											}
										]}
										placeholder={"Pilih Kebangsaan Penduduk "}
									/>
								</Col>

								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Keturunan</Label>
									<input
										className="form-control placeholder-sm"
										id="keturunan"
										type="text"
										defaultValue={this.state.dt.keturunan}
										value={this.state.dt.keturunan}
										placeholder="Masukkan nama negara asal, jika WNA"
										onChange={(e) => {
											this.changeInputText(e, "keturunan")
										}}
										// required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Datang dari</Label>
									<input
										className="form-control placeholder-sm"
										id="datang_dari"
										type="text"
										defaultValue={this.state.dt.datang_dari}
										value={this.state.dt.datang_dari}
										placeholder="Masukkan daerah asal tempat tinggal"
										onChange={(e) => {
											this.changeInputText(e, "datang_dari")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tujuan Kedatangan</Label>
									<input
										className="form-control placeholder-sm"
										id="tujuan_kedatangan"
										type="text"
										defaultValue={this.state.dt.tujuan_kedatangan}
										value={this.state.dt.tujuan_kedatangan}
										placeholder="Masukkan tujuan kedatangan"
										onChange={(e) => {
											this.changeInputText(e, "tujuan_kedatangan")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Nama yang didatangi</Label>
									<input
										className="form-control placeholder-sm"
										id="nama_yang_didatangi"
										type="text"
										defaultValue={this.state.dt.nama_yang_didatangi}
										value={this.state.dt.nama_yang_didatangi}
										placeholder="Masukkan nama penduduk yang didatangi"
										onChange={(e) => {
											this.changeInputText(e, "nama_yang_didatangi")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Alamat yang didatangi</Label>
									<input
										className="form-control placeholder-sm"
										id="alamat_yang_didatangi"
										type="text"
										defaultValue={this.state.dt.alamat_yang_didatangi}
										value={this.state.dt.alamat_yang_didatangi}
										placeholder="Masukkan alamat penduduk yang didatangi"
										onChange={(e) => {
											this.changeInputText(e, "alamat_yang_didatangi")
										}}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tanggal Datang</Label>
									<Datetime
										inputProps={{ "data-testid": "tanggal_datang", placeholder: 'Klik disini untuk memilih tanggal', required: true }}
										inputId="tanggal_datang"
										name="tanggal_datang"
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
										dateFormat="yyyy-MM-DD"
										initialValue={this.state.dt.tanggal_datang}
										value={this.state.dt.tanggal_datang}
										onChange={(e) => {
											this.gantiTgl(e, 'tanggal_datang');
										}}
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tanggal Pergi</Label>
									<Datetime
										inputProps={{ "data-testid": "tanggal_pergi", placeholder: 'Klik disini untuk memilih tanggal', required: true }}
										inputId="tanggal_pergi"
										name="tanggal_pergi"
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
										dateFormat="yyyy-MM-DD"
										initialValue={this.state.dt.tanggal_pergi}
										value={this.state.dt.tanggal_pergi}
										onChange={(e) => {
											this.gantiTgl(e, 'tanggal_pergi');
										}}
									/>
								</Col>
							</div>

							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Keterangan</Label>
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder='Masukkan Keterangan Penduduk' defaultValue={this.state.dt.keterangan} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												keterangan: e.target.value
											}
										})
									}} rows="4" required />
								</Col>
							</div>

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuB4;
