import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DESA, JENIS_DUSUN, ID_DESA, Post } from '../../../function/Koneksi';

const ModalRT = ({ data, optionData, additionalData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	
	
	const frmDusun = {
		id: 0,
		idbaru: '',
		id_keldes: '',
		nama: '',
	}

	const initFormData = {
		dusunList: optionData.dusunList,
        dusunSet: additionalData.dusunSet,
		rwList: optionData.rwList,
        rwSet: additionalData.rwSet,
        dataWilayah: additionalData.dataWilayah,
        detailWilayahRW: additionalData.detailWilayahRW,

		isKelurahan: false,
		status: {
			form:false,
			btnForm:false,
			btnAksi:false,
			select:false
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDusun)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			setFormData({
				...formData,
				isKelurahan: true
			})
		}

		if(data){
			ubahPilihan('rw', additionalData.detailWilayahRW);
		}


		setDt(data ? data : frmDusun)

		setFormData(data ? {
			...initFormData,
			dusunList: optionData.dusunList,
			rwList: optionData.rwList,
		} : initFormData)
	}, [isOpen])
	
	const tutupForm = () => {
		setDt(frmDusun)
		setIsOpen(false)
	};

	const simpan = (e) => {
		e.preventDefault();
		
		// Jika Kelurahan
		if (dt.id_rw > 0 && document.getElementById("nama").value !== '') {
			setFormData({
				...formData,
				status:{
					...formData.status,
					btnForm: true,
				},
			})

			let id = document.getElementById("id").value;
			let addRT = {
				// id: formData.dataWilayah.next_id,
				id : dt.id_rw + document.getElementById("nama").value,
				id_rw: dt.id_rw,
				nama: document.getElementById("nama").value,
			};

			
			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (id === '0') {
				psn = "Tambah";
				resstat = 201;
				id = null;

			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}

			Post('rt/'+metode,id,addRT, (res) => {
				setFormData({
					...formData,
					status: {
						...formData.status,
						btnForm: false,
						form: false,
					}
				})
				setIsOpen(false)

				if (res.status === resstat) {
					const alertData = {
						show: true,
						basicType: "success",
						basicTitle: "Data RT",
						pesanAlert: "Berhasil " + psn + " Data",
						successAlert: true,
					}
					setAlert(alertData)
				} else {
					const alertData = {
						show: true,
						basicType: "danger",
						basicTitle: "Data RT",
						pesanAlert: "Gagal " + psn + " Data"
					}
					setAlert(alertData)
				}
				
				// Refresh list
				setStatusRefresh(true)
				// this.componentDidMount();
			});
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	const changeSelectValue = (e, params) => {
		if (e){
			switch (params) {
				case 'dusun': 

					setFormData({
						...formData,
						dusunSet:e,
						status:{
							...formData.status,
							btnForm: false,
						},
					})

					setDt({
						...dt,
						id_dusun: e.value,
					})
				break;
				case 'rw':
					setFormData({
						...formData,
						status:{
							...formData.status,
							btnForm: true,
						},
					})

					Get('rt/rw/' + e.value + '/list', null, (data) => {
						if (data.results.length !== 0) {
							let akhir = data.results.length - 1;
							let getDataNext = data.results[akhir];
							let idRW = getDataNext.id.substring(0, 16);
							let idRT = getDataNext.id.substring(16, 19);
							let nextIDRT = parseInt(idRT) + 1;
							let normRT = nextIDRT.toString();
							let nextID = '';
							if (normRT.length === 1) {
								nextID = (getDataNext.id.slice(0, -3)) + '00' + normRT
								// console.log(nextID)
							}
							else if (normRT.length === 2) {
								nextID = (getDataNext.id.slice(0, -3)) + '0' + normRT
							}
							else if (normRT.length === 3) {
								nextID = (getDataNext.id.slice(0, -3)) + normRT
							}


							setFormData({
								...formData,
								rwSet: e,
								dataWilayah: {
									...formData.dataWilayah,
									next_id: nextID,
								},
								status:{
									...formData.status,
									btnForm: false,
								},
							})
						}
						else {
							let setnextID = e.value + '001';
							let nextID = setnextID;
							setFormData({
								...formData,
								rwSet: e,
								dataWilayah: {
									...formData.dataWilayah,
									next_id: nextID,
								},
								status:{
									...formData.status,
									btnForm: false,
								},
							})
						}
					});
					
					setDt({
						...dt,
						id_rw: e.value,
					})
					break;
				default:
					// no default
					break;
			}
		}
		else{
			switch (params) {
				case 'dusun': 
					setFormData({
						...formData,
						dusunSet:[],
						rwList:[],
						status:{
							...formData.status,
							btnForm: false,
						},
					})
					setDt({
						...dt,
						id_dusun: 0,
					})
				break;
				case 'rw': 
					setFormData({
						...formData,
						rwSet:[],
						status:{
							...formData.status,
							btnForm: false,
						},
					})
					setDt({
						...dt,
						id_rw: 0,
					})
				break;
				default:
					// no default
					break;
			}
		}
	};

	const getChild = (e, self, parent, child) => {
		setFormData({
			...formData,
			status: {
				...formData.status,
				select: true,
			},
		})

		changeSelectValue(e, self)
		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'rw': 
						setFormData({
							...formData,
							dusunSet:e,
							rwSet:[],
							rwList: frm,
							status: {
								...formData.status,
								select: false,
							},
						})
					break;
					default:
						// no default
						break;
				}
			});
		}
	}

	
	const ubahPilihan = (table, data) => {
		switch (table) {
			case 'rw':
				if (formData.isKelurahan) {
					setFormData({
						...initFormData,
						rwSet: additionalData.rwSet,
					})
				}
				else {

					if(data){
						Get('rw/dus/' + additionalData.dusunSet.value + '/list', null, (data) => {
							let frm = [];
							data.results.forEach((dt) => {
								frm.push({ value: dt.id, label: dt.nama });
							});

							setFormData({
								...formData,
								dusunList: optionData.dusunList,
								dusunSet: additionalData.dusunSet,
								rwList: frm,
								rwSet: additionalData.rwSet,
							})
						});
					}
				}
				break;
			default:
				// no default
				break;
		}
	}

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			styles={{ modal: { width: "35%" } }}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} 
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.id == '' ? 'Tambah' : 'Edit'} Data RT</h5>
				</div>
				<div className="modal-body">
					<input
						className="form-control"
						id="id"
						type="hidden"
						placeholder=""
						defaultValue={dt.id}
					/>
					{
						formData.isKelurahan?
						<></>
						:
						<>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Dusun</Label>
								<Col sm='9'>
									<Select
										isDisabled={formData.status.select}
										classNamePrefix="select"
										onChange={((e)=>{ getChild(e, 'dusun', 'dus', 'rw')})}
										defaultValue={formData.dusunSet} 
										value={formData.dusunSet} 
										name="dusun"
										options={formData.dusunList}
										placeholder={"Pilih " + JENIS_DUSUN}
										isClearable
										/>
								</Col>
							</FormGroup>
						</>
					}
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">RW</Label>
						<Col sm='9'>
							<Select
								isDisabled={formData.status.select}
								classNamePrefix="select"
								onChange={((e)=>{ changeSelectValue(e,'rw')})}
								defaultValue={formData.rwSet} 
								value={formData.rwSet} 
								name="dusun"
								options={formData.rwList}
								placeholder={"Pilih RW"}
								isClearable
								/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"> RT</Label>
						<Col sm='9'>
							<input className="form-control" id="nama" type="text" placeholder="Contoh: 001" defaultValue={dt.nama}/>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setDt(frmDusun)
							tutupForm()
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalRT;
