import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DUSUN, JENIS_DESA, Post } from '../../../../function/Koneksi';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
	  // Configure the URL to the upload script in your back-end here!
	  return new ModalStrukturOrganisasiDesa(loader);
	};
  }
  

const ModalStrukturOrganisasiDesa = ({ data, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	const frmStruktur = {
		uuid: '',
		visi: '',
		misi: '',
		struktur_opd: '',
	}

	const initFormData = {
		// frmPrgTTD: optionData.frmPrgTTD,
		// frmStatusTTD: optionData.frmStatusTTD,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmStruktur)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmStruktur)
		setFormData(data ? {
			...initFormData,
			// status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const simpan = (e) => {
		e.preventDefault();
		if(dt.struktur_opd){
			let bodyRaw = {
				...dt,
				uuid : document.getElementById("uuid").value,
				visi : document.getElementById("visi").value,
				misi : dt.misi,
				struktur_opd: dt.struktur_opd,
			}

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			
			Post("profile-desa/" + metode, bodyRaw.uuid, bodyRaw,res => {
				setFormData({
					...formData,
					status: {
						...formData.status,
						btnForm: false,
						form: false,
					}
				})
				setIsOpen(false)
				if (res.status === resstat) {
					const alertData = {
						show: true,
						basicType: "success",
						basicTitle: "Data Struktur Organisasi Pemerintah "+ JENIS_DESA,
						pesanAlert: "Berhasil " + psn + " Data",
						successAlert: true,
					}
					setAlert(alertData)
				} else {
					const alertData = {
						show: true,
						basicType: "danger",
						basicTitle: "Data Struktur Organisasi Pemerintah "+ JENIS_DESA,
						pesanAlert: "Gagal " + psn + " Data"
					}
					setAlert(alertData)
				}

				// Refresh list
				setStatusRefresh(true)
			});
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	const processGbr = (d) => {
		let type = d[0].type.split('/');
		if (
			type[1] === 'jpg' ||
			type[1] === 'jpeg' ||
			type[1] === 'png' ||
			type[1] === 'bmp' ||
			type[1] === 'tiff' ||
			type[1] === 'webp'
		) {
			Resizer.imageFileResizer(
				d[0],
				300,
				300,
				type[1],
				100,
				0,
				(uri) => {
					setDt({
						...dt,
						struktur_opd: uri,
						foto_info: ''
					})
				},
				'base64'
			);
		} else {
			setDt({
				...dt,
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
			})
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			styles={{ modal: { width: '75%', maxWidth: 'unset' } }}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan}
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Struktur Organisasi Pemerintah {JENIS_DESA}</h5>
				</div>
				<div className="modal-body">
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<div className="col-md-12">
						<div className="row text-center" style={{ justifyContent: "center" }}>
							<div className="col-md-9">
								<Label className="col-form-label" htmlFor="inputEmail3">
									Gambar Struktur Organisasi Pemerintah {JENIS_DESA}
								</Label>
								<Dropzone
									onDrop={(acceptedFiles) => processGbr(acceptedFiles)}
									maxFiles={1}
								>
									{({ getRootProps, getInputProps }) => (
										<section>
											<div
												{...getRootProps()}
												style={{
													minHeight: '100px',
													paddingTop: '40px',
													padding: '5px 5px 5px 5px',
													border: '1px solid #d2d2d2'
												}}
											>
												<input {...getInputProps()} />
												<p>
													{dt.struktur_opd ? (
														<img
															alt="Gambar Struktur Organisasi"
															src={dt.struktur_opd}
															style={{
																minHeight: '50px',
																minWidth: '50px',
																maxWidth:'100%', border:'none',align:'center'
															}}
														/>
													) : (
														"Drag 'n' drop some files here, or click to select files"
													)}
													{dt.foto_info ? (
														dt.foto_info
													) : (
														''
													)}
												</p>
											</div>
										</section>
									)}
								</Dropzone>
							</div>
						</div>
					</div>
					<br/><br/>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Visi Desa</Label>
						<Col sm='9'>
							<input className="form-control" id="visi" type="text" placeholder="Masukkan Visi Desa" defaultValue={dt.visi} required/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Misi Desa</Label>
						<Col sm='9'>
							<CKEditor
								onReady={editor => {
								// Insert the toolbar before the editable area.
								editor.ui.getEditableElement().parentElement.insertBefore(
									editor.ui.view.toolbar.element,
									editor.ui.getEditableElement()
								);

								editor = editor;
								}}
								onError={(error, { willEditorRestart }) => {
								if (willEditorRestart) {
									this.editor.ui.view.toolbar.element.remove();
								}
								}}
								onChange={(event, editor) => {
								const data = editor.getData();
								// console.log(data);
									setDt({
										...dt,
										misi: data
									})
								}}
								editor={DecoupledEditor}
								data={dt.misi}
								config={{
								extraPlugins: [MyCustomUploadAdapterPlugin],
								}}
							/>
							{/* <input className="form-control" id="misi" type="text" placeholder="Masukkan Misi Desa" defaultValue={dt.misi} required/> */}
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalStrukturOrganisasiDesa;
