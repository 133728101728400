import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';

// import Select from 'react-select';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get,} from '../function/Koneksi';
import { DataToken } from '../function/Format';

//slider
import Slider from 'react-slick';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// import CKEditors from 'react-ckeditor-component';
// var HtmlToReactParser = require('html-to-react').Parser;



const frmDef = {
	uuid: '',
	link_gambar: ''
};

// function imageFormatter(cell, row) {
// 	// return "<img src='"+cell+"'/>" ;
// 	return (
// 		<div>
// 			<img src={cell} alt="" style={{ width: '250px' }} />
// 		</div>
// 	);
// }

class Banner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDef,
			judul: 'Tambah Banner Dashboard',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			konten: false,
			content: '',
			akun: DataToken(sessionStorage.getItem('access_token')),

			// BANNER
			foto_desa: '',
			link_gambar: '',
			link_gambar_show: null,
			get_link_gambar: null,
			data_cek: '',
            
			loading: true,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ========================================================================

	/// upload file Banner

	processGbr = (d) => {
		if (d.length === 1) {
			let type = d[0].type.split('/');
			if (
				type[1] === 'jpg' ||
				type[1] === 'jpeg' ||
				type[1] === 'png' ||
				type[1] === 'bmp' ||
				type[1] === 'tiff' ||
				type[1] === 'webp'
			) {
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				Post('upload-file/banner', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
							this.setState({
								foto_desa: uri,
								foto_info: '',
								link_gambar: data.data.results
							});
						},
						'base64'
					);
				});
			} else {
				this.setState({
					foto_desa: '',
					foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
				}); 
			}
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
			});
		}
	};

	// ================== simpan data banner==================
	simpan = (e) => {
		e.preventDefault();

		// let link_lama = this.state.get_link_gambar;

        let link_baru = {
            banner_dashboard: this.state.link_gambar
        }

		if (this.state.link_gambar === '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Banner Dashboard',
				pesanAlert: 'Silahkan lengkapi data!'
			});
		} else {
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})
			this.forceUpdate();
			// let linknya = '';
			let psn = '';
			let resstat = '204';
			let metode = '';
			// let uuidnya = '';
			if (this.state.data.uuid === null) {
                // let linkStr = link_baru
				// linknya = linkStr;
				this.state.data.link_web = JSON.stringify(link_baru)

				metode = 'create';
				psn = 'Tambah';
				resstat = 201;
				// uuidnya = document.getElementById("uuid").value;
			} else {
				// linknya = '' + this.state.link_gambar + ',' + this.state.link_gambar_show + '';
				
                // let linkStr = link_baru
				// linknya = linkStr;
				this.state.data.link_web = JSON.stringify(link_baru)

				metode = 'update';
				psn = 'Ubah';
				resstat = 200;
				// uuidnya = document.getElementById("uuid").value;
			}
            // let addData = {
            //     uuid: uuidnya,
            //     link_web: linknya,
            // };
         
            
			Post('banner/' + metode, this.state.data.uuid, this.state.data, (res) => {
				this.setState({
					status:{
						...this.state.status,
						btnForm: false,
						form: false,
					},
				})
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Banner Dashboard',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.componentDidMount();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Banner Dashboard',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.componentDidMount();
				}
			});
		}
	};
	// ========================================================================

	// ============================== Ubah Data ===============================
	ubahData = (id) => {
		let link_gambar_ubah = this.state.link_gambar
		this.setState({
			judul: 'Ubah Banner Dashboard',
			link_gambar: link_gambar_ubah,
			status:{
				...this.state.status,
				btnAksi: true,
			},
			dt:{
				uuid: this.state.data.uuid,
			}
		})
		this.forceUpdate();

		this.bukaForm();
	};
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();

		let metode = 'update';
		let psn = 'Hapus';
		let resstat = 200;
		let newArray = [];

        let data_all = JSON.parse(this.state.data.link_web)
		data_all.banner_dashboard = JSON.stringify(newArray);
		
		this.state.data.link_web = JSON.stringify(data_all)

		Post('banner/' + metode, this.state.data.uuid, this.state.data, (res) => {
			this.setState({
				status:{
					...this.state.status,
					btnForm: false,
					form: false,
				}
			})
			this.forceUpdate();
			if (res.status === resstat) {
				axios.get(`${this.state.link_gambar_show}/delete`).then(resp => {
					// //console.log("Respon Hapus DI Folder");
					// //console.log(resp.data);
				});
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Banner Dashboard',
					pesanAlert: 'Berhasil ' + psn + ' Gambar'
				});
				this.componentDidMount();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Banner Dashboard',
					pesanAlert: 'Gagal ' + psn + ' Gambar'
				});
				this.componentDidMount();
			}
		});

		this.forceUpdate();
	};


	// ========================================================================

	bukaForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: true,
			}
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: false,
			}
		})
		this.forceUpdate();
	};

	componentDidMount() {
        
		Get('banner', null, (dtkat) => {
			if (dtkat.results === null || dtkat.results === 'undefined') {
				this.setState({
					data_cek: 'Kosong',
                    loading: false,
                    data:{
                        uuid: null
                    }
				});
			} else {
				let statData = '';
				let banner_dashboard = null

				if(dtkat.results.link_web){
					let ambil = JSON.parse(dtkat.results.link_web);

					if (dtkat.results.link_web === null || dtkat.results.link_web === '[]') {
							statData = 'Kosong';
					} else {
						if(ambil.banner_dashboard === null || ambil.banner_dashboard === '[]'){
							statData = 'Kosong';
						}
						else{
							statData = 'Tidak Kosong';
						}
					}

					if(dtkat.results.link_web){
						banner_dashboard = ambil.banner_dashboard
					}
				}
				else{
					statData = 'Kosong';
				}

				this.setState({
					data: dtkat.results,
					link_gambar_show: banner_dashboard,
					get_link_gambar: banner_dashboard,
					data_cek: statData,
                    loading: false,
				});
			}
		});
	}

	render() {
		let gambarList = [];
		// let listGambarnya = this.state.link_gambar_show;

		if (this.state.link_gambar_show) {
			gambarList = 
					<div>
						<div className="p-2" align="center">
							{/* <img className="p-2" style={sliderStyle} src={dt} alt="" /> */}
							<img className="p-2" style={{ width: '500px' }} src={this.state.link_gambar_show} alt="" />
						</div>
						<div align="center">
							{this.state.akun.sub.data_role.nama_role === "Super Admin" ? (
							<Button size="md" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(this.state.link_gambar_show)}>
								<i className="fa fa-trash" />
								&nbsp; Hapus
							</Button>
							) : ''}
						</div>
					</div>
		}

		// var size = Math.ceil(
		// 	this.state.pagination.total / this.state.pagination.per_page
		// );
		// // var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		// var data = this.state.data;
		const frmDef = {
			uuid: '',
			link_gambar: ''
		};

		// const sliderStyle = {
		// 	margin: '0px auto',
		// 	width: '30%',
		// 	// maxWidth: '1280px',
		// 	maxWidth: '15px',
		// 	// height: '357px',
		// 	borderRadius: '21px',
		// 	objectFit: 'cover',
		// 	objectPosition: '50% 50%'
		// };

		var Merge_settings = {
			dots: true,
			infinite: true,
			speed: 100,
			slidesToShow: 1,
			slidesToScroll: 1,
			margin: 0,
			padding: 0
			// centerMode: true,
			// variableWidth: true,
			// adaptiveHeight:true,
		};

		return (
			<div>
				{/* <Breadcrumb title="Data Banner Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header" />

								<div className="card-body datatable-react">
                                {this.state.loading ? (
                                    <div className="row">
                                        <div className="col-sm-12" align="center">
                                            <img 
                                            src={require("../assets/images/tes-loading.gif")} 
											alt=""
                                            style={{
                                                // width: "100%",
                                                borderRadius: "10px",
                                                width: '100px'
                                            }}/> 
                                        </div>
                                    </div>
                                )
                                : 
                                <>
                                    <div className="row">
										<div className="col-sm-6">
											<b><font color='red'>CATATAN:</font></b> <br/>
											untuk menghasilkan banner dengan kualitas terbaik, mohon perhatikan anjuran gambar yang akan diupload yaitu menggunakan gambar dengan <b>format .JPG</b>, dan <b>resolusi 500x265 px</b> 
										</div>
                                        <div className="col-sm-6 text-right">
                                        {this.state.data_cek === 'Kosong' 
                                            ?
                                            <>
                                                
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                        </div>
                                    </div>
									<br/><br/>

                                    <div className="row">
                                        {/* <Col md={1} /> */}
                                        <Col md={12}>
                                            {this.state.data_cek === 'Kosong' ? (
                                                <div>
                                                    <h3 align="center">Data Banner Belum Ada</h3>
                                                    <br />
                                                    <div align="center">
                                                        <Button
                                                            size="sm"
                                                            color="success"
                                                            className="btn-square"
                                                            onClick={() => {
                                                                this.setState({
                                                                    judul: 'Tambah Banner Dashboard',
                                                                    dt: frmDef,
                                                                    link_gambar: ''
                                                                });
                                                                this.bukaForm();
                                                            }}
                                                        >
                                                            Tambah Data Sekarang
                                                        </Button>
                                                    </div>
                                                    <br />
                                                </div>
                                            ) : (
                                                <Slider {...Merge_settings}>{gambarList}</Slider>
                                            )}
                                        </Col>
                                    </div>
                                </>
								}

								</div>

							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.data.uuid}
								value={this.state.data.uuid}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto Banner
								</Label>
								<Col sm="9">
									<Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)} maxFiles={12}>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													{...getRootProps()}
													style={{
														minHeight: '100px',
														paddingTop: '40px',
														padding: '5px 5px 5px 5px',
														border: '1px solid #d2d2d2'
													}}
												>
													<input {...getInputProps()} />
													<div
														style={{
															justifyContent: 'center',
															alignItems: 'center'
														}}
													>
														<p className="text-center">
															{this.state.link_gambar ? (
																<img
																	src={this.state.link_gambar}
																	style={{
																		// minHeight: '50px',
																		// minWidth: '50px'
																		width: '470px'
																	}}
																	alt=""
																/>
															) : (
																<Label
																	className="col-form-label text-center"
																	htmlFor="inputEmail3"
																>
																	Drag 'n' drop some files here, or click to select
																	files
																</Label>
															)}
															{this.state.foto_info ? this.state.foto_info : ''}
														</p>
													</div>
												</div>
											</section>
										)}
									</Dropzone>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>

            </div>
		);
	}
}

export default Banner;