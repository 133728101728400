import React, { Component } from "react";


import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-responsive-modal";
import classnames from "classnames";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";



// koneksi
import {
  Post,
  Get,
  Delete,
} from "../function/Koneksi";
import { DataToken } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";



const frmDef = {
  uuid: "",
  nikStat: null,
  sttStat: null,
  pntStat: null,
  catatan: ""
};
const status = ["Belum Selesai", "Selesai"];
const penalti = ["Tidak", "Ya"];
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataBelumSelesai: [],
      dataSelesai: [],
      active_tab_icon: "1",
      pagination: [],
      loading: false,
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        dataPenduduk: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Catatan",
      // =========== Select 2 ============
      frmNik: [],
      nikStat: null,
      frmPnt: [
        { value: 0, label: "Tidak" },
        { value: 1, label: "Ya" }
      ],
      pntStat: null,
      frmStt: [
        { value: 0, label: "Belum Selesai" },
        { value: 1, label: "Selesai" }
      ],
      sttStat: null,
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: ""
      // ==============================
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("catatan-penduduk?status=" + params.tab + link, null, dtkat => {
      switch (params.tab) {
        case "0":
          this.setState({
            dataBelumSelesai: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "1":
          this.setState({
            dataSelesai: dtkat.results.data,
            pagination: dtkat.results
          });
          break;
        default:
          // no default
          break;
      }
      this.setState({ loading: false })
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addCatatan = {
        nama: e.target.value
      };
      Post("catatan-penduduk", null, addCatatan, data => {
        this.setState({ data: data.data.results, pagination: data.data });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    const token = DataToken(sessionStorage.getItem("access_token"));
    if (this.state.nikStat && this.state.sttStat && this.state.pntStat) {
      this.state.status.btnForm = true;
      this.forceUpdate();
      let addCatatan = {
        uuid: document.getElementById("uuid").value,
        nik: this.state.nikStat.value,
        catatan: document.getElementById("catatan").value,
        penalti: this.state.pntStat.value,
        status: this.state.sttStat.value,
        id_pengguna: token.sub.uuid
      };

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addCatatan.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addCatatan.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post("catatan-penduduk/" + metode, addCatatan.uuid, addCatatan, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Catatan",
            pesanAlert: "Berhasil " + psn + " Data"
          });
          this.tutupForm()
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Catatan",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Catatan",
        pesanAlert: "NIK, Pembatasan Layanand dan Status harus diisi"
      });
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.state.status.btnAksi = true;
    this.state.judul = "Ubah Catatan";
    this.forceUpdate();
    Get("catatan-penduduk/find", id, data => {
      this.state.dt.uuid = data.results.uuid;
      this.state.dt.catatan = data.results.catatan;
      this.state.nikStat = { value: data.results.nik, label: data.results.nik };
      this.state.sttStat = {
        value: data.results.status,
        label: status[parseInt(data.results.status)]
      };
      this.state.pntStat = {
        value: data.results.penalti,
        label: penalti[parseInt(data.results.penalti)]
      };
      this.state.status.btnAksi = false;
      this.getPenduduk(
        { value: data.results.nik, label: data.results.nik },
        "nik"
      );
      this.bukaForm();
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("catatan-penduduk/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Catatan",
          pesanAlert: "Berhasil " + psn + " Data"
        });
        this.componentDidMount();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Catatan",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      status: {
        form: false
      },
      nikStat: null,
      pntStat: null,
      sttStat: null,

    })
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button> */}
      </>
    );
  };

  ubahPilihan = (table, id) => {
    Get(table + "/find", id, data => {
      switch (table) {
        case "provinsi":
          this.setState({
            prvStat: { value: data.results.id, label: data.results.nama }
          });
          this.bukaForm();
          break;
        default:
          // no default
          break;
      }
    });
  };

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      }
    }
  };

  getPenduduk = (e, sel) => {
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        // const data_rt = data.results.data_kk.data_rt;
        // const data_rw = data_rt.data_rw;
        // const data_dusun = data_rw.data_dusun;
        // const data_desa = data_dusun.data_keldes;
        // const data_kecamatan = "";
        // const data_kabkota = "";

        // let alamat = "";
        // if (JENIS_DESA.toLowerCase() !== 'kelurahan') {
        // 	const data_dusun = data_rw.data_dusun;
        // 	const data_desa = data_dusun.data_keldes;
        // 	data_kecamatan = data_desa.data_kecamatan;
        // 	data_kabkota = data_kecamatan.data_kabkota;
        // 	alamat = 'RT/RW : ' + data_rt.nama +
        // 		'/' + data_rw.nama +
        // 		', ' + JENIS_DUSUN + ' ' + data_dusun.nama +
        // 		', ' + JENIS_DESA + ' ' + data_desa.nama +
        // 		', Kecamatan ' + data_kecamatan.nama +
        // 		', ' + data_kabkota.nama;
        // } else {
        // 	const data_desa = data_rw.data_keldes;
        // 	data_kecamatan = data_desa.data_kecamatan;
        // 	data_kabkota = data_kecamatan.data_kabkota;
        // 	alamat = 'RT/RW : ' + data_rt.nama +
        // 		'/' + data_rw.nama +
        // 		', Kelurahan ' + data_desa.nama +
        // 		', Kecamatan ' + data_kecamatan.nama +
        // 		', ' + data_kabkota.nama;
        // }
        // if (JENIS_DESA.toLowerCase() === "kelurahan") {
        // alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan
        //     .nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan
        //     .data_kabkota.nama;
        // } else {
        //   if (data.results.data_kk.id_rt === "0") {
        // alamat =
        //   JENIS_DESA +
        //   " " +
        //   data.results.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_keldes.data_kecamatan.nama +
        //   ", " +
        //   data.results.data_keldes.data_kecamatan.data_kabkota.nama +
        //   ", " +
        //   data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi
        //     .nama;
        // } else {
        // alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   JENIS_DUSUN +
        //   " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   JENIS_DESA +
        //   " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama;
        //   }
        // }

        this.state.tanggal_lahir = data.results.tanggal_lahir;
        this.state.dt.kk = data.results.id_kk;
        this.state.dt.nama = data.results.nama;
        this.state.dt.tanggal_lahir = new Date(
          data.results.tanggal_lahir
        ).toLocaleDateString("id-ID");
        this.state.dt.tempat_lahir = data.results.tempat_lahir;
        this.state.status.dataPenduduk = "inherit";
        this.forceUpdate();
      });
    } else {
      this.state.nikStat = null;
      this.state.dt.nama = "";
      this.state.dt.tanggal_lahir = "";
      this.state.dt.tempat_lahir = "";
      this.state.status.dataPenduduk = "none";
      this.forceUpdate();
    }
  };

  changeSelectValue = (e, sel) => {
    switch (sel) {
      case "nik":
        this.setState({ nikStat: e });
        break;
      case "penalti":
        this.setState({ pntStat: e });
        break;
      case "status":
        this.setState({ sttStat: e });
        break;
      default:
        // no default
        break;
    }
  };

  componentDidMount(tab = "1") {
    let tabActive = tab;

    if (typeof this.props.location.tabProps !== "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
      tabActive = this.props.location.tabProps.toString();
    }
    if (typeof this.props.location.state !== "undefined") {
      this.state.active_tab_icon = this.props.location.state.tab;
    }

    Get("penduduk", null, data => {
      let frmNik = [];
      data.results.data.forEach(dt => {
        frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
      });
      this.setState({
        frmNik,
      });
    });

    switch (this.state.active_tab_icon) {
      case "1":
        this.fetch({ page: null, where: [], tab: "0" });
        break;

      case "2":
        this.fetch({ page: null, where: [], tab: "1" });
        break;

      default:
        this.fetch({ page: null, where: [], tab: "0" });
        break;
    }
    this.forceUpdate();
  }

  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      switch (tab) {
        case "1":
          this.fetch({ page: null, where: [], tab: "0" });
          break;

        case "2":
          this.fetch({ page: null, where: [], tab: "1" });
          break;

        default:
          this.fetch({ page: null, where: [], tab: "0" });
          break;
      }

      this.setState({
        active_tab_icon: tab
      });
    }
  }

  render() {
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    const frmDef = {
      id: "",
      nikStat: null,
      sttStat: null,
      pntStat: null,
      catatan: ""
    };

    switch (this.state.active_tab_icon) {
      case "1":
        data = this.state.dataBelumSelesai;
        break;

      case "2":
        data = this.state.dataSelesai;
        break;
      default:
        // no default
        break;
    }

    // var no = 0;
    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "nik",
        headerAlign: 'center',
        align: 'center',
        text: "NIK",
        sort: true
      },
      {
        dataField: "nama",
        headerAlign: 'center',
        align: 'center',
        text: "Nama",
        isDummyField: true,
        formatter: (cell, obj) => {
          return obj.data_penduduk ? obj.data_penduduk.nama : "-";
        },
        sort: true
      },
      {
        dataField: "catatan",
        headerAlign: 'center',
        align: 'center',
        text: "Catatan",
        sort: true
      },
      {
        dataField: "penalti",
        headerAlign: 'center',
        align: 'center',
        text: "Pembatasan Layanan",
        isDummyField: true,
        formatter: (cell, obj) => {
          return obj.penalti === 1 ? "Ya" : "Tidak";
        },
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
        align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          return obj.status === 1 ? "Selesai" : "Belum Selesai";
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
        align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href={() => false} onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    let bootstrapTable = (
      <div>
        {this.state.loading ? (
          <div className="row">
            <div className="col-sm-12" align="center">
              <img 
              alt="loading"
              src={require("../assets/images/tes-loading.gif")} 
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '100px'
              }}/> 
            </div>
          </div>
        ) : data.length !== 0 ? (
          <>
            <BootstrapTable keyField="uuid" data={data} columns={columns} />
            <div className="pull-right text-white">
              {this.state.awal}
              {this.state.sebelum}
              {this.state.hal.map(dt => {
                return dt;
              })}
              {this.state.setelah}
              {this.state.akhir}
            </div>
          </>
        ) : (
          <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
        )}
      </div>
    );

    return (
      <div>
        {/* <Breadcrumb title="Data Catatan Penduduk" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Data Catatan Penduduk</h5>
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Catatan",
                            dt: frmDef
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />
                        Belum Selesai
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />
                        Selesai
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "1"
                              ? bootstrapTable
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "2"
                              ? bootstrapTable
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                jika pada pilihan Pembatasan Layanan dipilih Ya, Catatan
                Penduduk ini akan mempengaruhi akses penduduk terhadap layanan.
                Akibatnya NIK ybs tidak bisa mengakses layanan - layanan
                tertentu.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Catatan"
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK
                </Label>
                <Col sm="9">
                  <Select
                    classNamePrefix="select"
                    onChange={e => {
                      this.getPenduduk(e, "nik");
                    }}
                    defaultValue={this.state.nikStat}
                    value={this.state.nikStat}
                    name="NIK"
                    options={this.state.frmNik}
                    placeholder="Pilih NIK"
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    isClearable
                  />
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Catatan
                </Label>
                <Col sm="9">
                  <textarea
                    className="form-control"
                    id="catatan"
                    placeholder="Catatan"
                    defaultValue={this.state.dt.catatan}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pembatasan Layanan
                </Label>
                <Col sm="9">
                  <Select
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "penalti");
                    }}
                    defaultValue={this.state.pntStat}
                    value={this.state.pntStat}
                    name="PembatasanLayanan"
                    options={this.state.frmPnt}
                    placeholder="Pembatasan Layanan"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status
                </Label>
                <Col sm="9">
                  <Select
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "status");
                    }}
                    defaultValue={this.state.sttStat}
                    value={this.state.sttStat}
                    name="Status"
                    options={this.state.frmStt}
                    placeholder="Status"
                    isClearable
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                onClick={this.simpan}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
