import React, {Component} from 'react';
// import { Link } from 'react-router-dom'

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

// koneksi
import {Post, Get, Delete} from '../../function/Koneksi';

// import NumberFormat from 'react-number-format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';




const frmDef = {
    uuid: '',
    uuid_bansos: '',
    periode: '',
    bantuan_nominal: '',
    bantuan_barang: [{isi:[""]}],
}


let detail = [];

class BasicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form:false,
                formDetail:false,
                btnForm:false,
                btnAksi:false,
                select:false
            },
            awal: "",
            sebelum: "",
            hal: [],
            setelah: "",
            akhir: "",
            dt:frmDef,
            judul: "Tambah Data Bantuan Sosial",
            judulDetail: "Tambah Data Detail Bantuan Sosial",
           
            // =================================
            // =========== Alert ============
            alert:null,
            show: false,
						loading: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
            // ==============================

            jenisForm: '',

            // Pilih Data Penduduk
			frmPenduduk: [],
			setPenduduk: null,
			detailPenduduk:[],
			showDataPenduduk: "none",
			detail: [],

            // Pencarian
            dataCari: {
				q: ''
			},

            // Loading
			loading: true,
        };
    }
    
    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = (params = {}) => 
    {    
				this.setState({ loading: true })
        let link = "";
        if (params.page)
        {
            switch(params.page)
            {
                case '>>': link = "?page="+(Math.ceil(this.state.pagination.total / this.state.pagination.per_page));break;
                case '>': link = "?page="+(parseInt(this.state.pagination.current_page) + 1);break;
                case '<': link = "?page="+(parseInt(this.state.pagination.current_page) - 1);break;
                case '<<': link = "?page=1";break;
                default: link = "?page="+params.page;break;
            }
        }
        
        let setLink = '';
        if(link){
            setLink = `${link}&q=${this.state.dataCari.q}`
        }
        else{
            setLink = `?q=${this.state.dataCari.q}`
        }

		let getData = this.props.location.state;
        
        Get('bansos/periode/penerima/'+getData.uuid+setLink, null, (dtkat) => {
            this.setState({data:dtkat.results.data, loading: false, pagination:dtkat.results, loading: false});
            this.pagination(dtkat.results);
        });
    }

    // Pagination
    pagination = (data) => {
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0)
        {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3)
            {   
                start = 1;
                end = 5;
                if (data.last_page > data.current_page)
                {
                    n = data.current_page + 1;
                }
            }
            else 
            {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2)
            {
                p = data.current_page - 1;
                if (start >= 5)
                {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i=start;i<=end;i++) 
            {
                let warna = "primary-custom";
                if (i === data.current_page)
                {
                    warna = "danger"
                }
                hal.push(<a href={() => false} onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }
            if (p > 0)
            {
                sebelum = (<a href={() => false} onClick={()=>this.fetch({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }
            if (n > 0)
            {
                setelah = (<a href={() => false} onClick={()=>this.fetch({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }
            awal = (<a href={() => false} onClick={()=>this.fetch({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a href={() => false} onClick={()=>this.fetch({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }
        this.setState({awal,sebelum,hal,setelah,akhir});
    }
    
    // Cari Data Table
	cariData = (e) => {
		if (e.key === 'Enter') {
			// console.log("Search")
			// console.log(e.target.value)
			let dataCari = {
				q: e.target.value
			};
			this.setState({ dataCari });
			setTimeout(
				function() {
					// mywindow.close();
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

    // ========================================================================

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();

        let cek_validasi = false
        let hitungJumlah = this.state.detail.length;
       
        // console.log('simpan data detailnya')
        // console.log(this.state.detail)
        // console.log(hitungJumlah)

        if(this.state.detail.length!==0){
            cek_validasi = true
        }

        if (cek_validasi)
        {
            this.setState({
                status:{
                    ...this.state.status,
                    btnForm: true,
                },
            })
            this.forceUpdate();

            this.state.detail.forEach((dt, i) => {
                let addData = {
                    uuid_bansos_periode: this.props.location.state.uuid,
                    nik: dt.nik,
                    status: "Belum disalurkan",
                }

                // console.log('Data Simpan Adalah'+i)
                // console.log(addData)

                let psn = '';
                let resstat = 204;
                let metode = "";
                if (this.state.jenisForm === 'create')
                {
                    psn = "Tambah"; 
                    resstat = 201;
                    addData.uuid = null;
                    metode = "create";
                } 
                else if(this.state.jenisForm === 'update')
                {
                    psn = "Ubah"; 
                    resstat = 200;
                    metode = "update";
                    addData.uuid = this.state.dt.uuid
                }

                Post('bansos/periode/penerima/'+ metode,addData.uuid, addData, (res) => {
                    this.setState({
                        status:{
                            ...this.state.status,
                            btnForm: false,
                            form: false,
                        },
                    })
                    this.forceUpdate();
                    if (res.status === resstat)
                    {
                        this.setState({ show: true, basicType:'success', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:'Berhasil '+psn+' Data' });
                        this.tutupForm()
                    }
                    else if (res.status === 409)
                    {
                        if(hitungJumlah === 1){
                            this.setState({ show: true, basicType:'danger', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:'Penerima Telah Tercatat' });
                            this.tutupForm()
                        }
                        else{
                            this.setState({ show: true, basicType:'success', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:'Berhasil '+psn+' Data' });
                            this.tutupForm()
                        }
                    }
                    else
                    {
                        this.setState({ show: true, basicType:'danger', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:'Gagal '+psn+' Data' });
                    }
                    
                    this.componentDidMount();
                });
            })
        }
        else
        {
            this.setState({ show: true, basicType:'danger', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:"Data tidak lengkap!" });
        }
    }
    // ========================================================================
    
    // ============================== Ubah Data ===============================
    
    ubahData = (data) => {
        let bantuan_barang = JSON.parse(data.bantuan_barang)
        // console.log('Data Detail')
        // console.log(data)
        // console.log(data.bantuan_nominal.toLocaleString())
        this.setState({
            jenisForm: 'update',
            status:{
                ...this.state.status,
                btnAksi: true,
            },
            dt:{
                uuid: data.uuid,
                uuid_bansos: data.uuid_bansos,
                periode: data.periode,
                bantuan_nominal: data.bantuan_nominal === 0?'':data.bantuan_nominal.toLocaleString(),
                bantuan_barang: [
                    bantuan_barang.length === 0? {isi:[""]}
                    : {isi:bantuan_barang}
                ],
            },
            judul: "Ubah Data Periode Bantuan",
        })
        this.bukaForm();
        this.forceUpdate();
    }
    // ========================================================================
    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="Hapus"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    type="danger"
                    title="Yakin ingin hapus data ?"
                    onCancel={this.hideAlert}
                    onConfirm={()=> this.hapusData(id)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    hapusData = (id) => {
        this.hideAlert();
        let psn = "Hapus";
        Delete('bansos/periode/penerima/delete', id, (res) => {
            if (res === 200)
            {
                this.setState({ show: true, basicType:'success', basicTitle:'Data Penerima Bantuan', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Penerima Bantuan', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.fetch({page: this.state.pagination.current_page});
        })
    }

    // Salurkan Bansos
    konfirmStatus = (data) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="info"
                    type="warning"
                    title={data.status === "Tersalurkan"? "Yakin Ingin Batal Salurkan Bantuan?" : "Yakin Ingin Salurkan Bantuan?"}
                    onCancel={this.hideAlert}
                    onConfirm={()=> this.gantiStatus(data)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    gantiStatus = (data) => {
        this.hideAlert();
        // console.log('Ganti Status')
        // console.log(data)

        let addData = {
            uuid: data.uuid,
            uuid_bansos_periode: data.uuid_bansos_periode,
            nik: data.nik,
            status: data.status === "Tersalurkan"? "Belum disalurkan" : "Tersalurkan",
        }

        // console.log('Data Simpan Adalah')
        // console.log(addData)

        let psn = "Ubah"; 
        let resstat = 200;
        let metode = "update";

        Post('bansos/periode/penerima/'+ metode,addData.uuid, addData, (res) => {
            this.forceUpdate();
            if (res.status === resstat)
            {
                this.setState({ show: true, loading: true, basicType:'success', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:'Berhasil '+psn+' Status' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:'Gagal '+psn+' Status' });
            }

            this.fetch({page: this.state.pagination.current_page});
        });
    }
    
    // End Salurkan Bansos

    bukaForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: true,
            },
        })
        this.forceUpdate();
    }

    tutupForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: false,
            },
            jenisForm: '',
            setPenduduk:null,
			detailPenduduk:[],
			showDataPenduduk: "none",
			detail: [],
        })
        this.forceUpdate();
    }

    tombolAksi = (cell, row) => {
        return (
            <>
                {/* <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} title='Edit Data' disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp; */}
                {/* <Button  size="xs" title='Hapus Data' color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>   */}
            </>
        )
    }

    tombolStatus = (cell, row) => {
        switch(row.status){
            case 'Belum disalurkan':
                return (
                        <Button size="sm" title='Salurkan Bansos' color="success" className="btn-icon" onClick={() => this.konfirmStatus(row)}>Salurkan Bantuan</Button>  
                    )
            case 'Tersalurkan':
                return (
                        <Button size="sm" title='Batal Salurkan Bansos' color="danger" className="btn-icon" onClick={() => this.konfirmStatus(row)}>Batalkan Penyaluran</Button>  
                    )
            default:
                // no default
                break;
        }
        return (
            <>

                <Button  size="xs" title='Hapus Data' color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>  
            </>
        )
    }

    componentDidMount() {
        this.fetch({page:null,where:[]});

        Get("penduduk/list", null, data => {
            // console.log('data pdd')
            // console.log(data)
            let frmPenduduk = [];
            data.results.forEach(dt => {
                frmPenduduk.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ 
              frmPenduduk,
            });
        });
    }

    tambahPenduduk = (e) => {
		if (this.state.setPenduduk) {
			detail = this.state.detail;
			// Get('penduduk/find', this.state.input['cari_nik3'], (data) => {
			Get('penduduk/find', this.state.setPenduduk.value, (data) => {
				if (data.results) {
					detail.push({
						nik: data.results.id,
						nama: data.results.nama,
                        tempat_lahir: data.results.tempat_lahir,
                        tanggal_lahir: data.results.tanggal_lahir,
                        pekerjaan: data.results.data_pekerjaan?data.results.data_pekerjaan.nama:''
					});
					this.setState({
						detail,
						setPenduduk: null,
                        showDataPenduduk:'none',
					});

					this.forceUpdate();
				}
			});
		} else {
            this.setState({ show: true, basicType:'danger', basicTitle:'Data Penerima Bantuan Sosial', pesanAlert:"Pilih Penduduk Terlebih Dahulu!" });
		}
	};

    handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "penduduk") {
				let newArray = this.state.frmPenduduk;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmPenduduk:newArray,
				})
			}
		}
	};

    changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case "penduduk":
					this.setState({ setPenduduk: e });
                        Get('penduduk/find', e.value, (data) => {
						    // console.log('data')
						    // console.log(data)
                            if(data.results){
                                this.setState({ 
                                    detailPenduduk: {
                                        nama: data.results.nama,
                                        tempat_lahir: data.results.tempat_lahir,
                                        tanggal_lahir: data.results.tanggal_lahir,
                                        pekerjaan: data.results.data_pekerjaan?data.results.data_pekerjaan.nama:'',
                                    },
                                    showDataPenduduk: 'block', 
                                });
                            }
                            this.forceUpdate();
			            });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case "penduduk":
					this.setState({ setPenduduk: null });
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

    hapus = (i) => {
        this.state.detail.splice(i, 1);
        this.forceUpdate();
    };

    render() {
        // console.log('Data Render')
        // console.log(this.state.pagination.current_page)
        // console.log(parseInt(this.state.pagination.current_page))
        // console.log(this.state.hal)
        var data = this.state.data;
        const frmDef = {
            uuid: '',
            uuid_bansos: '',
            periode: '',
            bantuan_nominal: '',
            bantuan_barang: [{isi:[""]}],
        }   
        // const { SearchBar } = Search;
        const columns = [
            {
                dataField: "no",
                text: "No",
                headerAlign: 'center',
                align: 'center',
								style: {
									width: '80px'
								},
                formatter: (key, obj, index) =>{          
                    let current_pagenum = this.state.pagination.current_page;
                    let total_records_per_page = this.state.pagination.per_page;
                    let row_index = (index+1);
                    let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                    return serial_num; 
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15px', textAlign: 'left' };
                }
            },
            {
                dataField: 'nik',
                text: 'NIK', 
								headerAlign: 'center',
                align: 'center',
                sort: true
            },
            {
                dataField: 'data_penduduk.nama',
                text: 'Nama', 
								headerAlign: 'center',
                align: 'center',
                sort: true,
            },
            {
                dataField: 'status',
                text: 'Status', 
								headerAlign: 'center',
                align: 'center',
                sort: true,
            },
            {
                dataField: 'status',
                text: 'Penyaluran', 
								headerAlign: 'center',
                align: 'center',
                sort: true,
                formatter: this.tombolStatus,
            },
            // {
            //     dataField: 'aksi',
            //     text: 'Aksi',
            //     headerAlign: 'center',
            //     align: 'center',
						// 		style: {
						// 			width: '100px'
						// 		},
            //     formatter: this.tombolAksi,
            //     headerStyle: (colum, colIndex) => {
            //         return { width: '80px', };
            //     }
            // },
        ];

		let no = 0;
        const dataPendudukAll = this.state.detail.map((dt, i) => {
			no++;
			return (
				<tr key={i}>
					<td>{no}</td>
					<td>{dt.nik}</td>
					<td>{dt.nama}</td>
					<td>
						{dt.tempat_lahir}, {dt.tanggal_lahir}
					</td>
					<td>{dt.pekerjaan}</td>
					<td>
						<Button size="xs" onClick={() => this.hapus(i)} color="danger" className="btn-icon">
							<i className="fa fa-trash" />
						</Button>
					</td>
				</tr>
			);
		});

        return (
            <div>
                {/* <Breadcrumb title="Data RT" parent="Admin"/> */}
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                            {this.state.loading ? (
                                <div className="row">
                                    <div className="col-sm-12" align="center">
                                        <img 
                                        alt="loading"
                                        src={require("../../assets/images/tes-loading.gif")} 
                                        style={{
                                            // width: "100%",
                                            borderRadius: "10px",
                                            width: '100px'
                                        }}/> 
                                    </div>
                                </div>
                            ) : 
                            <>
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Button  size="sm" color="success" className="btn-square" onClick={()=> {
                                                this.setState({
                                                    judul:"Tambah Data Bantuan Sosial", 
                                                    dt:frmDef, 
                                                    jenisForm: 'create',
                                                });
                                                this.bukaForm();}}>
                                                Tambah Data
                                            </Button>   
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>                                   
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-11 text-right">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cariTable"
                                                placeholder="Cari Data berdasarkan Nama Penduduk"
                                                onKeyPress={this.cariData}
                                            />                                 
                                        </div>
                                        <div className="col-sm-1 text-left">
                                            <Button  size="sm" color="success" className="btn-icon" title='Cari Data'><i className="fa fa-search"></i></Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body datatable-react">
																	{this.state.loading ? (
																		<div className="row">
																			<div className="col-sm-12" align="center">
																				<img
																					alt="loading"
																					src={require("../../assets/images/tes-loading.gif")}
																					style={{
																						borderRadius: "10px",
																						width: '100px'
																					}} />
																			</div>
																		</div>
																	) : data.length > 0 ? (
																		<>
                                    <BootstrapTable
                                        keyField="id"
                                        data={ data }
                                        columns={ columns }
                                        />
                                    <div className="pull-right text-white">
                                        {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir}
                                    </div>
																		</>
																	) : (
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	)}
                                </div>
                            </>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false} styles={{ modal: { width: '60%' } }}>
                    <Form className="theme-form" onSubmit={this.simpan}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.judul}</h5>
                        </div>
                        <div className="modal-body">
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                    Penduduk
                                </Label>
                                <div className="col-sm-9">
                                    <Select
										inputId="setPenduduk"
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue(e, "penduduk");
										}}
										defaultValue={this.state.setPenduduk}
										value={this.state.setPenduduk}
										name="NIK"
										options={this.state.frmPenduduk}
										placeholder="Pilih Penduduk"
										onInputChange={e => this.handleInputChangeNIK(e, "penduduk")}
										isClearable
									/>
                                </div>
                            </FormGroup>
                            <div style={{ display: this.state.showDataPenduduk }}>
								<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.detailPenduduk.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
                                </FormGroup>
                                <FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.detailPenduduk.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
                                </FormGroup>
                                <FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.detailPenduduk.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
								</FormGroup>
                                <FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Pekerjaan
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="pekerjaan"
										type="text"
										placeholder="Pekerjaan"
										value={this.state.detailPenduduk.pekerjaan}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
								</FormGroup>
                            </div>

                            <FormGroup className="row">
                                <Col sm="12">
                                    <button type="button" className="btn btn-success" onClick={this.tambahPenduduk}>
                                        Tambah
                                    </button>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row">
                                <Col sm="12">
                                    <table className="table table-bordered table-stripes table-hovered">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>NIK</th>
                                                <th>Nama</th>
                                                <th>Tempat/Tanggal Lahir</th>
                                                <th>Pekerjaan</th>
                                                <th>
                                                    <i className="fa fa-trash" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{dataPendudukAll}</tbody>
                                    </table>
                                </Col>
                            </FormGroup>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
                            <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}


export default BasicTable;
