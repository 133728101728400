import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL,{ Post, Delete, Get, Nama_DESA, JENIS_DESA } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import Datetime from 'react-datetime';

import ReactSelect from 'react-select';

const dateFormat = require('dateformat');

const frmData = {
	jenis_surat: "",
	keterangan: "",
	nomor: "",
	pengirim_penerima: "",
	tanggal_penerimaan_pengiriman: "",
	tanggal_surat: "",
	uraian: "",
	uuid: "",
}

class MenuA7 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dt: frmData,
			judul: 'Tambah Agenda Desa',
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			jenis_surat: null,
			tanggal_surat: new Date(),
			tanggal_penerimaan_pengiriman: new Date(),
			loading: false,
			loadingPreview: false,
			detailPreview: false,
			detailData: null,
			// Pagination
			pagination: [],
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			// Alert
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// Cari Data
			textCari: '',
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}
		Post("agenda-desa" + link, null, {
			q: this.state.textCari
		}, dtkat => {

			if (dtkat.data.results.data) {
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
	};

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();
		
		this.setState({ loadingPreview: true })

		if(this.state.jenis_surat){
			let bodyData = {
				jenis_surat: this.state.jenis_surat.value,
				keterangan: document.getElementById("keterangan").value,
				nomor: document.getElementById("nomor").value,
				pengirim_penerima: document.getElementById("pengirim_penerima").value,
				tanggal_penerimaan_pengiriman: dateFormat(this.state.tanggal_surat, 'yyyy-mm-dd HH:MM:ss'),
				tanggal_surat: dateFormat(this.state.tanggal_penerimaan_pengiriman, 'yyyy-mm-dd HH:MM:ss'),
				uraian: document.getElementById("uraian").value,
				uuid: document.getElementById("uuid").value,
			}
	
			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyData.uuid === "") {
				psn = "tambah";
				resstat = 201;
				bodyData.uuid = null;
			} else {
				psn = "ubah";
				resstat = 200;
				metode = "update";
			}
	
			Post('agenda-desa/' + metode, bodyData.uuid, bodyData, res => {
				if (res.status === resstat) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "success",
						basicTitle: "Data Agenda",
						pesanAlert: "Berhasil " + psn + " Data",
						dt: frmData,
						status: {
							...this.state.status,
							form: false
						}
					});
				} else {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Agenda",
						pesanAlert: "Gagal " + psn + " Data"
					});
				}

				this.componentDidMount();
			})
		}
		else{
			this.setState({
				loadingPreview: false,
				show: true,
				basicType: "danger",
				basicTitle: "Data Agenda",
				pesanAlert: "Data Harus Lengkap!"
			});
		}
	});
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		
		Delete('agenda-desa/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Agenda',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Agenda',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Agenda",
			editMode: true
		})

		if (data) {
			this.setState({
				dt: {
					jenis_surat: data.jenis_surat,
					keterangan: data.keterangan,
					nomor: data.nomor,
					pengirim_penerima: data.pengirim_penerima,
					tanggal_penerimaan_pengiriman: data.tanggal_penerimaan_pengiriman,
					tanggal_surat: data.tanggal_surat,
					uraian: data.uraian,
					uuid: data.uuid,
				},	
				tanggal_penerimaan_pengiriman: new Date(data.tanggal_penerimaan_pengiriman),
				tanggal_surat: new Date(data.tanggal_surat),
				jenis_surat: {
					value: data.jenis_surat, label: (
						data.jenis_surat == 'masuk' ? 'Surat Masuk'
								: 'Surat Keluar'
					)
				},
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	componentDidMount() {
		this.fetch()
	}

	
	gantiTgl = (date, fld) => {
		switch (fld) {
		  case "tanggal_surat":
			if (typeof date._isValid !== 'undefined') {
			  	let tgl = date.format('YYYY-MM-DD HH:mm:ss');
				this.setState({ tanggal_surat: new Date(tgl) });
			}
			break;
		  case "tanggal_penerimaan_pengiriman":
			if (typeof date._isValid !== 'undefined') {
			  	let tgl = date.format('YYYY-MM-DD HH:mm:ss');
				this.setState({ tanggal_penerimaan_pengiriman: new Date(tgl) });
			}
			break;
		  default:
			// no default
			break;
		}
	};

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	

  // DATA DOWNLOAD LAPORAN
	downloadLaporan = () => {
		let link = API_URL + 'agenda-desa/cetak';
		let title = 'Buku Agenda '+ JENIS_DESA +' '+ Nama_DESA
		fetch(link, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
			'Content-Type': 'application/pdf',
		},
		})
		.then((response) => response.blob())
		.then((blob) => {
		// Create blob link to download
		const url = window.URL.createObjectURL(
			new Blob([blob]),
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			`${title}.pdf`,
		);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();

		// Clean up and remove the link
		link.parentNode.removeChild(link);
		this.setState({
			loading: false,
		})
		});
		// ==============

		this.forceUpdate();
	};

	// END DATA DOWNLOAD LAPORAN

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'tanggal_penerimaan_pengiriman',
				headerAlign: 'center',
				align: 'center',
				text: 'Tanggal Penerimaan',
				sort: false
			},
			{
				dataField: 'jenis_surat',
				text: 'Jenis Surat',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: (cell, obj) => {
					if(obj.jenis_surat == 'masuk'){
					  	return ('Surat Masuk');
					}
					else{
						return ('Surat Keluar');
					}
				},
			},
			{
				dataField: 'nomor',
				text: 'No. Surat',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'tanggal_surat',
				text: 'Tanggal Terbit Surat',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'pengirim_penerima',
				text: 'Pengirim/Penerima',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="info"
								className="btn-icon mr-2"
								onClick={() => { 
									this.setState({
										detailPreview: true,
										detailData: row
									})
								}}
							>
								<i className="fa fa-eye"></i>
							</Button>{" "}
							<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
							
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Peraturan Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													this.setState({
														loading: true,
													});
													this.downloadLaporan();
												}}
												>
													Cetak
											</Button>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Agenda',
														dt: frmData,
														jenis_surat: null,
														tanggal_surat: new Date(),
														tanggal_penerimaan_pengiriman: new Date(),
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
										{/* <div className="col-sm-12 text-left">
											&nbsp;
										</div>
										<div className="col-sm-12 text-left">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Agenda',
														dt: frmData,
														jenis_surat: null,
														tanggal_surat: new Date(),
														tanggal_penerimaan_pengiriman: new Date(),
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div> */}
									</div>
								</div>

								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-md-12">
											{this.state.loading ? (
												<div className="row">
													<div className="col-sm-12" align="center">
														<img
															alt="loading"
															src={require("../../../assets/images/tes-loading.gif")}
															style={{
																borderRadius: "10px",
																width: '100px'
															}} />
													</div>
												</div>
											) : (
												<>
													<div className='row'>
														<div className="col-md-5 text-right">
															<input
																type="text"
																className="form-control"
																style={{ marginBottom: '15px' }}
																id="cariTable"
																placeholder="Cari berdasarkan jenis surat atau tanggal penerimaan/pengiriman surat"
																defaultValue={this.state.textCari}
																onChange={(e) => {
																	this.setState({ textCari: e.target.value })
																}}
																onKeyPress={this.cariData}
															/>
															<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
																<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
																	this.setState({
																		textCari: '',
																		loading: true
																	});
																	setTimeout(
																		function () {
																			this.fetch();
																		}.bind(this),
																		500
																	);
																	document.getElementById('cariTable').value = ''
																}} ></span>
															</div>
														</div>
														<div className="col-md-7" style={{ marginBottom: '15px' }}>
															<Button
																size="sm"
																color="info"
																className="btn-square"
																onClick={() => {
																	this.setState({
																		textCari: document.getElementById("cariTable").value,
																		loading: true
																	});
																	setTimeout(
																		function () {
																			this.fetch();
																		}.bind(this),
																		500
																	);
																}}
															>
																<i className="fa fa-search"></i>
															</Button>
														</div>
														{data.length > 0 ? (
															<>
																<div className="col-md-12">
																	<BootstrapTable
																		keyField="id"
																		data={data}
																		columns={columns}
																	/>
																	<div className="pull-right text-white">
																		{this.state.awal}
																		{this.state.sebelum}
																		{this.state.hal.map(dt => {
																			return dt;
																		})}
																		{this.state.setelah}
																		{this.state.akhir}
																	</div>
																</div>
															</>
														) : (
															<div className="col-md-12">
																<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
															</div>
														)}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				
				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail Data Agenda Desa</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Jenis Surat</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.jenis_surat === 'keluar'?'Surat Keluar':'Surat Masuk'}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor dan Tanggal Terbit Surat</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor + ' / ' + this.state.detailData.tanggal_surat}</span></td>
													</tr>
													<tr>
														<th width="280"> Nama {this.state.detailData.jenis_surat === 'keluar'?'Penerima':'Pengirim'}</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.pengirim_penerima}</span></td>
													</tr>
													<tr>
														<th width="280"> Tanggal {this.state.detailData.jenis_surat === 'keluar'?'Pengiriman':'Penerimaan'}</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tanggal_penerimaan_pengiriman}</span></td>
													</tr>
													<tr>
														<th width="280">Uraian</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.uraian}</span></td>
													</tr>
													<tr>
														<th width="280">Keterangan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.keterangan}</span></td>
													</tr>
												</tbody>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="nik">Jenis Surat</Label>
									<div data-testid="selectJenisSurat">
										<ReactSelect
											inputId="jenis_surat"
											classNamePrefix="select"
											onChange={e => {
												this.setState({ 
													jenis_surat: e
												 })
												// this.setState({ dt: {
												// 	...this.state.dt,
												// 	jenis: e
												// } })
											}}
											defaultValue={this.state.jenis_surat}
											value={this.state.jenis_surat}
											name="jenis_surat"
											options={[
												{
													value: 'masuk',
													label: 'Surat Masuk',
												},
												{
													value: 'keluar',
													label: 'Surat Keluar',
												},
											]}
											placeholder="Pilih Jenis Surat"
										/>
									</div>
								</Col>
							</FormGroup>

							{this.state.jenis_surat ?
								<>
									<FormGroup className="row">
										<Col sm="12" className="mb-2">
										<Label htmlFor="validationCustom05">Nomor surat</Label>
											<input
												className="form-control"
												id="nomor"
												type="text"
												placeholder="Masukkan nomor surat"
												defaultValue={this.state.dt.nomor}
												onChange={(e) => {
													this.setState({
														dt: {
															...this.state.dt,
															nomor: e.target.value
														}
													})
												}}
												required
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Col sm="12" className="mb-2">
											<Label htmlFor="validationCustom05">Tanggal surat</Label>
											<Datetime
												inputProps={{ 
													"data-testid": "tanggal_surat", 
													placeholder: 'Masukkan Tanggal Surat', 
													required: true
												}}
												inputId="tanggal_surat"
												name="tanggal_surat"
												locale="id-ID"
												timeFormat={false}
												dateFormat="DD/MM/yyyy"
												// input={true}
												closeOnTab={true}
												closeOnSelect={true}
												initialValue={this.state.tanggal_surat}
												value={this.state.tanggal_surat}
												onChange={e => {
													this.gantiTgl(e, 'tanggal_surat')
												}}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Col sm="12" className="mb-2">
										<Label htmlFor="inputEmail3">
											{this.state.jenis_surat && this.state.jenis_surat.value == 'masuk'? 'Pengirim':'Penerima'}
										</Label>
											<input
												className="form-control"
												id="pengirim_penerima"
												type="text"
												placeholder={ this.state.jenis_surat && this.state.jenis_surat.value == 'masuk'? 'Masukkan nama Pengirim':'Masukkan nama Penerima'}
												defaultValue={this.state.dt.pengirim_penerima}
												required
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Col sm="12" className="mb-2">
											<Label htmlFor="inputEmail3">
											{this.state.jenis_surat && this.state.jenis_surat.value == 'masuk'? 'Tanggal Penerimaan':'Tanggal Pengiriman'}
											</Label>
											<Datetime
													inputProps={{ 
														"data-testid": "tanggal_penerimaan_pengiriman", 
														placeholder: 'Masukkan Tanggal Pengiriman/Penerimaan', 
														required: true
													}}
													inputId="tanggal_penerimaan_pengiriman"
													name="tanggal_penerimaan_pengiriman"
													locale="id-ID"
													timeFormat={false}
													dateFormat="DD/MM/yyyy"
													// input={true}
													closeOnTab={true}
													closeOnSelect={true}
													initialValue={this.state.tanggal_penerimaan_pengiriman}
													value={this.state.tanggal_penerimaan_pengiriman}
													onChange={e => {
														this.gantiTgl(e, 'tanggal_penerimaan_pengiriman')
													}}
												/>
										</Col>
									</FormGroup>
								</>
							:
								<></>
							}
							
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
								<Label htmlFor="inputEmail3">
									Uraian
								</Label>
									<textarea className='form-control' name="uraian" id="uraian" cols="30" defaultValue={this.state.dt.uraian} rows="3" required />
								</Col>
							</FormGroup>
							
							<FormGroup className="row">
								<Col sm="12" className="mb-2">
								<Label htmlFor="inputEmail3">
									Keterangan
								</Label>
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" defaultValue={this.state.dt.keterangan} rows="3" />
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuA7;
