import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL, { Post, Delete, Get, JENIS_DESA, ID_DESA, } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import Datetime from 'react-datetime';

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ReactSelect from 'react-select';

let DefaultIcon = L.icon({
	iconSize: [25, 41],
	iconAnchor: [10, 41],
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const currentDate = new Date();
Date.prototype.addDays = function (days) {
	var date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}

const frmDex = {
	uuid: '',
	id_jenis: "",
	nik: "",
	nama: "",
	agama: "",
	tempat_lahir: "",
	tanggal_lahir: null,
	jk: "",
	pendidikan_terakhir: null,
	status_perkawinan: null,
	alamat: "",
	keterangan: "",

	kewarganegaraan: null,

	datang_dari: "",
	tanggal_datang: null,
	pindah_ke: "",
	tanggal_pindah: null,
	meninggal: "",
	tanggal_meninggal: null,
}
const dateFormat = require('dateformat');

const optionKewarganegaraan = {
	WNI: "Warga Negara Indonesia ( WNI )",
	WNA: "Warga Negara Asing ( WNA )"
}
const optionDapatMembaca = {
	L: "Latin",
	D: "Daerah",
	A: "Arab",
	AL: "Arab dan Latin",
	ALD: "Arab, Latin dan Daerah",
}

class MenuB2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			listPenduduk: [],
			tmplistPenduduk: {},
			detailPenduduk: null,
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			loading: false,
			loadingPreview: false,
			imagePreview: null,
			cetakPreview: false,
			detailPreview: false,
			detailData: null,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDex,
			judul: 'Tambah Penduduk ' + JENIS_DESA,
			optionKewarganegaraan: optionKewarganegaraan,
			optionDapatMembaca: optionDapatMembaca,
			textCari: '',
			filterCetak: new Date().getFullYear(),
			filterCetak2: null,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			editMode: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}

		// if (this.state.textCari === '') {
		// 	Get("penduduk" + link, null, dtkat => {
		// 		console.log("dtkat", dtkat);

		// 		if (dtkat.results) {
		// 			this.setState({
		// 				data: dtkat.results.data,
		// 				pagination: dtkat.results,
		// 				loading: false
		// 			})
		// 			this.pagination(dtkat.results);
		// 		}

		// 	});
		// } else { // Jika pencarian
		Post("buku-mutasi-penduduk" + link, null, {
			q: this.state.textCari
		}, dtkat => {
			console.log(dtkat);
			if (dtkat.data.results) {
				console.log('masuk');
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
		// }

	};

	handleCetak = ((e) => {
		e.preventDefault()

		if (this.state.filterCetak && this.state.filterCetak2) {
			this.setState({ loading: false, cetakPreview: false })
			this.downloadSurat()
		}
	})

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();

		if (this.state.detailPenduduk && this.state.dt.kewarganegaraan && this.state.dt.datang_dari && this.state.dt.tanggal_datang) {
			this.setState({ loadingPreview: true })
			console.log(this.state.dt);

			let tanggal_datang = null
			let tanggal_pindah = null
			let tanggal_meninggal = null

			if (this.state.dt.tanggal_datang) {
				tanggal_datang = dateFormat(new Date(this.state.dt.tanggal_datang), 'yyyy-mm-dd')
			}
			if (this.state.dt.tanggal_pindah) {
				tanggal_pindah = dateFormat(new Date(this.state.dt.tanggal_pindah), 'yyyy-mm-dd')
			}
			if (this.state.dt.tanggal_meninggal) {
				tanggal_meninggal = dateFormat(new Date(this.state.dt.tanggal_meninggal), 'yyyy-mm-dd')
			}

			let bodyData = this.state.dt
			bodyData = {
				...bodyData,
				tanggal_lahir: dateFormat(new Date(bodyData.tanggal_lahir), 'yyyy-mm-dd'),
				kewarganegaraan: bodyData.kewarganegaraan.value,
				tanggal_datang,
				tanggal_pindah,
				tanggal_meninggal,
			}
			let psn = "ubah";
			let resstat = 201;
			let metode = "create";
			if (bodyData.id === "") {
				psn = "tambah";
				resstat = 201;
				bodyData.id = null;
			} else {
				psn = "ubah";
				resstat = 200;
				metode = "update";
			}

			console.log(bodyData);

			Post('buku-mutasi-penduduk/' + metode, bodyData.id, bodyData, res => {
				// console.log(res);
				// console.log(resstat);
				if (res.status == 200 || res.status == 201) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "success",
						basicTitle: "Data Penduduk " + JENIS_DESA,
						pesanAlert: "Berhasil " + psn + " Data"
					});

					document.getElementById('form-data').reset()
					this.fetch();
					this.setState({
						dt: frmDex,
						status: {
							...this.state.status,
							form: false
						}
					})
				} else {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Penduduk " + JENIS_DESA,
						pesanAlert: "Gagal " + psn + " Data"
					});
				}
			})

		} else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: "Data Mutasi Penduduk " + JENIS_DESA,
				pesanAlert: "Isi data kewarganegaraan"
			});
		}

	});
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status: {
				...this.state.status,
				form: false,
			},
		})
		// document.getElementById('form-data').reset()
		this.forceUpdate();
	};



	ubahData = data => {
		// console.log(data);
		this.setState({
			judul: "Ubah Data Penduduk " + JENIS_DESA,
			editMode: true
		})

		if (data) {
			let extra = {
				dapat_membaca: '',
				kewarganegaraan: null,
				// keterangan: '',
			}

			let extra2 = {
				datang_dari: "",
				tanggal_datang: null,
				pindah_ke: "",
				tanggal_pindah: null,
				meninggal: "",
				tanggal_meninggal: null,
				keterangan: '',
			}

			if (data.data_penduduk_detail) {
				extra = {
					dapat_membaca: data.data_penduduk_detail.dapat_membaca,
					kewarganegaraan: {
						value: data.data_penduduk_detail.kewarganegaraan,
						label: optionKewarganegaraan[data.data_penduduk_detail.kewarganegaraan],
					},
				}
			}

			if (data.data_penduduk_mutasi) {
				extra2 = {
					datang_dari: data.data_penduduk_mutasi.datang_dari,
					pindah_ke: data.data_penduduk_mutasi.pindah_ke,
					keterangan: data.data_penduduk_mutasi.keterangan,

					tanggal_datang: data.data_penduduk_mutasi.tanggal_datang ? new Date(data.data_penduduk_mutasi.tanggal_datang) : null,
					tanggal_pindah: data.data_penduduk_mutasi.tanggal_pindah ? new Date(data.data_penduduk_mutasi.tanggal_pindah) : null,
					tanggal_meninggal: data.data_penduduk_mutasi.tanggal_meninggal ? new Date(data.data_penduduk_mutasi.tanggal_meninggal) : null,
				}
			}

			this.setState({
				// tmpImages: [],
				dt: {
					...this.state.dt,
					id: data.id,
					id_kk: data.id_kk,
					nama: data.nama,
					alamat: data.alamat,
					id_keldes: data.id_keldes,
					tempat_lahir: data.tempat_lahir,
					tanggal_lahir: data.tanggal_lahir,
					jk: data.jk,
					status_kehidupan: data.status_kehidupan,
					status_perkawinan: data.status_perkawinan,
					shdk: data.shdk,
					agama: data.agama,
					pendidikan_terakhir: data.pendidikan_terakhir,
					pekerjaan: data.pekerjaan,
					nama_ibu_kandung: data.nama_ibu_kandung,
					status_penduduk: data.status_penduduk,
					nama_ayah_kandung: data.nama_ayah_kandung,

					dapat_membaca: extra.dapat_membaca,
					kewarganegaraan: extra.kewarganegaraan,

					datang_dari: extra2.datang_dari,
					pindah_ke: extra2.pindah_ke,
					keterangan: extra2.keterangan,
					tanggal_datang: extra2.tanggal_datang,
					tanggal_pindah: extra2.tanggal_pindah,
					tanggal_meninggal: extra2.tanggal_meninggal,
				},
				detailPenduduk: {
					value: data.id,
					label: `${data.id} | ${data.nama}`,
				},
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	cariData = (e) => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	};

	changeInputText = (e, fld) => {
		this.setState({
			dt: {
				...this.state.dt,
				[fld]: e.target.value
			}
		})
	};

	gantiTgl = (date, fld) => {
		let value = null
		if (typeof date._isValid !== 'undefined') {
			value = new Date(date.format('YYYY-MM-DD'))
			// this.setState({
			// 	dt: {
			// 		...this.state.dt,
			// 		[fld]: new Date(date.format('YYYY-MM-DD'))
			// 	}
			// })
		}
		this.setState({
			dt: {
				...this.state.dt,
				[fld]: value
			}
		})
	}

	downloadSurat = () => {
		let token = sessionStorage.getItem("access_token")
			? sessionStorage.getItem("access_token")
			: "public";

		fetch(API_URL + 'buku-mutasi-penduduk/cetak?bulan=' + this.state.filterCetak2.value + "&tahun=" + this.state.filterCetak, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer ' + token,
			},
		})
			// .then((response) => response.blob())
			.then((response) => {
				if (response.status == 200) {
					return response.blob()
				}
				this.setState({
					loading: false,
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Penduduk ' + JENIS_DESA,
					pesanAlert: 'Gagal cetak surat'
				});
				throw new Error('Something went wrong');
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`Buku Mutasi Penduduk ${JENIS_DESA}.pdf`,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
				this.setState({
					loading: false,
				})
			})
			.catch((e) => {
				// console.log(e);
			})
			;
	}
	handleInputChangeNIK = (e, fld) => {
		console.log(e);
		Post("buku-mutasi-penduduk", null, { q: e }, datanik => {
			let frmNik = [];
			let tmplistPenduduk = {}
			datanik.data.results.data.forEach(dt => {
				frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				tmplistPenduduk = {
					...tmplistPenduduk,
					[dt.id]: dt
				}
				this.setState({
					frmNik,
					listPenduduk: frmNik,
					tmplistPenduduk,
				})
			});

		});
	};
	componentDidMount() {
		this.fetch()
		Get("buku-mutasi-penduduk", null, dtkat => {
			console.log(dtkat);
			if (dtkat.results.data) {
				// this.setState({ listPerangkat: dtkat.results })
				let frm = [];
				let tmplistPenduduk = {}
				dtkat.results.data.forEach(dt => {
					// if (dt.data_penduduk) {
					frm.push({ value: dt.id, label: `${dt.id} | ${dt.nama}` });
					tmplistPenduduk = {
						...tmplistPenduduk,
						[dt.id]: dt
					}
					// }
				});
				console.log(frm);
				this.setState({ listPenduduk: frm, frmNik: frm, tmplistPenduduk });
			}
		});
	}

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'nama',
				text: 'NAMA',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'id',
				text: 'NIK',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			// {
			// 	dataField: 'data_penduduk_mutasi.datang_dari',
			// 	text: 'DATANG DARI',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// },
			{
				dataField: 'data_penduduk_mutasi.tanggal_datang',
				text: 'TANGGAL DATANG',
				headerAlign: 'center',
				align: 'center',
				sort: false,
			},
			// {
			// 	dataField: 'data_penduduk_mutasi.pindah_ke',
			// 	text: 'PINDAH KE',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// },
			{
				dataField: 'data_penduduk_mutasi.tanggal_pindah',
				text: 'TANGGAL PINDAH',
				headerAlign: 'center',
				align: 'center',
				sort: false,
			},
			// {
			// 	dataField: 'data_penduduk_detail.kewarganegaraan',
			// 	text: 'KEWARGANEGARAAN',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// 	formatter: function (cell, row) {
			// 		let text = ''
			// 		if (row.data_penduduk_detail) {
			// 			text = optionKewarganegaraan[row.data_penduduk_detail.kewarganegaraan]
			// 		}

			// 		return text
			// 	}
			// },
			// {
			// 	dataField: 'data_penduduk_detail.keterangan',
			// 	text: 'Ket.',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div style={{ minWidth: "61px" }}>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="info"
								className="btn-icon mr-2"
								onClick={() => {
									this.setState({
										detailPreview: true,
										detailData: row
									})
								}}
							>
								<i className="fa fa-eye"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Penduduk Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													// this.setState({ loading: true })
													// this.downloadSurat()
													this.setState({ cetakPreview: true })
												}}
											>
												Cetak
											</Button>{" "}
											{/* <Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Penduduk ' + JENIS_DESA,
														dt: frmDex,
														tmpImages: ['kosong'],
														editMode: false,
														detailPenduduk: null,
														currentLocation: this.state.curStaticLocation
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button> */}
										</div>
									</div>
								</div>

								<div className="card-body datatable-react custom-font-sm">
									{/* <div className="row">
										<div className="col-md-12"> */}
									{this.state.loading ? (
										<div className="row">
											<div className="col-sm-12" align="center">
												<img
													alt="loading"
													src={require("../../../assets/images/tes-loading.gif")}
													style={{
														borderRadius: "10px",
														width: '100px'
													}} />
											</div>
										</div>
									) : (
										<div className='row'>
											<div className="col-md-5 text-right">
												<input
													type="text"
													className="form-control"
													style={{ marginBottom: '15px' }}
													id="cariTable"
													placeholder={"Cari berdasarkan nama, nik, atau kk"}
													defaultValue={this.state.textCari}
													onChange={(e) => {
														this.setState({ textCari: e.target.value })
													}}
													onKeyPress={this.cariData}
												/>
												<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
													<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
														this.setState({
															textCari: '',
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
														document.getElementById('cariTable').value = ''
													}} ></span>
												</div>
											</div>
											<div className="col-md-7" style={{ marginBottom: '15px' }}>
												<Button
													size="sm"
													color="info"
													className="btn-square"
													onClick={() => {
														this.setState({
															textCari: document.getElementById("cariTable").value,
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
													}}
												>
													<i className="fa fa-search"></i>
												</Button>
											</div>
											{data.length > 0 ? (
												<>
													<div className="col-md-12">
														<BootstrapTable
															keyField="id"
															data={data}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal}
															{this.state.sebelum}
															{this.state.hal.map(dt => {
																return dt;
															})}
															{this.state.setelah}
															{this.state.akhir}
														</div>
													</div>
												</>
											) : (
												<div className="col-md-12">
													<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
												</div>
											)}
										</div>
									)}
									{/* </div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.cetakPreview ? true : false}
					onClose={() => {
						this.setState({ cetakPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '40%', maxWidth: 'unset' } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.handleCetak(e) }}>
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Cetak Data Mutasi Penduduk {JENIS_DESA}</h5>
						</div>
						<div className="modal-body">
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Bulan</Label>
									<input
										className="form-control"
										id="tahun"
										type="number"
										min={2010}
										placeholder="Masukkan tahun"
										defaultValue={this.state.filterCetak}
										onChange={(e) => {
											this.setState({
												filterCetak: e.target.value
											})
										}}
										required
									/>
								</Col>
							</div>
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Bulan</Label>
									<ReactSelect
										classNamePrefix="select"
										// isClearable
										onChange={e => {
											this.setState({
												filterCetak2: e
											})
										}}
										defaultValue={this.state.filterCetak2}
										value={this.state.filterCetak2}
										options={[
											{
												value: "1",
												label: "Januari"
											},
											{
												value: "2",
												label: "Februari"
											},
											{
												value: "3",
												label: "Maret"
											},
											{
												value: "4",
												label: "April"
											},
											{
												value: "5",
												label: "Mei"
											},
											{
												value: "6",
												label: "Juni"
											},
											{
												value: "7",
												label: "Juli"
											},
											{
												value: "8",
												label: "Agustus"
											},
											{
												value: "9",
												label: "September"
											},
											{
												value: "10",
												label: "Oktober"
											},
											{
												value: "11",
												label: "November"
											},
											{
												value: "12",
												label: "Desember"
											},
										]}
										placeholder={"Pilih Bulan"}
									/>
								</Col>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								onClick={() => {
									this.setState({ cetakPreview: false })
								}}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success">
								Cetak
							</button>
						</div>
					</Form>
				</Modal>

				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Detail Data Penduduk {JENIS_DESA}</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Nama</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nama}</span></td>
													</tr>
													<tr>
														<th width="280">NIK</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.id}</span></td>
													</tr>
													<tr>
														<th width="280">No. KK</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.id_kk}</span></td>
													</tr>
													<tr>
														<th width="280">Jenis Kelamin</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.jk}</span></td>
													</tr>
													<tr>
														<th width="280">Tempat / Tanggal Lahir</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tempat_lahir + ", " + this.state.detailData.tanggal_lahir}</span></td>
													</tr>
													<tr>
														<th width="280">Agama</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_agama.nama}</span></td>
													</tr>
													<tr>
														<th width="280">Alamat</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.alamat}</span></td>
													</tr>
													<tr>
														<th width="280">Pendidikan Terakhir</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_pendidikan_terakhir.nama}</span></td>
													</tr>
													<tr>
														<th width="280">Status Perkawinan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.status_perkawinan}</span></td>
													</tr>
													<tr>
														<th width="280">Status Penduduk</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.status_penduduk}</span></td>
													</tr>

													<tr>
														<th width="280">Dapat Membaca</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_detail ? optionDapatMembaca[this.state.detailData.data_penduduk_detail.dapat_membaca] : ''}</span></td>
													</tr>
													<tr>
														<th width="280">Kewarganegaraan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_detail ? optionKewarganegaraan[this.state.detailData.data_penduduk_detail.kewarganegaraan] : ''}</span></td>
													</tr>
													<tr>
														<th width="280">Keterangan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.keterangan : ''}</span></td>
													</tr>

													<tr>
														<th colSpan={3}>
															<hr className='m-1' />
														</th>
													</tr>

													<tr>
														<th><b>Penambahan</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th width="280">Datang dari</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.datang_dari : ''}</span></td>
													</tr>
													<tr>
														<th width="280">Tanggal datang</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.tanggal_datang : ''}</span></td>
													</tr>

													<tr>
														<th colSpan={3}>
															<hr className='m-1' />
														</th>
													</tr>

													<tr>
														<th><b>Pengurangan</b></th>
														<th></th>
														<th></th>
													</tr>
													<tr>
														<th width="280">Pindah ke</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.pindah_ke : ''}</span></td>
													</tr>
													<tr>
														<th width="280">Tanggal pindah</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.tanggal_pindah : ''}</span></td>
													</tr>
													<tr>
														<th width="280">Tempat meninggal</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.meninggal : ''}</span></td>
													</tr>
													<tr>
														<th width="280">Tanggal meninggal</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.data_penduduk_mutasi ? this.state.detailData.data_penduduk_mutasi.tanggal_meninggal : ''}</span></td>
													</tr>
												</tbody>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}

							{/* <div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Penduduk {JENIS_DESA}</Label>
									<ReactSelect
										inputId="detail_penduduk"
										classNamePrefix="select"
										isClearable
										onChange={e => {
											this.setState({ detailPenduduk: e })
											if (e) {
												const pendudukSelected = this.state.tmplistPenduduk[e.value]
												console.log(pendudukSelected);

												let kewarganegaraan = ''
												let ket = ''
												if (pendudukSelected.data_penduduk_detail) {
													kewarganegaraan = pendudukSelected.data_penduduk_detail.kewarganegaraan
													ket = pendudukSelected.data_penduduk_detail.ket
												}

												this.setState({
													dt: {
														...this.state.dt,
														id: pendudukSelected.id,
														id_kk: pendudukSelected.id_kk,
														nama: pendudukSelected.nama,
														alamat: pendudukSelected.alamat,
														id_keldes: pendudukSelected.id_keldes,
														tempat_lahir: pendudukSelected.tempat_lahir,
														tanggal_lahir: pendudukSelected.tanggal_lahir,
														jk: pendudukSelected.jk,
														status_perkawinan: pendudukSelected.status_perkawinan,
														shdk: pendudukSelected.shdk,
														agama: pendudukSelected.agama,
														pendidikan_terakhir: pendudukSelected.pendidikan_terakhir,
														pekerjaan: pendudukSelected.pekerjaan,
														nama_ibu_kandung: pendudukSelected.nama_ibu_kandung,
														status_penduduk: pendudukSelected.status_penduduk,
														nama_ayah_kandung: pendudukSelected.nama_ayah_kandung,
														status_kehidupan: pendudukSelected.status_kehidupan,
														kewarganegaraan,
														ket,
														
													}
												})
											}
										}}
										onInputChange={e => this.handleInputChangeNIK(e, 'nik')}
										defaultValue={this.state.detailPenduduk}
										value={this.state.detailPenduduk}
										options={this.state.listPenduduk}
										placeholder={"Pilih Penduduk " + JENIS_DESA}
									/>
								</Col>
							</div> */}

							<div style={{ display: this.state.detailPenduduk ? 'block' : 'none' }}>
								<div className="form-row">
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">NIK</Label>
										<input
											className="form-control placeholder-sm"
											id="id"
											type="text"
											value={this.state.dt.id}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan nama"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														id: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">No. KK</Label>
										<input
											className="form-control placeholder-sm"
											id="id_kk"
											type="text"
											value={this.state.dt.id_kk}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan No. KK"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														id_kk: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Nama</Label>
										<input
											className="form-control placeholder-sm"
											id="nama"
											type="text"
											value={this.state.dt.nama}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan nama"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														nama: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Alamat</Label>
										<input
											className="form-control placeholder-sm"
											id="alamat"
											type="text"
											value={this.state.dt.alamat}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Alamat"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														alamat: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									{/* <Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">ID Keldes</Label>
										<input
											className="form-control placeholder-sm"
											id="id_keldes"
											type="text"
											value={this.state.dt.id_keldes}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan ID Keldes"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														id_keldes: e.target.value
													}
												})
											}}
											required
										/>
									</Col> */}
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Tempat Lahir</Label>
										<input
											className="form-control placeholder-sm"
											id="tempat_lahir"
											type="text"
											value={this.state.dt.tempat_lahir}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Tempat Lahir"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														tempat_lahir: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Tanggal Lahir</Label>
										<input
											className="form-control placeholder-sm"
											id="tanggal_lahir"
											type="text"
											value={this.state.dt.tanggal_lahir}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Tanggal Lahir"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														tanggal_lahir: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Jenis Kelamin</Label>
										<input
											className="form-control placeholder-sm"
											id="jk"
											type="text"
											value={this.state.dt.jk}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Jenis Kelamin"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														jk: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Status Perkawinan</Label>
										<input
											className="form-control placeholder-sm"
											id="status_perkawinan"
											type="text"
											value={this.state.dt.status_perkawinan}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Status Perkawinan"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														status_perkawinan: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Nama Ibu Kandung</Label>
										<input
											className="form-control placeholder-sm"
											id="nama_ibu_kandung"
											type="text"
											value={this.state.dt.nama_ibu_kandung}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Nama Ibu Kandung"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														nama_ibu_kandung: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Nama Ayah Kandung</Label>
										<input
											className="form-control placeholder-sm"
											id="nama_ayah_kandung"
											type="text"
											value={this.state.dt.nama_ayah_kandung}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Nama Ayah Kandung"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														nama_ayah_kandung: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
									<Col sm="12 mb-2">
										<Label htmlFor="validationCustom05">Status Penduduk</Label>
										<input
											className="form-control placeholder-sm"
											id="status_penduduk"
											type="text"
											value={this.state.dt.status_penduduk}
											readOnly
											disabled
											style={{ backgroundColor: "#c9d1d8" }}
											placeholder="Masukkan Status Penduduk"
											onChange={(e) => {
												this.setState({
													dt: {
														...this.state.dt,
														status_penduduk: e.target.value
													}
												})
											}}
											required
										/>
									</Col>
								</div>
							</div>
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Kewarganegaraan</Label>
									<ReactSelect
										inputId="kewarganegaraan"
										classNamePrefix="select"
										// isClearable
										onChange={e => {
											this.setState({
												dt: {
													...this.state.dt,
													kewarganegaraan: e
												}
											})
										}}
										defaultValue={this.state.dt.kewarganegaraan}
										value={this.state.dt.kewarganegaraan}
										options={[
											{
												value: "WNI",
												label: "Warga Negara Indonesia ( WNI )"
											},
											{
												value: "WNA",
												label: "Warga Negara Asing ( WNA )"
											}
										]}
										placeholder={"Pilih Kewarganegaraan Penduduk "}
									/>
								</Col>
							</div>

							<hr />
							<div className="form-row">
								<Col sm="12">
									<Label htmlFor="validationCustom05"><b>Penambahan</b></Label>
								</Col>
							</div>
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Datang Dari</Label>
									<input
										data-testid="datang_dari"
										id="datang_dari"
										name="datang_dari"
										className="form-control"
										type="text"
										placeholder=""
										defaultValue={this.state.dt.datang_dari}
										value={this.state.dt.datang_dari}
										onChange={e => this.changeInputText(e, "datang_dari")}
										required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tanggal Datang</Label>
									<Datetime
										inputProps={{ "data-testid": "tanggal_datang", placeholder: 'Klik disini untuk memilih tanggal', required: true }}
										inputId="tanggal_datang"
										name="tanggal_datang"
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
										dateFormat="yyyy-MM-DD"
										initialValue={this.state.dt.tanggal_datang}
										value={this.state.dt.tanggal_datang}
										onChange={(e) => {
											this.gantiTgl(e, 'tanggal_datang');
										}}
									/>
								</Col>
							</div>
							<hr />

							<div className="form-row">
								<Col sm="12">
									<Label htmlFor="validationCustom05"><b>Pengurangan</b></Label>
								</Col>
							</div>
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Pindah Ke</Label>
									<input
										data-testid="pindah_ke"
										id="pindah_ke"
										name="pindah_ke"
										className="form-control"
										type="text"
										placeholder=""
										defaultValue={this.state.dt.pindah_ke}
										value={this.state.dt.pindah_ke}
										onChange={e => this.changeInputText(e, "pindah_ke")}
									// required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tanggal Pindah</Label>
									<Datetime
										inputProps={{ "data-testid": "tanggal_pindah", placeholder: 'Klik disini untuk memilih tanggal' }}
										inputId="tanggal_pindah"
										name="tanggal_pindah"
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
										dateFormat="yyyy-MM-DD"
										initialValue={this.state.dt.tanggal_pindah}
										value={this.state.dt.tanggal_pindah}
										onChange={(e) => {
											this.gantiTgl(e, 'tanggal_pindah');
										}}
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tempat Meninggal</Label>
									<input
										data-testid="meninggal"
										id="meninggal"
										name="meninggal"
										className="form-control"
										type="text"
										placeholder=""
										defaultValue={this.state.dt.meninggal}
										value={this.state.dt.meninggal}
										onChange={e => this.changeInputText(e, "meninggal")}
									// required
									/>
								</Col>
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tanggal Meninggal</Label>
									<Datetime
										inputProps={{ "data-testid": "tanggal_meninggal", placeholder: 'Klik disini untuk memilih tanggal' }}
										inputId="tanggal_meninggal"
										name="tanggal_meninggal"
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
										dateFormat="yyyy-MM-DD"
										initialValue={this.state.dt.tanggal_meninggal}
										value={this.state.dt.tanggal_meninggal}
										onChange={(e) => {
											this.gantiTgl(e, 'tanggal_meninggal');
										}}
									/>
								</Col>
							</div>
							<hr />

							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Keterangan</Label>
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder='Masukkan Keterangan Penduduk' defaultValue={this.state.dt.keterangan} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												keterangan: e.target.value
											}
										})
									}} rows="4" required />
								</Col>
							</div>

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuB2;
