import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";

// koneksi
import { Post, Get, Delete, Nama_DESA, JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, HariTanggal, DataToken } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import ModalSPT from "./modal/perintah-tugas";


const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  agama: "",
  jenis_kelamin: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  tujuan_pembuatan: "",
  lokasi: "",
  kk: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  tgl_mulai: "",
  tgl_selesai: "",
  waktu_mulai: "",
  waktu_selesai: "",
  tempat: "",
  hiburan: ""
};

const dateFormat = require('dateformat');

class MenuSPT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: [],
        dataPenduduk: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      tmplistPenduduk: null,
      frmNik: [],
      listPerangkat: [],
      dataKades: [],
      tmpListPerangkat: [],
      // List TTD
      accButtonPerangkatDesa: [],
      listPerangkatDesa: [],
      ttdSelected: [],
      // End List TTD
      jenisTtd: null,
      nikStat: null,
      loading: true,
      judul: "Tambah Surat Perintah Tugas",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio: false,
      tanggal_mulai: new Date(),
      tanggal_selesai: new Date(),
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: []
    };
  }

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: true,
      successAlert: false
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "&page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "&page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "&page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }
    Get("surat-internal?id_jenis=" + this.props.location.state.uuid + link, null, dtkat => {
      console.log(dtkat);
      if(dtkat.results){
        this.setState({ 
          data: dtkat.results.data, 
          loading: false,
          pagination: dtkat.results
        });
        this.pagination(dtkat.results);

        // For testing
        // if (this.props.getListSuratIzinKeramaian) {
        //   this.props.getListSuratIzinKeramaian(dtkat.results.data)
        // }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    // //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/grup/" + this.props.location.state.uuid,
        null,
        dataSuket,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.state.status.btnForm = true;
    this.forceUpdate();

    this.setState({
      loading: true,
    })

    let uuid = document.getElementById("uuid").value;
    //detail

    // //console.log(this.state.tanggal_mulai);
    let formDetail = [
      {
        nama_item: "tgl_mulai",
        isi_item: dateFormat(this.state.tanggal_mulai, 'yyyy-mm-dd HH:MM:ss')
      },
      {
        nama_item: "tgl_selesai",
        isi_item: dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd HH:MM:ss')
      },
      {
        nama_item: "tempat",
        isi_item: document.getElementById("tempat").value
      },
      {
        nama_item: "hiburan",
        isi_item: document.getElementById("hiburan").value
      }
    ];

    let addSkck = {
      id_jenis: this.props.location.state.uuid,

      // nik: document.getElementById("nik").value,
      nik_pelapor: this.state.pendStat.value,
      nik: this.state.pendStat.value,
      nama_pemohon: document.getElementById("nama_pemohon").value,
      tempat_lahir: document.getElementById("tempat_lahir").value,
      tanggal_lahir: document.getElementById("tanggal_lahir").value,
      pendidikan_terakhir: document.getElementById("pendidikan_terakhir").value,
      pekerjaan: document.getElementById("pekerjaan").value,
      agama: document.getElementById("agama").value,
      status_perkawinan: document.getElementById("status_perkawinan").value,
      alamat: document.getElementById("alamat").value,
      jenis_kelamin: document.getElementById("jenis_kelamin").value,
      kewarganegaraan: document.getElementById("kewarganegaraan").value,
      tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
      kk: document.getElementById("kk").value,

      detail: formDetail
    };

    //console.log("Data Simpan Izin Keramaian")
    //console.log(addSkck)
    //console.log(formDetail)

    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (uuid === "") {
      psn = "Tambah";
      resstat = 201;
      uuid = null;
    } else {
      psn = "Ubah";
      resstat = 200;
      metode = "update";
    }
    Post(
      "surat-keterangan/grup/" + metode + "/with-detail",
      uuid,
      addSkck,
      res => {
        // For testing
        if (this.props.resSubmit) {
          this.props.resSubmit(res.status)
        }

        this.tutupForm();
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            showBio: false,
            show: true,
            basicType: "success",
            basicTitle: "Data Permohonan Keramaian",
            successAlert: true,
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            showBio: false,
            show: true,
            basicType: "danger",
            basicTitle: "Data Permohonan Keramaian",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        // this.componentDidMount();
        this.fetch({ page: null, where: [] });
        this.setState({
          loading: false,
        })
      }
    );
  };
  // ========================================================================
  
  // ============================== Ubah Data ===============================
  ubahData = data => {
    this.setState({
      judul: "Ubah Surat Perintah Tugas",
    })
    console.log(data);

    if (data) {
      let detailSuratParsed = JSON.parse(data.detail_surat)
      console.log(detailSuratParsed);

			let ttdSelected = this.state.listPerangkatDesa.map((x) => x.uuid == detailSuratParsed.uuidTtd)

			let temp = this.state.listPerangkatDesa.map((x) => '0');

			let getTtdSelected = null
			ttdSelected.map((x, index) => {
				if (x) {
					temp[index] = '1'
					getTtdSelected = this.state.listPerangkatDesa[index]
				}
			})

      this.setState({
        dt: {
          // ...this.state.dt,
          uuid: data.uuid,
          nik: detailSuratParsed.nik,

          nama_pegawai: detailSuratParsed.nama_pegawai,
          pangkat_golongan: detailSuratParsed.pangkat_golongan,
          jabatan_instansi: detailSuratParsed.jabatan_instansi,
          keperluan: detailSuratParsed.keperluan,
          alat_transportasi: detailSuratParsed.alat_transportasi,
          tempat_berangkat: detailSuratParsed.tempat_berangkat,
          tempat_tujuan: detailSuratParsed.tempat_tujuan,
          lama_perjalanan: detailSuratParsed.lama_perjalanan,
          tanggal_berangkat: new Date(detailSuratParsed.tanggal_berangkat),
          tanggal_kembali: new Date(detailSuratParsed.tanggal_kembali),
          sumber_anggaran: detailSuratParsed.sumber_anggaran,

          status_ttd: data.status_ttd,
          jabatan_ttd: data.jabatan_ttd,
          nama_ttd: data.nama_ttd,
          jenis_ttd: data.jenis_ttd,
          nip_ttd: data.nip_ttd,
          gol_ttd: data.gol_ttd,
        },
        accButtonPerangkatDesa: temp,
				ttdSelected: getTtdSelected,
				jenisTtd: {value: data.jenis_ttd, label: data.jenis_ttd},
        status: {
          ...this.state.status,
          dataPenduduk: "block",
          form: true,
        },
        nikStat: {
          value: detailSuratParsed.nik,
          label: detailSuratParsed.nik + ' (' + detailSuratParsed.nama_pegawai + ')'
        },
      })
    }

    // Get("surat-keterangan/grup/find", id, data => {
    //   if (typeof data.results !== "undefined") {
    //     data.results.data_detail_suket.forEach(dt => {
    //       if (dt.nama_item === "tgl_mulai") {
    //         this.state.tanggal_mulai = new Date(dt.isi_item);
    //       }

    //       if (dt.nama_item === "tgl_selesai") {
    //         if (dt.isi_item !== null) {
    //           this.state.tanggal_selesai = new Date(dt.isi_item);
    //         }
    //       }
    //       if (dt.nama_item === "tempat") {
    //         this.state.dt.tempat = dt.isi_item;
    //       }
    //       if (dt.nama_item === "hiburan") {
    //         this.state.dt.hiburan = dt.isi_item;
    //       }
    //       this.forceUpdate();
    //     });

    //     this.state.dt.uuid = data.results.uuid;
    //     this.state.dt.nomor = data.results.nomor;
    //     this.state.dt.tujuan_pembuatan = data.results.tujuan_pembuatan;
    //     this.state.dt.lokasi = data.results.lokasi;
    //     this.state.dt.kk = data.results.kk;
    //     this.state.tanggal_surat = new Date(data.results.tanggal_surat);
    //     this.state.dt.nik = data.results.nik;
    //     this.state.pendStat = { 
    //       value: data.results.nik, 
    //       label: data.results.nik + ' (' + data.results.nama_pemohon + ')' 
    //     };
    //     this.state.dt.nama_pemohon = data.results.nama_pemohon;
    //     this.state.dt.tempat_lahir = data.results.tempat_lahir;
    //     this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
    //     this.state.dt.pekerjaan = data.results.pekerjaan;
    //     this.state.dt.pendidikan_terakhir = data.results.pendidikan_terakhir;
    //     this.state.dt.agama = data.results.agama;
    //     this.state.dt.jenis_kelamin = data.results.jenis_kelamin;
    //     this.state.dt.status_perkawinan = data.results.status_perkawinan;
    //     this.state.dt.alamat = data.results.alamat;
    //     this.state.showBio = true;
    //     this.state.status.btnForm = false;
    //     this.state.judul = "Ubah Permohonan Keramaian";
    //     this.state.status.form = true;
    //     this.forceUpdate();
    //     // this.state.status.btnAksi = false;
    //     // this.componentDidMount();
    //     // this.bukaForm();
    //   }
    // });
  };
  // ========================================================================

  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/grup/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.state.tanggal_mulai = new Date();
    this.state.tanggal_selesai = null;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.showBio = false;
    this.state.pendStat = [];
    this.state.pendStat2 = [];
    this.state.status.btnForm = true;
    this.state.formAcc = false;
    this.state.tableAcc = "none";
    this.state.accStat = [];
    this.state.formDisposisi = false;
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    let btnAksi = false;
    let btnCetak = true;

    if (row.status_surat.toLowerCase() === "draft") {
      btnAksi = true
    }

    return (
      <>
        {btnAksi ? (
          <>
            <Button
              size="xs"
              color="success"
              className="btn-icon"
              onClick={() => this.ubahData(row)}
              // disabled={btnAksi}
            >
              <i className="fa fa-pencil"></i>
            </Button>{" "}
          </>
        ) : ''}

        {btnCetak ? (
          <Button
            size="xs"
            title="Cetak Surat"
            color="info"
            className="btn-icon"
            onClick={(e) => {
              let linkDownload = localStorage.getItem('SET_API_URL') + 'cetak-surat/perintah-tugas/' + row.uuid +
              '?status_barcode=tidak'

              this.setState({ loading: true })

              this.downloadSurat(linkDownload, row)
            }}
          >
            <i className="fa fa-print" style={{ color: 'white' }} />
          </Button>
        ) : ''}
      </>
    );
  };

  downloadSurat = (linkDownload, data_surat) => {
		let token = sessionStorage.getItem("access_token")
			? sessionStorage.getItem("access_token")
			: "public";
    
    let dataSuratParsed = JSON.parse(data_surat.detail_surat)

		fetch(linkDownload, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer ' + token,
			},
		})
		.then((response) => {
			if (response.status == 200) {
				return response.blob()
			}
			this.setState({
				loading: false,
				show: true,
				basicType: 'danger',
				basicTitle: 'Data Penerbitan Surat',
				pesanAlert: 'Gagal download surat'
			});
			throw new Error('Something went wrong');
		})
		.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${data_surat.data_jenis.nama} (${dataSuratParsed.nama_pegawai}).pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);

      // Refresh
      this.fetch({ page: null, where: [] });
			this.setState({
				loading: false,
			})
		})
		.catch((e) => {
			// console.log(e);
		})
		;
	}

  simpanAcc = e => {
    e.preventDefault();
    this.state.btnFormAcc = true;

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/grup/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            tableAcc: "none",
            accStat: [],
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            btnFormAcc: false,

            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.forEach(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };

  componentDidMount() {
    let tes = DataToken(sessionStorage.getItem('access_token'));
		console.log(tes);
    if (this.props.location.state === undefined) {
      this.setState({ redirect: true });
    } else {
      this.fetch({ page: null, where: [] });

      // Get data perangkat
      Get("perangkat/list", null, dtkat => {
        console.log(dtkat);

        let listPerangkat = []
        let tmpListPerangkat = []
        let dataKades = []
        dtkat.results.forEach(dt => {
          listPerangkat.push({ value: dt.nik, label: dt.nik + ' (' + dt.data_penduduk.nama + ')' })
          tmpListPerangkat = {
            ...tmpListPerangkat,
            [dt.nik]: dt
          }

          // Set data kades
          if (dt.data_jabatan && (dt.data_jabatan.nama == 'Kepala Desa' || dt.data_jabatan.nama == 'Kepala Kelurahan')) {
            // this.setState({ dataKades: [{ value: dt.nik, label: dt.nik + ' (' + dt.data_penduduk.nama + ')' }] })
            dataKades.push({ value: dt.nik, label: dt.nik + ' (' + dt.data_penduduk.nama + ')' })
          }

        })
        this.setState({ listPerangkat, tmpListPerangkat, dataKades })

      });

      // Get list ttd
      Get('tanda-tangan/list', null, (data) => {
        if (data.results) {
          let statUuidPerangkatDesa = null
  
          this.setState({ listPerangkatDesa: data.results });
          
          data.results.forEach((dt, i) => {
            if (dt.aktif === "1") {
              this.setState({ ttdSelected: dt });
              statUuidPerangkatDesa = dt.uuid
            }
          });
  
          let listTtd = data.results.map((x) => x.aktif)
  
          // Jika tidak ada ttd utama, maka set perangkat pertama sbg dipilih
          if (statUuidPerangkatDesa === null) {
            if (data.results.length > 0) {
              let temp = listTtd.map((x) => '0');
              temp[0] = '1';
              this.setState({ 
                accButtonPerangkatDesa: temp,
                ttdSelected: data.results[0]
              })
            }
          } else {
            this.setState({accButtonPerangkatDesa: listTtd})
          }
          this.forceUpdate();
        }
      });

    }
  }

  tambahData = e => {
    let statUuidPerangkatDesa = null

		this.state.listPerangkatDesa.forEach((dt, i) => {
			if (dt.aktif === "1") {
				this.setState({ ttdSelected: dt });
				statUuidPerangkatDesa = dt.uuid
			}
		});

		let listTtd = this.state.listPerangkatDesa.map((x) => x.aktif)
		
		// Jika tidak ada ttd utama, maka set perangkat pertama sbg dipilih
		if (statUuidPerangkatDesa === null) {
			if (this.state.listPerangkatDesa.length > 0) {
				let temp = listTtd.map((x) => '0');
				temp[0] = '1';
				this.setState({ 
					accButtonPerangkatDesa: temp,
					ttdSelected: this.state.listPerangkatDesa[0]
				})
			}
		} else {
			this.setState({accButtonPerangkatDesa: listTtd})
		}

    this.setState({
      status: {
        ...this.state.status,
        dataPenduduk: "none",
        form: true
      },
      jenisTtd: {value: 'internal', label: 'internal'},
      judul: "Tambah Surat Perintah Tugas",
      // dt: frmDex,
      dt: null,
      nikStat: null,
    });
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    var data = this.state.data;

    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
        align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
          if (obj.created_at) {
            let date = new Date(obj.created_at)
            date = date.toLocaleString('id-ID')

            const datetime = date.split(' ')


            date = datetime[0]
            let time = datetime[1]

            const splitDate = date.split('/')
            time = time.replaceAll('.', ':')

            console.log(date)
            const list_bulan = [
              'Januari',
              'Februari',
              'Maret',
              'April',
              'Mei',
              'Juni',
              'Juli',
              'Agustus',
              'September',
              'Oktober',
              'November',
              'Desember'
            ];

            plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
          }

          return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
        align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_pegawai",
        headerAlign: 'center',
        align: 'center',
        text: "Nama Pegawai",
        formatter: (cell, obj) => {
          const detail_surat = JSON.parse(obj.detail_surat)

          return detail_surat.nama_pegawai
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "jenis_ttd",
        headerAlign: 'center',
        align: 'center',
        text: "Jenis",
        formatter: (cell, obj) => {
          return <span style={{ textTransform: 'capitalize' }}>{obj.jenis_ttd}</span>
        }
      },
      {
        dataField: "status_surat",
        headerAlign: 'center',
        align: 'center',
        text: "Status",
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
        align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    return (
      <div data-testid="page-surat Perintah Tugas">
        {/* <Breadcrumb title="Data Surat Keramaian" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                Oke
              </Button>
              {/* <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.tambahData()
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button> */}
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Surat Perintah Tugas</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratIzinKeramaian"
                        size="sm"
                        color="success"
                        // className="btn btn-primary-custom"
                        onClick={() => {
                          this.tambahData();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      {/* <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data berdasarkan NIK"
                        onKeyPress={this.cariData}
                      /> */}
                    </div>
                  </div>
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img 
                        alt="loading"
                        src={require("../assets/images/tes-loading.gif")} 
                        style={{
                          borderRadius: "10px",
                          width: '100px'
                        }}/> 
                      </div>
                    </div>
                  ) : (
                    <>
                      <BootstrapTable keyField="id" data={data} columns={columns} />
                      <div className="pull-right text-white">
                        {this.state.awal}
                        {this.state.sebelum}
                        {this.state.hal.map(dt => {
                          return dt;
                        })}
                        {this.state.setelah}
                        {this.state.akhir}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <ModalSPT
          data={this.state.dt}
          isOpen={this.state.status.form}
          propsData={this.props}
          kiosk={false}
          optionData={{
            listPerangkat: this.state.listPerangkat,
            dataKades: this.state.dataKades,
            tmpListPerangkat: this.state.tmpListPerangkat,
            nikStat: this.state.nikStat,
            accButtonPerangkatDesa: this.state.accButtonPerangkatDesa,
            listPerangkatDesa: this.state.listPerangkatDesa,
            ttdSelected: this.state.ttdSelected,
            jenisTtd: this.state.jenisTtd,
            status: this.state.status,
          }}
          setAlert={(e) => {
            this.setState(e)
          }}
          setStatusRefresh={(e) => {
            if (e) {
              this.fetch({ page: null, where: [] });
            }
          }}
          setIsOpen={(e) => {
            if (!e) {
              this.setState({ dt: null })
            }
            this.setState({
              status: {
                ...this.state.status,
                form: e
              }
            })
          }}
        />
      </div>
    );
  }
}

export default MenuSPT;
