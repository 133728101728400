import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL, { Post, Delete, Get, JENIS_DESA, } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import Datetime from 'react-datetime';

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ReactSelect from 'react-select';

let DefaultIcon = L.icon({
	iconSize: [25, 41],
	iconAnchor: [10, 41],
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const frmDex = {
	uuid: '',
	jenis_peraturan: null,
	nomor_ditetapkan: '',
	tanggal_ditetapkan: new Date(),
	tentang: '',
	uraian_singkat: '',
	tanggal_kesepakatan_peraturan: new Date(),
	nomor_dilaporkan: '',
	tanggal_dilaporkan: new Date(),
	nomor_diundangkan_dlmlembaran_desa: "",
	tanggal_diundangkan_dlmlembaran_desa: new Date(),
	nomor_diundangkan_dlmberita_desa: "",
	tanggal_diundangkan_dlmberita_desa: new Date(),
	keterangan: '',
}

const dateFormat = require('dateformat');

class MenuA1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			loading: false,
			loadingPreview: false,
			imagePreview: null,
			detailPreview: false,
			detailData: null,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDex,
			judul: 'Tambah Peraturan Desa',
			textCari: '',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			editMode: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}

		// if (this.state.textCari === '') {
		// 	Get("peraturan-desa" + link, null, dtkat => {
		// 		// console.log(dtkat);

		// 		if (dtkat.results) {
		// 			this.setState({
		// 				data: dtkat.results.data,
		// 				pagination: dtkat.results,
		// 				loading: false
		// 			})
		// 			this.pagination(dtkat.results);
		// 		}

		// 	});
		// } else { // Jika pencarian
		Post("peraturan-desa" + link, null, {
			q: this.state.textCari
		}, dtkat => {
			if (dtkat.data.results) {
				console.log('masuk');
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
		// }

	};

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();

		if (this.state.dt.jenis_peraturan) {
			this.setState({ loadingPreview: true })

			let bodyData = this.state.dt
			bodyData = {
				...bodyData,
				jenis_peraturan: bodyData.jenis_peraturan.value,
				keterangan: document.getElementById("keterangan").value,
				tentang: document.getElementById("tentang").value,
				uraian_singkat: document.getElementById("uraian_singkat").value,
				tanggal_kesepakatan_peraturan: null,
				tanggal_ditetapkan: dateFormat(bodyData.tanggal_ditetapkan, "yyyy-mm-dd"),
				tanggal_dilaporkan: dateFormat(bodyData.tanggal_dilaporkan, "yyyy-mm-dd"),
				tanggal_diundangkan_dlmlembaran_desa: dateFormat(bodyData.tanggal_diundangkan_dlmlembaran_desa, "yyyy-mm-dd"),
				tanggal_diundangkan_dlmberita_desa: dateFormat(bodyData.tanggal_diundangkan_dlmberita_desa, "yyyy-mm-dd"),
			}
			// Jika Peraturan Desa
			if (this.state.dt.jenis_peraturan.value == 'Peraturan Desa') {
				bodyData = {
					...bodyData,
					tanggal_kesepakatan_peraturan: dateFormat(bodyData.tanggal_kesepakatan_peraturan, "yyyy-mm-dd"),
				}
			}

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyData.uuid === "") {
				psn = "tambah";
				resstat = 201;
				bodyData.uuid = null;
			} else {
				psn = "ubah";
				resstat = 200;
				metode = "update";
			}

			console.log(bodyData);

			Post('peraturan-desa/' + metode, bodyData.uuid, bodyData, res => {
				// console.log(res);
				if (res.status === resstat) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "success",
						basicTitle: "Data Peraturan" + JENIS_DESA,
						pesanAlert: "Berhasil " + psn + " Data"
					});

					document.getElementById('form-data').reset()
					this.fetch();
					this.setState({
						dt: frmDex,
						status: {
							...this.state.status,
							form: false
						}
					})
				} else {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Peraturan" + JENIS_DESA,
						pesanAlert: "Gagal " + psn + " Data"
					});
				}
			})

		} else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: "Data Peraturan" + JENIS_DESA,
				pesanAlert: "Pilih Jenis Peraturan"
			});
		}



	});
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Peraturan" + JENIS_DESA,
			editMode: true
		})

		if (data) {
			this.setState({
				tmpImages: [],
				dt: {
					...this.state.dt,
					uuid: data.uuid,
					jenis_peraturan: {
						value: data.jenis_peraturan,
						label: data.jenis_peraturan,
					},
					nomor_ditetapkan: data.nomor_ditetapkan,
					tanggal_ditetapkan: new Date(data.tanggal_ditetapkan),
					tentang: data.tentang,
					uraian_singkat: data.uraian_singkat,
					tanggal_kesepakatan_peraturan: new Date(data.tanggal_kesepakatan_peraturan),

					nomor_dilaporkan: data.nomor_dilaporkan,
					tanggal_ditetapkan: new Date(data.tanggal_ditetapkan),

					nomor_diundangkan_dlmlembaran_desa: data.nomor_diundangkan_dlmlembaran_desa,
					tanggal_diundangkan_dlmlembaran_desa: new Date(data.tanggal_diundangkan_dlmlembaran_desa),

					nomor_diundangkan_dlmberita_desa: data.nomor_diundangkan_dlmberita_desa,
					tanggal_diundangkan_dlmberita_desa: new Date(data.tanggal_diundangkan_dlmberita_desa),

					keterangan: data.keterangan,
				},
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	cariData = (e) => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	};

	downloadSurat = () => {
		let token = sessionStorage.getItem("access_token")
			? sessionStorage.getItem("access_token")
			: "public";

		fetch(API_URL + 'peraturan-desa/cetak', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer ' + token,
			},
		})
		// .then((response) => response.blob())
		.then((response) => {
			if (response.status == 200) {
				return response.blob()
			}
			this.setState({
				loading: false,
				show: true,
				basicType: 'danger',
				basicTitle: 'Data Peraturan ' + JENIS_DESA,
				pesanAlert: 'Gagal cetak surat'
			});
			throw new Error('Something went wrong');
		})
		.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Peraturan ${JENIS_DESA}.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
			this.setState({
				loading: false,
			})
		})
		.catch((e) => {
			// console.log(e);
		})
		;
	}

	componentDidMount() {
		this.fetch()
	}

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'jenis_peraturan',
				headerAlign: 'center',
				align: 'center',
				text: 'JENIS PERATURAN DI ' + JENIS_DESA,
				sort: false
			},
			{
				dataField: 'nomor_tanggal_ditetapkan',
				text: 'NOMOR DAN TANGGAL DITETAPKAN',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: (cell, row) => {
					if (row) {
						return `${row.nomor_ditetapkan} / ${row.tanggal_ditetapkan}`
					}
					return '';
				}
			},
			{
				dataField: 'tentang',
				text: 'JUDUL',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'uraian_singkat',
				text: 'URAIAN SINGKAT',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			// {
			// 	dataField: 'tanggal_kesepakatan_peraturan',
			// 	text: 'Tanggal Kesepakatan Peraturan ' + JENIS_DESA,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'nomor_tanggal_dilaporkan',
				text: 'NOMOR DAN TANGGAL DILAPORKAN',
				headerAlign: 'center',
				align: 'center',
				sort: false,
				formatter: (cell, row) => {
					if (row) {
						return `${row.nomor_dilaporkan} / ${row.tanggal_dilaporkan}`
					}
					return '';
				}
			},
			// {
			// 	dataField: 'nomor_tanggal_diundangkan_dlmlembaran_desa',
			// 	text: 'NOMOR DAN TANGGAL DIUNDANGKAN DALAM LEMBARAN ' + JENIS_DESA,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// 	formatter: (cell, row) => {
			// 		if (row) {
			// 			return `${row.nomor_diundangkan_dlmlembaran_desa} / ${row.tanggal_diundangkan_dlmlembaran_desa}`
			// 		}
			// 		return '';
			// 	}
			// },
			// {
			// 	dataField: 'nomor_tanggal_diundangkan_dlmberita_desa',
			// 	text: 'NOMOR DAN TANGGAL DIUNDANGKAN DALAM BERITA ' + JENIS_DESA,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false,
			// 	formatter: (cell, row) => {
			// 		if (row) {
			// 			return `${row.nomor_diundangkan_dlmberita_desa} / ${row.tanggal_diundangkan_dlmberita_desa}`
			// 		}
			// 		return '';
			// 	}
			// },
			// {
			// 	dataField: 'keterangan',
			// 	text: 'KET.',
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	sort: false
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div style={{ minWidth: "61px" }}>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="info"
								className="btn-icon mr-2"
								onClick={() => {
									this.setState({
										detailPreview: true,
										detailData: row
									})
								}}
							>
								<i className="fa fa-eye"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="danger"
								className="btn-icon"
								onClick={() => {
									this.setState({
										alert: (
											<SweetAlert
												showCancel
												confirmBtnText="Hapus"
												confirmBtnBsStyle="danger"
												cancelBtnBsStyle="success"
												type="danger"
												title="Yakin ingin hapus data ?"
												onCancel={this.hideAlert}
												onConfirm={() => {
													Delete('peraturan-desa/delete', row.uuid, (res) => {
														if (res === 200) {
															this.setState({
																show: true,
																basicType: 'success',
																basicTitle: 'Data Peraturan ' + JENIS_DESA,
																pesanAlert: 'Berhasil hapus data'
															});
															this.fetch()
														} else {
															this.setState({
																show: true,
																basicType: 'danger',
																basicTitle: 'Data Peraturan ' + JENIS_DESA,
																pesanAlert: 'Gagal hapus data'
															});
														}
														this.setState({ alert: null })
													})
												}}
											>
												Proses ini tidak dapat dibatalkan!
											</SweetAlert>
										)
									});
								}}
								disabled={this.state.status.btnAksi}
							>
								<i className="fa fa-trash"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Peraturan Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													this.setState({ loading: true })
													this.downloadSurat()
												}}
											>
												Cetak
											</Button>{" "}
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Peraturan di ' + JENIS_DESA,
														dt: frmDex,
														tmpImages: ['kosong'],
														editMode: false,
														currentLocation: this.state.curStaticLocation
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react custom-font-sm">
									{/* <div className="row">
										<div className="col-md-12"> */}
									{this.state.loading ? (
										<div className="row">
											<div className="col-sm-12" align="center">
												<img
													alt="loading"
													src={require("../../../assets/images/tes-loading.gif")}
													style={{
														borderRadius: "10px",
														width: '100px'
													}} />
											</div>
										</div>
									) : (
										<div className='row'>
											<div className="col-md-5 text-right">
												<input
													type="text"
													className="form-control"
													style={{ marginBottom: '15px' }}
													id="cariTable"
													placeholder="Cari berdasarkan jenis peraturan"
													defaultValue={this.state.textCari}
													onChange={(e) => {
														this.setState({ textCari: e.target.value })
													}}
													onKeyPress={this.cariData}
												/>
												<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
													<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
														this.setState({
															textCari: '',
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
														document.getElementById('cariTable').value = ''
													}} ></span>
												</div>
											</div>
											<div className="col-md-7" style={{ marginBottom: '15px' }}>
												<Button
													size="sm"
													color="info"
													className="btn-square"
													onClick={() => {
														this.setState({
															textCari: document.getElementById("cariTable").value,
															loading: true
														});
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
													}}
												>
													<i className="fa fa-search"></i>
												</Button>
											</div>
											{data.length > 0 ? (
												<>
													<div className="col-md-12">
														<BootstrapTable
															keyField="id"
															data={data}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal}
															{this.state.sebelum}
															{this.state.hal.map(dt => {
																return dt;
															})}
															{this.state.setelah}
															{this.state.akhir}
														</div>
													</div>
												</>
											) : (
												<div className="col-md-12">
													<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
												</div>
											)}
										</div>
									)}
									{/* </div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Detail Data Peraturan</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Jenis Peraturan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.jenis_peraturan}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor dan Tanggal ditetapkannya Peraturan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor_ditetapkan + ' / ' + this.state.detailData.tanggal_ditetapkan}</span></td>
													</tr>
													<tr>
														<th width="280">Judul / Nama Peraturan </th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tentang}</span></td>
													</tr>
													<tr>
														<th width="280">Uraian Singkat</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.uraian_singkat}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor dan Tanggal Surat Dilaporkan Kepada Bupati/Walikota</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor_dilaporkan + ' / ' + this.state.detailData.tanggal_dilaporkan}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor dan Tanggal Surat di Lembaran {JENIS_DESA}</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor_diundangkan_dlmlembaran_desa + ' / ' + this.state.detailData.tanggal_diundangkan_dlmlembaran_desa}</span></td>
													</tr>
													<tr>
														<th width="280">Nomor dan Tanggal Surat di Berita {JENIS_DESA}</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nomor_diundangkan_dlmberita_desa + ' / ' + this.state.detailData.tanggal_diundangkan_dlmberita_desa}</span></td>
													</tr>
												</tbody>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}


							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
								>
									Jenis Peraturan
								</Label>
								<Col sm="9">
									<div data-testid="selectJenisPeraturan">
										<ReactSelect
											inputId="jenis_peraturan"
											classNamePrefix="select"
											onChange={e => {
												this.setState({
													dt: {
														...this.state.dt,
														jenis_peraturan: e
													}
												})
											}}
											defaultValue={this.state.dt.jenis_peraturan}
											value={this.state.dt.jenis_peraturan}
											name="nik"
											options={[
												{
													value: 'Peraturan Desa',
													label: 'Peraturan Desa',
												},
												{
													value: 'Peraturan Bersama',
													label: 'Peraturan Bersama',
												},
												{
													value: 'Peraturan Kepala Desa',
													label: 'Peraturan Kepala Desa',
												},
											]}
											placeholder="Pilih Jenis Peraturan"
										/>
									</div>
								</Col>
							</FormGroup> */}

							<div className="form-row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Jenis Peraturan</Label>
									<div data-testid="selectJenisPeraturan">
										<ReactSelect
											inputId="jenis_peraturan"
											classNamePrefix="select"
											onChange={e => {
												this.setState({
													dt: {
														...this.state.dt,
														jenis_peraturan: e
													}
												})
											}}
											defaultValue={this.state.dt.jenis_peraturan}
											value={this.state.dt.jenis_peraturan}
											name="nik"
											options={[
												{
													value: 'Peraturan Desa',
													label: 'Peraturan Desa',
												},
												{
													value: 'Peraturan Bersama',
													label: 'Peraturan Bersama',
												},
												{
													value: 'Peraturan Kepala Desa',
													label: 'Peraturan Kepala Desa',
												},
											]}
											placeholder="Pilih Jenis Peraturan"
										/>
									</div>
								</Col>
								{this.state.dt.jenis_peraturan && this.state.dt.jenis_peraturan.value == 'Peraturan Desa' ? (
									<Col sm="12" className="mb-2">
										<Label htmlFor="validationCustom05">Tanggal Kesepakatan Peraturan {JENIS_DESA}</Label>
										<Datetime
											inputProps={{
												"data-testid": "tanggal_kesepakatan_peraturan",
												placeholder: 'Tanggal kesepakatan pemerintah desa dan BPD',
												required: true
											}}
											inputId="tanggal_kesepakatan_peraturan"
											name="tanggal_kesepakatan_peraturan"
											locale="id-ID"
											timeFormat={false}
											dateFormat="DD/MM/yyyy"
											closeOnTab={true}
											closeOnSelect={true}
											initialValue={this.state.dt.tanggal_kesepakatan_peraturan}
											value={this.state.dt.tanggal_kesepakatan_peraturan}
											onChange={e => {
												if (typeof e._isValid !== 'undefined') {
													let tgl = e.format('YYYY-MM-DD');
													this.setState({
														dt: {
															...this.state.dt,
															tanggal_kesepakatan_peraturan: new Date(tgl)
														}
													})
												}
											}}
										/>
									</Col>
								) : ''}
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>Nomor dan tanggal ditetapkannya peraturan</b>
								</Label>
							</FormGroup>
							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Nomor surat</Label>
									<input
										className="form-control"
										id="nomor_ditetapkan"
										type="text"
										placeholder="Masukkan nomor surat"
										defaultValue={this.state.dt.nomor_ditetapkan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													nomor_ditetapkan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tanggal surat</Label>
									<Datetime
										inputProps={{
											"data-testid": "tanggal_ditetapkan",
											placeholder: 'Tanggal ditetapkan',
											required: true
										}}
										inputId="tanggal_ditetapkan"
										name="tanggal_ditetapkan"
										locale="id-ID"
										timeFormat={false}
										dateFormat="DD/MM/yyyy"
										closeOnTab={true}
										closeOnSelect={true}
										initialValue={this.state.dt.tanggal_ditetapkan}
										value={this.state.dt.tanggal_ditetapkan}
										onChange={e => {
											if (typeof e._isValid !== 'undefined') {
												let tgl = e.format('YYYY-MM-DD');
												this.setState({
													dt: {
														...this.state.dt,
														tanggal_ditetapkan: new Date(tgl)
													}
												})
											}
										}}
									/>
								</Col>
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>Judul dan uraian singkat</b>
								</Label>
							</FormGroup>
							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Judul / nama Peraturan</Label>
									<textarea className='form-control' name="tentang" id="tentang" cols="30" placeholder='Masukkan judul/penamaan Peraturan Desa, Peraturan Bersama, atau Peraturan Kepala Desa' defaultValue={this.state.dt.tentang} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												tentang: e.target.value
											}
										})
									}} rows="4" required />
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Uraian</Label>
									<textarea className='form-control' name="uraian_singkat" id="uraian_singkat" cols="30" placeholder='Penjelasan singkat tentang materi pokok pada Peraturan Desa, Peraturan Bersama Kepala Desa atau Peraturan Kepala Desa yang telah ditetapkan' defaultValue={this.state.dt.uraian_singkat} onChange={(e) => {
										this.setState({
											dt: {
												...this.state.dt,
												uraian_singkat: e.target.value
											}
										})
									}} rows="4" required />
								</Col>
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>Nomor dan tanggal surat yang dilaporkan kepada Bupati/ Walikota</b>
								</Label>
							</FormGroup>
							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Nomor surat</Label>
									<input
										className="form-control"
										id="nomor_dilaporkan"
										type="text"
										placeholder="Masukkan nomor surat"
										defaultValue={this.state.dt.nomor_dilaporkan}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													nomor_dilaporkan: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tanggal surat </Label>
									<Datetime
										inputProps={{
											"data-testid": "tanggal_dilaporkan",
											placeholder: 'Tanggal surat',
											required: true
										}}
										inputId="tanggal_dilaporkan"
										name="tanggal_dilaporkan"
										locale="id-ID"
										timeFormat={false}
										dateFormat="DD/MM/yyyy"
										closeOnTab={true}
										closeOnSelect={true}
										initialValue={this.state.dt.tanggal_dilaporkan}
										value={this.state.dt.tanggal_dilaporkan}
										onChange={e => {
											if (typeof e._isValid !== 'undefined') {
												let tgl = e.format('YYYY-MM-DD');
												this.setState({
													dt: {
														...this.state.dt,
														tanggal_dilaporkan: new Date(tgl)
													}
												})
											}
										}}
									/>
								</Col>
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>Nomor dan tanggal surat yang diundangkan di Lembaran {JENIS_DESA}</b>
								</Label>
							</FormGroup>
							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Nomor surat</Label>
									<input
										className="form-control"
										id="nomor_diundangkan_dlmlembaran_desa"
										type="text"
										placeholder={"Masukkan nomor surat pada Lembaran " + JENIS_DESA}
										defaultValue={this.state.dt.nomor_diundangkan_dlmlembaran_desa}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													nomor_diundangkan_dlmlembaran_desa: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tanggal surat</Label>
									<Datetime
										inputProps={{
											"data-testid": "tanggal_diundangkan_dlmlembaran_desa",
											required: true
										}}
										inputId="tanggal_diundangkan_dlmlembaran_desa"
										name="tanggal_diundangkan_dlmlembaran_desa"
										locale="id-ID"
										timeFormat={false}
										dateFormat="DD/MM/yyyy"
										closeOnTab={true}
										closeOnSelect={true}
										initialValue={this.state.dt.tanggal_diundangkan_dlmlembaran_desa}
										value={this.state.dt.tanggal_diundangkan_dlmlembaran_desa}
										onChange={e => {
											if (typeof e._isValid !== 'undefined') {
												let tgl = e.format('YYYY-MM-DD');
												this.setState({
													dt: {
														...this.state.dt,
														tanggal_diundangkan_dlmlembaran_desa: new Date(tgl)
													}
												})
											}
										}}
									/>
								</Col>
							</div>

							<hr />

							<FormGroup className="row ml-0 mr-0 mb-2" style={{ backgroundColor: '#666666' }}>
								<Label
									className="col-sm-12 col-form-label text-white"
								>
									<b>Nomor dan tanggal surat yang diundangkan di Berita {JENIS_DESA}</b>
								</Label>
							</FormGroup>
							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Nomor surat</Label>
									<input
										className="form-control"
										id="nomor_diundangkan_dlmberita_desa"
										type="text"
										placeholder={"Masukkan nomor surat"}
										defaultValue={this.state.dt.nomor_diundangkan_dlmberita_desa}
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													nomor_diundangkan_dlmberita_desa: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Tanggal surat</Label>
									<Datetime
										inputProps={{
											"data-testid": "tanggal_diundangkan_dlmberita_desa",
											required: true
										}}
										inputId="tanggal_diundangkan_dlmberita_desa"
										name="tanggal_diundangkan_dlmberita_desa"
										locale="id-ID"
										timeFormat={false}
										dateFormat="DD/MM/yyyy"
										closeOnTab={true}
										closeOnSelect={true}
										initialValue={this.state.dt.tanggal_diundangkan_dlmberita_desa}
										value={this.state.dt.tanggal_diundangkan_dlmberita_desa}
										onChange={e => {
											if (typeof e._isValid !== 'undefined') {
												let tgl = e.format('YYYY-MM-DD');
												this.setState({
													dt: {
														...this.state.dt,
														tanggal_diundangkan_dlmberita_desa: new Date(tgl)
													}
												})
											}
										}}
									/>
								</Col>
							</div>

							<hr />

							<div className="form-row ">
								<Col sm="12" className="mb-2">
									<Label htmlFor="validationCustom05">Keterangan tambahan</Label>
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder='Diisi dengan catatan-catatan lain yang dianggap perlu.' defaultValue={this.state.dt.keterangan} rows="3" />
								</Col>
							</div>

							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
								>
									Keterangan
								</Label>
								<Col sm="9">
									<textarea className='form-control' name="keterangan" id="keterangan" cols="30" placeholder='Diisi dengan catatan-catatan lain yang dianggap perlu.' defaultValue={this.state.dt.keterangan} rows="3" />
								</Col>
							</FormGroup> */}

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuA1;
