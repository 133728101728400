import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

// koneksi
import { Post, Get, Delete, ID_DESA, JENIS_DESA, JENIS_DUSUN } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';

import ModalDusun from "./modal/dusun";
import ModalRW from "./modal/rw";
import ModalRT from "./modal/rt";


const frmDusun = {
  id: 0,
  idbaru: '',
  id_keldes: '',
  nama: '',
}

const frmRW = {
  id: 0,
  idbaru: '',
  id_keldes: '',
  id_dusun: '',
  id_rw: '',
  nama  : '',
}


const frmRT = {
	id: 0,
	idbaru: '',
	id_rw: '',
	id_rt: '',
	nama: '',
}

class BasicTable extends Component {
  _isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
      // WILAYAH DUSUN
			dataDusun: [],
			paginationDusun: [],
			statusDusun: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
      awalDusun: "",
      sebelumDusun: "",
      halDusun: [],
      setelahDusun: "",
      akhirDusun: "",
      dtDusun: frmDusun,
      judulDusun: "Tambah " + JENIS_DUSUN,
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      loading: false,
      basicTitle: '',
      basicType: "default",
      pesanAlert: '',
      // ==============================
      dataWilayah:[],
      dataWilayahDusun:{
          id_provinsi:'',
          id_kabkota:'',
          id_kecamatan:'',
          id_keldes:'',
          next_id: '',
      },
      // END WILAYAH DUSUN
			
      // WILAYAH RW
      dataRW: [],
      paginationRW: [],
      statusRW: {
          form:false,
          btnForm:false,
          btnAksi:false,
          select:false
      },
      awalRW: "",
      sebelumRW: "",
      halRW: [],
      setelahRW: "",
      akhirRW: "",
      dtRW:frmRW,
      judulRW: "Tambah RW",
      // =========== Select 2 ============
      dusunList: [],
      dsnStatRW: [],
      // ==============================
      dataWilayahRW:{
          next_id: '',
      },

      // END WILAYAH RW

      // WILAYAH RT
      dataRT: [],
			paginationRT: [],
			statusRT: {
				form: false,
				btnForm: false,
				btnAksi: false,
				select: false
			},
			awalRT: "",
			sebelumRT: "",
			halRT: [],
			setelahRT: "",
			akhirRT: "",
			dtRT: frmRT,
			judulRT: "Tambah RT",
			// =========== Select 2 ============
			frmDsnRT: [],
			dusunSetRT: [],
			rwList: [],
			rwSetRT: [],
			detailWilayahRW: null,
			// =================================
			dataWilayahRT: {
				next_id: '',
			},


      // END WILAYAH RT

			
      isKelurahan: false,
			// Tab Vertical
        active_tab_vertical: '1',
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// Toggle Vertical Tab
	toggle_vertical(tab) {
        if (this.state.active_tab_vertical !== tab) {
            this.setState({
                active_tab_vertical: tab,
                detailDataStat: false,
                detailLembaga: [],
            });
        }
    }

  
  // DATA DUSUN

	// ================== Ambil data dari db untuk table ==================
  fetchDusun = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //     where = params.where;
    // }
    if (params.page) {
        switch (params.page) {
            case '>>': link = "?page=" + (Math.ceil(this.state.paginationDusun.total / this.state.paginationDusun.per_page)); break;
            case '>': link = "?page=" + (parseInt(this.state.paginationDusun.current_page) + 1); break;
            case '<': link = "?page=" + (parseInt(this.state.paginationDusun.current_page) - 1); break;
            case '<<': link = "?page=1"; break;
            default: link = "?page=" + params.page; break;
        }
    }
    Get('dusun' + link, null, (dtkat) => {
      if (this._isMounted) {
        if (dtkat.results) {
          this.setState({ dataDusun: dtkat.results.data, paginationDusun: dtkat.results, loading: false });
          this.paginationDusun(dtkat.results);
        }
      }
    });
}

paginationDusun = (data) => {
  let awalDusun = "";
  let halDusun = [];
  let sebelumDusun = "";
  let setelahDusun = "";
  let akhirDusun = "";
  if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
          start = 1;
          end = 5;
          if (data.last_page > data.current_page) {
              n = data.current_page + 1;
          }
      }
      else {
          p = data.current_page - 1;
          n = data.current_page + 1;
          start = data.current_page - 2;
          end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
          p = data.current_page - 1;
          if (start >= 5) {
              start = data.last_page - 4;
          }
          end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
          let warna = "primary-custom";
          if (i === data.current_page) {
              warna = "danger"
          }
          halDusun.push(<a href={() => false} onClick={() => this.fetchDusun({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
      }
      if (p > 0) {
          sebelumDusun = (<a href={() => false} onClick={() => this.fetchDusun({ page: p })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
      }
      if (n > 0) {
          setelahDusun = (<a href={() => false} onClick={() => this.fetchDusun({ page: n })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
      }
      awalDusun = (<a href={() => false} onClick={() => this.fetchDusun({ page: 1 })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
      akhirDusun = (<a href={() => false} onClick={() => this.fetchDusun({ page: data.last_page })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
  }
  this.setState({ awalDusun, sebelumDusun, halDusun, setelahDusun, akhirDusun });
}

tombolAksiDusun = (cell, row) => {
  return (
      <>
          <Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataDusun(row)} disabled={this.state.statusDusun.btnAksi}><i className="fa fa-pencil"></i></Button>
      </>
  )
}
	// ========================================================================

// ============================== Tambah Data ===============================
  
tambahDataDusun = e => {
  this.setState({
    statusDusun: {
      ...this.state.statusDusun,
      form: true
    },
    judul: "Tambah Dusun",
    dtTTD: null,
    frmPenggantiTTD:'none',
    statusStatTTD: [],
    prgStatTTD: [],
    prgStatTTD2: [],
  });
};

	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahDataDusun = (data) => {
    this.setState({
        statusDusun:{
            ...this.state.statusDusun,
            btnAksi: true,
        },
        judulDusun: "Ubah Dusun",
    })
    this.forceUpdate();
      this.setState({
          dtDusun: data,
          statusDusun:{
              ...this.state.statusDusun,
              btnAksi: false,
          }
      })
      this.bukaFormDusun();
	};

  bukaFormDusun = () => {
    this.setState({
        statusDusun:{
            ...this.state.statusDusun,
            form: true,
        }
    })
    this.forceUpdate();
  }


// END DATA DUSUN

// DATA RW
    fetchRW = (params = {}) => 
    {    
      this.setState({ loading: true })
      let link = "";
      if (params.page)
      {
          switch(params.page)
          {
              case '>>': link = "?page="+(Math.ceil(this.state.paginationRW.total / this.state.paginationRW.per_page));break;
              case '>': link = "?page="+(parseInt(this.state.paginationRW.current_page) + 1);break;
              case '<': link = "?page="+(parseInt(this.state.paginationRW.current_page) - 1);break;
              case '<<': link = "?page=1";break;
              default: link = "?page="+params.page;break;
          }
      }
      Get('rw'+link, null, (dtkat) => {
          this.setState({dataRW:dtkat.results.data, paginationRW:dtkat.results, loading: false });
          this.paginationRW(dtkat.results);
      });
    }

    // paginationRW
    paginationRW = (data) => {
      let awalRW = "";
      let halRW = [];
      let sebelumRW = "";
      let setelahRW = "";
      let akhirRW = "";
      if (data.total > 0)
        {
          let start = 1;
          let end = 5;
          let n = 0;
          let p = 0;
          if (data.current_page <= 3)
          {   
              start = 1;
              end = 5;
              if (data.last_page > data.current_page)
              {
                  n = data.current_page + 1;
              }
          }
          else 
          {
              p = data.current_page - 1;
              n = data.current_page + 1;
              start = data.current_page - 2;
              end = data.current_page + 2;
          }
          if (end >= data.last_page - 2)
          {
              p = data.current_page - 1;
              if (start >= 5)
              {
                  start = data.last_page - 4;
              }
              end = data.last_page;
          }

          for (let i=start;i<=end;i++) 
          {
              let warna = "primary-custom";
              if (i === data.current_page)
              {
                  warna = "danger"
              }
              halRW.push(<a href={() => false} onClick={()=>this.fetchRW({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
          }
          if (p > 0)
          {
              sebelumRW = (<a href={() => false} onClick={()=>this.fetchRW({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
          }
          if (n > 0)
          {
              setelahRW = (<a href={() => false} onClick={()=>this.fetchRW({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
          }
          awalRW = (<a href={() => false} onClick={()=>this.fetchRW({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
          akhirRW = (<a href={() => false} onClick={()=>this.fetchRW({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
      }
      this.setState({awalRW,sebelumRW,halRW,setelahRW,akhirRW});
    }

    // ============================== Tambah Data ===============================
  
    tambahDataRW = e => {
      this.setState({
        statusRW: {
          ...this.state.statusRW,
          form: true
        },
        judulRW: "Tambah RW",
        dtRW: null,
        // frmPenggantiTTD:'none',
        // statusStatTTD: [],
        // prgStatTTD: [],
        // prgStatTTD2: [],
      });
    };

   // ============================== Ubah Data ===============================
    
   ubahDataRW = (data) => {
    this.setState({
      statusRW:{
          ...this.state.statusRW,
          btnAksi: true,
      },
      judulRW: "Ubah RW",
    })
    this.forceUpdate();
    this.setState({
        dtRW: data,
        statusRW:{
            ...this.state.statusRW,
            btnAksi: false,
        }
    })

    if(this.state.isKelurahan===false){
        this.setState({
            dsnStatRW:{value:data.data_dusun.id_dusun, label:data.data_dusun.nama}
        })
    }

    this.bukaFormRW();
    this.forceUpdate();
  }

  
  bukaFormRW = () => {
    this.setState({
        statusRW:{
            ...this.state.statusRW,
            form: true,
        }
    })
    this.forceUpdate();
  }

  
  tombolAksiRW = (cell, row) => {
    return (
        <>
            <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataRW(row)} disabled={this.state.statusRW.btnAksi}><i className="fa fa-pencil"></i></Button>
        </>
    )
  }
// END DATA RW

// DATA RT
// ================== Ambil data dari db untuk table ==================
  fetchRT = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    if (params.page) {
      switch (params.page) {
        case '>>': link = "?page=" + (Math.ceil(this.state.paginationRT.total / this.state.paginationRT.per_page)); break;
        case '>': link = "?page=" + (parseInt(this.state.paginationRT.current_page) + 1); break;
        case '<': link = "?page=" + (parseInt(this.state.paginationRT.current_page) - 1); break;
        case '<<': link = "?page=1"; break;
        default: link = "?page=" + params.page; break;
      }
    }
    Get('rt' + link, null, (dtkat) => {
      this.setState({ dataRT: dtkat.results.data, paginationRT: dtkat.results, loading: false });
      this.paginationRT(dtkat.results);
    });
  }

  // paginationRT
  paginationRT = (data) => {
    let awalRT = "";
    let halRT = [];
    let sebelumRT = "";
    let setelahRT = "";
    let akhirRT = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      }
      else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger"
        }
        halRT.push(<a href={() => false} onClick={() => this.fetchRT({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
      }
      if (p > 0) {
        sebelumRT = (<a href={() => false} onClick={() => this.fetchRT({ page: p })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
      }
      if (n > 0) {
        setelahRT = (<a href={() => false} onClick={() => this.fetchRT({ page: n })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
      }
      awalRT = (<a href={() => false} onClick={() => this.fetchRT({ page: 1 })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
      akhirRT = (<a href={() => false} onClick={() => this.fetchRT({ page: data.last_page })} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
    }
    this.setState({ awalRT, sebelumRT, halRT, setelahRT, akhirRT });
  }

  // ============================== Tambah Data ===============================

  tambahDataRT = e => {
    this.setState({
      statusRT: {
        ...this.state.statusRT,
        form: true
      },
      judulRT: "Tambah RT",
      dtRT: null,
    });
  };
  

  ubahDataRT = (data) => {
    this.setState({
      statusRT:{
          ...this.state.statusRT,
          btnAksi: true,
      },
      judulRT: "Ubah RT",
    })
    this.forceUpdate();
    this.setState({
      dtRT: {
				...this.state.dtRT,
				id: data.id,
				id_rw: data.id_rw,
				nama: data.nama,
			},
			statusRT: {
				...this.state.statusRT,
				btnAksi: false,
			},
			dataWilayahRT: {
				next_id: data.id,
			},
      detailWilayahRW:data.data_rw
    })

    this.ubahPilihan('rw', data.data_rw);
		this.forceUpdate();
  }

  
	ubahPilihan = (table, data) => {
		switch (table) {
			case 'rw':
				if (this.state.isKelurahan) {
					this.setState({
						rwSetRT: { value: data.id, label: data.nama }
					});
				}
				else {
					this.getChild({ value: data.data_dusun.id, label: data.data_dusun.nama }, 'dusun', 'dus', 'rw');

					/////////////// NEW /////////


					this.setState({ dusunSetRT: { value: data.data_dusun.id, label: data.data_dusun.nama }, rwSetRT: { value: data.id, label: data.nama } });
				}
        
        this.bukaFormRT();
				break;
			default:
				// no default
				break;
		}
	}

  
	getChild = (e, self, parent, child) => {
		this.setState({
			statusRT: {
				...this.state.statusRT,
				select: true,
			},
		})
		this.forceUpdate();
		this.changeSelectValue(e, self);
		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'dusun': this.setState({ dusunList: frm, frmRW: [] }); break;
					case 'rw': this.setState({ rwList: frm }); break;
					default:
						// no default
						break;
				}
				this.setState({
					statusRT: {
						...this.state.statusRT,
						select: false,
					},
				})
				this.forceUpdate();
			});
		}
	}

  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'dusun': this.setState({ dusunSetRT: e, rwSetRT: [] }); break;
				case 'rw':
					this.setState({
						rwSetRT: e,
						dtRT: {
							...this.state.dtRT,
							id_rw: e.value,
						},
					});
					Get('rt/rw/' + e.value + '/list', null, (data) => {
						if (data.results.length !== 0) {
							let akhir = data.results.length - 1;
							let getDataNext = data.results[akhir];
							let idRW = getDataNext.id.substring(0, 16);
							let idRT = getDataNext.id.substring(16, 19);
							let nextIDRT = parseInt(idRT) + 1;
							let normRT = nextIDRT.toString();
							let nextID = '';
							if (normRT.length === 1) {
								nextID = (getDataNext.id.slice(0, -3)) + '00' + normRT
								// console.log(nextID)
							}
							else if (normRT.length === 2) {
								nextID = (getDataNext.id.slice(0, -3)) + '0' + normRT
							}
							else if (normRT.length === 3) {
								nextID = (getDataNext.id.slice(0, -3)) + normRT
							}

							this.setState({
								dataWilayahRT: {
									next_id: nextID,
								}
							})
						}
						else {
							let setnextID = e.value + '001';
							let nextID = setnextID;
							this.setState({
								dataWilayahRT: {
									next_id: nextID,
								}
							})
						}

					});
					break;
				default:
					// no default
					break;
			}
		}
		else {
			switch (sel) {
				case 'dusun': this.setState({ dusunSetRT: [], rwSetRT: [], rwList: [] }); break;
				case 'rw':
					this.setState({
						rwSetRT: [],
						dtRT: {
							...this.state.dtRT,
							id_rw: 0,
						},
					});
					break;
				default:
					// no default
					break;
			}
		}

		this.setState({
			statusRT: {
				...this.state.statusRT,
				select: false,
			},
		})
		this.forceUpdate();
	}

  
  bukaFormRT = () => {
    this.setState({
        statusRT:{
            ...this.state.statusRT,
            form: true,
        }
    })
    this.forceUpdate();
  }
  
	tombolAksiRT = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataRT(row)} disabled={this.state.statusRT.btnAksi}><i className="fa fa-pencil"></i></Button>
			</>
		)
	}
// END DATA RT

	// ========================================================================

	componentDidMount() {
		this.fetchDusun({ page: null, where: [] });
		this.fetchRW({ page: null, where: [] });
		this.fetchRT({ page: null, where: [] });

    // DATA DUSUN
    this._isMounted = true;

    Get('keldes/find/'+ID_DESA, null, (data) => {
      this.setState({
          dataWilayah:{
              ...this.state.dataWilayah,
              id_provinsi: data.results.data_kecamatan.data_kabkota.data_provinsi.id,
              id_kabkota: data.results.data_kecamatan.data_kabkota.id,
              id_kecamatan: data.results.data_kecamatan.id,
              id_keldes: data.results.id,
          }
      })
    })

    // END DATA DUSUN

    // DATA RW
      if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        this.setState({ isKelurahan: true });
        Get('rw/kel/'+ID_DESA+'/list', null, (data) => {
            if(data.results.length!==0){
                let akhir = data.results.length-1;
                let getDataNext = data.results[akhir];
                let nextID = getDataNext.id+1;
                this.setState({
                    dataWilayahRW:{
                        next_id: nextID,
                    }
                })
            }
            else{
                let setnextID = ID_DESA+'000'+'001';
                let nextID = parseInt(setnextID);
                this.setState({
                    dataWilayahRW:{
                        next_id: parseInt(nextID),
                    }
                })
            }
        });
      }
      else{
        Get('dusun/kel/'+ID_DESA+'/list', null, (data) => {
            let dusunList = [];
            data.results.forEach((dt) => {
                dusunList.push({value:dt.id, label:dt.nama});
            });
            this.setState({
                dusunList,
                isKelurahan: false,
            });
        })
      }
    // END DATA RW

	}

  
  componentWillUnmount() {
    this._isMounted = false;
  }


	render() {
    var dataDusun = this.state.dataDusun;
    var dataRW = this.state.dataRW;
    var dataRT = this.state.dataRT;

    const frmDusun = {
      id: 0,
      idbaru: '',
      id_keldes: '',
      nama: '',
    };

    const columnsDusun = [
      {
          dataField: "no",
          text: "No",
          style: {
            width: '30px'
          },
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationDusun.current_page;
              let total_records_per_page = this.state.paginationDusun.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
          dataField: 'nama_keldes',
          text: 'Kelurahan/' + JENIS_DESA,
          isDummyField: true,
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, obj) => { return obj.data_keldes ? obj.data_keldes.nama : '' },
          sort: true
      },
      {
          dataField: 'nama',
          text: JENIS_DUSUN,
          headerAlign: 'center',
          align: 'center',
          sort: true
      },
      {
          dataField: 'aksi',
          text: 'Aksi',
          headerAlign: 'center',
          align: 'center',
          style: {
            width: '130px'
          },
          formatter: this.tombolAksiDusun,
      },
    ];

    let columnsRW = [];
    if(this.state.isKelurahan){
      columnsRW = [
          {
              dataField: "no",
              text: "No",
              headerAlign: 'center',
              align: 'center',
              style: {
                width: '30px'
              },
              formatter: (key, obj, index) =>{          
                  let current_pagenum = this.state.paginationRW.current_page;
                  let total_records_per_page = this.state.paginationRW.per_page;
                  let row_index = (index+1);
                  let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                  return serial_num; 
              }
          },
          // {
          //     dataField: 'id',
          //     text: 'ID',
          //     sort: false
          // },
          // {
          //     dataField: 'nama_keldes',
          //     text: JENIS_DESA,
          //     isDummyField: true,
          //     formatter: (cell, obj)=> {return obj.data_keldes ? obj.data_keldes.nama : ''},
          //     sort: true
          // },
          {
              dataField: 'nama',
              text: 'RW',
              headerAlign: 'center',
              align: 'center',
              sort: true
          },
          {
              dataField: 'aksi',
              text: 'Aksi',
              isDummyField: true,
              csvExport: false,
              formatter: this.tombolAksiRW,
          },
      ];
    }
    else{
      columnsRW = [
          {
              dataField: "no",
              text: "No",
              headerAlign: 'center',
              align: 'center',
              style: {
                width: '30px'
              },
              formatter: (key, obj, index) =>{          
                  let current_pagenum = this.state.paginationRW.current_page;
                  let total_records_per_page = this.state.paginationRW.per_page;
                  let row_index = (index+1);
                  let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                  return serial_num; 
              }
          },
          {
              dataField: 'nama_dusun',
              text: JENIS_DUSUN,
              headerAlign: 'center',
              align: 'center',
              formatter: (cell, obj)=> {return obj.data_dusun ? obj.data_dusun.nama : ''},
              sort: true
          },
          {
              dataField: 'nama',
              text: 'RW',
              headerAlign: 'center',
              align: 'center',
              style: {
                width: '150px'
              },
              sort: true
          },
          {
              dataField: 'aksi',
              text: 'Aksi',
              style: {
                width: '130px'
              },
              headerAlign: 'center',
              align: 'center',
              formatter: this.tombolAksiRW,
          },
      ];
    }

    let columnsRT = [];
		if (this.state.isKelurahan) {
			columnsRT = [
				{
					dataField: "no",
					text: "No",
					headerAlign: 'center',
					align: 'center',
					style: {
            width: '30px'
          },
					formatter: (key, obj, index) => {
						let current_pagenum = this.state.paginationRT.current_page;
						let total_records_per_page = this.state.paginationRT.per_page;
						let row_index = (index + 1);
						let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
						return serial_num;
					}
				},
				{
					dataField: 'nama_rw',
					text: 'RW',
					headerAlign: 'center',
					align: 'center',
					formatter: (cell, obj) => { return obj.data_rw ? obj.data_rw.nama : '' },
				},
				{
					dataField: 'nama',
					text: 'RT',
					headerAlign: 'center',
					align: 'center',
					sort: true
				},
				{
					dataField: 'aksi',
					text: 'Aksi',
					style: {
						width: '130px'
					},
					headerAlign: 'center',
					align: 'center',
					formatter: this.tombolAksiRT,
				},
			];
		}
		else {
			columnsRT = [
				{
					dataField: "no",
					text: "No",
					style: {
            width: '30px'
          },
					headerAlign: 'center',
					align: 'center',
					formatter: (key, obj, index) => {
						let current_pagenum = this.state.paginationRT.current_page;
						let total_records_per_page = this.state.paginationRT.per_page;
						let row_index = (index + 1);
						let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
						return serial_num;
					}
				},
				// {
				//     dataField: 'id',
				//     text: 'ID',
				//     sort: false
				// },
				{
					dataField: 'nama_rw',
					text: 'Wilayah',
					isDummyField: true,
					headerAlign: 'center',
					align: 'center',
					formatter: (cell, obj) => {
						let rw = ''
						let kelurahan = ''
						// let dusun = ''
						if (JENIS_DESA.toLowerCase() === 'kelurahan') {
							rw = obj.data_rw
							kelurahan = rw.data_keldes
							let kec = kelurahan.data_kecamatan
							let kab = kec.data_kabkota
							let prov = kab.data_provinsi
							return ` ${JENIS_DESA.toLowerCase()} ${kelurahan.nama}, Kec. ${kec.nama}, ${kab.nama}, ${prov.nama}`
						} else {
							let dusun = obj.data_rw ? obj.data_rw.data_dusun : ''
							kelurahan = dusun ? dusun.data_keldes : ''
							let kec = kelurahan ? kelurahan.data_kecamatan : ''
							let kab = kec ? kec.data_kabkota : ''
							let prov = kab ? kab.data_provinsi : ''
							return `${dusun ? 'Dusun ' + dusun.nama : ''}`
							return `${dusun ? dusun.nama : ''}, ${JENIS_DESA.toLowerCase()} ${kelurahan ? kelurahan.nama : ''}, Kec. ${kec ? kec.nama : ''}, ${kab ? kab.nama : ''}, ${prov ? prov.nama : ''}`
						}
					},
				},
				{
					dataField: 'nama_rw',
					text: 'RW',
					isDummyField: true,
					headerAlign: 'center',
					align: 'center',
					formatter: (cell, obj) => { return obj.data_rw ? obj.data_rw.nama : '' },
				},
				{
					dataField: 'nama',
					text: 'RT',
					headerAlign: 'center',
					align: 'center',
					sort: true
				},
				{
					dataField: 'aksi',
					text: 'Aksi',
					style: {
						width: '130px'
					},
					headerAlign: 'center',
					align: 'center',
					formatter: this.tombolAksiRT,
				},
			];
		}

		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
									<div className="row">
										<div className="col-lg-3">
											<Nav  className={'nav flex-column nav-pills'}>
												<NavItem>
													<NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
															onClick={() => { this.toggle_vertical('1'); }}>
														Dusun
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink className={classnames({ active: this.state.active_tab_vertical === '2' })}
															onClick={() => { this.toggle_vertical('2'); }}>
														RW
													</NavLink>
												</NavItem>
                        <NavItem>
													<NavLink className={classnames({ active: this.state.active_tab_vertical === '3' })}
															onClick={() => { this.toggle_vertical('3'); }}>
														RT
													</NavLink>
												</NavItem>
											</Nav>
										</div>
										<div className="col-lg-9">
											<TabContent activeTab={this.state.active_tab_vertical}>
												<TabPane tabId="1">
													<Row>
														<Col sm="12">
                              <div className="row">
                                <div className="col-sm-12">
                                    <div className="card mb-0">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5>Data Dusun</h5>
                                                </div>
                                                <div className="col-sm-6 text-right">
                                                  <Button
                                                    size="sm"
                                                    color="success"
                                                    onClick={() => {
                                                      Get('dusun/kel/'+ID_DESA+'/list', null, (data) => {
                                                        if(data.results.length!==0){
                                                            let akhir = data.results.length-1;
                                                            let getDataNext = data.results[akhir];
                                                            let nextID = getDataNext.id+1;
                                                            this.setState({
                                                                dataWilayahDusun:{
                                                                    ...this.state.dataWilayahDusun,
                                                                    next_id: nextID,
                                                                }
                                                            })
                                                        }
                                                        else{
                                                            let nextID = ID_DESA+'001';
                                                            this.setState({
                                                                dataWilayahDusun:{
                                                                    ...this.state.dataWilayahDusun,
                                                                    next_id: nextID,
                                                                }
                                                            })
                                                        }
                                                      });
                                                      this.tambahDataDusun();
                                                      // this.setState({
                                                      //   dtDusun: frmDusun,
                                                      // })
                                                    }}
                                                  >
                                                    Tambah Data
                                                  </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body datatable-react">
                                          {this.state.loading ? (
                                            <div className="row">
                                              <div className="col-sm-12" align="center">
                                                <img
                                                  alt="loading"
                                                  src={require("../../assets/images/tes-loading.gif")}
                                                  style={{
                                                    borderRadius: "10px",
                                                    width: '100px'
                                                  }} />
                                              </div>
                                            </div>
                                          ) : dataDusun.length > 0 ? (
                                            <>
                                            <BootstrapTable
                                                keyField="id"
                                                data={dataDusun}
                                                columns={columnsDusun}
                                            />
                                            <div className="pull-right text-white">
                                                {this.state.awalDusun}{this.state.sebelumDusun}{this.state.halDusun.map(dtDusun => { return dtDusun })}{this.state.setelahDusun}{this.state.akhirDusun}
                                            </div>
                                            </>
                                          ) : (
                                            <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                          )}
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <ModalDusun
                                data={this.state.dtDusun}
                                isOpen={this.state.statusDusun.form}
                                propsData={this.props}
                                additionalData={{
                                  dataWilayahDusun: this.state.dataWilayahDusun,
                                }}
                                setAlert={(e) => {
                                  this.setState(e)
                                }}
                                setStatusRefresh={(e) => {
                                  if (e) {
                                    this.fetchDusun({ page: null, where: [] });
                                  }
                                }}
                                setIsOpen={(e) => {
                                  if (!e) {
                                    this.setState({ dtDusun: null })
                                  }
                                  this.setState({
                                    statusDusun: {
                                      ...this.state.statusDusun,
                                      form: e
                                    }
                                  })
                                }}
                              />
														</Col>
													</Row>
												</TabPane>
												<TabPane tabId="2">
                        <Row>
														<Col sm="12">
                              <div className="row">
                                <div className="col-sm-12">
                                    <div className="card mb-0">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5>Data RW</h5>
                                                </div>
                                                <div className="col-sm-6 text-right">
                                                  <Button
                                                    size="sm"
                                                    color="success"
                                                    onClick={() => {
                                                      this.setState({
                                                        dtRW: frmRW,
                                                        dsnStatRW: [],
                                                      })
                                                      this.tambahDataRW();
                                                    }}
                                                  >
                                                    Tambah Data
                                                  </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body datatable-react">
                                          {this.state.loading ? (
                                            <div className="row">
                                              <div className="col-sm-12" align="center">
                                                <img
                                                  alt="loading"
                                                  src={require("../../assets/images/tes-loading.gif")}
                                                  style={{
                                                    borderRadius: "10px",
                                                    width: '100px'
                                                  }} />
                                              </div>
                                            </div>
                                          ) : dataRW.length > 0 ? (
                                            <>
                                            <BootstrapTable
                                                keyField="id"
                                                data={dataRW}
                                                columns={columnsRW}
                                            />
                                            <div className="pull-right text-white">
                                                {this.state.awalRW}{this.state.sebelumRW}{this.state.halRW.map(dtRW => { return dtRW })}{this.state.setelahRW}{this.state.akhirRW}
                                            </div>
                                            </>
                                          ) : (
                                            <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                          )}
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <ModalRW
                                data={this.state.dtRW}
                                isOpen={this.state.statusRW.form}
                                propsData={this.props}
                                optionData={{
                                  dusunList: this.state.dusunList,
                                }}
                                additionalData={{
                                  dataWilayahDusun: this.state.dataWilayahRW,
                                  dusunSet: this.state.dsnStatRW,
                                }}
                                setAlert={(e) => {
                                  this.setState(e)
                                }}
                                setStatusRefresh={(e) => {
                                  if (e) {
                                    this.fetchRW({ page: null, where: [] });
                                  }
                                }}
                                setIsOpen={(e) => {
                                  if (!e) {
                                    this.setState({ dtRW: null })
                                  }
                                  this.setState({
                                    statusRW: {
                                      ...this.state.statusRW,
                                      form: e
                                    }
                                  })
                                }}
                              />
														</Col>
													</Row>
												</TabPane>
												<TabPane tabId="3">
													<Row>
														<Col sm="12">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card mb-0">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5>Data RT</h5>
                                                </div>
                                                <div className="col-sm-6 text-right">
                                                  <Button
                                                    size="sm"
                                                    color="success"
                                                    onClick={() => {
                                                      this.setState({
                                                        dtRT: frmRT,
                                                        dusunSetRT: [],
                                                        rwList: [],
                                                        rwSetRT: [],
                                                      })
                                                      this.tambahDataRT();
                                                    }}
                                                  >
                                                    Tambah Data
                                                  </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body datatable-react">
                                          {this.state.loading ? (
                                            <div className="row">
                                              <div className="col-sm-12" align="center">
                                                <img
                                                  alt="loading"
                                                  src={require("../../assets/images/tes-loading.gif")}
                                                  style={{
                                                    borderRadius: "10px",
                                                    width: '100px'
                                                  }} />
                                              </div>
                                            </div>
                                          ) : dataRT.length > 0 ? (
                                            <>
                                            <BootstrapTable
                                                keyField="id"
                                                data={dataRT}
                                                columns={columnsRT}
                                            />
                                            <div className="pull-right text-white">
                                                {this.state.awalRT}{this.state.sebelumRT}{this.state.halRT.map(dtRT => { return dtRT })}{this.state.setelahRT}{this.state.akhirRT}
                                            </div>
                                            </>
                                          ) : (
                                            <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                          )}
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <ModalRT
                                data={this.state.dtRT}
                                isOpen={this.state.statusRT.form}
                                propsData={this.props}
                                optionData={{
                                  dusunList: this.state.dusunList,
                                  rwList: this.state.rwList,
                                }}
                                additionalData={{
                                  dataWilayah: this.state.dataWilayahRT,
                                  dusunSet: this.state.dusunSetRT,
                                  rwSet: this.state.rwSetRT,
                                  detailWilayahRW: this.state.detailWilayahRW,
                                }}
                                setAlert={(e) => {
                                  this.setState(e)
                                }}
                                setStatusRefresh={(e) => {
                                  if (e) {
                                    this.fetchRT({ page: null, where: [] });
                                  }
                                }}
                                setIsOpen={(e) => {
                                  if (!e) {
                                    this.setState({ dtRT: null })
                                  }
                                  this.setState({
                                    statusRT: {
                                      ...this.state.statusRT,
                                      form: e
                                    }
                                  })
                                }}
                              />
														</Col>
													</Row>
												</TabPane>
											</TabContent>
										</div>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

export default BasicTable;