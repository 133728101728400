import React, { Component } from "react";
import HeaderBaru from "../components/common/header/header.baru";
import SidebarAdmin from "./common/sidebar/sidebar.admin";
import SidebarSuperAdmin from "./common/sidebar/sidebar.superadmin";
import SidebarSuperOperator from "./common/sidebar/sidebar.superoperator";
// import Header from "../components/common/header/header.component";
// import { borderRadius } from "react-select/src/theme";

import { Link, Redirect } from "react-router-dom";
import { DataToken } from "../function/Format";
import { Get, GetMarketplace, ID_DESA, Nama_DESA } from "../function/Koneksi";

const JENIS_DESA = localStorage.getItem('JENIS_DESA')

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paid: null,
      lisensi: null,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      perizinan: [],
      dataNotif: [],
      paginationNotif: {
        total: 0
      },
      totalNotif: 0,
      pesanNotif: '',
      menuUtama: "inherit",
      menuWilayah: "none",
      menuPengaturan: "none",
      menuPerangkat: "none",
      menuLaporan: "none",
      menuAdministrasi: "none",
      menuSuku: "none",
      menuDaftarLayanan: "none",
      menuPerubahanData: "none",
      akun: DataToken(sessionStorage.getItem("access_token")),
      loading: true
    };
  }

  componentDidMount(){
    GetMarketplace("list-layanan-desa", ID_DESA, data => {
      if (data.result) {
        // get data notif
        this.getNotifData()

        if(data.result.data_api.data_invoice!==null){
          let tgl = Date.now();
          let set_tgl = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(tgl);
          let ex_tgl = set_tgl.split("/");
          let hari_ini = ex_tgl[2] + "-" + ex_tgl[0] + "-" + ex_tgl[1];
          
          let expired = data.result.data_api.data_invoice.tgl_expired;
          let ex_tgl_expired = expired.split(' ');
          let tgl_expired = ex_tgl_expired[0];
         
          let set_hari_ini = new Date(hari_ini)
          let set_expired = new Date(tgl_expired)

          if(set_hari_ini.getTime() > set_expired.getTime()){
            // Kondisi expired
            this.setState({ 
              lisensi: false, 
            })
          }
          else{
            // Kondisi belum expired
            this.setState({ 
              lisensi: true, 
            })
          }
        }

        if (data.result.pembayaran === 1) {
          this.setState({ paid: true })
        } else {
          this.setState({ paid: false })
        }

        this.setState({ loading: false })
        // For testing
        if (this.props.appLoading) {
          this.props.appLoading()
        }
      }
    })
  }

  getNotifData = () => {
    Get("list-notif", null, res => {
      let data = res.results.data.data;
      data = data.filter(day => day.status === '0');
      let total = res.results.total;
      // let total = data.length;
      let results = res.results;

      this.setState({
        dataNotif: data,
        paginationNotif: results.data,
        totalNotif: total,
      });
      // console.log(data);
      // console.log(total);

      if (data.length === 0) {
        this.setState({
          pesanNotif: "Belum ada Notifikasi"
        });
      } else {
        if (res.results.data.current_page === res.results.data.last_page || data.length <= 10 ) {
          this.setState({
            pesanNotif: ""
          });
        } 
      }
    });
  }

  fetchMoreNotif = () => {
    // console.log('tes');
    // console.log(this.state.paginationNotif);
    // console.log(this.state.dataNotif);
    // console.log(this.state.totalNotif);

    let tmpDataNotif = this.state.dataNotif
    if (tmpDataNotif.length < this.state.totalNotif) {
      // console.log('masuk');
      // if (this.state.dataNotif.length >= 10) {
        // console.log('masuk lebih sama dengan 10');
        let link = "?page=" + (parseInt(this.state.paginationNotif.current_page) + 1);
        this.setState({ pesanNotif: "Memuat notifikasi...." });
  
        Get("list-notif" + link, null, res => {
          let data = res.results.data.data;
          data = data.filter(day => day.status === '0');

          let dataNotif = [...tmpDataNotif, ...data]

          this.setState({
            dataNotif,
            paginationNotif: res.results.data
          });
          this.forceUpdate()
        });
      // }
    } else {
      this.setState({ pesanNotif: "" });
    }
  };

  bukaMenu = wil => {
    switch (wil) {
      case "laporan":
        this.setState({ menuUtama: "none", menuLaporan: "inherit" });
        break;
      case "wilayah":
        this.setState({ menuUtama: "none", menuWilayah: "inherit" });
        break;
      case "perangkat":
        this.setState({ menuUtama: "none", menuPerangkat: "inherit" });
        break;
      case "pengaturan":
        this.setState({ menuUtama: "none", menuPengaturan: "inherit" });
        break;

      case "perubahan_data":
        this.setState({
          menuUtama: "none",
          menuAdministrasi: "none",
          menuSuku: "none",
          menuDaftarLayanan: "none",
          menuPerubahanData: "inherit"
        });
        break;
      case "administrasi":
        this.setState({
          menuUtama: "none",
          menuPerubahanData: "none",
          menuSuku: "none",
          menuDaftarLayanan: "none",
          menuAdministrasi: "inherit"
        });
        break;
      case "suku":
        this.setState({
          menuUtama: "none",
          menuAdministrasi: "none",
          menuPerubahanData: "none",
          menuSuku: "inherit",
          menuDaftarLayanan: "none"
        });
        break;
      case "sub-daftar-layanan":
        this.setState({
          menuUtama: "none",
          menuAdministrasi: "none",
          menuPerubahanData: "none",
          menuSuku: "none",
          menuDaftarLayanan: "inherit"
        });
        break;
      case "utama":
        this.setState({
          menuPengaturan: "none",
          menuPerangkat: "none",
          menuWilayah: "none",
          menuLaporan: "none",
          menuAdministrasi: "none",
          menuSuku: "none",
          menuDaftarLayanan: "none",
          menuPerubahanData: "none",
          menuUtama: "inherit"
        });
        break;
      default:
        // no default
        break;
    }
  };

  render() {
    if (this.state.akun === null) {
      return <Redirect to="/" push={true} />;
    }

    let title = "";
    if (this.props.location) {
      if (this.props.location.pathname === "/admin/dashboard") {
        title = "Dashboard";
      } else if (this.props.location.pathname === "/admin/role") {
        title = "Role";
      } else if (this.props.location.pathname === "/admin/provinsi") {
        title = "Provinsi";
      } else if (this.props.location.pathname === "/admin/kabkota") {
        title = "Kabupaten/Kota";
      } else if (this.props.location.pathname === "/admin/kecamatan") {
        title = "Kecamatan";
      } else if (this.props.location.pathname === "/admin/keldes") {
        title = "Kelurahan/" + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/pekerjaan") {
        title = "Pekerjaan";
      } else if (this.props.location.pathname === "/admin/agama") {
        title = "Agama";
      } else if (this.props.location.pathname === "/admin/shdk") {
        title = "shdk";
      } else if (this.props.location.pathname === "/admin/jenjang-pendidikan") {
        title = "Jenjang Pendidikan";
      } else if (this.props.location.pathname === "/admin/surat-keterangan") {
        title = "Surat Keterangan Umum";
      } else if (this.props.location.pathname === "/admin/jabatan-perangkat") {
        title = "Jabatan Perangkat";
      } else if (this.props.location.pathname === "/admin/dusun") {
        title = "Dusun";
      } else if (this.props.location.pathname === "/admin/rw") {
        title = "RW";
      } else if (this.props.location.pathname === "/admin/rt") {
        title = "RT";
      } else if (this.props.location.pathname === "/admin/verifikasi") {
        title = "Verifikasi Data";
      } else if (this.props.location.pathname === "/admin/persyaratan-surat") {
        title = "Persyaratan Surat";
      } else if (this.props.location.pathname === "/admin/persyaratan-surat") {
        title = "Verifikasi Data";
      } else if (this.props.location.pathname === "/admin/pejabat-penandatangan") {
        title = "Pejabat Penandatangan";
      } else if (this.props.location.pathname === "/admin/daftar-aplikasi") {
        title = "Daftar Aplikasi";
      } else if (this.props.location.pathname === "/admin/nomor-surat") {
        title = "Nomor Surat";
      } else if (this.props.location.pathname === "/admin/jenis-surat") {
        title = "Jenis Surat";
      } else if (
        this.props.location.pathname === "/admin/surat-pernyataan-belum-menikah"
      ) {
        title = "Surat Belum Menikah";
      } else if (
        this.props.location.pathname === "/admin/surat-pernyataan-tidak-menikah"
      ) {
        title = "Surat Pernyataan Tidak Menikah";
      } else if (this.props.location.pathname === "/admin/kartu-keluarga") {
        title = "Kartu Keluarga";
      } else if (this.props.location.pathname === "/admin/verifikasi") {
        title = "Verifikasi Data";
      } else if (this.props.location.pathname === "/admin/data-potensi") {
        title = "Data Potensi Masyarakat";
      } else if (this.props.location.pathname === "/admin/perangkat") {
        title = "Perangkat " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/konfigurasi-kiosk") {
        title = "Konfigurasi Layanan KiosK";
      } else if (this.props.location.pathname === "/admin/operator") {
        title = "Operator";
      } else if (this.props.location.pathname === "/admin/pengguna") {
        title = "Pengguna";
      } else if (this.props.location.pathname === "/admin/lembaga-masyarakat") {
        title = "Lembaga Kemasyarakatan";
      } else if (this.props.location.pathname === "/admin/tanda-tangan") {
        title = "Tanda Tangan";
      } else if (this.props.location.pathname === "/admin/hari-libur") {
        title = "Hari Libur";
      } else if (this.props.location.pathname === "/admin/jam-kerja") {
        title = "Jam Kerja";
      } else if (this.props.location.pathname === "/admin/rekap-absensi") {
        title = "Rekap Absensi";
      } else if (this.props.location.pathname === "/admin/rekap-absensi-detail") {
        title = "Detail Rekap Absensi";
      } else if (this.props.location.pathname === "/admin/banner-dashboard") {
        title = "Banner Web";
      } else if (this.props.location.pathname === "/admin/surat-undangan") {
        title = "Surat undangan";
      } else if (this.props.location.pathname === "/admin/surat-pengantar-skck") {
        title = "Surat Pengantar SKCK";
      } else if (
        this.props.location.pathname === "/admin/surat-pernyataan-kehilangan"
      ) {
        title = "Surat Pernyataan Kehilangan";
      } else if (
        this.props.location.pathname ===
        "/admin/surat-keterangan-belum-memiliki-rumah"
      ) {
        title = "Surat Belum Memiliki rumah";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-jalan"
      ) {
        title = "Surat Keterangan Jalan";
      } else if (this.props.location.pathname === "/admin/surat-izin-keramaian") {
        title = "Surat Izin Keramaian";
      } else if (this.props.location.pathname === "/admin/surat-izin-mendirikan-bangunan") {
        title = "Surat Izin Mendirikan Bangunan";
      } else if (this.props.location.pathname === "/admin/surat-keterangan-tidak-sengketa") {
        title = "Surat Keterangan Tidak Sengketa";
      } else if (this.props.location.pathname === "/admin/surat-keterangan-beda-nama") {
        title = "Surat Keterangan Beda Nama";
      } else if (this.props.location.pathname === "/admin/surat-keterangan-penghasilan-orang-tua") {
        title = "Surat Keterangan Penghasilan Orang Tua";
      } else if (this.props.location.pathname === "/admin/surat-keterangan-penguburan") {
        title = "Surat Keterangan Penguburan";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-usaha"
      ) {
        title = "Surat Keterangan Usaha";
      } else if (this.props.location.pathname === "/admin/surat-kuasa-skgr") {
        title = "Surat Kuasa SKGR";
      } else if (this.props.location.pathname === "/admin/surat-kuasa") {
        title = "Surat Kuasa";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-penghasilan"
      ) {
        title = "Surat Keterangan Penghasilan";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-pindah-datang-wni"
      ) {
        title = "Surat Keterangan Pindah WNI";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-kematian"
      ) {
        title = "Surat Keterangan Kematian";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-pernah-menikah"
      ) {
        title = "Surat Keterangan Pernah Menikah";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-kelahiran"
      ) {
        title = "Surat Keterangan Kelahiran";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-ahli-waris"
      ) {
        title = "Surat Keterangan Ahli Waris";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-domisili"
      ) {
        title = "Surat Keterangan Domisili";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-domisili-usaha"
      ) {
        title = "Surat Keterangan Domisili Usaha";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-tidak-mampu"
      ) {
        title = "Surat Keterangan Tidak Mampu";
      } else if (
        this.props.location.pathname ===
        "/admin/surat-keterangan-kepemilikan-rumah"
      ) {
        title = "Surat Keterangan Kepemilikan Rumah";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-tidak-mampu"
      ) {
        title = "Surat Keterangan Tidak Mampu";
      } else if (
        this.props.location.pathname === "/admin/surat-keterangan-penyaksian-tanah"
      ) {
        title = "Surat Keterangan Penyaksian Tanah";
      } else if (this.props.location.pathname === "/admin/surat-perintah-perjalanan-dinas") {
        title = "Surat Perintah Perjalanan Dinas";
      } else if (this.props.location.pathname === "/admin/surat-perintah-tugas") {
        title = "Surat Perintah Tugas";
      } else if (this.props.location.pathname === "/admin/wilayah") {
        title = "Wilayah " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/profile-" + JENIS_DESA.toLowerCase()) {
        title = "Profile " + JENIS_DESA;
      // } else if (this.props.location.pathname === "/admin/pengaturan-" + JENIS_DESA.toLowerCase()) {
      //   title = "Pengaturan " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/struktur-organisasi-" + JENIS_DESA.toLowerCase()) {
        title = "Struktur Organisasi Pemerintah " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/absensi") {
        title = "Absensi";
      } else if (this.props.location.pathname === "/admin/aplikasi") {
        title = "Pengaturan Aplikasi";
      } else if (this.props.location.pathname === "/admin/catatan") {
        title = "Catatan Penduduk";
      } else if (this.props.location.pathname === "/admin/notif-desa") {
        title = "Notifikasi Desa";
      } else if (this.props.location.pathname === "/admin/bansos/master-kategori-bansos") {
        title = "Data Master Kategori Bansos";
      } else if (this.props.location.pathname === "/admin/bansos/master-bansos") {
        title = "Data Master Bansos";
      } else if (this.props.location.pathname === "/admin/bansos/statistik") {
        title = "Data Statistik";
      } else if (this.props.location.pathname === "/admin/bansos/detail-bansos") {
        title = "Detail Data Bansos";
      } else if (this.props.location.pathname === "/admin/bansos/detail-bansos-penduduk") {
        title = "Detail Data Bansos";
      } else if (this.props.location.pathname === "/admin/banner") {
        title = "Banner Mobile";
      } else if (this.props.location.pathname === "/admin/suku") {
        title = "Data Suku";
      } else if (this.props.location.pathname === "/admin/datuak") {
        title = "Data Datuak";
      } else if (this.props.location.pathname === "/admin/penduduksuku") {
        title = "Data Penduduk Suku";
      } else if (this.props.location.pathname === "/admin/layanan/kua/kua") {
        title = "Data Pelayanan KUA";
      } else if (
        this.props.location.pathname === "/admin/layanan/kua/pernikahan"
      ) {
        title = "Data Pelayanan Pernikahan KUA";
      } else if (
        this.props.location.pathname === "/admin/layanan/capil/disdukcapil"
      ) {
        title = "Data Pelayanan Disdukcapil";
      } else if (
        this.props.location.pathname === "/admin/layanan/kecamatan/beranda"
      ) {
        title = "Data Pelayanan Kecamatan";
      } else if (
        this.props.location.pathname === "/admin/layanan/capil/kelahiran"
      ) {
        title = "Data Pelayanan Kelahiran Disdukcapil";
      } else if (
        this.props.location.pathname === "/admin/layanan/capil/kematian"
      ) {
        title = "Data Pelayanan Kematian Disdukcapil";
      } else if (
        this.props.location.pathname === "/admin/layanan/capil/pindah-datang"
      ) {
        title = "Data Pelayanan Pindah Datang Disdukcapil";
      } else if (
        this.props.location.pathname === "/admin/layanan/capil/kartu-keluarga"
      ) {
        title = "Data Pelayanan Kartu Keluarga Disdukcapil";
      } else if (
        this.props.location.pathname === "/admin/layanan/kecamatan/pindah-datang"
      ) {
        title = "Data Pelayanan Surat Pindah Kecamatan";
      } else if (
        this.props.location.pathname === "/admin/layanan/kecamatan/rekomendasi-usaha"
      ) {
        title = "Data Pelayanan Surat Rekomendasi Izin Usaha";
      } else if (
        this.props.location.pathname === "/admin/layanan/kecamatan/iumk"
      ) {
        title = "Data Pelayanan Surat Rekomendasi Izin Usaha Mikro Kecil";
      } else if (this.props.location.pathname === "/admin/penduduk") {
        title = "Data Penduduk";
      } else if (
        this.props.location.pathname.indexOf("/admin/alur-disposisi") !== -1
      ) {
        title = "Alur Disposisi";
      } else if (this.props.location.pathname === "/admin/disposisi") {
        title = "Disposisi Surat";
      } else if (this.props.location.pathname === "/admin/berita") {
        title = "Berita dan Pengumuman";
      } else if (this.props.location.pathname === "/admin/perubahan-data/nik-kk") {
        title = "Perubahan Data Nik & KK";
      } else if (
        this.props.location.pathname === "/admin/perubahan-data/pindah-kk"
      ) {
        title = "Pindah KK";
      } else if (
        this.props.location.pathname === "/admin/perubahan-data/pengajuan"
      ) {
        title = "Pengajuan Perubahan Data";
      } else if (this.props.location.pathname === "/admin/pengaturan-aplikasi") {
        title = "Pengaturan Aplikasi";
      } else if (this.props.location.pathname === "/admin/data-masyarakat") {
        title = "Data Potensi Masyarakat";
      } else if (this.props.location.pathname === "/admin/notifikasi") {
        title = "Notifikasi";
      } else if (this.props.location.pathname === "/admin/wisata") {
        title = "Wisata";
      } else if (this.props.location.pathname === "/admin/laporan-potensi") {
        title = "Laporan Potensi Masyarakat";
      } else if (this.props.location.pathname === "/admin/data-master-potensi") {
        title = "Data Master Potensi Desa";
      } else if (this.props.location.pathname === "/admin/layanan/join-us") {
        title = "Bergabung Bersama Kami";
      } else if (this.props.location.pathname === "/admin/layanan/expired") {
        title = "Perpanjang Layanan Bersama Kami";
      } else if (this.props.location.pathname === "/admin/layanan/belum-setting") {
        title = "Konfigurasi Sedang Berlangsung";
      } else if (this.props.location.pathname === "/admin/buku") {
        title = "Buku Administrasi " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-umum") {
        title = "Buku Administrasi Umum";
      } else if (this.props.location.pathname === "/admin/buku-a1") {
        title = "Buku Peraturan di " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a2") {
        title = "Buku Keputusan Kepala " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a3") {
        title = "Buku Inventaris dan Kekayaan " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a4") {
        title = "Buku Aparat Pemerintah " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a5") {
        title = "Buku Tanah Kas " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a6") {
        title = "Buku Tanah di " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a7") {
        title = "Buku Agenda di " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a8") {
        title = "Buku Ekspedisi di " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-a9") {
        title = "Buku Lembaran " + JENIS_DESA + " dan Buku Berita " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-keuangan") {
        title = "Buku Administrasi Keuangan";
      } else if (this.props.location.pathname === "/admin/buku-penduduk") {
        title = "Buku Administrasi Penduduk";
      } else if (this.props.location.pathname === "/admin/buku-b1") {
        title = "Buku Induk Penduduk";
      } else if (this.props.location.pathname === "/admin/buku-b2") {
        title = "Buku Mutasi Penduduk " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-b3") {
        title = "Buku Rekapitulasi Jumlah Penduduk";
      } else if (this.props.location.pathname === "/admin/buku-b4") {
        title = "Buku Penduduk Sementara";
      } else if (this.props.location.pathname === "/admin/buku-b5") {
        title = "Buku Kartu Tanda Penduduk dan Kartu Keluarga";
      } else if (this.props.location.pathname === "/admin/buku-c1") {
        title = "Buku Anggaran Pendapatan dan Belanja " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-c2") {
        title = "Buku Rencana Anggaran Biaya";
      } else if (this.props.location.pathname === "/admin/buku-c3") {
        title = "Buku Kas Pembantu Kegiatan";
      } else if (this.props.location.pathname === "/admin/buku-c4") {
        title = "Buku Kas Umum";
      } else if (this.props.location.pathname === "/admin/buku-c5") {
        title = "Buku Kas Pembantu";
      } else if (this.props.location.pathname === "/admin/buku-c6") {
        title = "Buku Bank " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/c3/detail") {
        title = "Detail Kas Pembantu Kegiatan";
      } else if (this.props.location.pathname === "/admin/c2/detail") {
        title = "Detail Rencana Anggaran Biaya";
      } else if (this.props.location.pathname === "/admin/c1/detail") {
        title = "Detail Anggaran Pendapatan dan Belanja";
      } else if (this.props.location.pathname === "/admin/buku-pembangunan") {
        title = "Buku Administrasi Pembangunan";
      } else if (this.props.location.pathname === "/admin/buku-d1") {
        title = "Buku Rencana Kerja Pembangunan " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-d2") {
        title = "Buku Kegiatan Pembangunan " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-d3") {
        title = "Buku Inventaris Hasil-hasil Pembangunan " + JENIS_DESA;
      } else if (this.props.location.pathname === "/admin/buku-d4") {
        title = "Buku Kader Pemberdayaan Masyarakat " + JENIS_DESA;
      }
    }

    if (this.state.loading) {
      return (
        <div style={{ display: "table", margin: "0 auto", height: "100%" }}>
          <div className="row" style={{ display: "table-cell", verticalAlign: "middle" }}>
            <div className="col-sm-12" align="center">
              <img 
                src={require("../assets/images/tes-loading.gif")} 
                alt=""
                style={{
                  borderRadius: "10px",
                  width: '150px'
                }}
              /> 
            </div>
          </div>
        </div>
      )
    } else {

    let namaAdmin = ''
    if (typeof this.state.akun.sub.nama !== "undefined") {
      namaAdmin = this.state.akun.sub.nama
    }

    let nama_desa = localStorage.getItem('JENIS_DESA') + ' ' + localStorage.getItem('DESA');

    return (
      <div>
        <div className="page-wrapper box-layout" style={{ overflow: "auto" }}>
          <HeaderBaru 
            paid={this.state.paid} 
            lisensi={this.state.lisensi}
            dataNotif={this.state.dataNotif}
            totalNotif={this.state.totalNotif}
            paginationNotif={this.state.paginationNotif}
            pesanNotif={this.state.pesanNotif}
            refreshNotif={this.getNotifData}
            moreNotif={this.fetchMoreNotif}
           />
          <div className="page-body-wrapper">
            {this.props.menu}
            {this.state.akun.sub.data_role.nama_role === "Admin" ? (
              <SidebarAdmin paid={this.state.paid} lisensi={this.state.lisensi} />
              ) : this.state.akun.sub.data_role.nama_role === "Super Operator" ?
                (<SidebarSuperOperator />)
              :
                (<SidebarSuperAdmin />)
            }
            <div className="bayu-body" style={{ minHeight: 'calc(100vh - 200px)' }}>
              <div className="container-fluid">
                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                  <h6 style={{ fontSize: "1rem", display: "inline" }}><i className="fa fa-user"></i> Admin {namaAdmin}</h6>
                  {/* <h6 className="text-right pl-3" style={{ fontSize: "1rem", display: "inline" }}>{Nama_DESA}</h6> */}
                  <h6 className="text-right pl-3" style={{ fontSize: "1rem", display: "inline" }}>{nama_desa}</h6>
                </div>
                {title !== "Dashboard" ? (
                  <div className="page-header">
                    <div className="row">
                      <div className="col-lg-6">
                        <h3>
                          {title}
                          <small>
                            {JENIS_DESA && JENIS_DESA.toLowerCase() === "nagari"
                              ? "Aplikasi Manajemen Pelayanan Masyarakat Nagari"
                              : "Sistem Informasi Layanan " + JENIS_DESA}
                          </small>
                        </h3>
                      </div>
                      <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                          <li className="breadcrumb-item">
                            <Link to="/admin/dashboard" className="primary-custom">
                              <i className="fa fa-home" />
                            </Link>
                          </li>
                          <li className="breadcrumb-item">Dashboard</li>
                          {title !== "Dashboard" ? (
                            <li className="breadcrumb-item active">{title}</li>
                          ) : (
                            <div />
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              {this.props.children}
              {/* {newChildrenProps} */}
            </div>
          </div>
        </div>
      </div>
    );
    }
  }
}

export default App;