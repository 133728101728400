import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { Get, JENIS_DUSUN, JENIS_DESA, Post } from '../../../../function/Koneksi';

const ModalDetailPerangkat = ({ data, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {

	console.log('dataaaa detail perangkat')
	console.log(data)
	
	const frmLembaga = {
		uuid: '',
		nama_lembaga: '',
		tahun_berdiri: '',
		status: 1,
	}

	const initFormData = {
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmLembaga)

	
	const changeStatus = (e) => {
		e.preventDefault();
		if(dt.status === 0){
			setDt({
				...dt,
				status: 1,
			})
		}
		else{
			setDt({
				...dt,
				status: 0,
			})
		}
	};

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmLembaga)
		setFormData(data ? {
			...initFormData,
		} : initFormData)
	}, [isOpen])

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				// onSubmit={simpan} 
			>
				<div className="modal-header">
					<h5 className="modal-title">Detail Perangkat Lembaga</h5>
				</div>
				<div className="modal-body">
					<div className="col-md-12 text-center">
						<img alt="foto_perangkat" src={dt.foto} style={{ maxHeight: '330px', maxWidth: '100%', height: 'auto' }} />
					</div>
					<br/><br/>
					<div className="col-md-12 text-center">
						<div className='row'>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
							<div className='col-md-4' style={{textAlign:'left'}}><b>Nama Perangkat</b></div>
							<div className='col-md-4' style={{textAlign:'left'}}>: &nbsp; {dt.nama}</div>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
						</div>
						<br/>
						<div className='row'>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
							<div className='col-md-4' style={{textAlign:'left'}}><b>Jabatan</b></div>
							<div className='col-md-4' style={{textAlign:'left'}}>: &nbsp; {dt.jabatan}</div>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
						</div>
						<br/>
						<div className='row'>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
							<div className='col-md-4' style={{textAlign:'left'}}><b>Tanggal Mulai Jabatan</b></div>
							<div className='col-md-4' style={{textAlign:'left'}}>: &nbsp; {dt.tanggal_mulai}</div>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
						</div>
						<br/>
						<div className='row'>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
							<div className='col-md-4' style={{textAlign:'left'}}><b>Tanggal Selesai Jabatan</b></div>
							<div className='col-md-4' style={{textAlign:'left'}}>: &nbsp; {dt.tanggal_selesai}</div>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
						</div>
						<br/>
						<div className='row'>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
							<div className='col-md-4' style={{textAlign:'left'}}><b>Status</b></div>
							<div className='col-md-4' style={{textAlign:'left'}}>: &nbsp; {dt.status && dt.status===0?'Tidak Aktif':'Aktif'}</div>
							<div className='col-md-2' style={{textAlign:'right'}}>&nbsp;</div>
						</div>
						<br/>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalDetailPerangkat;
