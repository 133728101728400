import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
// import Datepicker from 'react-datepicker';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
// import CountUp from 'react-countup';
// import { Chart } from 'react-google-charts';
// import Highcharts, { map } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
// import { CommonLoading } from 'react-loadingg';

// koneksi
import {
	Post,
	Get,
	GetMarketplace,
	ID_DESA,
	JENIS_DESA,
	JENIS_DUSUN
} from '../function/Koneksi';
import { Tanggal } from '../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../assets/css/site.css';

// drilldown(Highcharts);


var moment = require('moment');

const frmDef = {
	id: '',
	id_rt: '',
	alamat: ''
};

// const { ExportCSVButton } = CSVExport;

class DataPotensi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_rt: [],
			pagination: [],
			pagination_rt: [],

			// Detail
			data_detail: [],
			data_detail_sumber_air: [],
			data_detail_kepemilikan_rumah: [],
			data_detail_penduduk: [],
			data_umur: [],
			pagination_detail: [],
			dataPenduduk: {
				nik: ''
			},

			status: {
				view: false,
				form: false,
				btnForm: false,
				btnAksi: false,
				dataPotensi: false,
				dataPotensi2: false,
				dataPotensi3: false,
				dataPotensi4: false,
				dataPotensi5: false,
				dataPotensi6: false,
				dataPotensi7: false,
				dataPotensi8: false,
				dataPotensi9: false,
				dataPotensi60: false,
				dataPotensi61: false,
				dataPotensi62: false,
				dataPotensi63: false
			},
			status2: {
				view: false,
				form: false,
				btnForm: false,
				btnEdit: false,
				btnDelete: false
			},
			datakk: {
				id: '',
				nama: '',
				tempat_lahir: '',
				nama_ibu_kandung: '',
				nama_ayah_kandung: ''
			},
			active_tab_icon: '1',

			// Pagination
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',

			// Pagination Detail
			awalDetail: '',
			sebelumDetail: '',
			halDetail: [],
			setelahDetail: '',
			akhirDetail: '',

			shdk: [],
			detailshdk: [],
			batasKK: '',
			penduduk_kk_baru: [],
			data_keluarga: [],
			diskk: false,
			tampilkk: 'inherit',
			wilayah: 'inherit',
			addForm: false,
			addMode: 'provinsi',
			addID: '',
			addNama: '',
			frmAnggota: 'none',
			detail: [],
			view_kk: '',
			view_penduduk: [],
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			pekerjaan: [],
			hubungan: [],
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			dusStat: null,
			frmDus: [],
			frmShdk: [],
			shdkStat: null,
			frmAgm: [],
			agmStat: null,
			frmPtr: [],
			ptrStat: null,
			frmPkr: [],
			pkrStat: null,
			frmKk: [],
			kkStat: null,
			frmStp: [],
			stpStat: null,
			frmJk: [],
			jkStat: null,
			frmSpk: [],
			spkStat: null,
			agmkkStat: null,
			ptrkkStat: null,
			pkrkkStat: null,
			stpkkStat: null,
			jkkkStat: null,
			spkkkStat: null,
			// ============ Tanggal ==============
			startDate: new Date(),
			startDatekk: new Date(),
			// ===================================
			data_penduduk: [],
			statUbah: false,
			pecahKK: false,

			// =========== Data Profil Desa ========
			data_desa: {
				id_keldes: '',
				nama_desa: '',
				kode_desa: '',
				alamat_desa: ''
			},

			// =========== Data Potensi ========
			data_kk_terdaftar: [],
			data_potensi_penduduk: [],
			list_penduduk: [],
			tmp_nik: '',
			filterValue: '',
			// Detail
			urlDetail: null,
			urlPartDetail: "",
			urlCari: "",
			tableDetail: null,
			option_jenis_sumber_air: [],
			option_jenis_kepemilikan_rumah: [],
			option_jenis_kepemilikan_lahan: [],
			option_komoditas: [],
			option_aset: [],
			option_ekonomi: [],
			option_aset_pilihan: [],
			option_galian: [],
			option_agama: [],
			option_pekerjaan: [],
			option_usia: [],
			option_pernikahan: [],

			// ============= Highcharts data ================
			data_agama: [],
			drilldown_data_agama: [],
			data_pekerjaan: [],
			drilldown_data_pekerjaan: [],
			data_usia: [],
			drilldown_data_usia: [],
			data_pernikahan: [],
			drilldown_data_pernikahan: [],
			data_air_minum: [],
			drilldown_data_air_minum: [],
			data_kepemilikan_rumah: [],
			drilldown_data_kepemilikan_rumah: [],
			data_kategori_usaha: [],
			drilldown_data_kategori_usaha: [],

			data_kepemilikan_lahan: [],
			drilldown_data_kepemilikan_lahan: [],

			data_detail_produksi: [],
			drilldown_detail_produksi: [],

			data_aset: [],
			drilldown_data_aset: [],

			data_lembaga_ekonomi: [],
			drilldown_data_lembaga_ekonomi: [],

			data_aset_pilihan: [],
			drilldown_data_aset_pilihan: [],

			// Data Kesehatan
			dataGroupKesehatan: [],
			drilldown_data_group_kesehatan: [],

			// Data Persalinan
			dataGroupPersalinan: [],
			drilldown_data_group_persalinan: [],
			
			// Data Penyakit
			dataGroupPenyakit: [],
			drilldown_data_group_penyakit: [],

			data_produksi_galian: [],
			drilldown_data_produksi_galian: []
		};
	}

	// ================== Tanggal ===================

	gantiTgl = (date) => {
		this.setState({ startDate: date });
	};

	// ==============================================

	// ================== Additional Form ==================
	addForm = (e, mode) => {
		e.preventDefault();
		if (mode === 'provinsi') {
			this.setState({ addMode: mode, addForm: true });
		} else {
			switch (mode) {
				case 'kabupaten/kota':
					if (this.state.prvStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Kabupaten/Kota',
							pesanAlert: 'Untuk menambah Kabupaten/Kota, Silakan pilih terlebih dahulu Provinsi nya.'
						});
					}
					break;
				case 'kecamatan':
					if (this.state.kbktStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Kecamatan',
							pesanAlert: 'Untuk menambah Kecamatan, Silakan pilih terlebih dahulu Kabupaten/Kota nya.'
						});
					}
					break;
				case 'kelurahan/desa':
					if (this.state.kecStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Kelurahan/' + JENIS_DESA,
							pesanAlert:
								'Untuk menambah Kelurahan/' +
								JENIS_DESA +
								', Silakan pilih terlebih dahulu Kecamatan nya.'
						});
					}
					break;
				case 'dusun':
					if (this.state.kelStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data ' + JENIS_DUSUN,
							pesanAlert:
								'Untuk menambah ' +
								JENIS_DUSUN +
								', Silakan pilih terlebih dahulu Kelurahan/' +
								JENIS_DESA +
								' nya.'
						});
					}
					break;
				case 'rw':
					if (this.state.dusStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data RW',
							pesanAlert: 'Untuk menambah RW, Silakan pilih terlebih dahulu ' + JENIS_DUSUN + ' nya.'
						});
					}
					break;
				case 'rt':
					if (this.state.rwStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data RT',
							pesanAlert: 'Untuk menambah RT, Silakan pilih terlebih dahulu RW nya.'
						});
					}
					break;
				default:
					// no default
					break;
			}
		}
	};

	tutupAddForm = (e) => {
		e.preventDefault();
		this.setState({ mode: 'provinsi', addID: '', addNama: '', addForm: false });
	};

	// =====================================================
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		// let link = '';
		// let link_rt = '';
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					// link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					// link_rt = '?page=' + Math.ceil(this.state.pagination_rt.total / this.state.pagination_rt.per_page);
					break;
				case '>':
					// link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					// link_rt = '?page=' + (parseInt(this.state.pagination_rt.current_page) + 1);
					break;
				case '<':
					// link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					// link_rt = '?page=' + (parseInt(this.state.pagination_rt.current_page) - 1);
					break;
				case '<<':
					// link = '?page=1';
					// link_rt = '?page=1';
					break;
				default:
					// link = '?page=' + params.page;
					// link_rt = '?page=' + params.page;
					break;
			}
		}

		// Get('potensi-desa/data-masyarakat' + link, null, (dtkat) => {
		// 	this.setState({ data: dtkat.results.data, pagination: dtkat.results });
		// 	this.pagination(dtkat.results);
		// });
		// Get('data-rt' + link_rt, null, (dtkat) => {
		// 	this.setState({
		// 		data_rt: dtkat.results.data,
		// 		pagination_rt: dtkat.results
		// 	});
		// 	this.pagination(dtkat.results);
		// });

		// Post('penduduk' + link, null, this.state.dataPenduduk, (dtkat) => {
		// 	//console.log('Data PENDUDUK BOS');
		// 	//console.log(dtkat);

		// 	let age_now = '';
		// 	let data_umur = [];
		// 	if (dtkat.data.results) {
		// 		dtkat.data.results.data.forEach((dt) => {
		// 			let today = new Date();
		// 			let birthDate = new Date(dt.tanggal_lahir); // create a date object directly from `dob1` argument
		// 			age_now = today.getFullYear() - birthDate.getFullYear();
		// 			let m = today.getMonth() - birthDate.getMonth();
		// 			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		// 				age_now--;
		// 			}
	
		// 			data_umur.push({
		// 				id: dt.id,
		// 				nama: dt.nama,
		// 				umur_sekarang: age_now,
		// 				tanggal_lahir: dt.tanggal_lahir
		// 			});
		// 		});
	
		// 		this.setState({
		// 			data_detail_penduduk: dtkat.data.results.data,
		// 			data_umur: data_umur,
		// 			pagination: dtkat.data.results,
		// 			pagination_detail: dtkat.data.results
		// 		});
		// 		this.pagination(dtkat.data.results);
		// 		this.paginationDetail(dtkat.data.results);
		// 	}
			
		// });
	};

	// =================== End Ambil Data DB ====================

	// =================== Ambil Data Detail ====================
	fetchDetail = (params = {}) => {
		let link = '';
		// let link_rt = '';
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					if(this.state.urlPartDetail==="")
					{
						if(this.state.urlCari===""){
							link = '?page=' + Math.ceil(this.state.pagination_detail.total / this.state.pagination_detail.per_page);
						}
						else{
							link = '&page=' + Math.ceil(this.state.pagination_detail.total / this.state.pagination_detail.per_page);
						}
					}
					else{
						link = '&page=' + Math.ceil(this.state.pagination_detail.total / this.state.pagination_detail.per_page);
					}
					break;
				case '>':
					if(this.state.urlPartDetail==="")
					{
						if(this.state.urlCari===""){
							link = '?page=' + (parseInt(this.state.pagination_detail.current_page) + 1);
						}
						else{
							link = '&page=' + (parseInt(this.state.pagination_detail.current_page) + 1);
						}
					}
					else{
						link = '&page=' + (parseInt(this.state.pagination_detail.current_page) + 1);
					}
					break;
				case '<':
					if(this.state.urlPartDetail==="")
					{
						if(this.state.urlCari===""){
							link = '?page=' + (parseInt(this.state.pagination_detail.current_page) - 1);
						}
						else{
							link = '&page=' + (parseInt(this.state.pagination_detail.current_page) - 1);
						}
					}
					else{
						link = '&page=' + (parseInt(this.state.pagination_detail.current_page) - 1);
					}
					break;
				case '<<':
					if(this.state.urlPartDetail==="")
					{
						if(this.state.urlCari===""){
							link = '?page=1';
						}
						else{
							link = '&page=1';
						}
					}
					else{
						link = '&page=1';
					}
					break;
				default:
					if(this.state.urlPartDetail==="")
					{
						if(this.state.urlCari===""){
							link = '?page=' + params.page;
						}
						else{
							link = '&page=' + params.page;
						}
					}
					else{
						link = '&page=' + params.page;
					}
					break;
			}
		}
		if (params.url) {
			Get(params.url + link, null, (dtkat) => {
				// //console.log("Data apa ini")
				// //console.log(params.url);
				// //console.log(link);
				// //console.log(dtkat);

				// this.state.data_detail = [];
				// this.state.pagination_detail = dtkat.results;
				// this.forceUpdate();

				this.setState({ data_detail: dtkat.results.data, data_detail_penduduk: dtkat.results.data, pagination_detail: dtkat.results, pagination: dtkat.results });
				this.paginationDetail(dtkat.results, params.url, params.table);
			});
			// this.state.data_detail_sumber_air = data.results.data;
			// if (params.table) {
			// 	this.state.judul = 'Data Potensi ' + params.table;
			// 	this.state.status.dataPotensi = true;
			// 	this.forceUpdate();
			// }
		}
	};

	// =================== End Ambil Data Detail ====================

	// ============================= Pagination ========================
	pagination = (data) => {
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i === data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						href={() => false}
						key={i}
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	paginationDetail = (data, urlDetail, table) => {
		let awalDetail = '';
		let halDetail = [];
		let sebelumDetail = '';
		let setelahDetail = '';
		let akhirDetail = '';

		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i === data.current_page) {
					warna = 'danger';
				}
				halDetail.push(
					<a
						href={() => false}
						key={i}
						onClick={() => this.fetchDetail({ page: i, url: urlDetail, table: table })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelumDetail = (
					<a
						href={() => false}
						onClick={() => this.fetchDetail({ page: p, url: urlDetail, table: table })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelahDetail = (
					<a
						href={() => false}
						onClick={() => this.fetchDetail({ page: n, url: urlDetail, table: table })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awalDetail = (
				<a
					href={() => false}
					onClick={() => this.fetchDetail({ page: 1, url: urlDetail, table: table })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhirDetail = (
				<a
					href={() => false}
					onClick={() => this.fetchDetail({ page: data.last_page, url: urlDetail, table: table })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awalDetail, sebelumDetail, halDetail, setelahDetail, akhirDetail });
	};

	// ============================= End Pagination ========================
	// =========================== Simpan User =====================================

	simpanUser = (e, mode) => {
		e.preventDefault();

		let formData = {
			penduduk_id: this.state.pendStat.value,
			username: document.getElementById('username').value,
			password: document.getElementById('password').value,
			email: document.getElementById('email').value
		};
		// //console.log(formData);
		let metode = 'tambah';

		var data_rt = new FormData();
		if (typeof this.state.pendStat !== 'undefined') {
			data_rt.append('nik', this.state.pendStat.value);
			data_rt.append('username', document.getElementById('username').value);
			data_rt.append('password', document.getElementById('password').value);
			data_rt.append('email', document.getElementById('email').value);

			// Continue this
			Post('rt/create', null, formData, (res) => {
				// //console.log(res);
				this.tutupForm();
				if (res === 200) {
					this.state.status.btnForm = false;
					this.forceUpdate();
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Potensi Masyarakat',
						pesanAlert: 'Berhasil ' + metode + ' Data'
					});
					this.componentDidMount();
				}
			});
		}
	};

	// ================== simpan data ==================
	simpan = (e, mode) => {
		e.preventDefault();
	};

	columnDetail = (e, table) => {
		if (table) {
			switch (table) {
				case 'agama':
					return [
						{
							dataField: 'id',
							text: 'NIK',
							sort: true
						},
						{
							dataField: 'nama',
							text: 'Nama',
							sort: true
						},
						{
							dataField: 'data_agama.nama',
							text: 'Agama',
							sort: true
						}
					];
				// break;
				case 'pekerjaan':
					return [
						{
							dataField: 'id',
							text: 'NIK',
							sort: true
						},
						{
							dataField: 'nama',
							text: 'Nama',
							sort: true
						},
						{
							dataField: 'data_pekerjaan.nama',
							text: 'Pekerjaan',
							sort: true
						}
					];
				// break;
				case 'usia':
					return [
						{
							dataField: 'id',
							text: 'NIK',
							sort: true
						},
						{
							dataField: 'nama',
							text: 'Nama',
							sort: true
						},
						{
							dataField: 'umur_sekarang',
							text: 'Usia',
							sort: true
						}
					];
				// break;
				case 'pernikahan':
					return [
						{
							dataField: 'id',
							text: 'NIK',
							sort: true
						},
						{
							dataField: 'nama',
							text: 'Nama',
							sort: true
						},
						{
							dataField: 'status_perkawinan',
							text: 'Status Perkawinan',
							sort: true
						}
					];
				// break;

				default:
					break;
			}
		}
	};

	// =======================================================================
	// =======================================================================
	// ============================ Cari Data ================================
	// =======================================================================
	// =======================================================================

	// =================== Cari Data Table ====================
	cariData = (e) => {
		if (e.key === 'Enter') {
			let dataPenduduk = {
				q: e.target.value
			};
			Post('potensi-desa/data-masyarakat', null, dataPenduduk, (lihat) => {
				this.setState({
					data: lihat.data.results.data,
					pagination: lihat.data.results
				});
				this.pagination(lihat.data.results);
			});
		}
	};

	// =================== Cari Detail Penduduk ====================

	cariDetailPenduduk = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/data-masyarakat'
				}
				else{
					urlDetail = 'potensi-desa/pagination/data-masyarakat'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/data-masyarakat?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};
	// ======================= End Cari Detail Penduduk =====================

	// ======================= Cari Sumber Air Minum =====================

	cariDetailSumberAirMinum = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/sumber-air-minum-masyarakat'
				}
				else{
					urlDetail = 'potensi-desa/pagination/sumber-air-minum-masyarakat'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/sumber-air-minum-masyarakat?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};

	// cariDetailSumberAirMinum = (e) => {
	// 	if (e.key === 'Enter') {
	// 		//console.log(e);
	// 		let params = {
	// 			q: e.target.value
	// 		};

	// 		let urlDetail = 'potensi-desa/pagination/sumber-air-minum-masyarakat?q=' + e.target.value;

	// 		if (this.state.urlDetail !== null) {
	// 			urlDetail = this.state.urlDetail + '&q=' + e.target.value;
	// 		}
	// 		// //console.log(urlDetail);

	// 		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
	// 	}
	// };
	// ======================= End Cari Sumber Air Minum =====================

	// ======================= Cari Kepemilikan Rumah =====================
	cariDetailKepemilikanRumah = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/kepemilikan-rumah'
				}
				else{
					urlDetail = 'potensi-desa/pagination/kepemilikan-rumah'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/kepemilikan-rumah?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};

	// ======================= End Cari Kepemilikan Rumah =====================

	// ======================= Cari Kepemilikan Lahan =====================
	cariDetailKepemilikanLahan = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/kepemilikan-lahan'
				}
				else{
					urlDetail = 'potensi-desa/pagination/kepemilikan-lahan'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/kepemilikan-lahan?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};

	// ======================= End Cari Kepemilikan Lahan =====================

	// ======================= Cari Detail Komoditas =====================
	cariDetailKomoditas = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/komoditas-masyarakat'
				}
				else{
					urlDetail = 'potensi-desa/pagination/komoditas-masyarakat'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/komoditas-masyarakat?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};

	// ======================= End Cari Detail Komoditas =====================

	// ======================= Cari Detail Aset =====================
	cariDetailAset = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/aset-masyarakat'
				}
				else{
					urlDetail = 'potensi-desa/pagination/aset-masyarakat'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/aset-masyarakat?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};

	// ======================= End Cari Detail Aset =====================

	// ======================= Cari Detail Ekonomi =====================

	cariDetailEkonomi = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/lembaga-ekonomi-masyarakat'
				}
				else{
					urlDetail = 'potensi-desa/pagination/lembaga-ekonomi-masyarakat'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/lembaga-ekonomi-masyarakat?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};
	// ======================= End Cari Detail Ekonomi =====================

	// ======================= Cari Detail Aset Pilihan =====================
	cariDetailAsetPilihan = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/aset-pilihan-masyarakat'
				}
				else{
					urlDetail = 'potensi-desa/pagination/aset-pilihan-masyarakat'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/aset-pilihan-masyarakat?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};
	// ======================= Cari Detail Aset Pilihan =====================
	// ======================= Cari Detail Produksi Galian =====================
	cariDetailProduksiGalian = (e) => {
		if (e.key === 'Enter') {
			let urlDetail = "";

			if(e.target.value===null || e.target.value === ""){
				if(this.state.urlDetail===""){
					urlDetail = 'potensi-desa/pagination/galian'
				}
				else{
					urlDetail = 'potensi-desa/pagination/galian'+this.state.urlPartDetail
				}
			}
			else{
				if(this.state.urlDetail===null){
					urlDetail = 'potensi-desa/pagination/galian?q=' + e.target.value;
				}
				else{
					urlDetail = this.state.urlDetail + '&q=' + e.target.value;
				}
			}

			this.state.urlDetail = urlDetail;
			this.state.urlCari = e.target.value;
			this.forceUpdate();
			this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: this.state.tableDetail });
		}
	};
	// ======================= End Cari Detail Produksi Galian =====================

	// =======================================================================
	// =======================================================================
	// ========================== End Cari Data ==============================
	// =======================================================================
	// =======================================================================

	// ========================================================================

	bukaView = (id) => {
		Get('kartu-keluarga/keluarga', id, (data) => {
			this.state.view_kk = id;
			this.state.view_penduduk = data.results;
			this.state.status.view = true;
			this.forceUpdate();
		});
	};

	tutupView = () => {
		this.state.status.view = false;
		this.forceUpdate();
	};

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.status.form = false;
		this.state.agmkkStat = null;
		this.state.pkrkkStat = null;
		this.state.ptrkkStat = null;
		this.state.spkkkStat = null;
		this.state.stpkkStat = null;
		this.state.jkkkStat = null;

		this.state.status.dataPotensi = false;
		this.state.status.dataPotensi2 = false;
		this.state.status.dataPotensi3 = false;
		this.state.status.dataPotensi4 = false;
		this.state.status.dataPotensi5 = false;
		this.state.status.dataPotensi6 = false;
		this.state.status.dataPotensi7 = false;
		this.state.status.dataPotensi8 = false;
		this.state.status.dataPotensi60 = false;
		this.state.status.dataPotensi61 = false;
		this.state.status.dataPotensi62 = false;
		this.state.status.dataPotensi63 = false;

		this.state.data_detail_penduduk = []
		this.state.urlPartDetail = ""
		this.state.urlCari = ""

		this.state.urlDetail = null;
		this.state.tableDetail = null;
		this.state.filterValue = null;
		this.state.awalDetail = '';
		this.state.halDetail = [];
		this.state.sebelumDetail = '';
		this.state.setelahDetail = '';
		this.state.akhirDetail = '';
		this.state.data_detail = [];

		this.state.dataPenduduk.nik = '';
		// this.componentDidMount();

		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		return (
			<div>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					// onClick={() => this.ubahData(row.id)}
					disabled={this.state.status2.btnEdit}
				>
					<i className="fa fa-pencil" />
				</Button>{' '}
				&nbsp;&nbsp;
				{/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.id)} disabled={this.state.status2.btnDelete}><i className="fa fa-trash"></i></Button> */}
			</div>
		);
	};

	componentDidMount(tab = '1') {
		this.fetch({ page: null, where: [], tab: '1' });

		// //console.log(this.state.data);

		if (tab === '1') {
			// Get jumlah penduduk
			Get('potensi-desa/jumlah-masyarakat', null, (data) => {
				if (data.results) {
					let data_potensi_penduduk = {
						total: data.results.total,
						laki_laki: data.results.laki_laki,
						perempuan: data.results.perempuan
					};
					// //console.log(data);
					this.setState({ data_potensi_penduduk });
				}
			});
	
			// Master Galian
			Get('potensi-desa/master/galian', null, (data2) => {
				let option_galian = [];
				// //console.log(data2.results);
	
				if (data2.results) {
					data2.results.forEach((e) => {
						// //console.log(e);
						option_galian.push({
							value: e.uuid,
							label: e.nama
						});
					});
				}
				
				this.setState({ option_galian });
			});
	
			// Highcharts data
			// Pie
			Get('potensi-desa/agama-masyarakat', null, (data) => {
				// //console.log("Data Agama")
				// //console.log(data.results)
				var data_agama = [];
				var drilldown_data_agama = []; // Fitur untuk detail
				let option_agama = [];
	
				if (data.results) {
					for (var [ index, agama ] of data.results.entries()) {
						if (agama.data_agama) {
							data_agama.push({
								name: agama.data_agama.nama,
								y: agama.total,
								drilldown: agama.data_agama.nama + index
							});
							option_agama.push({
								value: agama.data_agama.uuid,
								label: agama.data_agama.nama
							});
						}
					}
					this.setState({ data_agama });
					this.setState({ drilldown_data_agama });
					this.setState({ option_agama });
				}
				
			});
	
			Get('potensi-desa/pekerjaan-masyarakat', null, (data) => {
				// //console.log("Data Pekerjaan")
				// //console.log(data.results)
				var data_pekerjaan = [];
				var drilldown_data_pekerjaan = []; // Fitur untuk detail
				let option_pekerjaan = [];
	
				if (data.results) {
					for (var [ index, pekerjaan ] of data.results.entries()) {
						if (pekerjaan && pekerjaan.data_pekerjaan) {
							data_pekerjaan.push({
								name: pekerjaan.data_pekerjaan.nama,
								y: pekerjaan.total,
								drilldown: pekerjaan.data_pekerjaan.nama + index
							});
							option_pekerjaan.push({
								value: pekerjaan.data_pekerjaan.uuid,
								label: pekerjaan.data_pekerjaan.nama
							});
						}
					}
					this.setState({ data_pekerjaan });
					this.setState({ drilldown_data_pekerjaan });
					this.setState({ option_pekerjaan });
				}
				
			});
	
			Get('potensi-desa/rentang-umur-masyarakat', null, (data) => {
				// //console.log("Data Usia")
				// //console.log(data.results)
				var data_usia = [];
				var drilldown_data_usia = []; // Fitur untuk detail
				let option_usia = [];
	
				if (data.results) {
					data_usia.push({
						name: 'Balita (0-5 tahun)',
						y: data.results.balita,
						drilldown: 'balita'
					});
					data_usia.push({
						name: 'Kanak-kanak (5-11 tahun)',
						y: data.results.kanak_kanak,
						drilldown: 'kanak_kanak'
					});
					data_usia.push({
						name: 'Remaja Awal (12-16 tahun)',
						y: data.results.remaja_awal,
						drilldown: 'remaja_awal'
					});
					data_usia.push({
						name: 'Remaja Akhir (17-25 tahun)',
						y: data.results.remaja_akhir,
						drilldown: 'remaja_akhir'
					});
					data_usia.push({
						name: 'Dewasa Awal (26-35 tahun)',
						y: data.results.dewasa_awal,
						drilldown: 'dewasa_awal'
					});
					data_usia.push({
						name: 'Dewasa Akhir (36-45 tahun)',
						y: data.results.dewasa_akhir,
						drilldown: 'dewasa_akhir'
					});
					data_usia.push({
						name: 'Lansia Awal (46-55 tahun)',
						y: data.results.lansia_awal,
						drilldown: 'lansia_awal'
					});
					data_usia.push({
						name: 'Lansia Akhir (56-65 tahun)',
						y: data.results.lansia_akhir,
						drilldown: 'lansia_akhir'
					});
					data_usia.push({
						name: 'Manula (> 65 tahun)',
						y: data.results.manula,
						drilldown: 'manula'
					});
		
					Object.keys(data.results).forEach((attribute) => {
						let labelUsia = attribute.replace('_', ' ')
		
						option_usia.push({
							value: attribute,
							label: labelUsia.charAt(0).toUpperCase() + labelUsia.slice(1)
						});
					})
		
					this.setState({ data_usia });
					this.setState({ drilldown_data_usia });
					this.setState({ option_usia });
				}
				
			});

			Get('potensi-desa/aset-pilihan-masyarakat', null, (data) => {
				// console.log(data);

				let dataGroupKesehatan = [];
				let dataGroupPersalinan = [];
				let dataGroupPenyakit = [];

				var data_aset_pilihan = [];
				var drilldown_data_group_kesehatan = [];
				var drilldown_data_group_persalinan = [];
				var drilldown_data_group_penyakit = [];
	
				if (data.results) {
					for (var [ index, jenis_aset_pilihan ] of data.results.entries()) {
						if (jenis_aset_pilihan.total > 0) {
							// KESEHATAN
							if (
								jenis_aset_pilihan.nama.toLowerCase() === 'perilaku hidup bersih' ||
								jenis_aset_pilihan.nama.toLowerCase() === 'pola makan keluarga' ||
								jenis_aset_pilihan.nama.toLowerCase() === 'cakupan imunisasi'
							) {
								dataGroupKesehatan.push({
									name: jenis_aset_pilihan.nama,
									y: jenis_aset_pilihan.total,
									drilldown: jenis_aset_pilihan.nama + index
								})
								
								var sub_aset_pilihan = [];
								jenis_aset_pilihan.child.map((aset_pilihan, key) => {
									// console.log(aset_pilihan);
									sub_aset_pilihan.push([ aset_pilihan.nama, aset_pilihan.total ]);
								})
	
								drilldown_data_group_kesehatan.push({
									id: jenis_aset_pilihan.nama + index,
									name: 'Jumlah ',
									data: sub_aset_pilihan
								});
							} 
							// PERSALINAN
							else if (
								jenis_aset_pilihan.nama.toLowerCase() === 'kualitas persalinan' ||
								jenis_aset_pilihan.nama.toLowerCase() === 'kualitas ibu hamil' ||
								jenis_aset_pilihan.nama.toLowerCase() === 'status gizi balita' ||
								jenis_aset_pilihan.nama.toLowerCase() === 'kualitas bayi' 
							) {
								dataGroupPersalinan.push({
									name: jenis_aset_pilihan.nama,
									y: jenis_aset_pilihan.total,
									drilldown: jenis_aset_pilihan.nama + index
								})
								
								var sub_aset_pilihan = [];
								jenis_aset_pilihan.child.map((aset_pilihan, key) => {
									// console.log(aset_pilihan);
									sub_aset_pilihan.push([ aset_pilihan.nama, aset_pilihan.total ]);
								})
								
								drilldown_data_group_persalinan.push({
									id: jenis_aset_pilihan.nama + index,
									name: 'Jumlah ',
									data: sub_aset_pilihan
								});
							}
							// PENYAKIT
							else if (
								jenis_aset_pilihan.nama.toLowerCase() === 'cacat fisik' || 
								jenis_aset_pilihan.nama.toLowerCase() === 'cacat mental' || 
								jenis_aset_pilihan.nama.toLowerCase() === 'jenis penyakit yang diderita'
							) {
								dataGroupPenyakit.push({
									name: jenis_aset_pilihan.nama,
									y: jenis_aset_pilihan.total,
									drilldown: jenis_aset_pilihan.nama + index
								})
								
								var sub_aset_pilihan = [];
								jenis_aset_pilihan.child.map((aset_pilihan, key) => {
									// console.log(aset_pilihan);
									sub_aset_pilihan.push([ aset_pilihan.nama, aset_pilihan.total ]);
								})
								
								drilldown_data_group_penyakit.push({
									id: jenis_aset_pilihan.nama + index,
									name: 'Jumlah ',
									data: sub_aset_pilihan
								});
							}
						}
					}
					// console.log(drilldown_data_group_kesehatan);
		
					if (dataGroupKesehatan.length > 0) {
						this.setState({ dataGroupKesehatan, drilldown_data_group_kesehatan });
					}
					if (dataGroupPersalinan.length > 0) {
						this.setState({ dataGroupPersalinan, drilldown_data_group_persalinan })
					}
					if (dataGroupPenyakit.length > 0) {
						this.setState({ dataGroupPenyakit, drilldown_data_group_penyakit })
					}

				}
				
			});
	
			// Donut
			Get('potensi-desa/pernikahan-masyarakat', null, (data) => {
				var data_pernikahan = [];
				var drilldown_data_pernikahan = []; // Fitur untuk detail
				let option_pernikahan = [];
	
				if (data.results) {
					for (var [ index, data_nikah ] of data.results.entries()) {
						data_pernikahan.push({
							name: data_nikah.status_perkawinan,
							y: data_nikah.total,
							drilldown: data_nikah.status_perkawinan + index
						});
						option_pernikahan.push({
							value: data_nikah.status_perkawinan,
							label: data_nikah.status_perkawinan
						});
					}
					this.setState({ data_pernikahan });
					this.setState({ drilldown_data_pernikahan });
					this.setState({ option_pernikahan });
				}
				
			});
			Get('potensi-desa/sumber-air-minum', null, (data) => {
				// //console.log(data.results);
				var data_air_minum = [];
				var drilldown_data_air_minum = []; // Fitur untuk detail
				let option_jenis_sumber_air = [];
	
				if (data.results) {
					for (var [ index, air_minum ] of data.results.entries()) {
						if (air_minum.data_sumber !== null) {
							data_air_minum.push({
								name: air_minum.data_sumber.nama,
								y: air_minum.total,
								drilldown: air_minum.data_sumber.nama + index
							});
							option_jenis_sumber_air.push({
								value: air_minum.data_sumber.uuid,
								label: air_minum.data_sumber.nama
							});
						}
					}
		
					this.setState({ data_air_minum });
					this.setState({ drilldown_data_air_minum });
					this.setState({ option_jenis_sumber_air });
				}
				
			});
			Get('potensi-desa/kepemilikan-rumah', null, (data) => {
				var data_kepemilikan_rumah = [];
				var drilldown_data_kepemilikan_rumah = []; // Fitur untuk detail
				let option_jenis_kepemilikan_rumah = [];
	
				if (data.results) {
					for (var [ index, rumah ] of data.results.entries()) {
						data_kepemilikan_rumah.push({
							name: rumah.data_sumber_kepemilikan.nama_status,
							y: rumah.total,
							drilldown: rumah.data_sumber_kepemilikan.nama_status + index
						});
						option_jenis_kepemilikan_rumah.push({
							value: rumah.data_sumber_kepemilikan.uuid,
							label: rumah.data_sumber_kepemilikan.nama_status
						});
					}
		
					this.setState({ data_kepemilikan_rumah });
					this.setState({ drilldown_data_kepemilikan_rumah });
					this.setState({ option_jenis_kepemilikan_rumah });
				}
				
			});
	
			// Statistik
			// Kategori usaha
			// Get('profile-desa', null, (dtkat) => {
				// //console.log(dtkat.results)
				GetMarketplace('chart/chart-kategori-usaha/' + ID_DESA, null, (data) => {
					var data_kategori_usaha = [];
					// var drilldown_data_kategori_usaha = [];
	
					if (typeof data.result !== 'undefined') {
						for (var [ index, kategori ] of data.result.entries()) {
							if (kategori.data_kategori_usaha !== null) {
								data_kategori_usaha.push({
									name: kategori.data_kategori_usaha.kategori_usaha,
									y: kategori.jumlah,
									drilldown: kategori.data_kategori_usaha.kategori_usaha + index
								});
							}
							// else {
							// 	data_kategori_usaha.push({
							// 		name: 'Toko Pribadi',
							// 		y: kategori.jumlah,
							// 		drilldown: 'Toko Pribadi' + index
							// 	});
							// }
						}
					}
					this.setState({ data_kategori_usaha });
					// this.setState({ drilldown_data_kategori_usaha });
				});
			// });
			// Galian
			Get('potensi-desa/galian', null, (data) => {
				console.log(data);
				var data_produksi_galian = [];
				var drilldown_data_produksi_galian = [];
	
				if (typeof data.results !== 'undefined') {
					data.results.forEach((jenis_galian, index) => {
						if (jenis_galian.total > 0) {
							data_produksi_galian.push({
								name: jenis_galian.nama,
								y: jenis_galian.total,
								drilldown: jenis_galian.nama + index
							});
		
							var drilldown2 = [];
							jenis_galian.child.forEach((kepemilikan, index2) => {
								// var drilldown3_id = kepemilikan.nama + index2;
								drilldown2.push({
									name: kepemilikan.nama,
									y: kepemilikan.total
									// drilldown: drilldown3_id
								});
		
								// var drilldown3 = [];
								// kepemilikan.child.forEach((penduduk, index3) => {
								// 	if (penduduk.data_penduduk !== null) {
								// 		drilldown3.push([ penduduk.data_penduduk.nama, penduduk.hasil ]);
								// 	}
								// });
		
								// // Push turunan ketiga
								// if (drilldown3.length > 0) {
								// 	drilldown_data_produksi_galian.push({
								// 		id: drilldown3_id,
								// 		// name: 'Jumlah ' + kepemilikan.nama,
								// 		name: 'Nominal transaksi pertahun',
								// 		data: drilldown3
								// 	});
								// }
							});
		
							drilldown_data_produksi_galian.push({
								id: jenis_galian.nama + index,
								// name: 'Total Kepemilikan ' + jenis_galian.nama,
								name: 'Jumlah warga yang memiliki',
								data: drilldown2
							});
						}
					});
				}
	
				this.setState({
					data_produksi_galian,
					drilldown_data_produksi_galian
				});
				// //console.log(this.state.data_produksi_galian);
				// //console.log(this.state.drilldown_data_produksi_galian);
			});
			
		} else if (tab === '2') {
			// Master kepemilikan lahan
			Get('potensi-desa/master/kepemilikan-lahan', null, (data2) => {
				let option_jenis_kepemilikan_lahan = [];
				// //console.log(data2.results);
	
				if (data2.results) {
					data2.results.forEach((e) => {
						// //console.log(e);
						option_jenis_kepemilikan_lahan.push({
							value: e.uuid,
							label: e.nama
						});
					});
		
					this.setState({ option_jenis_kepemilikan_lahan });
				}
				
			});
	
			// Master Komoditas
			Get('potensi-desa/master/komoditas', null, (data2) => {
				let option_komoditas = [];
				// //console.log(data2.results);
	
				if (data2.results) {
					data2.results.forEach((e) => {
						// //console.log(e);
						option_komoditas.push({
							value: e.uuid,
							label: e.nama
						});
					});
					this.setState({ option_komoditas });
				}
				
			});
	
			// Master Aset
			Get('potensi-desa/master/aset', null, (data2) => {
				let option_aset = [];
				// //console.log(data2.results);
	
				if (data2.results) {
					data2.results.forEach((e) => {
						// //console.log(e);
						option_aset.push({
							value: e.uuid,
							label: e.nama
						});
					});
		
					this.setState({ option_aset });
				}
				
			});
	
			// Master Ekonomi
			Get('potensi-desa/master/lembaga-ekonomi', null, (data2) => {
				let option_ekonomi = [];
				// //console.log(data2.results);
	
				if (data2.results) {
					data2.results.forEach((e) => {
						// //console.log(e);
						option_ekonomi.push({
							value: e.uuid,
							label: e.nama
						});
					});
				}
				
				this.setState({ option_ekonomi });
			});
	
			// Master Aset Pilihan
			Get('potensi-desa/master/aset-pilihan', null, (data2) => {
				let option_aset_pilihan = [];
				// //console.log(data2.results);
	
				if (data2.results) {
					data2.results.forEach((e) => {
						// //console.log(e);
						option_aset_pilihan.push({
							value: e.uuid,
							label: e.nama
						});
					});
				}
				
				this.setState({ option_aset_pilihan });
			});
	
			// Statistik
			Get('potensi-desa/kepemilikan-lahan', null, (data) => {
				var data_kepemilikan_lahan = [];
				var drilldown_data_kepemilikan_lahan = [];
	
				if (data.results) {
					for (var [ index, jenis_lahan ] of data.results.entries()) {
						var tmp_jumlah = 0;
						var sub_lahan = []; // Untuk drilldown
		
						// //console.log(data.results);
		
						for (var [ lahan ] of jenis_lahan.child.entries()) {
							if (lahan.status !== 'Tidak Memiliki') {
								sub_lahan.push([ lahan.status, lahan.total ]); // Untuk drilldown
								tmp_jumlah += lahan.total;
							}
						}
		
						// if (tmp_jumlah > 0) {
						data_kepemilikan_lahan.push({
							name: jenis_lahan.nama,
							y: tmp_jumlah,
							drilldown: jenis_lahan.nama + index
						});
		
						drilldown_data_kepemilikan_lahan.push({
							id: jenis_lahan.nama + index,
							// name: 'Total Kepemilikan ' + jenis_lahan.nama,
							name: 'Jumlah warga yang memiliki',
							data: sub_lahan
						});
						// }
					}
		
					this.setState({ data_kepemilikan_lahan });
					this.setState({ drilldown_data_kepemilikan_lahan });
				}
				
			});
			Get('potensi-desa/komoditas-masyarakat', null, (data) => {
				var data_detail_produksi = [];
				var drilldown_detail_produksi = [];
	
				if (data.results) {
					data.results.forEach((jenis_komoditas, index) => {
						data_detail_produksi.push({
							name: jenis_komoditas.nama,
							y: jenis_komoditas.total,
							drilldown: jenis_komoditas.nama + index
						});
		
						var drilldown2 = [];
						if (jenis_komoditas.child.length > 0) {
							jenis_komoditas.child.forEach((komoditas, index2) => {
								var drilldown3_id = komoditas.nama + index2;
								// set data series
								drilldown2.push({
									name: komoditas.nama,
									y: komoditas.total,
									drilldown: drilldown3_id
								});
		
								var drilldown3 = [];
								if (typeof komoditas.child !== 'undefined') {
									komoditas.child.forEach((penduduk, index3) => {
										if (penduduk.data_penduduk !== null) {
											drilldown3.push([ penduduk.data_penduduk.nama, parseInt(penduduk.jumlah) ]);
										}
									});
								}
		
								// push data ke drilldown
								if (drilldown3.length > 0) {
									drilldown_detail_produksi.push({
										id: drilldown3_id,
										name: 'Jumlah ' + komoditas.nama,
										data: drilldown3
									});
								}
							});
						}
		
						if (drilldown2.length > 0) {
							drilldown_detail_produksi.push({
								id: jenis_komoditas.nama + index,
								// name: 'Total Kepemilikan ' + jenis_komoditas.nama,
								name: 'Jumlah warga yang memiliki',
								data: drilldown2
							});
						}
					});
		
					this.setState({ data_detail_produksi });
					this.setState({ drilldown_detail_produksi });
				}
			});
			Get('potensi-desa/aset-masyarakat', null, (data) => {
				var data_aset = []; // data turunan petama
				var drilldown_data_aset = []; // data turunan kedua,dst
	
				// //console.log(data.results);
	
				if (data.results !== null || typeof data.results !== 'undefined') {
					for (var [ index, kategori_aset ] of data.results.entries()) {
						data_aset.push({
							name: kategori_aset.nama,
							y: parseInt(kategori_aset.total),
							drilldown: kategori_aset.nama + index
						});
						var sub_data_aset = [];
						for (var [ aset ] of kategori_aset.child.entries()) {
							var tmp_id = '';
	
							// var sub_data_aset2 = [];
							// for (var [ index3, penduduk ] of aset.child.entries()) {
							// 	tmp_id = aset.nama + index3;
							// 	if (penduduk.data_penduduk !== null) {
							// 		sub_data_aset2.push([ penduduk.data_penduduk.nama, penduduk.jumlah ]);
							// 	}
							// }
	
							// // push data turunan ketiga
							// if (sub_data_aset2.length > 0) {
							// 	drilldown_data_aset.push({
							// 		id: tmp_id,
							// 		name: 'Jumlah ' + aset.nama,
							// 		data: sub_data_aset2
							// 	});
							// }
	
							// pengumpulan data turunan kedua
							sub_data_aset.push({
								name: aset.nama,
								y: aset.total,
								drilldown: tmp_id
							});
						}
						// push data turunan kedua
						drilldown_data_aset.push({
							id: kategori_aset.nama + index,
							name: 'Jumlah Kepemilikan',
							data: sub_data_aset
						});
					}
				}
	
				this.setState({ data_aset });
				this.setState({ drilldown_data_aset });
				// //console.log(this.state.data_aset);
				// //console.log(this.state.drilldown_data_aset);
			});
			Get('potensi-desa/lembaga-ekonomi-masyarakat', null, (data) => {
				// //console.log(data);
				var data_lembaga_ekonomi = [];
				var drilldown_data_lembaga_ekonomi = [];
	
				if (typeof data.results !== 'undefined') {
					for (var [ jenis_lembaga_ekonomi ] of data.results.entries()) {
						data_lembaga_ekonomi.push({
							name: jenis_lembaga_ekonomi.data_lembaga.nama,
							y: jenis_lembaga_ekonomi.total
							// drilldown: jenis_lembaga_ekonomi.data_lembaga.nama + index
						});
	
						// var listPenduduk = [];
						// if (typeof jenis_lembaga_ekonomi.child !== 'undefined') {
						// 	jenis_lembaga_ekonomi.child.map((el, index) => {
						// 		if (el.data_penduduk !== null) {
						// 			listPenduduk.push([ el.data_penduduk.nama, el.nominal ]);
						// 		}
						// 	});
						// }
	
						// // add drilldown
						// drilldown_data_lembaga_ekonomi.push({
						// 	id: jenis_lembaga_ekonomi.data_lembaga.nama + index,
						// 	name: 'Nominal Transaksi pertahun',
						// 	data: listPenduduk
						// });
					}
				}
	
				this.setState({ data_lembaga_ekonomi });
				this.setState({ drilldown_data_lembaga_ekonomi });
			});
			Get('potensi-desa/aset-pilihan-masyarakat', null, (data) => {
				// //console.log(data.results);
				var data_aset_pilihan = [];
				var drilldown_data_aset_pilihan = [];
	
				if (data.results) {
					for (var [ index, jenis_aset_pilihan ] of data.results.entries()) {
						data_aset_pilihan.push({
							name: jenis_aset_pilihan.nama,
							y: jenis_aset_pilihan.total,
							drilldown: jenis_aset_pilihan.nama + index
						});
						var sub_aset_pilihan = [];
						for (var [ aset_pilihan ] of jenis_aset_pilihan.child.entries()) {
							sub_aset_pilihan.push([ aset_pilihan.nama, aset_pilihan.total ]);
						}
						drilldown_data_aset_pilihan.push({
							id: jenis_aset_pilihan.nama + index,
							// name: 'Total Kepemilikan',
							name: 'Jumlah ',
							data: sub_aset_pilihan
						});
					}
		
					this.setState({ data_aset_pilihan });
					this.setState({ drilldown_data_aset_pilihan });
				}
				
			});
			// END KEPEMILIKAN -------------------
		}
		
	}

	// getDataPenduduk = (table, url = null) => {
	// 	let data_detail_agama = [];
	// 	let urlDetail = 'potensi-desa/pagination/sumber-air-minum-masyarakat';

	// 	if (url !== null) {
	// 		urlDetail = url;
	// 	}

	// 	this.state.urlDetail = url;
	// 	this.state.tableDetail = table;
	// 	this.forceUpdate();

	// 	this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	// 	this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results });
	//         this.pagination(dtkat.data.results);
	// };

	getDataSumberAir = (table, url = null) => {
		// let data_detail_sumber_air = [];
		let urlDetail = 'potensi-desa/pagination/sumber-air-minum-masyarakat';

		if (url !== null) {
			urlDetail = url;
		}

		// this.state.urlDetail = url;
		// this.state.tableDetail = table;
		// this.forceUpdate();
		this.setState({ urlDetail: url, tableDetail: table })

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataMasyarakat = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/data-masyarakat';
		if (url !== null) {
			urlDetail = url;
		}

		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataKepemilikanRumah = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/kepemilikan-rumah';

		if (url !== null) {
			urlDetail = url;
		}

		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataKepemilikanLahan = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/kepemilikan-lahan';

		if (url !== null) {
			urlDetail = url;
		}

		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataKomoditas = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/komoditas-masyarakat';

		if (url !== null) {
			urlDetail = url;
		}

		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataAset = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/aset-masyarakat';

		if (url !== null) {
			urlDetail = url;
		}

		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataEkonomi = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/lembaga-ekonomi-masyarakat';

		if (url !== null) {
			urlDetail = url;
		}
		//console.log(urlDetail);
		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataAsetPilihan = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/aset-pilihan-masyarakat';

		if (url !== null) {
			urlDetail = url;
		}
		//console.log(urlDetail);
		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getDataProduksiGalian = (table, url = null) => {
		let urlDetail = 'potensi-desa/pagination/galian';

		if (url !== null) {
			urlDetail = url;
		}
		//console.log(urlDetail);
		this.state.urlDetail = url;
		this.state.tableDetail = table;
		this.forceUpdate();

		this.fetchDetail({ page: null, where: [], tab: '1', url: urlDetail, table: table });
	};

	getJumlahKeluarga = (cell, row) => {
		let jumlah = 0;
		if (Object.keys(row.data_keluarga).length > 0) {
			jumlah = Object.keys(row.data_keluarga).length;
		}
		return jumlah + ' Orang';
	};

	getKepalaKeluarga = (cell, row) => {
		const kepala_keluarga = row.data_kk.data_keluarga.find(this.findKepalaKeluarga);
		// //console.log(row);
		if (kepala_keluarga !== undefined) {
			return kepala_keluarga.nama;
		} else {
			return row.data_kk.data_keluarga[0].nama;
		}
	};

	findKepalaKeluarga = (list_data_keluarga) => {
		if (list_data_keluarga.data_shdk.nama === 'Kepala Keluarga') {
			return list_data_keluarga.nama;
		}
	};

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			// switch (tab) {
			// 	case '1':
			// 		// this.fetch({ page: null, where: [], tab: '2' });
			// 		break;
			// 	case '2':
			// 		// this.fetch({ page: null, where: [], tab: '3' });
			// 		break;
			// 	default:
			// 		break;
			// }
			this.componentDidMount(tab)
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	highchartPieFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'pie',
				height: 280
			},
			credits: {
				enabled: false
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: 'Total: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 20,
						overflow: 'allow',
						style: {
							fontSize: '9px'
						}
					}
					// showInLegend: true
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				}
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: 'previous',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	highchartDonutFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				// plotBackgroundColor: null,
				// plotBorderWidth: 0,
				plotShadow: false,
				height: 280
			},
			credits: {
				enabled: false
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 10,
						style: {
							fontSize: '9px'
						}
					}
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				},
				align: 'center'
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: 'Total',
					type: 'pie',
					innerSize: '60%',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	highchartStatisticFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'column'
				// scrollablePlotArea: {
				// minWidth: 900,
				// 	scrollPositionX: 1
				// }
			},
			credits: {
				enabled: false
			},
			title: {
				text: title_chart
			},
			xAxis: {
				type: 'category',
				// title: {
				// 	text: null
				// },
				min: 0,
				max: 6
				// scrollbar: {
				// 	enabled: true
				// },
				// tickLength: 0
			},
			legend: {
				enabled: false
			},
			series: [
				{
					name: 'Jumlah warga yang memiliki',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			},
			scrollbar: {
				enabled: true
			}
		};
		return options;
	};

	render() {
		// //console.log("Cek Aja")
		// //console.log(this.state.option_usia)
		// //console.log("Url Part: "+this.state.urlPartDetail)
		// //console.log("URL Cari: "+this.state.urlCari)
		// //console.log("Url Detail: "+this.state.urlDetail)
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		// var data = this.state.data;
		var data_rt = this.state.data_rt;
		var data_potensi_penduduk = this.state.data_potensi_penduduk;
		// var data_kk_terdaftar = this.state.data_kk_terdaftar;
		var data_detail_penduduk = this.state.data_detail_penduduk;
		var data_umur = this.state.data_umur;

		// var persentase_data_masyarakat = data_kk_terdaftar.total / data_potensi_penduduk.total * 100;

		const Highcharts = require('highcharts/highstock');
		drilldown(Highcharts);

		// const frmDef = {
		// 	id: '',
		// 	id_rt: 0,
		// 	alamat: ''
		// };

		const { SearchBar } = Search;
		// #tablecolumn
		// const columns = [
		// 	{
		// 		dataField: 'no_kk',
		// 		text: 'Nomor KK',
		// 		sort: true
		// 		// formatter: this.getKepalaKeluarga
		// 	},
		// 	{
		// 		dataField: 'data_penduduk.data_keldes.nama',
		// 		text: 'Nama ' + JENIS_DESA,
		// 		sort: true
		// 	},
		// 	{
		// 		dataField: 'data_penduduk.nama',
		// 		text: 'Nama Penduduk',
		// 		sort: true
		// 	},
		// 	// {
		// 	// 	dataField: 'data_kk',
		// 	// 	text: 'Nama Kepala Keluarga',
		// 	// 	sort: true,
		// 	// 	formatter: this.getKepalaKeluarga
		// 	// },
		// 	{
		// 		dataField: 'data_penduduk.alamat',
		// 		text: 'Alamat',
		// 		sort: true
		// 		// isDummyField: true,
		// 		// formatter: this.getJumlahKeluarga
		// 	}
		// 	// {
		// 	// 	dataField: 'data_user.username',
		// 	// 	text: 'Diinputkan oleh',
		// 	// 	sort: true
		// 	// }
		// ];

		const columns_rt = [
			{
				dataField: 'username',
				text: 'Username',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama RT',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				formatter: this.tombolAksi
			}
		];

		// =================== Kolom Detail Agama =================
		const columns_detail_agama = [
			{
				dataField: 'id',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_agama.nama',
				text: 'Agama',
				sort: true
			}
		];
		// =================== End Kolom Detail Agama =================

		// =================== Kolom Detail Pekerjaan =================
		const columns_detail_pekerjaan = [
			{
				dataField: 'id',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_pekerjaan.nama',
				text: 'Pekerjaan',
				sort: true
			}
		];
		// =================== End Kolom Detail Pekerjaan =================

		// =================== Kolom Detail Umur =================
		const columns_detail_umur = [
			{
				dataField: 'id',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'tanggal_lahir',
				text: 'Usia',
				sort: true,
				headerAlign: "center",
				align: "center",
				formatter: (cell, obj) => {
					const now = new Date()
					const tglLahir = new Date(obj.tanggal_lahir)

					let tgl1 = moment(now)
					let tgl2 = moment(tglLahir)

					const yearDiff = tgl1.diff(tgl2, 'years')

					return yearDiff
				}
			},
			{
				dataField: 'tanggal_lahir',
				text: 'Rentang Usia',
				sort: true,
				formatter: (cell, obj) => {
					const now = new Date()
					const tglLahir = new Date(obj.tanggal_lahir)

					let tgl1 = moment(now)
					let tgl2 = moment(tglLahir)

					const yearDiff = tgl1.diff(tgl2, 'years')

					let rentangUsia = ''

					if (yearDiff > 0 && yearDiff <= 5) {
						rentangUsia = "Balita"
					} else if (yearDiff > 5 && yearDiff <= 11) {
						rentangUsia = "Kanak Kanak"
					} else if (yearDiff > 11 && yearDiff <= 16) {
						rentangUsia = "Remaja Awal"
					} else if (yearDiff > 16 && yearDiff <= 25) {
						rentangUsia = "Remaja Akhir"
					} else if (yearDiff > 25 && yearDiff <= 35) {
						rentangUsia = "Dewasa Awal"
					} else if (yearDiff > 35 && yearDiff <= 45) {
						rentangUsia = "Dewasa Akhir"
					} else if (yearDiff > 45 && yearDiff <= 55) {
						rentangUsia = "Lansia Awal"
					} else if (yearDiff > 55 && yearDiff <= 65) {
						rentangUsia = "Lansia Akhir"
					} else if (yearDiff > 65) {
						rentangUsia = "Manula"
					}

					return rentangUsia
				}
			},
			// {
			// 	dataField: 'umur_sekarang',
			// 	text: 'Umur (Tahun)',
			// 	sort: true,
			// 	align: 'center'
			// },
			// {
			// 	dataField: 'rentang_umur',
			// 	text: 'Rentang Umur',
			// 	sort: true,
			// 	align: 'center'
			// }
		];
		// =================== End Kolom Detail Umur =================

		// =================== Kolom Detail Perkawinan =================
		const columns_detail_perkawinan = [
			{
				dataField: 'id',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'status_perkawinan',
				text: 'Status Perkawinan',
				sort: true
			}
		];
		// =================== Kolom Detail Perkawinan =================

		// =================== Kolom Detail Sumber Air =================
		const columns_sumber_air = [
			// {
			// 	dataField: 'no',
			// 	text: 'No. ',
			// 	sort: true,
			// 	formatter: (cell, row, rowIndex) => {
			// 		return rowIndex + 1;
			// 	}
			// },
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_sumber.nama',
				text: 'Jenis Sumber Air',
				sort: true
			}
			// {
			// 	dataField: 'status',
			// 	text: 'Status',
			// 	sort: true
			// }
		];
		// =================== End Kolom Detail Sumber Air =================

		// =================== Kolom Detail Kepemilikan Rumah =================
		const columns_kepemilikan_rumah = [
			// {
			// 	dataField: 'no',
			// 	text: 'No. ',
			// 	sort: true,
			// 	formatter: (cell, row, rowIndex) => {
			// 		return rowIndex + 1;
			// 	}
			// },
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_sumber_kepemilikan.nama_status',
				text: 'Jenis Kepemilikan',
				sort: true
			}
			// {
			// 	dataField: 'status',
			// 	text: 'Status',
			// 	sort: true
			// }
		];
		// =================== End Kolom Detail Kepemilikan Rumah =================

		// =================== Kolom Detail Kepemilikan Lahan =================
		const columns_kepemilikan_lahan = [
			// {
			// 	dataField: 'no',
			// 	text: 'No. ',
			// 	sort: true,
			// 	formatter: (cell, row, rowIndex) => {
			// 		return rowIndex + 1;
			// 	}
			// },
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_sumber_kepemilikan.nama',
				text: 'Jenis Kepemilikan',
				sort: true
			}
			// {
			// 	dataField: 'status',
			// 	text: 'Status',
			// 	sort: true
			// }
		];
		// =================== End Kolom Detail Kepemilikan Lahan =================

		// =================== Kolom Detail Komoditas Masyarakat =================
		const columns_komoditas_masyarakat = [
			// {
			// 	dataField: 'no',
			// 	text: 'No. ',
			// 	sort: true,
			// 	formatter: (cell, row, rowIndex) => {
			// 		return rowIndex + 1;
			// 	}
			// },
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_komoditas.nama',
				text: 'Jenis Komoditas',
				sort: true
			}
		];
		// =================== End Kolom Detail Komoditas Masyarakat =================

		// =================== Kolom Detail Aset Masyarakat =================
		const columns_aset_masyarakat = [
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_kategori.nama',
				text: 'Aset',
				sort: true
			}
		];
		// =================== End Kolom Detail Aset Masyarakat =================

		// =================== Kolom Detail Lembaga Ekonomi Masyarakat =================
		const columns_lembaga_ekonomi = [
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_lembaga.nama',
				text: 'Lembaga Ekonomi',
				sort: true
			}
		];
		// =================== End Kolom Detail Lembaga Ekonomi Masyarakat =================

		// =================== Kolom Detail Aset Pilihan Masyarakat =================
		const columns_aset_pilihan = [
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_kategori.nama',
				text: 'Aset',
				sort: true
			}
		];
		// =================== End Kolom Detail Aset Pilihan Masyarakat =================

		// =================== Kolom Detail Produksi Galian Masyarakat =================
		const columns_produksi_galian = [
			{
				dataField: 'no_kk',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'master_produksi_galian.nama',
				text: 'Jenis Galian',
				sort: true
			}
		];
		// =================== End Kolom Detail Produksi Galian Masyarakat =================

		// const defaultSorted = [
		// 	{
		// 		dataField: 'nama_KartuKeluarga',
		// 		order: 'asc'
		// 	}
		// ];

		// const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
		// 	const handleClick = (e) => {
		// 		e.preventDefault();
		// 		this.fetch({ where: [], page: page });
		// 	};
		// 	var classname = '';
		// 	classname = 'btn btn-success';
		// 	return (
		// 		<li className="page-item pl-1" key={page}>
		// 			<a href={() => false} onClick={handleClick} className={classname}>
		// 				{page}
		// 			</a>
		// 		</li>
		// 	);
		// };

		// const options = {
		// 	alwaysShowAllBtns: true,
		// 	pageButtonRenderer,
		// 	// custom: true,
		// 	paginationTotalRenderer: () => (
		// 		<span className="react-bootstrap-table-pagination-total">
		// 			Showing {from} to {to} of {total} Results
		// 		</span>
		// 	),
		// 	paginationSize: size,
		// 	totalSize: total
		// };

		// const chartAgama = () => {
		// 	return <div />;
		// };

		return (
			<div>
				{/* <Breadcrumb title="Data Potensi Masyarakat" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <h5></h5> */}
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span>< span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<Nav tabs className={'tabs-color'}>
										<NavItem>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '1'
												})}
												onClick={() => {
													this.toggle_icon('1');
												}}
											>
												<i className="icofont icofont-ui-home"> </i>Data Kependudukan
											</NavLink>
										</NavItem>
										{/* <NavItem>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '2'
												})}
												onClick={() => {
													this.toggle_icon('2');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />Data Kepemilikan
											</NavLink>
										</NavItem> */}
										{/* <NavItem>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '3'
												})}
												onClick={() => {
													this.toggle_icon('3');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />Data RT
											</NavLink>
										</NavItem> */}
									</Nav>

									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											<div style={{ padding: 15 }}>
												<Row>
													<Col sm="4">
														<div className="card o-hidden">
															<div className="bg-success b-r-4 card-body">
																<div className="media static-top-widget">
																	<div className="align-self-center text-center">
																		<i className="icofont icofont-users-social" />
																	</div>
																	<div className="media-body">
																		<span className="m-0">Jumlah Penduduk</span>
																		<h4 className="counter mb-0">
																			{/* <CountUp end={500} /> */}
																			<span>{data_potensi_penduduk.total}</span>
																		</h4>
																		<i className="icofont icofont-users-social icon-bg" />
																	</div>
																</div>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card o-hidden">
															<div className="bg-info b-r-4 card-body">
																<div className="media static-top-widget">
																	<div className="align-self-center text-center">
																		<i className="icofont icofont-business-man" />
																	</div>
																	<div className="media-body">
																		<span className="m-0">
																			Jumlah Penduduk Laki-laki
																		</span>
																		<h4 className="counter mb-0">
																			<span>
																				{data_potensi_penduduk.laki_laki}
																			</span>
																		</h4>
																		<i className="icofont icofont-business-man icon-bg" />
																	</div>
																</div>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card o-hidden">
															<div className="bg-danger b-r-4 card-body">
																<div className="media static-top-widget">
																	<div className="align-self-center text-center">
																		<i className="icofont icofont-girl" />
																	</div>
																	<div className="media-body">
																		<span className="m-0">
																			Jumlah Penduduk Perempuan
																		</span>
																		<h4 className="counter mb-0">
																			<span>
																				{data_potensi_penduduk.perempuan}
																			</span>
																		</h4>
																		<i className="icofont icofont-girl icon-bg" />
																	</div>
																</div>
															</div>
														</div>
													</Col>
												</Row>

												{this.state.data_agama.length > 0 || this.state.data_pekerjaan.length > 0 || this.state.data_usia.length > 0 ?(
													<Row>
														{this.state.data_agama.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartPieFunction(
																				this.state.data_agama,
																				this.state.drilldown_data_agama,
																				'Data Agama Masyarakat'
																			)}
																		/>
																		<div align="center">
																			<button
																				className="btn btn-sm btn-info"
																				onClick={() => {
																					this.getDataMasyarakat('Agama');
																					this.state.judul = 'Data Detail Agama';
																					this.state.status.dataPotensi60 = true;
																					this.state.dataPenduduk.nik = '';
																					this.forceUpdate();
																				}}
																				style={{width: '220px'}}
																			>
																				<span>Lihat data</span>
																			</button>
																		</div>
																	</div>
																</div>
															</Col>
														) : ''}
														{this.state.data_pekerjaan.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartPieFunction(
																				this.state.data_pekerjaan,
																				this.state.drilldown_data_pekerjaan,
																				'Data Pekerjaan Masyarakat'
																			)}
																		/>
																		<div align="center">
																			<button
																				className="btn btn-sm btn-info"
																				onClick={() => {
																					this.getDataMasyarakat('Pekerjaan');
																					this.state.judul = 'Data Detail Pekerjaan Masyarakat';
																					this.state.status.dataPotensi61 = true;
																					this.state.dataPenduduk.nik = '';
																					this.forceUpdate();

																					// this.state.judul =
																					// 	'Data Detail ' + 'Pekerjaan Masyarakat';
																					// this.state.status.dataPotensi61 = true;
																					// this.state.dataPenduduk.nik = '';
																					// this.forceUpdate();
																				}}
																				style={{width: '220px'}}
																			>
																				<span>Lihat data</span>
																			</button>
																		</div>
																	</div>
																</div>
															</Col>
														) : ''}
														{this.state.data_usia.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartPieFunction(
																				this.state.data_usia,
																				this.state.drilldown_data_usia,
																				'Data Rentang Usia Masyarakat'
																			)}
																		/>

																		<div align="center">
																			<button
																				className="btn btn-sm btn-info"
																				onClick={() => {
																					this.getDataMasyarakat('Usia');
																					this.state.judul = 'Data Detail ' +
																					'Rentang Usia Masyarakat';
																					this.state.status.dataPotensi62 = true;
																					this.state.dataPenduduk.nik = '';
																					this.forceUpdate();
																				}}
																				style={{width: '220px'}}
																			>
																				<span>Lihat data</span>
																			</button>
																		</div>
																	</div>
																</div>
															</Col>
														) : ''}
													</Row>
												) : ''}
												
												{this.state.data_pernikahan.length > 0 || this.state.data_air_minum.length > 0 || this.state.data_kepemilikan_rumah.length > 0 ? (
													<Row>
														{this.state.data_pernikahan.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartDonutFunction(
																				this.state.data_pernikahan,
																				this.state.drilldown_data_pernikahan,
																				'Data Status Perkawinan Masyarakat'
																			)}
																		/>

																		<div align="center">
																			<button
																				className="btn btn-sm btn-info"
																				onClick={() => {
																					this.getDataMasyarakat('Pernikahan');
																					this.state.judul = 'Data Detail Perkawinan Masyarakat';
																					this.state.status.dataPotensi63 = true;
																					this.state.dataPenduduk.nik = '';
																					this.forceUpdate();


																					// this.state.judul =
																					// 	'Data Detail ' +
																					// 	'Perkawinan Masyarakat';
																					// this.state.status.dataPotensi63 = true;
																					// this.state.dataPenduduk.nik = '';
																					// this.forceUpdate();
																				}}
																				style={{width: '220px'}}
																			>
																				<span>Lihat data</span>
																			</button>
																		</div>
																	</div>
																</div>
															</Col>
														) : ''}
														{this.state.data_air_minum.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartDonutFunction(
																				this.state.data_air_minum,
																				this.state.drilldown_data_air_minum,
																				'Data Sumber Air Minum Masyarakat'
																			)}
																		/>
																		<div align="center">
																			<button
																				className="btn btn-sm btn-info"
																				onClick={() => {
																					this.getDataSumberAir('Sumber Air Minum');
																					this.state.judul =
																						'Data Detail Sumber Air Minum';
																					this.state.status.dataPotensi = true;
																					this.forceUpdate();
																				}}
																				style={{width: '220px'}}
																			>
																				<span>Lihat data</span>
																			</button>
																		</div>
																	</div>
																</div>
															</Col>
														) : ''}
														{this.state.data_kepemilikan_rumah.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartDonutFunction(
																				this.state.data_kepemilikan_rumah,
																				this.state.drilldown_data_kepemilikan_rumah,
																				'Data Kepemilikan Rumah Masyarakat'
																			)}
																		/>
																		<div align="center">
																			<button
																				className="btn btn-sm btn-info"
																				onClick={() => {
																					this.getDataKepemilikanRumah(
																						'Kepemilikan Rumah'
																					);
																					this.state.judul =
																						'Data Detail Kepemilikan Rumah';
																					this.state.status.dataPotensi2 = true;
																					this.forceUpdate();
																				}}
																				style={{width: '220px'}}
																			>
																				<span>Lihat data</span>
																			</button>
																		</div>
																	</div>
																</div>
															</Col>
														) : ''}
													</Row>
												) : ''}
												
												{/* Data Kesehatan */}
												{this.state.dataGroupKesehatan.length > 0 || this.state.dataGroupPersalinan.length > 0 || this.state.dataGroupPenyakit.length > 0 ? ( 
													<Row style={{ justifyContent: 'center' }}>
														{this.state.dataGroupKesehatan.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartPieFunction(
																				this.state.dataGroupKesehatan,
																				this.state.drilldown_data_group_kesehatan,
																				'Data Kesehatan Masyarakat'
																			)}
																		/>
																	</div>
																</div>
															</Col>
														) : ''}
														{this.state.dataGroupPersalinan.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartPieFunction(
																				this.state.dataGroupPersalinan,
																				this.state.drilldown_data_group_persalinan,
																				'Data Persalinan Masyarakat'
																			)}
																		/>
																	</div>
																</div>
															</Col>
														) : ''}
														{this.state.dataGroupPenyakit.length > 0 ? (
															<Col sm="4">
																<div className="card">
																	<div className="card-body chart-block p-2">
																		<HighchartsReact
																			highcharts={Highcharts}
																			options={this.highchartPieFunction(
																				this.state.dataGroupPenyakit,
																				this.state.drilldown_data_group_penyakit,
																				'Riwayat Penyakit Masyarakat'
																			)}
																		/>
																	</div>
																</div>
															</Col>
														) : ''}
													</Row>
												) : ''}

												{this.state.data_kategori_usaha.length > 0 ? (
													<div
														className="row"
														style={{ display: 'flex', justifyContent: 'center' }}
													>
														<Col sm="12">
															<div className="card">
																<div className="card-body chart-block p-2">
																	<HighchartsReact
																		highcharts={Highcharts}
																		options={this.highchartStatisticFunction(
																			this.state.data_kategori_usaha,
																			this.state.drilldown_data_kategori_usaha,
																			'Data Kategori Usaha Masyarakat'
																		)}
																	/>
																</div>
															</div>
														</Col>
													</div>
												) : ''}
												
												{/* Data Lembaga Ekonomi */}
												{/* <Row>
													<Col sm="12">
														<div className="card">
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_lembaga_ekonomi,
																		this.state.drilldown_data_lembaga_ekonomi,
																		'Data Lembaga Ekonomi Masyarakat'
																	)}
																/>
																<div align="center">
																	<button
																		className="btn btn-sm btn-info"
																		onClick={() => {
																			this.getDataEkonomi('Lembaga Ekonomi');
																			this.state.judul =
																				'Data Detail ' + 'Lembaga Ekonomi';
																			this.state.status.dataPotensi6 = true;
																			this.forceUpdate();
																		}}
																		style={{width: '220px'}}
																	>
																		<span>Lihat data</span>
																	</button>
																</div>
															</div>
														</div>
													</Col>
												</Row> */}

												{/* DATA GALIAN */}
												{this.state.data_produksi_galian.length > 0 ? (
													<Row>
														<Col sm="12">
															<div className="card">
																<div className="card-body chart-block">
																	<HighchartsReact
																		highcharts={Highcharts}
																		options={this.highchartStatisticFunction(
																			this.state.data_produksi_galian,
																			this.state.drilldown_data_produksi_galian,
																			'Data Produksi Galian Masyarakat'
																		)}
																	/>
																	<div align="center">
																		<button
																			className="btn btn-sm btn-info"
																			onClick={() => {
																				this.getDataProduksiGalian(
																					'Produksi Galian'
																				);
																				this.state.judul =
																					'Data Detail Produksi Galian';
																				this.state.status.dataPotensi8 = true;
																				this.forceUpdate();
																			}}
																			style={{width: '220px'}}
																		>
																			<span>Lihat data</span>
																		</button>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												) : (
													<Row>
														<Col sm="12">
															<div className="card">
																<div className="card-body chart-block">
																	<div align="center">
																		<h4>Data Galian</h4>
																		<h5>Kosong</h5>
																		{/* <button
																			className="btn btn-sm btn-info"
																			onClick={() => {
																				this.getDataProduksiGalian(
																					'Produksi Galian'
																				);
																				this.state.judul =
																					'Data Detail Produksi Galian';
																				this.state.status.dataPotensi8 = true;
																				this.forceUpdate();
																			}}
																			style={{width: '220px'}}
																		>
																			<span>Lihat data</span>
																		</button> */}
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												)}

											</div>
										</TabPane>
										<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
												{/* Kepemilikan Lahan */}
												<Row>
													<Col sm="12">
														<div className="card">
															{this.state.data_kepemilikan_lahan.length > 0 ? (
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_kepemilikan_lahan,
																		this.state.drilldown_data_kepemilikan_lahan,
																		'Data Kepemilikan Lahan Masyarakat'
																	)}
																/>
																<div align="center">
																	<button
																		className="btn btn-sm btn-info"
																		onClick={() => {
																			this.getDataKepemilikanLahan(
																				'Kepemilikan Lahan'
																			);
																			this.state.judul =
																				'Data Detail Kepemilikan Lahan';
																			this.state.status.dataPotensi3 = true;
																			this.forceUpdate();
																		}}
																		style={{width: '220px'}}
																	>
																		<span>Lihat data</span>
																	</button>
																</div>
															</div>
															) : (
																<div className="card-body chart-block">
																	<h5 style={{ textAlign: "center" }}>Data Kepemilikan Lahan Masyarakat</h5>
																</div>
															)}
														</div>
													</Col>
												</Row>
												{/* Data Komoditas */}
												<Row>
													<Col sm="12">
														<div className="card">
															{this.state.data_detail_produksi.length > 0 ? (
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_detail_produksi,
																		this.state.drilldown_detail_produksi,
																		'Data Komoditas Masyarakat'
																	)}
																/>
																<div align="center">
																	<button
																		className="btn btn-sm btn-info"
																		onClick={() => {
																			this.getDataKomoditas(
																				'Komoditas Masyarakat'
																			);
																			this.state.judul =
																				'Data Detail Komoditas Masyarakat';
																			this.state.status.dataPotensi4 = true;
																			this.forceUpdate();
																		}}
																		style={{width: '220px'}}
																	>
																		<span>Lihat data</span>
																	</button>
																</div>
															</div>
															) : (
																<div className="card-body chart-block">
																	<h5 style={{ textAlign: "center" }}>Data Komoditas Masyarakat</h5>
																</div>
															)}
														</div>
													</Col>
												</Row>
												{/* Data Aset */}
												<Row>
													<Col sm="12">
														<div className="card">
															{this.state.data_aset.length > 0 ? (
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_aset,
																		this.state.drilldown_data_aset,
																		'Data Aset Masyarakat'
																	)}
																/>
																<div align="center">
																	<button
																		className="btn btn-sm btn-info"
																		onClick={() => {
																			this.getDataAset('Aset Masyarakat');
																			this.state.judul =
																				'Data Detail Aset Masyarakat';
																			this.state.status.dataPotensi5 = true;
																			this.forceUpdate();
																		}}
																		style={{width: '220px'}}
																	>
																		<span>Lihat data</span>
																	</button>
																</div>
															</div>
															) : (
																<div className="card-body chart-block">
																	<h5 style={{ textAlign: "center" }}>Data Aset Masyarakat</h5>
																</div>
															)}
														</div>
													</Col>
												</Row>
												{/* Data Aset Lanjutan */}
												<Row>
													<Col sm="12">
														<div className="card">
															{this.state.data_aset_pilihan.length > 0 ? (
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_aset_pilihan,
																		this.state.drilldown_data_aset_pilihan,
																		'Data Aset Lanjutan Masyarakat'
																	)}
																/>
																<div align="center">
																	<button
																		className="btn btn-sm btn-info"
																		onClick={() => {
																			this.getDataAsetPilihan('Aset Lanjutan');
																			this.state.judul =
																				'Data Detail Aset Lanjutan';
																			this.state.status.dataPotensi7 = true;
																			this.forceUpdate();
																		}}
																		style={{width: '220px'}}
																	>
																		<span>Lihat data</span>
																	</button>
																</div>
															</div>
															) : (
																<div className="card-body chart-block">
																	<h5 style={{ textAlign: "center" }}>Data Aset Lanjutan Masyarakat</h5>
																</div>
															)}
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="3">
											<div style={{ marginTop: 15 }}>
												<Row>
													<div className="col-sm-6">
														<h5>Data RT</h5>
													</div>
													<div className="col-sm-6 text-right">
														<Button
															size="sm"
															color="success"
															className="btn-square mr-3"
															onClick={() => {
																this.bukaForm();
															}}
														>
															Tambah Data RT
														</Button>
													</div>
													<div className="col-sm-12" style={{ marginTop: '10px' }}>
														<div className="col-sm-4 p-0 m-0">
															{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
														</div>
														<ToolkitProvider
															keyField="username"
															data={data_rt}
															columns={columns_rt}
															search
														>
															{(props) => (
																<div>
																	<SearchBar {...props.searchProps} />
																	<BootstrapTable
																		{...props.baseProps}
																		keyField="username"
																		data={data_rt}
																		columns={columns_rt}
																		pagination={paginationFactory()}
																	/>
																	<div className="pull-right text-white">
																		{this.state.awal}
																		{this.state.sebelum}
																		{this.state.hal.map((dt) => {
																			return dt;
																		})}
																		{this.state.setelah}
																		{this.state.akhir}
																	</div>
																</div>
															)}
														</ToolkitProvider>
													</div>
												</Row>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.addForm}
					onClose={this.tutupAddForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.addMode}</h5>
					</div>
					<Form className="theme-form" onSubmit={(e) => this.addSimpan(e, this.state.addMode)}>
						<div className="modal-body">
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									ID Wilayah
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="id_wilayah"
										type="text"
										placeholder="Masukkan Kode Administratif Wilayah"
										required
										defaultValue={this.state.addID}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Wilayah
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_wilayah"
										type="text"
										placeholder="Masukkan Nama Wilayah"
										required
										defaultValue={this.state.addNama}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupAddForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
				{/* =================== Modal Detail Agama ========================				 */}
				<Modal
					open={this.state.status.dataPotensi60}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={data_detail_penduduk}
							keyField="id"
							columns={columns_detail_agama}
							search
							// exportCSV
							// search
						>
							{/* {(props) => (
								<div>
									<div className="card-body datatable-react">

										<BootstrapTable
											keyField="id"
											data={data_detail_agama}
											columns={columns_detail_agama}
										/>

									</div>
								</div>
							)} */}
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row" style={{ marginBottom: '12px' }}>
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailPenduduk}
													style={{ marginBottom: '0px' }}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/data-masyarakat?uuid_agama=' +
																e.value;
																
															this.state.urlPartDetail = '?uuid_agama='+e.value
														} else {
															// url = 'potensi-desa/pagination/sumber-air-minum-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataMasyarakat('Agama', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_agama}
													placeholder="Agama"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row" style={{ paddingBottom: '14px', paddingLeft: '15px' }}>
											<a
												href={
													API_URL +
													'potensi-desa/pagination/sumber-air-minum-masyarakat?cetak=true'
												}
												className="btn btn-sm btn-info"
												onClick={() => {
													this.getDataSumberAir('Sumber Air Minum');
													this.state.judul = 'Data Detail ' + 'Sumber Air Minum';
													this.state.status.dataPotensi = true;
													this.forceUpdate();
												}}
											>
												<span>Cetak Data</span>
											</a>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											keyField="id"
											data={data_detail_penduduk}
											columns={columns_detail_agama}
										/>

										{/* <div className="pull-right text-white">
											{this.state.awal}
											{this.state.sebelum}
											{this.state.hal.map((dt) => {
												return dt;
											})}
											{this.state.setelah}
											{this.state.akhir}
										</div> */}
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				{/* =================== End Modal Detail Agama ========================				 */}

				{/* =================== Modal Detail Pekerjaan ========================				 */}
				<Modal
					open={this.state.status.dataPotensi61}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={data_detail_penduduk}
							keyField="id"
							columns={columns_detail_pekerjaan}
							search
							// exportCSV
							// search
						>
							{/* {(props) => (
								<div>
									<div className="card-body datatable-react">

										<BootstrapTable
											keyField="id"
											data={data_detail_agama}
											columns={columns_detail_agama}
										/>

									</div>
								</div>
							)} */}
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row" style={{ marginBottom: '12px' }}>
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailPenduduk}
													style={{ marginBottom: '0px' }}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
											<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/data-masyarakat?uuid_pekerjaan=' +
																e.value;
															this.state.urlPartDetail = '?uuid_pekerjaan='+e.value
														} else {
															// url = 'potensi-desa/pagination/sumber-air-minum-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataMasyarakat('Pekerjaan', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_pekerjaan}
													placeholder="Pekerjaan"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row" style={{ paddingBottom: '14px', paddingLeft: '15px' }}>
											<a
												href={
													API_URL +
													'potensi-desa/pagination/sumber-air-minum-masyarakat?cetak=true'
												}
												className="btn btn-sm btn-info"
												onClick={() => {
													this.getDataSumberAir('Sumber Air Minum');
													this.state.judul = 'Data Detail ' + 'Sumber Air Minum';
													this.state.status.dataPotensi = true;
													this.forceUpdate();
												}}
											>
												<span>Cetak Data</span>
											</a>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											keyField="id"
											data={data_detail_penduduk}
											columns={columns_detail_pekerjaan}
										/>

										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				{/* =================== End Modal Detail Pekerjaan ========================				 */}

				{/* =================== Modal Detail Umur ========================				 */}
				<Modal
					open={this.state.status.dataPotensi62}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={data_umur}
							keyField="id"
							columns={columns_detail_umur}
							search
							// exportCSV
							// search
						>
							
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row" style={{ marginBottom: '12px' }}>
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailPenduduk}
													style={{ marginBottom: '0px' }}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/data-masyarakat?rentang_umur=' +
																e.value;
																
															this.state.urlPartDetail = '?rentang_umur='+e.value
														} else {
															// url = 'potensi-desa/pagination/sumber-air-minum-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataMasyarakat('Usia', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_usia}
													placeholder="Rentang Usia"
													isClearable
													required
												/>
											</div>
										</div>

										<BootstrapTable
											{...props.baseProps}
											keyField="id"
											data={data_detail_penduduk}
											columns={columns_detail_umur}
										/>

										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				{/* =================== End Modal Detail Umur ========================				 */}

				{/* =================== Modal Detail Perkawinan ========================				 */}
				<Modal
					open={this.state.status.dataPotensi63}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={data_detail_penduduk}
							keyField="id"
							columns={columns_detail_perkawinan}
							search
							// exportCSV
							// search
						>
							{/* {(props) => (
								<div>
									<div className="card-body datatable-react">

										<BootstrapTable
											keyField="id"
											data={data_detail_agama}
											columns={columns_detail_agama}
										/>

									</div>
								</div>
							)} */}
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row" style={{ marginBottom: '12px' }}>
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailPenduduk}
													style={{ marginBottom: '0px' }}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/data-masyarakat?status_perkawinan=' +
																e.value;
																this.state.urlPartDetail = '?status_perkawinan='+e.value
														} else {
															// url = 'potensi-desa/pagination/sumber-air-minum-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataMasyarakat('Pernikahan', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_pernikahan}
													placeholder="Status Perkawinan"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row" style={{ paddingBottom: '14px', paddingLeft: '15px' }}>
											<a
												href={
													API_URL +
													'potensi-desa/pagination/sumber-air-minum-masyarakat?cetak=true'
												}
												className="btn btn-sm btn-info"
												onClick={() => {
													this.getDataSumberAir('Sumber Air Minum');
													this.state.judul = 'Data Detail ' + 'Sumber Air Minum';
													this.state.status.dataPotensi = true;
													this.forceUpdate();
												}}
											>
												<span>Cetak Data</span>
											</a>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											keyField="id"
											data={data_detail_penduduk}
											columns={columns_detail_perkawinan}
										/>

										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				{/* =================== End Modal Detail Perkawinan ========================				 */}

				{/* =================== Modal Detail Sumber Air Minum ========================				 */}
				<Modal
					open={this.state.status.dataPotensi}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="no_kk"
							columns={columns_sumber_air}
							// exportCSV
							// search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row" style={{ marginBottom: '12px' }}>
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailSumberAirMinum}
													style={{ marginBottom: '0px' }}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/sumber-air-minum-masyarakat?uuid_sumber=' +
																e.value;
																this.state.urlPartDetail = '?uuid_sumber='+e.value
														} else {
															// url = 'potensi-desa/pagination/sumber-air-minum-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataSumberAir('Sumber Air Minum', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_jenis_sumber_air}
													placeholder="Jenis Sumber Air Minum"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row" style={{ paddingBottom: '14px', paddingLeft: '15px' }}>
											<a
												href={
													API_URL +
													'potensi-desa/pagination/sumber-air-minum-masyarakat?cetak=true'
												}
												className="btn btn-sm btn-info"
												onClick={() => {
													this.getDataSumberAir('Sumber Air Minum');
													this.state.judul = 'Data Detail ' + 'Sumber Air Minum';
													this.state.status.dataPotensi = true;
													this.forceUpdate();
												}}
											>
												<span>Cetak Data</span>
											</a>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_sumber_air}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>

				{/* =================== End Modal Detail Sumber Air Minum ========================				 */}

				<Modal
					open={this.state.status.dataPotensi2}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="no_kk"
							columns={columns_kepemilikan_rumah}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail2"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailKepemilikanRumah}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/kepemilikan-rumah?uuid_status=' +
																e.value;
																this.state.urlPartDetail = '?uuid_status='+e.value
														} else {
															// url = 'potensi-desa/pagination/kepemilikan-rumah';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataKepemilikanRumah('Kepemilikan Rumah', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_jenis_kepemilikan_rumah}
													placeholder="Jenis Kepemilikan Rumah"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_kepemilikan_rumah}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				<Modal
					open={this.state.status.dataPotensi3}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="uuid"
							columns={columns_kepemilikan_lahan}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail3"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailKepemilikanLahan}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/kepemilikan-lahan?uuid_kepemilikan=' +
																e.value;
																this.state.urlPartDetail = '?uuid_kepemilikan='+e.value
														} else {
															// url = 'potensi-desa/pagination/kepemilikan-lahan';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataKepemilikanLahan('Kepemilikan Lahan', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_jenis_kepemilikan_lahan}
													placeholder="Jenis Kepemilikan Lahan"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_kepemilikan_lahan}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				<Modal
					open={this.state.status.dataPotensi4}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="uuid"
							columns={columns_komoditas_masyarakat}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail4"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailKomoditas}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/komoditas-masyarakat?uuid_komoditas=' +
																e.value;
																this.state.urlPartDetail = '?uuid_komoditas='+e.value
														} else {
															// url = 'potensi-desa/pagination/komoditas-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataKomoditas('Komoditas Masyarakat', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_komoditas}
													placeholder="Komoditas"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_komoditas_masyarakat}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				<Modal
					open={this.state.status.dataPotensi5}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="uuid"
							columns={columns_aset_masyarakat}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail5"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailAset}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/aset-masyarakat?uuid_aset=' +
																e.value;
																this.state.urlPartDetail = '?uuid_aset='+e.value
														} else {
															// url = 'potensi-desa/pagination/aset-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataAset('Aset Masyarakat', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_aset}
													placeholder="Komoditas"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_aset_masyarakat}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				<Modal
					open={this.state.status.dataPotensi6}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="uuid"
							columns={columns_lembaga_ekonomi}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail6"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailEkonomi}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/lembaga-ekonomi-masyarakat?uuid_lembaga=' +
																e.value;
																this.state.urlPartDetail = '?uuid_lembaga='+e.value
														} else {
															// url = 'potensi-desa/pagination/lembaga-ekonomi-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataEkonomi('Lembaga Ekonomi', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_ekonomi}
													placeholder="Jenis Lembaga Ekonomi"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_lembaga_ekonomi}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				<Modal
					open={this.state.status.dataPotensi7}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					// closeOnEsc={true}
					// closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="uuid"
							columns={columns_aset_pilihan}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail7"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailAsetPilihan}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/aset-pilihan-masyarakat?uuid_aset=' +
																e.value;
																this.state.urlPartDetail = '?uuid_aset='+e.value
														} else {
															// url = 'potensi-desa/pagination/aset-pilihan-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataAsetPilihan('Aset Lanjutan', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_aset_pilihan}
													placeholder="Jenis Aset Lanjutan"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_aset_pilihan}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				<Modal
					open={this.state.status.dataPotensi8}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						{/* {(this.state.tableDetail === 'Sumber Air Minum') => ()} */}
						<ToolkitProvider
							data={this.state.data_detail}
							keyField="uuid"
							columns={columns_produksi_galian}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												{/* <SearchBar {...props.searchProps} /> */}
												<input
													type="text"
													className="form-control"
													id="cariDetail8"
													placeholder="Cari ..."
													onKeyPress={this.cariDetailProduksiGalian}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/galian?uuid_produksi_galian=' +
																e.value;
																this.state.urlPartDetail = '?uuid_produksi_galian='+e.value
														} else {
															// url = 'potensi-desa/pagination/aset-pilihan-masyarakat';
															url = null;
															this.state.urlPartDetail = ""
														}

														this.getDataProduksiGalian('Produksi Galian', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.state.urlCari = ""
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_galian}
													placeholder="Jenis Galian"
													isClearable
													required
												/>
											</div>
										</div>
										{/* <div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div> */}

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_detail}
											columns={columns_produksi_galian}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
				{/* <Modal
					open={this.state.status.dataPotensi9}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						<ToolkitProvider
							data={this.state.data_penduduk}
							keyField="uuid"
							columns={this.columnDetail('agama')}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												<input
													type="text"
													className="form-control"
													id="cariDetail9"
													placeholder="Cari ..."
													// onKeyPress={this.cariDetailProduksiGalian}
												/>
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														// let data_detail_sumber_air = [];
														let url = '';

														if (e !== null) {
															url =
																'potensi-desa/pagination/galian?uuid_produksi_galian=' +
																e.value;
														} else {
															// url = 'potensi-desa/pagination/aset-pilihan-masyarakat';
															url = null;
														}

														this.getDataProduksiGalian('Produksi Galian', url);

														this.state.filterValue = e;
														this.state.urlDetail = url;
														this.forceUpdate();
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_galian}
													placeholder="Jenis Galian"
													isClearable
													required
												/>
											</div>
										</div>

										<BootstrapTable
											{...props.baseProps}
											// keyField="no_kk"
											data={this.state.data_penduduk}
											columns={this.columnDetail('agama')}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											// pagination={paginationFactory()}
										/>
										<div className="pull-right text-white">
											{this.state.awalDetail}
											{this.state.sebelumDetail}
											{this.state.halDetail.map((dt) => {
												return dt;
											})}
											{this.state.setelahDetail}
											{this.state.akhirDetail}
										</div>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal> */}
			</div>
		);
	}
}

export default DataPotensi;
