import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";
import Switch from "react-switch";

// koneksi
import { PostStatus, Post, Get, Delete, ID_DESA, JENIS_DESA } from '../../../function/Koneksi';
import { DataToken } from "../../../function/Format";

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';


// MAP
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

var HtmlToReactParser = require("html-to-react").Parser;


const frmKodeSurat = {
  uuid: "",
  id_desa: 0,
  nama: "",
  id_ukuran_kertas: "",
  nama_table: ""
};

const frmDetailKodeSurat = {
  uuid: "",
  id_jenis: "",
  id_jabatan_perangkat: "",
  level: "",
};

const frmNotifDesa = {
  uuid: "",
  judul: "",
  konten: "",
  jenis: "",
  routing_mobile: "",
  args_routes: "",
  status: "",
  link_gambar: "",
  logo: "",
};

const frmPengaturanWS = {
	nomor_wa_perangkat: [{isi:[""]}],
};

class BasicTable extends Component {
  _isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
      // DATA KODE SURAT
        dataKodeSurat: [],
        paginationKodeSurat: [],
        statusKodeSurat: {
          form: false,
          btnForm: false,
          btnAksi: false,
          disKode: "inherit"
        },
        awalKodeSurat: "",
        sebelumKodeSurat: "",
        halKodeSurat: [],
        setelahKodeSurat: "",
        akhirKodeSurat: "",
        dtKodeSurat: frmKodeSurat,
        loadingKodeSurat: false,
        frmUkuranKertasKodeSurat: [],
        ukuranKertasStatKodeSurat: [],
        judulKodeSurat: '',
        detailKodeSuratStat: false,
        detailKodeSuratData: [],

        // DETAIL KODE SURAT
          dataDetailKodeSurat: [],
          paginationDetailKodeSurat: [],
          statusDetailKodeSurat: {
            form: false,
            btnForm: true,
            btnAksi: []
          },
          dtDetailKodeSurat: frmDetailKodeSurat,
          loadingDetailKodeSurat: false,
          judulDetailKodeSurat: "Tambah Surat Izin Keramaian",
        // END DETAIL KODE SURAT
      // END DATA KODE SURAT

      // DATA PENOMORAN SURAT
        dataNomorSurat: [],
        loadingNomorSurat: false,
        statusNomorSurat: {
          form: false,
          btnForm: false,
          btnAksi: false,
          disKode: "inherit"
        },
        judulNomorSurat:'',
        akun: DataToken(sessionStorage.getItem("access_token")),
      // END DATA PENOMORAN SURAT
      // DATA NOTIFIKASI DESA
        dataNotifDesa: [],
        paginationNotifDesa: [],
        statusNotifDesa: {
          form: false,
          btnForm: false,
          btnAksi: false
        },
        awalNotifDesa: "",
        sebelumNotifDesa: "",
        halNotifDesa: [],
        setelahNotifDesa: "",
        akhirNotifDesa: "",
        dtNotifDesa: frmNotifDesa,
        loadingNotifDesa: false,
        judulNotifDesa: "Tambah Agama",
        kontenNotifDesa: false,
        contentNotifDesa: "",
        fotoNotifDesa: "",
        linkGambarNotifDesa: "",
        statDetailNotifDesa: false,
        stpStat: {label : '', value : ''},

      // END DATA NOTIFIKASI DESA

      // DATA PENGATURAN APLIKASI
        data: [],
        dt: frmPengaturanWS,
        pagination: [],
        status: {
          form: false,
          btnForm: false,
          btnAksi: false
        },
        foto_desa: "",
        foto_info: "",
        input: "none",
        view: "inherit",
        judul: "Konfigurasi Aplikasi",
        sidebar: false,
        response_pengajuan_layanan: "",
        riwayat_disposisi: "",
        notif_global: false,
        notif_wa: false,
        notif_pengajuan_global: false,
        nomor_wa_perangkat: "",
        get_all_nomor: null,

        // Konfigurasi Web Service
        alamat_foto_kop: 'logo/logo_kop.png',
        provinsi: "",
        jenis_kabkota: "",
        kabkota: "",
        kecamatan: "",
        jenis_desa: "",
        desa: "",
        jenis_dusun: "",
        lat: 0,
        long: 0,
        curStaticLocation: [0, 0],
        currentLocation: [0, 0],

        jabatan_tertinggi: "",
        alamat_kantor_desa: "",
        kode_pos: "",

        url_bo_digidesa: "",
        url_marketplace: "https://ws-marketplace-desa-prod.digidesa.com",
        desa_one_signal: "",
        type_app: "prod",
        heading_notif: "Digidesa",
        style_nama_kabkota: "font-weight: bold;font-size: 24px; text-transform: uppercase; font-family:times new roman",
        style_kecamatan: "font-weight: bold;font-size: 22px; text-transform: uppercase;font-family:times new roman",
        style_desa: "font-weight: bold;font-style: calibri; font-size: 24px; text-transform: uppercase;font-family:times new roman",
        style_alamat: "font-weight: bold;font-size: 16px; text-transform: capitalize;font-family:times new roman",
        nama_kades: "",
        link_fb: "",
        link_ig: "",
        email: "",
        ukuran_kertas: "",
        foto_kades: "",
        selectedFile: null,
        frmKertas: [],
        selectedKertas: null,
        // End Konfigurasi Web Service
        // Loading
        loading: true,
        data_cek: '',

        link_gambar: "",
      // END DATA PENGATURAN APLIKASI
			alert: null,
			show: false,
			loading: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			
			// Tab Vertical
        active_tab_vertical: '1',

		};
		this.handleChange = this.handleChange.bind(this);
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// Toggle Vertical Tab
	toggle_vertical(tab) {
      if (this.state.active_tab_vertical !== tab) {
        this.setState({
            active_tab_vertical: tab,
            detailKodeSuratStat: false,
            detailKodeSuratData: [],
        });
      }
  }

  // KODE SURAT
    fetchKodeSurat = (params = {}) => {
      this.setState({ loadingKodeSurat: true })
      let link = "";
      // let where = [];
      // if (params.where) {
      //   where = params.where;
      // }
      if (params.page) {
        switch (params.page) {
          case ">>":
            link =
              "?page=" +
              Math.ceil(
                this.state.paginationKodeSurat.total / this.state.paginationKodeSurat.per_page
              );
            break;
          case ">":
            link = "?page=" + (parseInt(this.state.paginationKodeSurat.current_page) + 1);
            break;
          case "<":
            link = "?page=" + (parseInt(this.state.paginationKodeSurat.current_page) - 1);
            break;
          case "<<":
            link = "?page=1";
            break;
          default:
            link = "?page=" + params.page;
            break;
        }
      }
      Get("jenis-surat" + link, null, dtkat => {
        this.setState({ dataKodeSurat: dtkat.results.data, paginationKodeSurat: dtkat.results, loadingKodeSurat: false });
        this.paginationKodeSurat(dtkat.results);
      });
    };

    // paginationKodeSurat
    paginationKodeSurat = data => {
      // const data = this.state.paginationKodeSurat;
      //console.log(data);
      let awalKodeSurat = "";
      let halKodeSurat = [];
      let sebelumKodeSurat = "";
      let setelahKodeSurat = "";
      let akhirKodeSurat = "";
      if (data.total > 0) {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3) {
          start = 1;
          end = 5;
          if (data.last_page > data.current_page) {
            n = data.current_page + 1;
          }
        } else {
          p = data.current_page - 1;
          n = data.current_page + 1;
          start = data.current_page - 2;
          end = data.current_page + 2;
        }
        if (end >= data.last_page - 2) {
          p = data.current_page - 1;
          if (start >= 5) {
            start = data.last_page - 4;
          }
          end = data.last_page;
        }

        for (let i = start; i <= end; i++) {
          let warna = "primary-custom";
          if (i === data.current_page) {
            warna = "danger";
          }
          halKodeSurat.push(
            <a
              href={() => false}
              onClick={() => this.fetchKodeSurat({ page: i })}
              className={
                "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
              }
            >
              {i}
            </a>
          );
        }
        if (p > 0) {
          sebelumKodeSurat = (
            <a
              href={() => false}
              onClick={() => this.fetchKodeSurat({ page: p })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {"<"}
            </a>
          );
        }
        if (n > 0) {
          setelahKodeSurat = (
            <a
              href={() => false}
              onClick={() => this.fetchKodeSurat({ page: n })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {">"}
            </a>
          );
        }
        awalKodeSurat = (
          <a
            href={() => false}
            onClick={() => this.fetchKodeSurat({ page: 1 })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<<"}
          </a>
        );
        akhirKodeSurat = (
          <a
            href={() => false}
            onClick={() => this.fetchKodeSurat({ page: data.last_page })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">>"}
          </a>
        );
      }
      this.setState({ awalKodeSurat, sebelumKodeSurat, halKodeSurat, setelahKodeSurat, akhirKodeSurat });
    };

    // ================== simpan data ==================
    simpanKodeSurat = e => {
      e.preventDefault();
      //console.log(e);
      if (this.state.dtKodeSurat.id_ukuran_kertas !== "") {
        this.state.statusKodeSurat.btnForm = true;
        this.forceUpdate();
        let add = {
          uuid: this.state.dtKodeSurat.uuid,
          id_desa: ID_DESA,
          nama: document.getElementById("nama").value,
          id_ukuran_kertas: this.state.dtKodeSurat.id_ukuran_kertas,
          kategori: this.state.dtKodeSurat.kategori,
          nama_table: document.getElementById("nama_table").value,
          kode_surat: document.getElementById("kode_surat").value
        };
        //console.log(add);
        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (add.uuid === "") {
          psn = "Tambah";
          resstat = 201;
          add.uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }
        PostStatus("jenis-surat/" + metode, add.uuid, add, res => {
          this.state.statusKodeSurat.btnForm = false;
          this.state.statusKodeSurat.form = false;
          this.forceUpdate();
          if (res === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Jenis Surat",
              pesanAlert: "Berhasil " + psn + " Data"
            });
            this.tutupFormKodeSurat();
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Jenis Surat",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Jenis Surat",
          pesanAlert: "Data tidak boleh kosong"
        });
      }
    };

    // ============================== Ubah Data ===============================
    ubahDataKodeSurat = data => {
      this.setState({
      statusKodeSurat:{
          btnAksi: false,
          form: true,
      },
      dtKodeSurat:{
          uuid: data.uuid,
          id_desa: data.id_desa,
          id_ukuran_kertas: data.id_ukuran_kertas,
          nama: data.nama,
          nama_table: data.nama_table,
          kode_surat: data.kode_surat,
      },
      ukuranKertasStatKodeSurat: {
          value: data.data_ukuran_kertas.uuid,
          label: data.data_ukuran_kertas.nama
      },
      ktrStat: {
          value: data.kategori,
          label: data.kategori
      },
      judulKodeSurat: "Ubah Jenis Surat",
      })

      this.forceUpdate();
    };

    // ============================= Hapus Data ===============================
    konfirmHapusKodeSurat = id => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusDataKodeSurat(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusDataKodeSurat = id => {
      this.hideAlert();
      let psn = "Hapus";
      Delete("jenis-surat/delete", id, res => {
        if (res === 200) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Jenis Surat",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Jenis Surat",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    };
    
    bukaFormKodeSurat = () => {
      this.state.statusKodeSurat.form = true;
      this.forceUpdate();
    };

    tutupFormKodeSurat = () => {
      this.setState({
        statusKodeSurat:{
          form: false,
        },
        dtKodeSurat: frmKodeSurat,
        ukuranKertasStatKodeSurat: {
          value: '',
          label: ''
        },
        ktrStat: {
          value: '',
          label: ''
        },

      })
      this.forceUpdate();
    };

    tombolAksiKodeSurat = (cell, row) => {
      return (
        <>
          <Button
            size="xs"
            color="info"
            className="btn-icon"
            title="Alur Disposisi"
            onClick={() => {
              this.detailDataKodeSurat(row)
            }}
          >
            <i className="fa fa-tasks"></i>
          </Button>{" "}
          &nbsp;&nbsp;
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => this.ubahDataKodeSurat(row)}
            disabled={this.state.statusKodeSurat.btnAksi}
            title="Edit"
          >
            <i className="fa fa-pencil"></i>
          </Button>{" "}
          {/* &nbsp;&nbsp;
          <Button
            size="xs"
            color="danger"
            className="btn-icon"
            style={{ display: this.state.statusKodeSurat.disKode }}
            onClick={() => this.konfirmHapusKodeSurat(row.uuid)}
            disabled={this.state.statusKodeSurat.btnAksi}
            title="Hapus"
          >
            <i className="fa fa-trash"></i>
          </Button> */}
        </>
      );
    };
    
    changeSelectValueKodeSurat = (e, sel) => {
      if (e) {
        switch (sel) {
          case "jenis_surat":
            this.state.dtKodeSurat.kategori = e.value;
            this.setState({ ktrStat: e });
            break;
          case "ukuran_kertas":
            this.state.dtKodeSurat.id_ukuran_kertas = e.value;
            this.setState({ ukuranKertasStatKodeSurat: e });
            break;
          default:
            // no default
            break;
        }
      } else {
        switch (sel) {
          case "jenis_surat":
            this.state.dtKodeSurat.kategori = e.value;
            this.setState({ ktrStat: [] });
            break;
          case "ukuran_kertas":
            this.state.dtKodeSurat.id_ukuran_kertas = 0;
            this.setState({ ukuranKertasStatKodeSurat: [] });
            break;
          default:
            // no default
            break;
        }
      }
      this.state.statusKodeSurat.select = false;
      this.forceUpdate();
    };

    // DETAIL DISPOSISI KODE SURAT
     
      detailDataKodeSurat = (data) => {
        this.fetchDetailKodeSurat({ page: null, where: [], data_jenis_surat:data });
        this.setState({ loadingDetailKodeSurat: true })
        let link = "?page=1";
        this.setState({
            detailKodeSuratStat: true,
            detailKodeSuratData:data,
            statusDetailKodeSurat:{
                ...this.state.statusDetailKodeSurat,
                btnAksi: true,
            },
            judulDetailKodeSurat: "Detail Jenis Surat",
        })
        this.forceUpdate();
      }
      // ================== Ambil data dari db untuk table ==================
      fetchDetailKodeSurat = (data, params = {}) => {
        console.log('boblookk')
        console.log(data)
        this.setState({ loadingDetailKodeSurat: true })
        let link = "";
        if (params.page) {
          switch (params.page) {
            case ">>":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationDetailKodeSurat.total / this.state.paginationDetailKodeSurat.per_page
                );
              break;
            case ">":
              link = "?page=" + (parseInt(this.state.paginationDetailKodeSurat.current_page) + 1);
              break;
            case "<":
              link = "?page=" + (parseInt(this.state.paginationDetailKodeSurat.current_page) - 1);
              break;
            case "<<":
              link = "?page=1";
              break;
            default:
              link = "?page=" + params.page;
              break;
          }
        }

        Get(
          "alur-disposisi/" + data.data_jenis_surat.uuid + "" + link,
          null,
          dtkat => {
            if (this._isMounted) {
              this.setState({ dataDetailKodeSurat: dtkat.results, loadingDetailKodeSurat: false });
            }
          }
        );
      };

      simpanDetailKodeSurat = e => {
        e.preventDefault();
        this.state.statusDetailKodeSurat.btnForm = true;
        this.forceUpdate();
    
        let uuid = document.getElementById("uuid").value;
        //detail
      
    
        let addAlur = {
            id_jenis: this.state.detailKodeSuratData.uuid,
            id_jabatan_perangkat:this.state.jpStat.value,
            level: document.getElementById("level").value,
        };
        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (uuid === "") {
          psn = "Tambah";
          resstat = 201;
          uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }
        Post(
          "alur-disposisi/"+ this.state.detailKodeSuratData.uuid+'/'+ metode ,
          uuid,
          addAlur,
          res => {
            this.state.statusDetailKodeSurat.btnForm = false;
            this.state.statusDetailKodeSurat.form = false;
            this.forceUpdate();
            if (res.status === resstat) {
              this.fetchDetailKodeSurat({ page: null, where: [], data_jenis_surat:this.state.detailKodeSuratData });
              this.setState({
                show: true,
                statusDetailKodeSurat :{
                    ...this.state.statusDetailKodeSurat,
                    btnForm:true},
                basicType: "success",
                basicTitle: "Data Alur Disposisi",
                pesanAlert: "Berhasil " + psn + " Data"
              });
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Alur Disposisi",
                pesanAlert: "Gagal " + psn + " Data"
              });
            }
            this.componentDidMount();
          }
        );
      };

      // ============================== Ubah Data ===============================
      ubahDataDetailKodeSurat = id => {
        // this.state.status.btnAksi = true;
        // this.componentDidMount();
        Get("alur-disposisi/find", id, data => {
            
            this.setState({
                dtDetailKodeSurat: {
                    ...this.state.dtDetailKodeSurat,
                    level: data.results.level,
                    uuid:data.results.uuid
                },
                statusDetailKodeSurat :{
                    ...this.state.statusDetailKodeSurat,
                    btnForm:false},
                jpStat:{value:data.results.id_jabatan_perangkat,label:data.results.data_jabatan.nama}

            })
            // this.state.status.btnAksi = false;
            this.componentDidMount();
            this.bukaFormDetailKodeSurat();
        });
      };

      // ============================= Hapus Data ===============================
      konfirmHapusDetailKodeSurat = id => {
        this.setState({
          alert: (
            <SweetAlert
              showCancel
              confirmBtnText="Hapus"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              type="danger"
              title="Yakin ingin hapus data ?"
              onCancel={this.hideAlert}
              onConfirm={() => this.hapusDataDetailKodeSurat(id)}
            >
              Proses ini tidak dapat dibatalkan!
            </SweetAlert>
          )
        });
      };

      hapusDataDetailKodeSurat = id => {
        this.hideAlert();
        let psn = "Hapus";

        Delete("alur-disposisi/"+this.state.detailKodeSuratData.uuid+"/delete", id, res => {
          if (res === 200) {
            this.fetchDetailKodeSurat({ page: null, where: [], data_jenis_surat:this.state.detailKodeSuratData });
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Alur Disposisi",
              pesanAlert: "Berhasil " + psn + " Data"
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Alur Disposisi",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        });
      };
      
      bukaFormDetailKodeSurat = () => {
        this.state.statusDetailKodeSurat.form = true;
        this.forceUpdate();
      };

      tutupFormDetailKodeSurat = () => {
        this.state.statusDetailKodeSurat.btnForm = true;
        this.state.statusDetailKodeSurat.form = false;
        this.forceUpdate();
      };

      getJp=(e)=>{
        if(e){
          this.setState({
              statusDetailKodeSurat :{
                  ...this.state.statusDetailKodeSurat,
                  btnForm:false},
              jpStat:{value:e.value,label:e.label}})
        } else {
          this.setState({statusDetailKodeSurat :{
              ...this.state.statusDetailKodeSurat,
              btnForm:true},
              jpStat:[]})

        }
      }
      
      tombolAksiDetailKodeSurat = (cell, row) => {
        // let btnApproval = false;
        // let btnCetak = false;
        let btnAksi = false;
        // let status = "";
        
        
        return (
          <>
            <Button
              size="xs"
              color="success"
              className="btn-icon"
              onClick={() => this.ubahDataDetailKodeSurat(row.uuid)}
              disabled={btnAksi}
            >
              <i className="fa fa-pencil"></i>
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              size="xs"
              color="danger"
              className="btn-icon"
              onClick={() => this.konfirmHapusDetailKodeSurat(row.uuid)}
              disabled={btnAksi}
            >
              <i className="fa fa-trash"></i>
            </Button>{" "}
          
          </>
        );
      };

    // DETAIL DISPOSISI KODE SURAT
  // END KODE SURAT

  // PENOMORAN SURAT
    // ================== Ambil data dari db untuk table ==================
    fetchNomorSurat = (params = {}) => {
      this.setState({ loadingNomorSurat: true })
      let link = "?page=" + params.page;

      Get("penomoran" + link, null, dtkat => {
        if (dtkat.results.data && dtkat.results.data.length > 0) {
          this.setState({ dataNomorSurat: [dtkat.results.data[0]], loadingNomorSurat: false });
        }
        else{
          this.setState({ loadingNomorSurat: false });
        }
      });
    };
    
    // ================== simpan data ==================
    simpanNomorSurat = e => {
      e.preventDefault();

      const nomor_surat = document.getElementById("nomor_surat").value
      //console.log(e);

      if (nomor_surat) {
        // this.state.statusNomorSurat.btnForm = true;
        // this.forceUpdate();

        const currentTime = new Date()

        let add = {
          tahun: currentTime.getFullYear() + '',
          nomor: nomor_surat,
        };

        //console.log(add);

        let psn = "";
        let resstat = 204;
        let metode = "create";

        psn = "Perbarui";
        resstat = 201;

        // if (add.uuid === "") {
        //   psn = "Tambah";
        //   resstat = 201;
        //   add.uuid = null;
        // } else {
        //   psn = "Ubah";
        //   resstat = 200;
        //   metode = "update";
        // }

        Post("penomoran/" + metode, null, add, res => {
          // this.state.status.btnForm = false;
          this.setState({
            statusNomorSurat:{
                ...this.state.statusNomorSurat,
                form: false,
            },
          })
          this.forceUpdate();

          const statusCode = res.status

          if (statusCode === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Nomor Surat",
              pesanAlert: "Berhasil " + psn + " nomor surat"
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Nomor Surat",
              pesanAlert: "Gagal " + psn + " nomor surat"
            });
          }
          this.componentDidMount();
        });
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Penomoran Surat",
          pesanAlert: "Isi nomor surat terlebih dahulu"
        });
      }
    };
    
    bukaForm = () => {
      this.setState({
        statusNomorSurat:{
            ...this.state.statusNomorSurat,
            form: true,
        },
      })
      this.forceUpdate();
    };

    tutupFormNomorSurat = () => {
      this.setState({
          statusNomorSurat:{
            ...this.state.statusNomorSurat,
            form: false,
          },
        })
      this.forceUpdate();
    };
  // END PENOMORAN SURAT

  // NOTIFIKASI DESA
  
  // ================== Ambil data dari db untuk table ==================
    fetchNotifDesa = (params = {}) => {
      this.setState({ loadingNotifDesa: true })
      let link = "";
      // let where = [];
      // if (params.where) {
      //   where = params.where;
      // }
      if (params.page) {
        switch (params.page) {
          case ">>":
            link =
              "?page=" +
              Math.ceil(
                this.state.paginationNotifDesa.total / this.state.paginationNotifDesa.per_page
              );
            break;
          case ">":
            link = "?page=" + (parseInt(this.state.paginationNotifDesa.current_page) + 1);
            break;
          case "<":
            link = "?page=" + (parseInt(this.state.paginationNotifDesa.current_page) - 1);
            break;
          case "<<":
            link = "?page=1";
            break;
          default:
            link = "?page=" + params.page;
            break;
        }
      }
      Get("konfigurasi-notifikasi" + link, null, dtkat => {
        this.setState({ dataNotifDesa: dtkat.results.data, paginationNotifDesa: dtkat.results, loadingNotifDesa: false });
        this.paginationNotifDesa(dtkat.results);
      });
    };

    // paginationNotifDesa
    paginationNotifDesa = data => {
      // const data = this.state.paginationNotifDesa;
      //console.log(data);
      let awalNotifDesa = "";
      let halNotifDesa = [];
      let sebelumNotifDesa = "";
      let setelahNotifDesa = "";
      let akhirNotifDesa = "";
      if (data.total > 0) {
        let start = 1;
        let end = 5;
        let n = 0;
        let p = 0;
        if (data.current_page <= 3) {
          start = 1;
          end = 5;
          if (data.last_page > data.current_page) {
            n = data.current_page + 1;
          }
        } else {
          p = data.current_page - 1;
          n = data.current_page + 1;
          start = data.current_page - 2;
          end = data.current_page + 2;
        }
        if (end >= data.last_page - 2) {
          p = data.current_page - 1;
          if (start >= 5) {
            start = data.last_page - 4;
          }
          end = data.last_page;
        }

        for (let i = start; i <= end; i++) {
          let warna = "primary-custom";
          if (i === data.current_page) {
            warna = "danger";
          }
          halNotifDesa.push(
            <a
              href={() => false}
              onClick={() => this.fetchNotifDesa({ page: i })}
              className={
                "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
              }
            >
              {i}
            </a>
          );
        }
        if (p > 0) {
          sebelumNotifDesa = (
            <a
              href={() => false}
              onClick={() => this.fetchNotifDesa({ page: p })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {"<"}
            </a>
          );
        }
        if (n > 0) {
          setelahNotifDesa = (
            <a
              href={() => false}
              onClick={() => this.fetchNotifDesa({ page: n })}
              className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            >
              {">"}
            </a>
          );
        }
        awalNotifDesa = (
          <a
            href={() => false}
            onClick={() => this.fetchNotifDesa({ page: 1 })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<<"}
          </a>
        );
        akhirNotifDesa = (
          <a
            href={() => false}
            onClick={() => this.fetchNotifDesa({ page: data.last_page })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">>"}
          </a>
        );
      }
      this.setState({ awalNotifDesa, sebelumNotifDesa, halNotifDesa, setelahNotifDesa, akhirNotifDesa });
    };
    
    processGbrNotifDesa = d => {
      // //console.log(d[0].type);
      if (d.length === 1) {
        let type = d[0].type.split("/");
        if (
          type[1] === "jpg" ||
          type[1] === "jpeg" ||
          type[1] === "png" ||
          type[1] === "bmp" ||
          type[1] === "tiff" ||
          type[1] === "webp"
        ) {
          let bodyFormData = new FormData();
          bodyFormData.append("files", d[0]);

          Post("upload-file/notif-desa", null, bodyFormData, data => {
            //console.log(data);
            Resizer.imageFileResizer(
              d[0],
              300,
              300,
              type[1],
              100,
              0,
              uri => {
                this.setState({
                  fotoNotifDesa: uri,
                  fotoInfoNotifDesa: "",
                  linkGambarNotifDesa: data.data.results
                });
              },
              "base64"
            );
          });
        } else {
          this.setState({
            fotoNotifDesa: "",
            fotoInfoNotifDesa: (
              <div className="text-danger font-size-10">
                Type file tidak valid
              </div>
            )
          });
        }
      } else {
        this.setState({
          fotoNotifDesa: "",
          fotoInfoNotifDesa: (
            <div className="text-danger font-size-10">
              Silahkan masukan satu gambar
            </div>
          )
        });
      }
    };
    
    // ================== simpan data ==================
    simpanNotifDesa = e => {
      e.preventDefault();
      //console.log(this.state.stpStat)
      //console.log(this.state.dataNotifDesa.judul)

      let judul = document.getElementById("judul").value
      let konten = document.getElementById("konten").value
      let routing = document.getElementById("routing_mobile").value
      let args_routes = document.getElementById("args_routes").value

      if (judul === '' || routing === '' || args_routes === '') {
        this.setState({
          show: true,
          basicType: "info",
          basicTitle: "Data Notifikasi Desa",
          pesanAlert: "Silahkan lengkapi data"
        });
      } else {
          this.setState({
            statusNotifDesa:{
                ...this.state.statusNotifDesa,
                btnForm: true,
            },
          })
          this.forceUpdate();
    
          let addData = {
            uuid: document.getElementById("uuid").value,
            judul: judul,
            konten: konten,
            routing_mobile: routing,
            args_routes: args_routes,
            gambar: this.state.linkGambarNotifDesa,
            gambar_lama: document.getElementById("logo_lama").value,
          };

          let psn = "";
          let resstat = 204;
          let metode = "create";
          if (addData.uuid === "") {
            psn = "Tambah";
            resstat = 201;
            addData.uuid = null;
            addData.status= '0';
          } else {
            psn = "Ubah";
            resstat = 200;
            metode = "update";

            addData.status= this.state.status_notifikasi.value;
            if (addData.gambar === '') {
              addData.gambar = addData.gambar_lama;
            }
            // else {
            // 	// axios.get(`${addData.gambar_lama}/hapus`).then(resp => {
            // 	// 	// //console.log(resp.data);
            // 	// });
            // 	addData.gambar = addData.gambar;
            // }
          }

          
          //console.log("Data simpanNotifDesa cuy");
          //console.log(addData);
    
          Post("konfigurasi-notifikasi/" + metode, addData.uuid, addData, res => {
            this.setState({
              statusNotifDesa:{
                  ...this.state.statusNotifDesa,
                  btnForm: false,
              },
            })
            // this.state.status.form = false;
            this.tutupFormNotifDesa();
            this.forceUpdate();
            if (res.status === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Info " + JENIS_DESA,
                pesanAlert: "Berhasil " + psn + " Data"
              });
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Info " + JENIS_DESA,
                pesanAlert: "Gagal " + psn + " Data"
              });
            }
            this.componentDidMount();
          });
      }

    };

    detailNotifDesa = data => {
      //console.log(data);
      this.setState({
        statDetailNotifDesa: true,
        contentNotifDesa: data.konten,
        linkGambarNotifDesa: data.link_gambar
      });
    };

    // ============================== Ubah Data ===============================
    ubahDataNotifDesa = (data) => {
      this.setState({
        judulNotifDesa: "Ubah Notifikasi Desa ",
        statusNotifDesa:{
          ...this.state.statusNotifDesa,
          btnAksi: false,
          form: true,
        },
        status_notifikasi: {
          value: data.status, 
          label: (
              data.status === '0' ? 'Aktif'
              : 'Tidak Aktif'
              ),
        },
        linkGambarNotifDesa: data.gambar,
        dtNotifDesa:{
          uuid: data.uuid,
          judul: data.judul,
          konten: data.konten,
          routing_mobile: data.routing_mobile,
          args_routes: data.args_routes,
          logo: data.gambar
        }
      })
      this.forceUpdate();
    };

    // ============================= Hapus Data ===============================
    konfirmHapusNotifDesa = id => {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Hapus"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            type="danger"
            title="Yakin ingin hapus data ?"
            onCancel={this.hideAlert}
            onConfirm={() => this.hapusDataNotifDesa(id)}
          >
            Proses ini tidak dapat dibatalkan!
          </SweetAlert>
        )
      });
    };

    hapusDataNotifDesa = id => {
      this.hideAlert();
      let psn = "Hapus";
      Delete("konfigurasi-notifikasi/delete", id, res => {
        if (res === 200) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Info " + JENIS_DESA,
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Info " + JENIS_DESA,
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    };
    
    bukaFormNotifDesa = () => {
      this.setState({
        statusNotifDesa:{
            ...this.state.statusNotifDesa,
            form: true,
        },
      })
      this.forceUpdate();
    };

    tutupFormNotifDesa = () => {
      this.setState({
        dtNotifDesa:{
          ...this.state.dtNotifDesa,
          uuid: "",
          judul: "",
          konten: "",
          jenis: "",
          routing_mobile: "",
          args_routes: "",
          status: "",
          link_gambar: "",
          logo: "",
        },
        statusNotifDesa:{
            ...this.state.statusNotifDesa,
            form: false,
        },
        statDetailNotifDesa: false,
      })

      this.forceUpdate();
    };
    
    tombolAksiNotifDesa = (cell, row) => {
      return (
        <>
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => this.ubahDataNotifDesa(row)}
            disabled={this.state.statusNotifDesa.btnAksi}
          >
            <i className="fa fa-pencil"></i>
          </Button>{" "}
          <Button
            size="xs"
            color="danger"
            className="btn-icon"
            onClick={() => this.konfirmHapusNotifDesa(row.uuid)}
            disabled={this.state.statusNotifDesa.btnAksi}
          >
            <i className="fa fa-trash"></i>
          </Button>
          {/* //list */}
          {row.jenis === "info" ? (
            <Button
              size="xs"
              color="info"
              className="btn-icon btn-secondary-custom"
              onClick={e => this.detailNotifDesa(row)}
            >
              <i className="fa fa-list"></i>
            </Button>
          ) : (
            <></>
          )}
        </>
      );
    };

    onChangeSelectNotifDesa = (e, data) => {
      if (e) {
        switch (data) {
          case 'status_notifikasi':
            this.setState({ 
              status_notifikasi: { value: e.value, label: e.label },
            });
            break;
          default:
            // no default
            break;
        }
      } else {
        switch (data) {
          case 'status_notifikasi':
            this.setState({ 
              status_notifikasi: null,
            });
            break;
          default:
            // no default
            break;
        }
      }
    }
  // END NOTIFIKASI DESA

  // KONFIGURASI KEBUTUHAN WS
    onFileChange = event => {
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    };
    
    inputChange = (event) => {
      // //console.log(event.target.value);
      this.setState({ 
        dt: {...this.state.dt, 
          nomor_wa_perangkat: {...this.state.dt.nomor_wa_perangkat,
          }
        }
      })
    }

    openCloseSidebar = (type) => {
      let data = null

      if (type === "sidebar") {
        data = this.state.sidebar
      } else if (type === "notif_global") {
        data = this.state.notif_global
      } else if (type === "notif_wa") {
        data = this.state.notif_wa
      } else if (type === "notif_pengajuan_global") {
        data = this.state.notif_pengajuan_global
      }

      // Jika data === true
      if (data) {
        if (type === "sidebar") {
          this.setState({ sidebar: false });
        } else if (type === "notif_global") {
          this.setState({ notif_global: false });
        } else if (type === "notif_wa") {
          this.setState({ notif_wa: false });
        } else if (type === "notif_pengajuan_global") {
          this.setState({ notif_pengajuan_global: false });
        }
      } else {
        if (type === "sidebar") {
          this.setState({ sidebar: true });
        } else if (type === "notif_global") {
          this.setState({ notif_global: true });
        } else if (type === "notif_wa") {
          this.setState({ notif_wa: true });
        } else if (type === "notif_pengajuan_global") {
          this.setState({ notif_pengajuan_global: true });
        }
      }
    };

    // =================================================

    handleChange(checked, jenis_konfig) {
      //console.log("CHANGE JENIS Konfig")
      //console.log(jenis_konfig)
      switch (jenis_konfig) {
        // PPOB MOBILE
        case "sidebar":
          if(this.state.sidebar === true){
            this.setState({
              sidebar: checked
            })
          }
          else{
            this.setState({
              sidebar: checked
            })
          }
        break;
        case "notif_global":
          if(this.state.notif_global === true){
            this.setState({
              notif_global: checked
            })
          }
          else{
            this.setState({
              notif_global: checked
            })
          }
        break;
        case "notif_wa":
          if(this.state.notif_wa === true){
            this.setState({
              notif_wa: checked,
            })
          }
          else{
            this.setState({
              notif_wa: checked,
            })
          }
        break;
        case "notif_pengajuan_global":
          if(this.state.notif_pengajuan_global === true){
            this.setState({
              notif_pengajuan_global: checked,
            })
          }
          else{
            this.setState({
              notif_pengajuan_global: checked,
            })
          }
        break;
        default:
          // no default
          break;
        }

      this.forceUpdate();
      this.setState({ checked });
    }

    // ================== Ambil data dari db untuk table ==================
    fetch = () => {
      Get("pengaturan-aplikasi", null, dtkat => {
        this.getLocation()

        this.setState({
          loading: false,
        })
        let data_cek = ""
        if (dtkat.results !== null) {
          let nomor_wa_perangkat = JSON.parse(dtkat.results.nomor_wa_perangkat)
          data_cek = "Tidak Kosong"

          let kode_pos = dtkat.results.alamat_kantor_desa.split(" Kode POS ");

          this.setState({
            loading: false,
            data: dtkat.results,
            response_pengajuan_layanan: dtkat.results.response_pengajuan_layanan,
            nomor_wa_perangkat: dtkat.results.nomor_wa_perangkat,
            get_all_nomor: nomor_wa_perangkat, 
            sidebar: dtkat.results.riwayat_disposisi === 0 ? false : true,
            notif_global: dtkat.results.notif_global_perangkat === 1 ? true : false,
            notif_wa: dtkat.results.notif_wa === 1 ? true : false,
            data_cek: data_cek,

            // Konfigurasi Tambahan Baru
            alamat_foto_kop: dtkat.results.alamat_foto_kop,
            notif_pengajuan_global: dtkat.results.notif_pengajuan_global === 1 ? true : false,
            jenis_kabkota: dtkat.results.jenis_kabkota,
            jenis_desa: dtkat.results.jenis_desa,
            jenis_dusun: dtkat.results.jenis_dusun,
            alamat_kantor_desa: kode_pos[0],
            kode_pos: kode_pos[1],
            provinsi: dtkat.results.provinsi,
            kabkota: dtkat.results.kabkota,
            nama_kabkota: dtkat.results.nama_kabkota,
            kecamatan: dtkat.results.kecamatan,
            desa: dtkat.results.desa,
            nama_desa: dtkat.results.nama_desa,
            jabatan_tertinggi: dtkat.results.jabatan_tertinggi,
            pimpinan_desa: dtkat.results.pimpinan_desa,
            jabatan_ttd: "Data tidak ditemukan",
            nama_ttd: "Data tidak ditemukan",
            nip_ttd: "Data tidak ditemukan",
            url_bo_digidesa: dtkat.results.url_bo_digidesa,
            url_marketplace: dtkat.results.url_marketplace,
            heading_notif: dtkat.results.heading_notif,
            type_app: dtkat.results.type_app,
            desa_one_signal: dtkat.results.desa_one_signal,
            style_nama_kabkota: dtkat.results.style_nama_kabkota,
            style_kecamatan: dtkat.results.style_kecamatan,
            style_desa: dtkat.results.style_desa,
            style_alamat: dtkat.results.style_alamat,
            nama_kades: dtkat.results.nama_kades,
            foto_kades: dtkat.results.foto_kades,
            link_fb: dtkat.results.link_fb,
            link_ig: dtkat.results.link_ig,
            email: dtkat.results.email_desa,
            ukuran_kertas: dtkat.results.ukuran_kertas,

            // new for koordinat absensi
            lat: dtkat.results.lat,
            long: dtkat.results.long,
            currentLocation: [dtkat.results.lat, dtkat.results.long]
          });
        }
        else{
          data_cek = "Kosong"
          this.setState({
            loading: false,
            data_cek: data_cek,
          });
        }
      });
    };

    // ========================================================================

    tutupForm = e => {
      this.setState({ 
        input: "none", 
        view: "inherit",
        status:{
          ...this.state.status,
          form: false,
        },
        selectedFile: null,
        link_gambar: '',
      });
      this.forceUpdate()
    };

    // Proses Upload Kop Surat
    processGbr = d => {
      if (d.length === 1) {
        let type = d[0].type.split("/");
        if (
          type[1] === "jpg" ||
          type[1] === "jpeg" ||
          type[1] === "png" ||
          type[1] === "bmp" ||
          type[1] === "tiff" ||
          type[1] === "webp"
        ) {
          let bodyFormData = new FormData();
          bodyFormData.append("files", d[0]);

          Post("upload-file/foto-kop", null, bodyFormData, data => {
            Resizer.imageFileResizer(
              d[0],
              300,
              300,
              type[1],
              100,
              0,
              uri => {
                this.setState({
                  foto_desa: uri,
                  foto_info: "",
                  link_gambar: data.data.results
                });
              },
              "base64"
            );
          });

        } else {
          this.setState({
            foto_desa: "",
            foto_info: (
              <div className="text-danger font-size-10">
                Type file tidak valid
              </div>
            )
          });
        }
      } else {
        this.setState({
          foto_desa: "",
          foto_info: (
            <div className="text-danger font-size-10">
              Silahkan masukan satu gambar
            </div>
          )
        });
      }
    };

    // ================== simpan data ==================
    simpan = e => {
      e.preventDefault();
      // Check validation
      let kode_pos = document.getElementById("kode_pos").value
      if (kode_pos.length > 5) {
        this.setState({
          show: true,
          basicType: 'warning',
          basicTitle: 'Konfigurasi Aplikasi',
          pesanAlert: 'Jumlah digit kode pos tidak boleh lebih dari 5 angka'
        });
      }
      else{

        this.setState({
          status:{
              ...this.state.status,
              btnForm: true,
          },
        })
        this.forceUpdate();
        // let uuid = null;

        let get_nomor_wa = JSON.stringify(this.state.dt.nomor_wa_perangkat);
        let pch = get_nomor_wa.split(':');
        let pch2 = pch[1].split('}');
        let nomor_wa = pch2[0];
        let set = JSON.parse(nomor_wa)

        const depArray = set;
        var newNomorWa = [];
        newNomorWa = depArray.filter(function(elem, pos) {
                return depArray.indexOf(elem) === pos;
        });

        let alamat_kantor_desa = document.getElementById("alamat_kantor_desa").value + ' Kode POS ' + document.getElementById("kode_pos").value

        if (this.state.link_gambar === "" || this.state.selectedKertas === null) {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Pengaturan Aplikasi " + JENIS_DESA,
            pesanAlert: "Silahkan lengkapi data!"
          });
        }
        else{
          if (this.state.selectedFile) {
            let bodyFormData = new FormData();
            bodyFormData.append('files', this.state.selectedFile);
    
            Post('upload-file/foto-kades', null, bodyFormData, (dataGbr) => {
              // console.log(dataGbr);
              if (dataGbr.data.results) {
                const foto_kades = dataGbr.data.results
                this.setState({ foto_kades })
                this.state.foto_kades = foto_kades
                this.forceUpdate()
    
                let addProfile = {
                  uuid: document.getElementById("uuid").value,
                  response_pengajuan_layanan: document.getElementById("response_pengajuan_layanan").value,
                  nomor_wa_perangkat: JSON.stringify(newNomorWa),
                  riwayat_disposisi: this.state.sidebar ? 1 : 0,
                  notif_global_perangkat: this.state.notif_global ? 1 : 0,
                  notif_wa: this.state.notif_wa ? 1 : 0,
                  // Konfigurasi Tambahan Baru
                  alamat_foto_kop: this.state.link_gambar,
                  notif_pengajuan_global: this.state.notif_pengajuan_global ? 1 : 0,
                  jenis_kabkota: document.getElementById("jenis_kabkota").value,
                  jenis_desa: document.getElementById("jenis_desa").value,
                  jenis_dusun: document.getElementById("jenis_dusun").value,
                  alamat_kantor_desa: alamat_kantor_desa,
                  provinsi: document.getElementById("provinsi").value,
                  kabkota: document.getElementById("kabkota").value,
                  nama_kabkota: document.getElementById("jenis_kabkota").value + ' ' + document.getElementById("kabkota").value,
                  kecamatan: document.getElementById("kecamatan").value,
                  desa: document.getElementById("desa").value,
                  nama_desa: document.getElementById("jenis_desa").value + ' ' + document.getElementById("desa").value,
                  jabatan_tertinggi: document.getElementById("jabatan_tertinggi").value,
                  pimpinan_desa: document.getElementById("jabatan_tertinggi").value,
                  jabatan_ttd: "Data tidak ditemukan",
                  nama_ttd: "Data tidak ditemukan",
                  nip_ttd: "Data tidak ditemukan",
                  url_bo_digidesa: document.getElementById("url_bo_digidesa").value,
                  url_marketplace: document.getElementById("url_marketplace").value,
                  heading_notif: document.getElementById("heading_notif").value,
                  type_app: document.getElementById("type_app").value,
                  desa_one_signal: document.getElementById("type_app").value+'_'+ID_DESA,
                  style_nama_kabkota: document.getElementById("style_nama_kabkota").value,
                  style_kecamatan: document.getElementById("style_kecamatan").value,
                  style_desa: document.getElementById("style_desa").value,
                  style_alamat: document.getElementById("style_alamat").value,
                  nama_kades: document.getElementById("nama_kades").value,
                  link_fb: document.getElementById("link_fb").value,
                  link_ig: document.getElementById("link_ig").value,
                  email_desa: document.getElementById("email").value,
                  foto_kades,
                  ukuran_kertas: this.state.selectedKertas.value,
                  lat: this.state.lat,
                  long: this.state.long,
                };
          
                // console.log(addProfile);
                
                let psn = "";
                let resstat = 204;
                let metode = "create";
                if (addProfile.uuid === "") {
                  psn = "Tambah";
                  resstat = 201;
                  addProfile.uuid = null;
                } else {
                  psn = "Ubah";
                  resstat = 200;
                  metode = "update";
                }
          
                // console.log('Data SImpan')
                // console.log(addProfile)
          
                Post("pengaturan-aplikasi/" + metode, addProfile.uuid, addProfile, res => {
                  if (res.status === resstat) {
                    this.setState({
                      show: true,
                      basicType: "success",
                      basicTitle: "Data Pengaturan Aplikasi",
                      pesanAlert: "Berhasil " + psn + " Data"
                    });
                    this.tutupForm()
                  } else {
                    this.setState({
                      show: true,
                      basicType: "danger",
                      basicTitle: "Data Pengaturan Aplikasi",
                      pesanAlert: "Gagal " + psn + " Data"
                    });
                  }
                  this.setState({ input: "none", view: "inherit" });
                  this.fetch();
                });
              }
            });
          } else {
            let addProfile = {
              uuid: document.getElementById("uuid").value,
            //   foto_desa: this.state.foto_desa,
              response_pengajuan_layanan: document.getElementById("response_pengajuan_layanan").value,
              nomor_wa_perangkat: JSON.stringify(newNomorWa),
              riwayat_disposisi: this.state.sidebar ? 1 : 0,
              notif_global_perangkat: this.state.notif_global ? 1 : 0,
              notif_wa: this.state.notif_wa ? 1 : 0,
              // Konfigurasi Tambahan Baru
              alamat_foto_kop: this.state.link_gambar,
              notif_pengajuan_global: this.state.notif_pengajuan_global ? 1 : 0,
              jenis_kabkota: document.getElementById("jenis_kabkota").value,
              jenis_desa: document.getElementById("jenis_desa").value,
              jenis_dusun: document.getElementById("jenis_dusun").value,
              alamat_kantor_desa: alamat_kantor_desa,
              provinsi: document.getElementById("provinsi").value,
              kabkota: document.getElementById("kabkota").value,
              nama_kabkota: document.getElementById("jenis_kabkota").value + ' ' + document.getElementById("kabkota").value,
              kecamatan: document.getElementById("kecamatan").value,
              desa: document.getElementById("desa").value,
              nama_desa: document.getElementById("jenis_desa").value + ' ' + document.getElementById("desa").value,
              jabatan_tertinggi: document.getElementById("jabatan_tertinggi").value,
              pimpinan_desa: document.getElementById("jabatan_tertinggi").value,
              jabatan_ttd: "Data tidak ditemukan",
              nama_ttd: "Data tidak ditemukan",
              nip_ttd: "Data tidak ditemukan",
              url_bo_digidesa: document.getElementById("url_bo_digidesa").value,
              url_marketplace: document.getElementById("url_marketplace").value,
              heading_notif: document.getElementById("heading_notif").value,
              type_app: document.getElementById("type_app").value,
              desa_one_signal: document.getElementById("type_app").value+'_'+ID_DESA,
              style_nama_kabkota: document.getElementById("style_nama_kabkota").value,
              style_kecamatan: document.getElementById("style_kecamatan").value,
              style_desa: document.getElementById("style_desa").value,
              style_alamat: document.getElementById("style_alamat").value,
              nama_kades: document.getElementById("nama_kades").value,
              link_fb: document.getElementById("link_fb").value,
              link_ig: document.getElementById("link_ig").value,
              email_desa: document.getElementById("email").value,
              foto_kades: this.state.foto_kades,
              ukuran_kertas: this.state.selectedKertas.value,
              lat: this.state.lat,
              long: this.state.long,
            };
      
            // console.log(addProfile);
            
            let psn = "";
            let resstat = 204;
            let metode = "create";
            if (addProfile.uuid === "") {
              psn = "Tambah";
              resstat = 201;
              addProfile.uuid = null;
            } else {
              psn = "Ubah";
              resstat = 200;
              metode = "update";
            }
      
            // console.log('Data SImpan')
            // console.log(addProfile)
      
            Post("pengaturan-aplikasi/" + metode, addProfile.uuid, addProfile, res => {
              if (res.status === resstat) {
                this.setState({
                  show: true,
                  basicType: "success",
                  basicTitle: "Data Pengaturan Aplikasi",
                  pesanAlert: "Berhasil " + psn + " Data"
                });
                this.tutupForm()
              } else {
                this.setState({
                  show: true,
                  basicType: "danger",
                  basicTitle: "Data Pengaturan Aplikasi",
                  pesanAlert: "Gagal " + psn + " Data"
                });
              }
              this.setState({ input: "none", view: "inherit" });
              this.fetch();
            });
          }
        }
        
      }
    };

    // ============================== Ubah Data ===============================
    ubahData = (status, data) => {
      this.setState({
        status:{
            ...this.state.status,
            form: true,
        },
      })
      if(status==="update"){
        let nomor_wa_perangkat = [
          { 
            isi:[""]
          }
        ]

        if (data.nomor_wa_perangkat !== '') {
          nomor_wa_perangkat = JSON.parse(data.nomor_wa_perangkat)
        }

        
        this.setState({
          data: data,
          link_gambar: data.alamat_foto_kop,
          selectedKertas: {value: data.ukuran_kertas, label: data.ukuran_kertas},
          dt:{
              ...this.state.dt,
              nomor_wa_perangkat: [
                { 
                  isi:nomor_wa_perangkat
                }
              ],
          },
        })

        this.forceUpdate()
      }
      else{
        this.setState({
          data:{

          },
          judul: 'Ubah Pengaturan Aplikasi',
        })
      }
      // this.setState({ view: 'none', input: 'inherit' });
      this.forceUpdate();
    };

    getLocation() {
      if ("geolocation" in navigator) {
        // console.log("Geolocation Available");
        navigator.geolocation.getCurrentPosition(function(position) {
          const lat = (position.coords.latitude)
          const long = (position.coords.longitude)

          const currentLocation = [lat, long]
          this.setState({ 
            curStaticLocation: currentLocation
          })
          this.forceUpdate()
          
        }.bind(this));
      } else {
        // console.log("Geolocation Not Available");
      }
    }

    changeSelectValue = (e, sel) => {
      if (e) {
        switch (sel) {
          case 'ukuran_kertas':
            this.setState({ selectedKertas: e });
            break;
          default:
            // no default
            break;
        }
      } else {
        switch (sel) {
          case 'ukuran_kertas':
            this.setState({ selectedKertas: null });
            break;
          default:
            // no default
            break;
        }
      }
    };

  // END KONFIGURASI KEBUTUHAN WS

	componentDidMount() {
    this._isMounted = true;
		this.fetch({ page: null, where: [] });
    this.fetchNomorSurat({ page: null, where: [] });
    this.fetchNotifDesa({ page: null, where: [] });
    this.fetchKodeSurat({ page: null, where: [] });
    // KODE SURAT
      
      Get("ukuran-kertas/list", null, data => {
        let frmUkuranKertasKodeSurat = [];
        data.results.forEach(dt => {
          frmUkuranKertasKodeSurat.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmUkuranKertasKodeSurat });
      });

      Get("select-enum/jenis_surat/kategori", null, data => {
        let frmKategori = [];
        //console.log(data);
        data.forEach(dt => {
          frmKategori.push({ value: dt, label: dt });
        });
        this.setState({ frmKategori });
      });

      // DETAIL DISPOSISI KODE SURAT
        // if(this.state.detailKodeSuratData){
        // }

        Get("jabatan-perangkat", null, data => {
          let frmJp = [];
          data.results.data.forEach(dt => {
              frmJp.push({ value: dt.uuid, label: dt.nama });
          });
          this.setState({ frmJp });
        });
      // END DETAIL DISPOSISI KODE SURAT
    // END KODE SURAT

    // PENOMORAN SURAT
    // END PENOMORAN SURAT

    // NOTIFIKASI DESA

    Get("select-enum/info_desa/jenis", null, data => {
      let frmStp = [];
      data.forEach(dt => {
        frmStp.push({ value: dt, label: dt });
      });
      //console.log(frmStp)
      this.setState({ frmStp });
    });

    // END NOTIFIKASI DESA
    // KONFIGURASI WS

    Get('select-enum/pengaturan_aplikasi/ukuran_kertas', null, (data) => {
      let frmKertas = [];
      data.forEach((dt) => {
        frmKertas.push({ value: dt, label: dt });
      });
      this.setState({ frmKertas });
    });
    // END KONFIGURASI WS
	}

  componentWillUnmount() {
    this._isMounted = false;
  }

	render() {
    console.log('data render')
    console.log(this.state.detailKodeSuratData)
    const { akun } = this.state;
    // DATA KODE SURAT
      var dataKodeSurat = this.state.dataKodeSurat;

      const frmKodeSurat = {
        uuid: "",
        id_desa: 0,
        nama: "",
        id_ukuran_kertas: "",
        nama_table: ""
      };
      const level = akun.sub.data_role.nama_role === "Admin" ? 2 : 1;
      if (level > 1) {
        this.setState({
          statusNomorSurat:{
              ...this.state.statusNomorSurat,
              disKode: "none",
          },
          statusKodeSurat:{
            ...this.state.statusKodeSurat,
            disKode: "none",
          }
        })
      }
    
      const columnsKodeSurat = [
        {
          dataField: "no",
          text: "No",
          headerAlign: 'center',
          align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.paginationKodeSurat.current_page;
              let total_records_per_page = this.state.paginationKodeSurat.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
        },
        {
          dataField: "nama",
          text: "Nama",
          headerAlign: 'center',
          align: 'center',
          sort: true
        },
        {
          dataField: "kategori",
          text: "Kategori",
          headerAlign: 'center',
          align: 'center',
          sort: true
        },
        {
          dataField: "kode_surat",
          text: "Kode surat",
          headerAlign: 'center',
          align: 'center',
          sort: true
        },
        {
          dataField: "aksi",
          text: "Aksi",
          headerAlign: 'center',
          align: 'center',
          style: {
            width: '150px'
          },
          formatter: this.tombolAksiKodeSurat
        }
      ];

      // DETAIL DISPOSISI KODE SURAT
        var dataDetailKodeSurat = this.state.dataDetailKodeSurat;
        
        let frmDetailKodeSurat = {
          uuid: "",
          id_jenis: "",
          id_jabatan_perangkat: "",
          level: "",
        };
        const columnsDetailKodeSurat = [
          {
            dataField: "no",
            text: "No",
            headerAlign: 'center',
            align: 'center',
            style: {
              width: '50px'
            },
            formatter: (key, obj, index) =>{    
              return index + 1;
              let current_pagenum = this.state.paginationDetailKodeSurat.current_page;
              let total_records_per_page = this.state.paginationDetailKodeSurat.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
            }
          },
          {
            dataField: "id_jabatan_perangkat",
            text: "Disposisi",
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, obj) => {
              return obj.data_jabatan.nama;
            },
            isDummyField: true,
            sort: true
          },
          {
            dataField: "level",
            text: "Level",
            headerAlign: 'center',
            align: 'center',
            sort: true
          },
        
          {
            dataField: "aksi",
            text: "Aksi",
            headerAlign: 'center',
            align: 'center',
            style: {
              width: '100px'
            },
            formatter: this.tombolAksiDetailKodeSurat
          }
        ];
      // END DETAIL DISPOSISI KODE SURAT
    // END DATA KODE SURAT

    // DATA PENOMORAN SURAT
      var dataNomorSurat = this.state.dataNomorSurat;
      
      const columnsNomorSurat = [
        {
          dataField: "tahun",
          text: "Tahun",
          sort: true,
          headerAlign: "center",
          align: "center",
          style: {
            width: '100px'
          }
        },
        {
          dataField: "nomor",
          text: "Nomor Surat",
          sort: true,
          headerAlign: "center",
          align: "center"
        },
      ];
    // END DATA PENOMORAN SURAT

    // DATA NOTIFIKASI DESA
      var dataNotifDesa = this.state.dataNotifDesa;
      const frmNotifDesa = {
        uuid: "",
        judul: "",
        konten: "",
        jenis: "",
        routing_mobile: "",
        args_routes: "",
        status: "",
        link_gambar: "",
        logo: "",
      };

      const columnsNotifDesa = [
        {
            dataField: "no",
            text: "No",
            isDummyField: true,
            csvExport: false,
            headerAlign: 'center',
            align: 'center',
            formatter: (key, obj, index) =>{          
                let current_pagenum = this.state.paginationNotifDesa.current_page;
                let total_records_per_page = this.state.paginationNotifDesa.per_page;
                let row_index = (index+1);
                let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                return serial_num; 
            },
            headerStyle: (colum, colIndex) => {
                return { width: '15px', textAlign: 'left' };
            }
        },
        {
          dataField: 'judul',
          headerAlign: 'center',
          align: 'center',
          text: 'Judul',
          sort: true
        },
        {
          dataField: 'konten',
          headerAlign: 'center',
          align: 'center',
          text: 'Konten',
          sort: true
        },
        {
          dataField: 'routing_mobile',
          headerAlign: 'center',
          align: 'center',
          text: 'Routing Mobile',
          sort: true
        },
        {
          dataField: "status",
          headerAlign: 'center',
          align: 'center',
          text: "Status",
          sort: true,
          formatter: (cell, obj) => {
            let isinya = obj.status
            if(isinya==='0'){
            return ('Aktif');
            }
            else{
            return ('Tidak Aktif');
            }
          },
          },
        {
          dataField: "aksi",
          headerAlign: 'center',
          align: 'center',
          text: "Aksi",
          style: {
            width: '100px'
          },
          formatter: this.tombolAksiNotifDesa
        }
      ];
    // END DATA NOTIFIKASI DESA
    
    var htmlToReactParser = new HtmlToReactParser();

		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}
				
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-3">
                                      <Nav  className={'nav flex-column nav-pills'}>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
                                              onClick={() => { this.toggle_vertical('1'); }}>
                                            Kode Surat
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '2' })}
                                              onClick={() => { this.toggle_vertical('2'); }}>
                                            Nomor Surat
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '3' })}
                                              onClick={() => { this.toggle_vertical('3'); }}>
                                            Notifikasi Desa
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink className={classnames({ active: this.state.active_tab_vertical === '4' })}
                                              onClick={() => { this.toggle_vertical('4'); }}>
                                            Kebutuhan WS
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                    <div className="col-lg-9">
                                      <TabContent activeTab={this.state.active_tab_vertical}>
                                        <TabPane tabId="1">
                                          <Row>
                                            <Col sm="12">
                                              <div className="row">
                                              {
                                                this.state.detailKodeSuratStat?
                                                  <div className="col-sm-12">
                                                    <div className="card mb-0">
                                                      <div className="card-header">
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <h5>{this.state.detailKodeSuratData?this.state.detailKodeSuratData.nama:''}</h5>
                                                          </div>
                                                          <div className="col-sm-12">
                                                            <br/>
                                                          </div>
                                                          <div className="col-sm-6">
                                                            <button onClick={() => { 
                                                              this.setState({ 
                                                                detailKodeSuratStat: false,
                                                                detailKodeSuratData:[],
                                                              });
                                                            }}
                                                            color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
                                                          </div>
                                                          <div className="col-sm-6 text-right" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                            <Button
                                                              size="sm"
                                                              color="success"
                                                              className="btn-square"
                                                              onClick={() => {
                                                                this.setState({
                                                                  judulDetailKodeSurat: "Tambah Alur Disposisi",
                                                                  dtDetailKodeSurat: frmDetailKodeSurat,
                                                                  jpStat: []
                                                                });
                                                                this.bukaFormDetailKodeSurat();
                                                              }}
                                                            >
                                                              Tambah Data
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="card-body datatable-react">
                                                        {this.state.loadingDetailKodeSurat ? (
                                                          <div className="row">
                                                            <div className="col-sm-12" align="center">
                                                              <img
                                                                alt="loading"
                                                                src={require("../../../assets/images/tes-loading.gif")}
                                                                style={{
                                                                  borderRadius: "10px",
                                                                  width: '100px'
                                                                }} />
                                                            </div>
                                                          </div>
                                                        ) : dataDetailKodeSurat.length > 0 ? (
                                                          <>
                                                            <BootstrapTable
                                                              keyField="id"
                                                              data={dataDetailKodeSurat}
                                                              columns={columnsDetailKodeSurat}
                                                            />
                                                            {/* <div className="pull-right text-white">
                                                              {this.state.awal}
                                                              {this.state.sebelum}
                                                              {this.state.hal.map(dt => {
                                                                return dt;
                                                              })}
                                                              {this.state.setelah}
                                                              {this.state.akhir}
                                                            </div> */}
                                                          </>
                                                        ) : (
                                                          <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <br />
                                                    <Modal
                                                      open={this.state.statusDetailKodeSurat.form}
                                                      onClose={this.tutupFormDetailKodeSurat}
                                                      styles={{ modal: { width: "80%" } }}
                                                      closeOnEsc={false}
                                                      closeOnOverlayClick={false}
                                                    >
                                                      <Form className="theme-form" onSubmit={this.simpanDetailKodeSurat}>
                                                        <div className="modal-header">
                                                          <h5 className="modal-title">{this.state.judulDetailKodeSurat}</h5>
                                                        </div>
                                                        <div className="modal-body">
                                                          <input
                                                            className="form-control"
                                                            id="uuid"
                                                            type="hidden"
                                                            placeholder=""
                                                            defaultValue={this.state.dtDetailKodeSurat.uuid}
                                                          />
                                                          <FormGroup className="row">
                                                            <Label
                                                              className="col-sm-3 col-form-label"
                                                              htmlFor="inputEmail3"
                                                            >
                                                              Jabatan Perangkat{" "}
                                                            </Label>
                                                            <Col sm="9">
                                                              <Select
                                                                isDisabled={this.state.statusDetailKodeSurat.select}
                                                                classNamePrefix="select"
                                                                onChange={e => {
                                                                  this.getJp(e);
                                                                }}
                                                                defaultValue={this.state.jpStat}
                                                                value={this.state.jpStat}
                                                                name="penduduk"
                                                                options={this.state.frmJp}
                                                                placeholder="Pilih Jabatan Perangkat"
                                                                isClearable
                                                              />
                                                            </Col>
                                                          </FormGroup>

                                                          
                                                          <FormGroup className="row">
                                                            <Label
                                                              className="col-sm-3 col-form-label"
                                                              htmlFor="inputEmail3"
                                                            >
                                                              Level
                                                            </Label>
                                                            <Col sm="9">
                                                              <input
                                                                className="form-control"
                                                                id="level"
                                                                type="number"
                                                                required
                                                                placeholder="Level"
                                                                defaultValue={this.state.dtDetailKodeSurat.level}
                                                              />
                                                            </Col>
                                                          </FormGroup>
                                                        </div>
                                                        <div className="modal-footer">
                                                          <button
                                                            type="button"
                                                            className="btn btn-warning"
                                                            disabled={this.state.statusDetailKodeSurat.btnForm}
                                                            onClick={this.tutupFormDetailKodeSurat}
                                                          >
                                                            Tutup
                                                          </button>
                                                          <button
                                                            type="submit"
                                                            className="btn btn-success"
                                                            disabled={this.state.statusDetailKodeSurat.btnForm}
                                                          >
                                                            Simpan
                                                          </button>
                                                        </div>
                                                      </Form>
                                                    </Modal>
                                                  </div>
                                                :
                                                  <div className="col-sm-12">
                                                    <div className="card mb-0">
                                                      {/* <div className="card-header">
                                                        <div className="row">
                                                          <div className="col-sm-6">
                                                          </div>
                                                          <div className="col-sm-6 text-right" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                            <Button
                                                              size="sm"
                                                              color="success"
                                                              className="btn-square"
                                                              style={{ display: this.state.statusKodeSurat.disKode }}
                                                              onClick={() => {
                                                                this.setState({
                                                                  judulKodeSurat: "Tambah Jenis Surat",
                                                                  dtKodeSurat: frmKodeSurat,
                                                                  ukuranKertasStatKodeSurat: []
                                                                });
                                                                this.bukaFormKodeSurat();
                                                              }}
                                                            >
                                                              Tambah Data
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </div> */}
                                                      <div className="card-body datatable-react">
                                                        {this.state.loadingKodeSurat ? (
                                                          <div className="row">
                                                            <div className="col-sm-12" align="center">
                                                              <img
                                                                alt="loading"
                                                                src={require("../../../assets/images/tes-loading.gif")}
                                                                style={{
                                                                  borderRadius: "10px",
                                                                  width: '100px'
                                                                }} />
                                                            </div>
                                                          </div>
                                                        ) : dataKodeSurat.length > 0 ? (
                                                          <>
                                                            <BootstrapTable keyField="id" data={dataKodeSurat} columns={columnsKodeSurat} />
                                                            <div className="pull-right text-white">
                                                              {this.state.awalKodeSurat}
                                                              {this.state.sebelumKodeSurat}
                                                              {this.state.halKodeSurat.map(dt => {
                                                                return dt;
                                                              })}
                                                              {this.state.setelahKodeSurat}
                                                              {this.state.akhirKodeSurat}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                              }
                                              </div>
                                              <br />
                                              <Modal
                                                open={this.state.statusKodeSurat.form}
                                                onClose={this.tutupFormKodeSurat}
                                                styles={{ modal: { width: "80%" } }}
                                              >
                                                <div className="modal-header">
                                                  <h5 className="modal-title">{this.state.judulKodeSurat}</h5>
                                                </div>
                                                <Form className="theme-form" onSubmit={this.simpanKodeSurat}>
                                                  <div className="modal-body">
                                                    <input
                                                      className="form-control"
                                                      id="uuid"
                                                      type="hidden"
                                                      placeholder="Role"
                                                      defaultValue={this.state.dtKodeSurat.uuid}
                                                    />
                                                    <div style={{ display: this.state.statusKodeSurat.disKode }}>
                                                      <FormGroup className="row">
                                                        <Label
                                                          className="col-sm-3 col-form-label"
                                                          htmlFor="inputEmail3"
                                                        >
                                                          Ukuran kertas
                                                        </Label>
                                                        <Col sm="9">
                                                          <Select
                                                            isDisabled={this.state.statusKodeSurat.select}
                                                            classNamePrefix="select"
                                                            onChange={e => {
                                                              this.changeSelectValueKodeSurat(e, "ukuran_kertas");
                                                            }}
                                                            defaultValue={this.state.ukuranKertasStatKodeSurat}
                                                            value={this.state.ukuranKertasStatKodeSurat}
                                                            name="ukuran_kertas"
                                                            options={this.state.frmUkuranKertasKodeSurat}
                                                            placeholder="Pilih Ukuran kertas"
                                                            isClearable
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                      <FormGroup className="row">
                                                        <Label
                                                          className="col-sm-3 col-form-label"
                                                          htmlFor="inputEmail3"
                                                        >
                                                          Nama Surat
                                                        </Label>
                                                        <Col sm="9">
                                                          <input
                                                            className="form-control"
                                                            id="nama"
                                                            type="text"
                                                            placeholder="masukan nama surat"
                                                            required
                                                            defaultValue={this.state.dtKodeSurat.nama}
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                      <FormGroup className="row">
                                                        <Label
                                                          className="col-sm-3 col-form-label"
                                                          htmlFor="inputEmail3"
                                                        >
                                                          Jenis Surat
                                                        </Label>
                                                        <Col sm="9">
                                                          <Select
                                                            isDisabled={this.state.statusKodeSurat.select}
                                                            classNamePrefix="select"
                                                            onChange={e => {
                                                              this.changeSelectValueKodeSurat(e, "jenis_surat");
                                                            }}
                                                            defaultValue={this.state.ktrStat}
                                                            value={this.state.ktrStat}
                                                            name="Jenis Surat"
                                                            options={this.state.frmKategori}
                                                            placeholder="Pilih Jenis Surat"
                                                            isClearable
                                                          />
                                                        </Col>
                                                      </FormGroup>

                                                      <FormGroup className="row">
                                                        <Label
                                                          className="col-sm-3 col-form-label"
                                                          htmlFor="inputEmail3"
                                                        >
                                                          Nama table
                                                        </Label>
                                                        <Col sm="9">
                                                          <input
                                                            className="form-control"
                                                            id="nama_table"
                                                            type="text"
                                                            placeholder="masukan nama table"
                                                            required
                                                            defaultValue={this.state.dtKodeSurat.nama_table}
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                    <FormGroup className="row">
                                                      <Label
                                                        className="col-sm-3 col-form-label"
                                                        htmlFor="inputEmail3"
                                                      >
                                                        Kode Surat
                                                      </Label>
                                                      <Col sm="9">
                                                        <input
                                                          className="form-control"
                                                          id="kode_surat"
                                                          type="text"
                                                          placeholder="kode_surat"
                                                          required
                                                          defaultValue={this.state.dtKodeSurat.kode_surat}
                                                        />
                                                      </Col>
                                                    </FormGroup>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      disabled={this.state.statusKodeSurat.btnForm}
                                                      onClick={this.tutupFormKodeSurat}
                                                    >
                                                      Tutup
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success"
                                                      disabled={this.state.statusKodeSurat.btnForm}
                                                    >
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                          <Row>
                                              <Col sm="12">
                                                <div className="row">
                                                  <div className="col-sm-12">
                                                    <div className="card mb-0">
                                                      <div className="card-header">
                                                        <div className="row">
                                                          <div className="col-sm-6">
                                                          </div>
                                                          <div className="col-sm-6 text-right" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                            <Button
                                                              size="sm"
                                                              color="success"
                                                              className="btn-square"
                                                              style={{ display: this.state.statusNomorSurat.disKode }}
                                                              onClick={() => {
                                                                this.setState({
                                                                  judulNomorSurat: "Pembaruan Nomor Surat",
                                                                });
                                                                this.bukaForm();
                                                              }}
                                                            >
                                                              Perbarui Nomor Surat
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="card-body datatable-react">
                                                        <div className="row" style={{ justifyContent: 'center' }}>
                                                          <div className="col-sm-4">
                                                            {this.state.loadingNomorSurat ? (
                                                              <div className="row">
                                                                <div className="col-sm-12" align="center">
                                                                  <img
                                                                    alt="loading"
                                                                    src={require("../../../assets/images/tes-loading.gif")}
                                                                    style={{
                                                                      borderRadius: "10px",
                                                                      width: '100px'
                                                                    }} />
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <BootstrapTable keyField="id" data={dataNomorSurat} columns={columnsNomorSurat} />
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <br />
                                                <Modal
                                                  open={this.state.statusNomorSurat.form}
                                                  onClose={this.tutupFormNomorSurat}
                                                  styles={{ modal: { width: "80%" } }}
                                                >
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.judulNomorSurat}</h5>
                                                  </div>
                                                  <Form className="theme-form" onSubmit={this.simpanNomorSurat}>
                                                    <div className="modal-body">
                                                      <FormGroup className="row">
                                                        <Label
                                                          className="col-sm-3 col-form-label"
                                                          htmlFor="inputEmail3"
                                                        >
                                                          Nomor Surat
                                                        </Label>
                                                        <Col sm="9">
                                                          <input
                                                            className="form-control"
                                                            id="nomor_surat"
                                                            type="number"
                                                            placeholder="Masukkan nomor surat terakhir yang telah terbit"
                                                            min={1}
                                                            required
                                                            // defaultValue={this.state.dt.kode_surat}
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                    <div className="modal-footer">
                                                      <button
                                                        type="button"
                                                        className="btn btn-warning"
                                                        disabled={this.state.statusNomorSurat.btnForm}
                                                        onClick={this.tutupFormNomorSurat}
                                                      >
                                                        Tutup
                                                      </button>
                                                      <button
                                                        type="submit"
                                                        className="btn btn-success"
                                                        disabled={this.state.statusNomorSurat.btnForm}
                                                      >
                                                        Simpan
                                                      </button>
                                                    </div>
                                                  </Form>
                                                </Modal>
                                              </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                          <Row>
                                            <Col sm="12">
                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="card mb-0">
                                                    <div className="card-header">
                                                      <div className="row">
                                                        <div className="col-sm-6">
                                                            &nbsp;
                                                        </div>
                                                        <div className="col-sm-6 text-right">
                                                          <Button
                                                            size="sm"
                                                            color="success"
                                                            className="btn-square"
                                                            onClick={() => {
                                                              this.setState({
                                                                judulNotifDesa: "Tambah Info " + JENIS_DESA,
                                                                dtNotifDesa: frmNotifDesa,
                                                                linkGambarNotifDesa: "",
                                                                contentNotifDesa: ""
                                                              });
                                                              this.bukaFormNotifDesa();
                                                            }}
                                                          >
                                                            Tambah Data
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="card-body datatable-react">
                                                      {this.state.loadingNotifDesa ? (
                                                        <div className="row">
                                                          <div className="col-sm-12" align="center">
                                                            <img
                                                              alt="loadingNotifDesa"
                                                              src={require("../../../assets/images/tes-loading.gif")}
                                                              style={{
                                                                borderRadius: "10px",
                                                                width: '100px'
                                                              }} />
                                                          </div>
                                                        </div>
                                                      ) : dataNotifDesa.length > 0 ? (
                                                        <>
                                                          <BootstrapTable keyField="id" data={dataNotifDesa} columns={columnsNotifDesa} />
                                                          <div className="pull-right text-white">
                                                            {this.state.awalNotifDesa}
                                                            {this.state.sebelumNotifDesa}
                                                            {this.state.halNotifDesa.map(dt => {
                                                              return dt;
                                                            })}
                                                            {this.state.setelahNotifDesa}
                                                            {this.state.akhirNotifDesa}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <br />
                                              <Modal
                                                open={this.state.statusNotifDesa.form}
                                                onClose={this.tutupFormNotifDesa}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                                styles={{ modal: { width: "80%" } }}
                                              >
                                                <Form className="theme-form" onSubmit={this.simpanNotifDesa}>
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.judulNotifDesa}</h5>
                                                  </div>
                                                  <div className="modal-body">
                                                    <input
                                                      className="form-control"
                                                      id="uuid"
                                                      type="hidden"
                                                      placeholder="Agama"
                                                      defaultValue={this.state.dtNotifDesa.uuid}
                                                      value={this.state.dtNotifDesa.uuid}
                                                    />
                                                    
                                                    
                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Judul</Label>
                                                      <Col sm='9'>
                                                        <input
                                                          id="judul"
                                                          className="form-control"
                                                          defaultValue={this.state.dtNotifDesa.judul}
                                                          // value={this.state.dtNotifDesa.judul}
                                                          type="text"
                                                          placeholder="Judul"
                                                          required
                                                        />
                                                      </Col>
                                                    </FormGroup>

                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Konten</Label>
                                                      <Col sm='9'>
                                                        <input 
                                                          className="form-control" 
                                                          id="konten" 
                                                          type="text" 
                                                          placeholder="Konten" 
                                                          defaultValue={this.state.dtNotifDesa.konten} 
                                                          // required
                                                        />
                                                      </Col>
                                                    </FormGroup>
                                                    
                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Routing Mobile</Label>
                                                      <Col sm='9'>
                                                        <input 
                                                          className="form-control" 
                                                          id="routing_mobile" 
                                                          type="text" 
                                                          placeholder="Routing Mobile" 
                                                          defaultValue={this.state.dtNotifDesa.routing_mobile} 
                                                          required
                                                        />
                                                      </Col>
                                                    </FormGroup>
                                                    
                                                    <FormGroup className="row">
                                                      <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Argument Route</Label>
                                                      <Col sm='9'>
                                                        <input 
                                                          className="form-control" 
                                                          id="args_routes" 
                                                          type="text" 
                                                          placeholder="Argument Route" 
                                                          defaultValue={this.state.dtNotifDesa.args_routes} 
                                                          required
                                                        />
                                                      </Col>
                                                    </FormGroup>

                                                    {
                                                      this.state.dtNotifDesa.uuid?
                                                      <>
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
                                                          <Col sm='9'>
                                                          <Select
                                                              classNamePrefix="select"
                                                              onChange={(e) => this.onChangeSelectNotifDesa(e, 'status_notifikasi')}
                                                              value={this.state.status_notifikasi}
                                                              name="status_notifikasi"
                                                              options={[
                                                                { value: '0', label: 'Aktif' },
                                                                { value: '1', label: 'Tidak Aktif' },
                                                              ]}
                                                              placeholder="Pilih Status Notifikasi"
                                                              isClearable
                                                            />
                                                          </Col>
                                                        </FormGroup>
                                                      </>
                                                      :
                                                      <>
                                                      <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
                                                          <Col sm='9'>
                                                            <input 
                                                              className="form-control" 
                                                              id="konten" 
                                                              type="text" 
                                                              placeholder="Konten" 
                                                              defaultValue="Aktif"
                                                              value="Aktif"
                                                              readOnly
                                                              disabled
                                                            />
                                                          </Col>
                                                        </FormGroup>
                                                      </>
                                                    }

                                                        <FormGroup className="row">
                                                          <Label
                                                            className="col-sm-3 col-form-label"
                                                            htmlFor="inputEmail3"
                                                          >
                                                            Gambar
                                                          </Label>
                                                          <Col sm="9">
                                                            <input className="form-control" placeholder="Logo lama" id="logo_lama" type="hidden" defaultValue={this.state.dtNotifDesa.logo} />

                                                            <Dropzone
                                                              onDrop={acceptedFiles => this.processGbrNotifDesa(acceptedFiles)}
                                                              maxFiles={1}
                                                            >
                                                              {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                  <div
                                                                    {...getRootProps()}
                                                                    style={{
                                                                      minHeight: "100px",
                                                                      paddingTop: "40px",
                                                                      padding: "5px 5px 5px 5px",
                                                                      border: "1px solid #d2d2d2"
                                                                    }}
                                                                  >
                                                                    <input {...getInputProps()} />
                                                                    <div
                                                                      style={{
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                      }}
                                                                    >
                                                                      <p className="text-center">
                                                                        {this.state.linkGambarNotifDesa ? (
                                                                          <img
                                                                            alt="gambar"
                                                                            src={this.state.linkGambarNotifDesa}
                                                                            style={{
                                                                              minHeight: "50px",
                                                                              minWidth: "50px"
                                                                            }}
                                                                          />
                                                                        ) : (
                                                                          <Label
                                                                            className="col-form-label text-center"
                                                                            htmlFor="inputEmail3"
                                                                          >
                                                                            Drag 'n' drop some files here, or click to
                                                                            select files
                                                                          </Label>
                                                                        )}
                                                                        {this.state.fotoInfoNotifDesa
                                                                          ? this.state.fotoInfoNotifDesa
                                                                          : ""}
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </section>
                                                              )}
                                                            </Dropzone>
                                                          </Col>
                                                        </FormGroup>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <button
                                                      type="button"
                                                      className="btn btn-warning"
                                                      disabled={this.state.statusNotifDesa.btnForm}
                                                      onClick={this.tutupFormNotifDesa}
                                                    >
                                                      Tutup
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success"
                                                      disabled={this.state.statusNotifDesa.btnForm}
                                                    >
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>
                                              <Modal
                                                open={this.state.statDetailNotifDesa}
                                                onClose={this.tutupFormNotifDesa}
                                                styles={{ modal: { width: "80%" } }}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                              >
                                                <Form className="theme-form">
                                                  <div className="modal-header">
                                                    <h5 className="modal-title">Detail Info</h5>
                                                  </div>
                                                  <div className="modal-body ">
                                                    <img
                                                      alt="detailgambar"
                                                      src={this.state.linkGambarNotifDesa}
                                                      className="center"
                                                      style={{
                                                        minHeight: "50px",
                                                        minWidth: "50px"
                                                      }}
                                                    />
                                                    {htmlToReactParser.parse(this.state.contentNotifDesa)}
                                                  </div>
                                                </Form>
                                              </Modal>

                                            </Col>
                                          </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                          <Row>
                                            <Col sm="12">
                                              <div
                                                className="row"
                                                style={{ border: '3px outset black', borderRadius: '25px', padding: '20px' }}
                                              >
                                                {this.state.loading ? (
                                                  <div className="col-sm-12" align="center">
                                                    <img 
                                                    alt="loading"
                                                    src={require("../../../assets/images/tes-loading.gif")} 
                                                    style={{
                                                      // width: "100%",
                                                      borderRadius: "10px",
                                                      width: '120px'
                                                    }}/> 
                                                  </div>
                                                ) : 
                                                <>
                                                  <div className="col-md-12 text-right">
                                                  {
                                                    this.state.data.uuid
                                                    ?
                                                      <Button 
                                                        size="md" 
                                                        color="success" className="btn-icon" onClick={() => {
                                                          this.ubahData('update',this.state.data)
                                                        }} 
                                                        disabled={this.state.status.btnAksi}
                                                      >
                                                        <i className="fa fa-edit"></i>
                                                        Ubah Pengaturan
                                                      </Button> 
                                                    :
                                                      <Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Pengaturan</Button> 
                                                  }
                                                  </div>
                                                  <div>
                                                    <br/>&nbsp;<br/>
                                                  </div>
                                                  {
                                                  this.state.data.uuid
                                                  ?
                                                    <>
                                                      <div className="col-md-12" align="center">
                                                        {/* <img src={this.state.data.foto_desa} style={{ width: '350px' }} /> */}
                                                        <br/><br/>
                                                      </div>

                                                      <div className="col-md-12" align='center'>
                                                        <h5><b>Konfigurasi Umum</b></h5>
                                                        <hr/>
                                                      </div>
                                                      <div className="col-4">
                                                        <div className="col-md-12">
                                                          <b>Response pengajuan layanan</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          {this.state.response_pengajuan_layanan}
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>&nbsp;</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          &nbsp;
                                                          </div>

                                                        <div className="col-md-12">
                                                          <b>Status Riwayat Disposisi</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          {this.state.sidebar === false ? "Tidak Aktif" : "Aktif"}
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>&nbsp;</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          &nbsp;
                                                        </div>
                                                      </div>

                                                      {/* ======================= */}
                                                      <div className="col-4">
                                                        <div className="col-md-12">
                                                          <b>Notifikasi Layanan Global</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          {this.state.notif_global === false ? "Tidak Aktif" : "Aktif"}
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>&nbsp;</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          &nbsp;
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>Notifikasi Pengajuan Global</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          {this.state.notif_pengajuan_global === false ? "Tidak Aktif" : "Aktif"}
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>&nbsp;</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          &nbsp;
                                                        </div>

                                                      </div>
                                                      {/* ======================= */}
                                                      <div className="col-4">
                                                        <div className="col-md-12">
                                                          <b>Notifikasi Whatsapp</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          {this.state.notif_wa === false ? "Tidak Aktif" : "Aktif"}
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>&nbsp;</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          &nbsp;
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>Nomor Whatsapp</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          {
                                                            this.state.get_all_nomor.map((dt, i) => {
                                                              return (
                                                                <tr>
                                                                  <td style={{ padding: '5px 5px 5px 5px' }}>{i + 1}.</td>
                                                                  <td style={{ padding: '5px 5px 5px 5px' }}>{dt}</td>
                                                                </tr>
                                                              );
                                                            })
                                                          }
                                                        </div>

                                                        <div className="col-md-12">
                                                          <b>&nbsp;</b>
                                                        </div>
                                                        <div className="col-md-12">
                                                          &nbsp;
                                                          </div>
                                                      </div>

                                                      {/* ===================================== */}
                                                      <div className="col-md-12" align='center'>
                                                        <hr/>
                                                        <h5><b>Konfigurasi Web Service</b></h5>
                                                        <hr/>
                                                      </div>

                                                        <div className="col-4">
                                                          <div className="col-md-12">
                                                            <b>Nama Provinsi</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.provinsi}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Jenis Kabupaten/Kota</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.jenis_kabkota}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Nama {this.state.jenis_kabkota}</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.kabkota}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Nama Kecamatan</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.kecamatan}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Jenis Kelurahan/Desa</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.jenis_desa}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Nama {this.state.jenis_desa}</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.desa}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Jenis Dusun</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.jenis_dusun}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Nama Lengkap Kepala {JENIS_DESA}</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.nama_kades
                                                            }
                                                          </div>
                                                          
                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Foto Kepala {JENIS_DESA}</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            <img alt="foto_kades" src={this.state.foto_kades} style={{ width: '150px' }} />
                                                          </div>

                                                        </div>
                                                        {/* ======================= */}
                                                        <div className="col-4">
                                                          <div className="col-md-12">
                                                            <b>Jabatan Tertinggi {this.state.jenis_desa}</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.jabatan_tertinggi}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Alamat Kantor Desa</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.alamat_kantor_desa}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Kode Pos</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.kode_pos}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Url BO Digidesa</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.url_bo_digidesa}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Url Marketplace</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.url_marketplace}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Type App</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.type_app}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Heading Notif</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {this.state.heading_notif}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>
                                                          
                                                          <div className="col-md-12">
                                                            <b>Link Facebook</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.link_fb
                                                            }
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Email </b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.email
                                                            }
                                                          </div>


                                                        </div>
                                                        {/* ======================= */}
                                                        <div className="col-4">
                                                          <div className="col-md-12">
                                                            <b>Ukuran Kertas </b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.ukuran_kertas
                                                            }
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Style Nama Kabupaten/Kota</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {/* {this.state.style_nama_kabkota ? "Tidak Aktif" : "Aktif"} */}
                                                            {this.state.style_nama_kabkota}
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Style Kecamatan</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.style_kecamatan
                                                            }
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Style Desa</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.style_desa
                                                            }
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Style Alamat</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.style_alamat
                                                            }
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Link Instagram</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            {
                                                              this.state.link_ig
                                                            }
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>&nbsp;</b>
                                                          </div>

                                                          <div className="col-md-12">
                                                            <b>Foto Kop</b>
                                                          </div>
                                                          <div className="col-md-12">
                                                            <img alt="foto_kop" src={this.state.alamat_foto_kop} style={{ width: '150px' }} />
                                                          </div>

                                                        </div>
                                                    </>
                                                  :
                                                  (
                                                    <div className="col-md-12 text-center">
                                                      <h4 align="center">Pengaturan Aplikasi Belum Ada</h4>
                                                    </div>
                                                  )
                                                  }
                                                </>
                                              }
                                              </div>
                                              
                                              <Modal
                                                open={this.state.status.form}
                                                onClose={this.tutupForm}
                                                styles={{ modal: { width: '80%' } }}
                                                closeOnEsc={false}
                                                closeOnOverlayClick={false}
                                              >
                                                <Form className="theme-form" onSubmit={this.simpan}>
                                                  <div className="modal-header">
                                                    <h4 className="modal-title">{this.state.judul}</h4>
                                                  </div>

                                                  <div className="modal-body">
                                                    <input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid}/>

                                                    <div className="row">
                                                      <div className="col-12" align='center'>
                                                        <h5>Konfigurasi Umum</h5>
                                                        <hr/>
                                                      </div>
                                                      <div className="col-6">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Riwayat Disposisi Perangkat</b></Label>
                                                          <Col sm='3'>
                                                            <Switch 
                                                              onChange={(e) => {
                                                              this.handleChange(e,'sidebar');
                                                              }}
                                                              checked={this.state.sidebar} 
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>

                                                      <div className="col-6">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Layanan Global</b></Label>
                                                          <Col sm='3'>
                                                            <Switch 
                                                              onChange={(e) => {
                                                              this.handleChange(e,'notif_global');
                                                              }}
                                                              checked={this.state.notif_global} 
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>

                                                      <div className="col-6">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Pengajuan Global</b></Label>
                                                          <Col sm='3'>
                                                            <Switch 
                                                              onChange={(e) => {
                                                              this.handleChange(e,'notif_pengajuan_global');
                                                              }}
                                                              checked={this.state.notif_pengajuan_global} 
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>

                                                      <div className="col-6">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Whatsapp</b></Label>
                                                          <Col sm='3'>
                                                            <Switch 
                                                              onChange={(e) => {
                                                              this.handleChange(e,'notif_wa');
                                                              }}
                                                              checked={this.state.notif_wa} 
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>

                                                      {/* <div className="col-lg-6 pl-3">
                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                                          <b> Riwayat disposisi perangkat </b>
                                                          </Label>
                                                          <br/>
                                                          <div className="media-body text-right switch-sm">
                                                            <span>
                                                              {this.state.sidebar === false
                                                                ? "Tidak aktif"
                                                                : "Aktif"}
                                                            </span>
                                                            <label className="switch">
                                                              <input
                                                                type="checkbox"
                                                                id="sidebar-toggle"
                                                                checked={this.state.sidebar}
                                                                style={{width: '150px'}}
                                                                onClick={() => this.openCloseSidebar("sidebar")}
                                                              />
                                                              <span className="switch-state"></span>
                                                            </label>
                                                          </div>
                                                        </FormGroup>
                                                      </div>
                                                      <div className="col-lg-6 pl-3">
                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                                            <b>Notifikasi Layanan Global</b>
                                                          </Label>
                                                          <div className="media-body text-right switch-sm">
                                                            <span>
                                                              {this.state.notif_global === false
                                                                ? "Tidak aktif"
                                                                : "Aktif"}
                                                            </span>
                                                            <label className="switch">
                                                              <input
                                                                type="checkbox"
                                                                id="notif-toggle"
                                                                checked={this.state.notif_global}
                                                                onClick={() => this.openCloseSidebar("notif_global")}
                                                              />
                                                              <span className="switch-state"></span>
                                                            </label>
                                                          </div>
                                                        </FormGroup>
                                                      </div>
                                                      <div className="col-lg-6 pl-3">
                                                        <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                                            <b>Notifikasi Whatsapp</b>
                                                          </Label>
                                                          <div className="media-body text-right switch-sm">
                                                            <span>
                                                              {this.state.notif_wa === false
                                                                ? "Tidak aktif"
                                                                : "Aktif"}
                                                            </span>
                                                            <label className="switch">
                                                              <input
                                                                type="checkbox"
                                                                id="notif-toggle"
                                                                checked={this.state.notif_wa}
                                                                onClick={() => this.openCloseSidebar("notif_wa")}
                                                              />
                                                              <span className="switch-state"></span>
                                                            </label>
                                                          </div>
                                                        </FormGroup>
                                                      </div> */}

                                                      <div className="col-lg-12 pr-3">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">
                                                          <b>Response Pengajuan Layanan</b>
                                                          </Label>
                                                        <Col sm="8">
                                                        {/*<FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                                            Response Pengajuan Layanan
                                                          </Label> */}
                                                            <input
                                                              className="form-control"
                                                              id="response_pengajuan_layanan"
                                                              type="text"
                                                              placeholder="response"
                                                              defaultValue={this.state.response_pengajuan_layanan}
                                                            />
                                                          </Col>
                                                        </FormGroup>
                                                      </div>

                                                      <div className="col-lg-12 pl-3">
                                                      {this.state.dt.nomor_wa_perangkat.length > 0 ? (
                                                        <>
                                                          <FormGroup className="row">
                                                            <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                                              <b>Nomor HP Perangkat</b>
                                                            </Label>
                                                          </FormGroup>
                                                          {this.state.dt.nomor_wa_perangkat.map((dti, i) => {
                                                            return (
                                                              <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                                                                {/* Tombol hapus petunjuk */}
                                                                
                                                                {dti.isi.map((dt_isi, j) => {
                                                                  return (
                                                                    <>
                                                                      <FormGroup className="row">
                                                                        <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                                          Nomor HP {j+1}
                                                                        </Label>
                                                                        <Col sm="8">
                                                                          <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="Contoh: 62823xxxxx"
                                                                            required
                                                                            defaultValue={dt_isi}
                                                                            onChange={(event2) => {
                                                                              let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                                              let get_data = event2.target.value
                                                                              let cek = get_data.substring(0, 2);
                                                                              let set_data = null
                                                                              if(cek === '08'){
                                                                                set_data = get_data.replace("08", "628")
                                                                              }
                                                                              else{
                                                                                set_data = get_data
                                                                              }
                                                                              isi[j] = set_data

                                                                              let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                                              nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                                              
                                                                              this.setState({ 
                                                                                dt: {...this.state.dt, 
                                                                                  nomor_wa_perangkat
                                                                                }
                                                                              })
                                                                            }}
                                                                          />
                                                                        </Col>
                                                                        {j > 0 ? (
                                                                          <Col lg="1">
                                                                            <button
                                                                              className="btn btn-danger"
                                                                              title="Hapus detail petunjuk ini"
                                                                              style={{ padding: '6px 15px' }}
                                                                              onClick={() => {
                                                                                let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                                                isi.splice(j, 1)

                                                                                let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                                                nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                                                
                                                                                this.setState({ 
                                                                                  dt: {...this.state.dt, 
                                                                                    nomor_wa_perangkat
                                                                                  }
                                                                                })
                                                                              }}
                                                                            >-</button>
                                                                          </Col>
                                                                        ) : ''}
                                                                      </FormGroup>
                                                                    </>
                                                                  )
                                                                })}
                                                                <div className="row">
                                                                  <div className="col-sm-12">
                                                                    <button
                                                                      type="button"
                                                                      className="btn btn-info"
                                                                      // disabled={this.state.status.btnForm}
                                                                      onClick={() => {
                                                                        let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                                        isi.push('')
                                                                        let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                                        nomor_wa_perangkat[i].isi = isi
                                                                        this.setState({ 
                                                                          dt: {...this.state.dt,
                                                                            nomor_wa_perangkat
                                                                          }
                                                                        })
                                                                      }}
                                                                    >
                                                                      Tambah No HP Perangkat
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          })}
                                                        </>
                                                      ) : ''}
                                                      </div>
                                                      
                                                      <div className="col-12" align='center'>
                                                        <hr/>
                                                        <h5>Konfigurasi Web Services</h5>
                                                        <hr/>
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b> Provinsi</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="provinsi"
                                                              type="text"
                                                              placeholder="Contoh: Riau, Sumatera Barat, Dll."
                                                              defaultValue={this.state.provinsi}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jenis Kabupaten</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="jenis_kabkota"
                                                              type="text"
                                                              placeholder="Contoh: Kabupaten, Kota, Dll."
                                                              defaultValue={this.state.jenis_kabkota}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b> Kabupaten/Kota</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="kabkota"
                                                              type="text"
                                                              placeholder="Contoh: Pekanbaru, Kampar, Dll."
                                                              defaultValue={this.state.kabkota}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b> Kecamatan</b></Label>
                                                          <Col sm="9">
                                                            <input
                                                              className="form-control"
                                                              id="kecamatan"
                                                              type="text"
                                                              placeholder="Contoh: Sukajadi, Kampar Kiri Hilir, Dll."
                                                              defaultValue={this.state.kecamatan}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jenis Desa</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="jenis_desa"
                                                              type="text"
                                                              placeholder="Contoh: Desa, Kelurahan, Nagari, Dll."
                                                              defaultValue={this.state.jenis_desa}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                            <b>Kelurahan / {JENIS_DESA}</b>
                                                          </Label>
                                                          <Col sm="9">
                                                          <input
                                                              className="form-control"
                                                              id="desa"
                                                              type="text"
                                                              placeholder="Contoh: Kampung Tengah, Sungai Petai, dll."
                                                              defaultValue={this.state.desa}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jenis Dusun</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="jenis_dusun"
                                                              type="text"
                                                              placeholder="Contoh: Dusun, Jorong, Dll."
                                                              defaultValue={this.state.jenis_dusun}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Lokasi Kantor Desa</b></Label>
                                                          <Col sm='9'>
                                                            <MapContainer 
                                                            center={this.state.currentLocation} 
                                                            zoom={12} 
                                                            scrollWheelZoom={false} 
                                                            id="mapabsensi" >
                                                              <TileLayer
                                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                              />
                                                              <Marker
                                                                draggable={true}
                                                                position={this.state.currentLocation}
                                                                eventHandlers={{
                                                                  dragend: (event) => {
                                                                    const lat = event.target._latlng.lat
                                                                    const long = event.target._latlng.lng

                                                                    this.setState({
                                                                      currentLocation: [lat, long],
                                                                      // dt: {
                                                                      //   ...this.state.dt,
                                                                      //   lat,
                                                                      //   long
                                                                      // },
                                                                      lat,
                                                                      long
                                                                    })
                                                                    this.forceUpdate()
                                                                  }
                                                                }}
                                                              >
                                                                {/* <Popup>
                                                                  {this.state.dt.nama}
                                                                </Popup> */}
                                                              </Marker>
                                                            </MapContainer>
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jabatan Tertinggi {JENIS_DESA}</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="jabatan_tertinggi"
                                                              type="text"
                                                              placeholder="Contoh: Kepala Desa, dll."
                                                              defaultValue={this.state.jabatan_tertinggi}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Nama Lengkap Kepala {JENIS_DESA}</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="nama_kades"
                                                              type="text"
                                                              placeholder="Contoh: Bima Sakti, S.I.Kom."
                                                              defaultValue={this.state.nama_kades}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Foto Kepala {JENIS_DESA}</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="foto_kades"
                                                              type="file"
                                                              onChange={this.onFileChange}
                                                              placeholder={"Foto Kepala Kades untuk website " + JENIS_DESA}
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Link Facebook</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="link_fb"
                                                              type="text"
                                                              placeholder="Contoh: www.facebook.com/desasungaipetai"
                                                              defaultValue={this.state.link_fb}
                                                              // required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Link Instagram</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="link_ig"
                                                              type="text"
                                                              placeholder="Contoh: www.instagram.com/desasungaipetai"
                                                              defaultValue={this.state.link_ig}
                                                              // required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Email</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="email"
                                                              type="text"
                                                              placeholder="Contoh: sungaipetai@gmail.com"
                                                              defaultValue={this.state.email}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Alamat Kantor Desa</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="alamat_kantor_desa"
                                                              type="text"
                                                              placeholder="Contoh: Jl. Raya Sungai Pagar KM. 35"
                                                              defaultValue={this.state.alamat_kantor_desa}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Kode Pos</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="kode_pos"
                                                              type="number"
                                                              maxLength='5'
                                                              placeholder="Contoh: 28282"
                                                              defaultValue={this.state.kode_pos}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>URL BO Digidesa</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="url_bo_digidesa"
                                                              type="text"
                                                              placeholder="Contoh: https://demo.digidesa.com"
                                                              defaultValue={this.state.url_bo_digidesa}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>URL WS Marketplace</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="url_marketplace"
                                                              type="text"
                                                              placeholder="Contoh: https://ws-marketplace-desa-prod.digidesa.com"
                                                              defaultValue={this.state.url_marketplace}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Type App</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="type_app"
                                                              type="text"
                                                              placeholder="Contoh: dev, prod"
                                                              defaultValue={this.state.type_app}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Heading Notif</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="heading_notif"
                                                              type="text"
                                                              placeholder="Contoh: Digidesa"
                                                              defaultValue={this.state.heading_notif}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Ukuran Kertas</b></Label>
                                                          <Col sm='9'>
                                                            <Select
                                                              isDisabled={this.state.status.select}
                                                              classNamePrefix="select"
                                                              onChange={(e) => {
                                                                this.changeSelectValue(e, 'ukuran_kertas');
                                                              }}
                                                              defaultValue={this.state.selectedKertas}
                                                              value={this.state.selectedKertas}
                                                              name="Jenis Kelamin"
                                                              options={this.state.frmKertas}
                                                              placeholder="Pilih Ukuran Kertas"
                                                              isClearable
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Nama Kab Kota</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="style_nama_kabkota"
                                                              type="text"
                                                              // placeholder="Contoh: Digidesa"
                                                              defaultValue={this.state.style_nama_kabkota}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Kecamatan</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="style_kecamatan"
                                                              type="text"
                                                              // placeholder="Contoh: Digidesa"
                                                              defaultValue={this.state.style_kecamatan}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Desa</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="style_desa"
                                                              type="text"
                                                              // placeholder="Contoh: Digidesa"
                                                              defaultValue={this.state.style_desa}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Alamat</b></Label>
                                                          <Col sm='9'>
                                                            <input
                                                              className="form-control"
                                                              id="style_alamat"
                                                              type="text"
                                                              // placeholder="Contoh: Digidesa"
                                                              defaultValue={this.state.style_alamat}
                                                              required
                                                            />
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>
                                                      <div className="col-12">
                                                        <FormGroup className="row">
                                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Alamat Foto Kop</b></Label>
                                                          <Col sm='9'>
                                                            <Dropzone
                                                              onDrop={acceptedFiles => this.processGbr(acceptedFiles)}
                                                              maxFiles={1}
                                                            >
                                                              {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                  <div
                                                                    {...getRootProps()}
                                                                    style={{
                                                                      minHeight: "100px",
                                                                      paddingTop: "40px",
                                                                      padding: "5px 5px 5px 5px",
                                                                      border: "1px solid #d2d2d2"
                                                                    }}
                                                                  >
                                                                    <input {...getInputProps()} />
                                                                    <div
                                                                      style={{
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                      }}
                                                                    >
                                                                      <p className="text-center">
                                                                        {this.state.link_gambar ? (
                                                                          <img
                                                                            src={this.state.link_gambar}
                                                                            alt=""
                                                                            style={{
                                                                              minHeight: "50px",
                                                                              minWidth: "50px"
                                                                            }}
                                                                          />
                                                                        ) : (
                                                                          <Label
                                                                            className="col-form-label text-center"
                                                                            htmlFor="inputEmail3"
                                                                          >
                                                                            Drag 'n' drop some files here, or click to
                                                                            select files
                                                                          </Label>
                                                                        )}
                                                                        {this.state.foto_info
                                                                          ? this.state.foto_info
                                                                          : ""}
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </section>
                                                              )}
                                                            </Dropzone>
                                                          </Col>
                                                        </FormGroup>	
                                                      </div>

                                                    </div>
                                                  </div>

                                                  <div className="modal-footer">
                                                    <button type="button" className="btn btn-warning" onClick={this.tutupForm}>
                                                      Batal
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success"
                                                      // disabled={this.state.status.btnFormAcc}
                                                    >
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

export default BasicTable;